import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { hex2rgba } from "../../../shared/utils/utility-functions";

const SelectedOption = ({
  label = "",
  InputItem = <></>,
  selected = false,
  setSelected = () => {},
  elementKey = "",
  padding = "25px",
}) => {
  const theme = useTheme();
  return (
    <FlexContainer
      flexdirection="column"
      alignitems="flex-start"
      justifycontent="flex-start"
      height="100%"
      minwidth="275px"
      width="100%"
      padding={padding}
      background={
        selected
          ? hex2rgba(theme.palette.primary.main, 0.1)
          : "rgba(0, 0, 0, 0.1)"
      }
      border={selected ? `1px solid ${theme.palette.primary.main}` : "none"}
      onClick={(e) => setSelected(elementKey)}
      sx={{
        cursor: "pointer",
        boxShadow: selected ? "0 22px 32px -12px rgba(0, 0, 0, 0.1)" : "none",
        transition: "box-shadow 0.3s ease-in-out",
        flexBasis: "50%",
      }}
    >
      <Typography
        color={selected ? "primary" : "text.hint"}
        fontWeight="bold"
        sx={{ paddingBottom: "10px" }}
      >
        {label}
      </Typography>
      {InputItem}
    </FlexContainer>
  );
};

export default SelectedOption;
