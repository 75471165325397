export const PAYMENTS_BORROWER_ROLE = "payments.borrower";
export const PAYMENTS_SUPPORT_ROLE = "payments.support";
export const CLIENT_ADMIN_ROLE = "client.admin";

export const BORROWER = "Borrower";
export const CLIENT = "Client";
export const CLIENT_ADMIN = "Client Admin";

export const USER_IMPERSONATION_NOT_BORROWER = "User found is not a borrower";
export const USER_IMPERSONATION_NOT_ALLOWED =
  "You are not allowed to impersonate users. Contact your administrator.";
