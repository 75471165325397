import { useContext, useEffect, useState } from "react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, unifiedRequest } from "../../config/msal.config";
import { getBatchConfig } from "../../../data/configAPI";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import {
  CONFIGURATOR_KEYS,
  THEME_BACKGROUND_COLOR_KEY,
  THEME_ERROR_COLOR_KEY,
  THEME_INFO_COLOR_KEY,
  THEME_PAPER_COLOR_KEY,
  THEME_PRIMARY_COLOR_KEY,
  THEME_SECONDARY_COLOR_KEY,
  THEME_SUCCESS_COLOR_KEY,
  THEME_WARNING_COLOR_KEY,
} from "../../constants/Configurator.constants";
import { MISSING_TENANT_MESSAGE } from "../../constants/Defaults.constants";
import { acquireToken } from "../../utils/msal-functions";
import { AuthContext } from "../../../contexts/AuthContext";
import { Typography } from "@mui/material";
import { getLoanData } from "../../../data/loanAPI";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "../../constants/Roles.constants";
import { useNavigate } from "react-router-dom";

const RequireAuth = (props) => {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const {
    edittedFields,
    addBatchOfEdittedFields,
    hasRetrievedConfig,
    setHasRetrievedConfig,
  } = useContext(ConfiguratorContext);
  const {
    token,
    setToken,
    userDetails,
    setUserDetails,
    userRoles,
    setuserRoles,
    setVisibleRole,
    impersonatedUser,
    loans,
    setLoans,
    activeLoan,
    setActiveLoan,
    activeLoanDetails,
    setActiveLoanDetails,
  } = useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const { theme, setTheme } = useContext(ThemeContext);

  const [missingTenant, setMissingTenant] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("_ir")) {
      sessionStorage.removeItem("_ir");
      sessionStorage.removeItem("_hm");
      sessionStorage.removeItem("_m");
      sessionStorage.removeItem("_s");
      sessionStorage.removeItem("_l");
      sessionStorage.removeItem("_bc");
    }
    const urlParams = new URLSearchParams(window.location.search);
    const tenantParam = urlParams.get("co");
    if (tenantParam) {
      sessionStorage.setItem("co", tenantParam);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (inProgress === InteractionStatus.None && !token) {
        if (!userDetails) {
          setUserDetails(accounts[0]);
          setuserRoles(accounts[0].idTokenClaims.roles);
          setVisibleRole(
            accounts[0].idTokenClaims.roles.includes(CLIENT_ADMIN_ROLE)
              ? CLIENT_ADMIN_ROLE
              : accounts[0].idTokenClaims.roles[0],
          );
        }
        const request = {
          scopes: unifiedRequest.scopes,
          extraQueryParameters: loginRequest.extraQueryParameters,
          account: accounts[0],
        };
        acquireToken(
          instance,
          request,
          { checkLoans: false },
          addPendingRequest,
          removePendingRequest,
          setMissingTenant,
          userRoles,
          impersonatedUser,
          true,
          setToken,
        );
      }
    }
    if (!isAuthenticated) {
      if (inProgress === InteractionStatus.None) {
        addPendingRequest();
        if (sessionStorage.getItem("co")) {
          setMissingTenant(false);
          instance
            .loginRedirect(loginRequest)
            .then((data) => {
              console.log(data);
            })
            .catch((e) => {
              console.error(e);
            });
        } else {
          setMissingTenant(true);
          removePendingRequest();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, inProgress, isAuthenticated]);

  useEffect(() => {
    if (!activeLoan && token) {
      const request = {
        scopes: unifiedRequest.scopes,
        extraQueryParameters: loginRequest.extraQueryParameters,
        account: accounts[0],
      };
      const loanInfo = {
        checkLoans: true,
        loans: loans,
        setLoans: setLoans,
        setActiveLoan: setActiveLoan,
      };
      acquireToken(
        instance,
        request,
        loanInfo,
        addPendingRequest,
        removePendingRequest,
        setMissingTenant,
        userRoles,
        impersonatedUser,
      );
    }
    if (edittedFields.length === 0 && token) {
      callConfigApi(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const getLoanDetails = async () => {
      if (
        token &&
        activeLoan &&
        (!activeLoanDetails ||
          (activeLoan !== "no-loan" &&
            activeLoan !== activeLoanDetails?.loanDetails?.loanId))
      ) {
        addPendingRequest();

        const loanDetails = await getLoanData(
          token,
          activeLoan,
          impersonatedUser,
        );
        setActiveLoanDetails(loanDetails);

        removePendingRequest();
      }
    };

    getLoanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, activeLoan]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      if (
        message.eventType === EventType.LOGIN_SUCCESS ||
        message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (accounts[0]?.idTokenClaims?.roles?.includes(CLIENT_ADMIN_ROLE)) {
          navigate("/client-configurations");
          instance.removeEventCallback(callbackId);
        } else if (
          accounts[0]?.idTokenClaims?.roles?.includes(PAYMENTS_SUPPORT_ROLE)
        ) {
          navigate("/support");
          instance.removeEventCallback(callbackId);
        }
      }
    });

    return () => {
      if (callbackId) {
        // Remove Event callback when unmounting
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const callConfigApi = async (token) => {
    addPendingRequest();
    const body = CONFIGURATOR_KEYS.map((item) => {
      return {
        operation: "GET",
        property: {
          key: item,
        },
      };
    });
    const configData = await getBatchConfig(token, body, impersonatedUser);
    const savedData = configData
      .filter((item) => item.httpStatus === "OK")
      .map((item) => {
        const newTheme = { ...theme };
        switch (item.property.key) {
          case THEME_PRIMARY_COLOR_KEY:
            newTheme.palette.primary.main = item.property.value;
            break;
          case THEME_SECONDARY_COLOR_KEY:
            newTheme.palette.secondary.main = item.property.value;
            break;
          case THEME_INFO_COLOR_KEY:
            newTheme.palette.info.main = item.property.value;
            break;
          case THEME_SUCCESS_COLOR_KEY:
            newTheme.palette.success.main = item.property.value;
            break;
          case THEME_WARNING_COLOR_KEY:
            newTheme.palette.warning.main = item.property.value;
            break;
          case THEME_ERROR_COLOR_KEY:
            newTheme.palette.error.main = item.property.value;
            break;
          case THEME_BACKGROUND_COLOR_KEY:
            newTheme.palette.background.default = item.property.value;
            break;
          case THEME_PAPER_COLOR_KEY:
            newTheme.palette.background.paper = item.property.value;
            break;
          default:
            break;
        }
        setTheme(newTheme);
        return {
          key: item.property.key,
          value: item.property.value,
          modified: false,
        };
      });
    addBatchOfEdittedFields(savedData);
    setHasRetrievedConfig(true);
    removePendingRequest();
  };

  return isAuthenticated && hasRetrievedConfig ? (
    props.children
  ) : missingTenant ? (
    <Typography variant="h5" sx={{ padding: "10px" }}>
      {MISSING_TENANT_MESSAGE}
    </Typography>
  ) : (
    ""
  );
};

export default RequireAuth;
