import { useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { ConfiguratorContext } from "../../../../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../../../../shared/components/styled/Container.styled";
import {
  DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY,
  DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_LABEL,
  DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY,
  DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_LABEL,
  DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY,
} from "../../../../../../shared/constants/Configurator.constants";
import { NON_NEGATIVE_AMOUNT_ERROR_MESSAGE } from "../../../../../../shared/constants/Defaults.constants";
import { PAYOFF_REQUEST_REASONS } from "../../../../../../shared/constants/StatementsDocuments.constants";
import { hex2rgba } from "../../../../../../shared/utils/utility-functions";
import InputSetting from "../../../../shared/InputSetting";
import MultipleSelectionSetting from "../../../../shared/MultipleSelectionSetting";

const PayoffRequestPreferences = () => {
  const theme = useTheme();

  const { edittedFields } = useContext(ConfiguratorContext);

  const allowedPayoffRequestReasonsFromConfig = edittedFields.find(
    (item) => item.key === DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY,
  );
  const emailPayoffStatementFeeFromConfig = edittedFields.find(
    (item) => item.key === DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY,
  );
  const mailPayoffStatementFeeFromConfig = edittedFields.find(
    (item) => item.key === DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY,
  );

  const [allowedPayoffRequestReasons, setAllowedPayoffRequestReasons] =
    useState(
      allowedPayoffRequestReasonsFromConfig
        ? allowedPayoffRequestReasonsFromConfig?.value?.length > 0
          ? allowedPayoffRequestReasonsFromConfig.value.split(";")
          : []
        : [...PAYOFF_REQUEST_REASONS],
    );

  const [emailPayoffStatementFee, setEmailPayoffStatementFee] = useState(
    emailPayoffStatementFeeFromConfig?.value || "",
  );
  const [mailPayoffStatementFee, setMailPayoffStatementFee] = useState(
    mailPayoffStatementFeeFromConfig?.value || "",
  );

  const [
    emailPayoffStatementFeeErrorMessage,
    setEmailPayoffStatementFeeErrorMessage,
  ] = useState("");
  const [
    mailPayoffStatementFeeErrorMessage,
    setMailPayoffStatementFeeErrorMessage,
  ] = useState("");

  const onChangeHandler = (e, setter, errorMessageSetter) => {
    setter(e.target.value);
    validateInputValue(e, errorMessageSetter);
  };

  const validateInputValue = (e, errorMessageSetter) => {
    if (+e.target.value < 0) {
      errorMessageSetter(NON_NEGATIVE_AMOUNT_ERROR_MESSAGE);
      return;
    }
    if (e?.target?.validity?.valid === false) {
      errorMessageSetter(e.target.validationMessage);
      return;
    }
    errorMessageSetter("");
  };

  return (
    <FlexContainer
      background={hex2rgba(theme.palette.primary.main, 0.1)}
      padding="25px"
      width="100%"
      flexdirection="column"
      gap="15px"
    >
      <MultipleSelectionSetting
        elementKey={DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY}
        list={PAYOFF_REQUEST_REASONS}
        selection={allowedPayoffRequestReasons}
        setSelection={setAllowedPayoffRequestReasons}
        padding="0"
        listDirection="row"
      />
      <InputSetting
        elementKey={DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY}
        label={DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_LABEL}
        labelFontSize="1rem"
        inputPadding="0"
        padding="0 10px"
        inputValue={emailPayoffStatementFee}
        setInputValue={setEmailPayoffStatementFee}
        onChangeHandler={onChangeHandler}
        validateInputValue={validateInputValue}
        inputPlaceholder="0"
        blurOnWheel
        adornment
        adornmentPosition="start"
        adornmentSymbol="$"
        inputProps={{
          step: 0.01,
          min: 0,
          type: "number",
          style: {
            textAlign: "end",
          },
        }}
        errorMessage={emailPayoffStatementFeeErrorMessage}
        setErrorMessage={setEmailPayoffStatementFeeErrorMessage}
      />
      <InputSetting
        elementKey={DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY}
        label={DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_LABEL}
        labelFontSize="1rem"
        inputPadding="0"
        padding="0 10px"
        inputValue={mailPayoffStatementFee}
        setInputValue={setMailPayoffStatementFee}
        onChangeHandler={onChangeHandler}
        validateInputValue={validateInputValue}
        inputPlaceholder="0"
        blurOnWheel
        adornment
        adornmentPosition="start"
        adornmentSymbol="$"
        inputProps={{
          step: 0.01,
          min: 0,
          type: "number",
          style: {
            textAlign: "end",
          },
        }}
        errorMessage={mailPayoffStatementFeeErrorMessage}
        setErrorMessage={setMailPayoffStatementFeeErrorMessage}
      />
    </FlexContainer>
  );
};

export default PayoffRequestPreferences;
