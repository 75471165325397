import { ConfigBatchReadResponse } from "../mocks/Config.mocks";
import { API_BASE } from "../shared/config/config";

export async function getBatchConfig(accessToken, body, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(ConfigBatchReadResponse));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/properties/batch/read`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function postBatchConfig(accessToken, body, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/properties/batch/write`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function downloadFile(accessToken, id, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${API_BASE}/files/download/${id}`, options)
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
}

export async function uploadFile(accessToken, body, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const formData = new FormData();

    formData.append("id", body.id);
    formData.append("file", body.file);

    const options = {
      method: "POST",
      headers: headers,
      body: formData,
    };

    return fetch(`${API_BASE}/files/upload/`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}
