import {
  Chip,
  ClickAwayListener,
  Fab,
  Fade,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  AbsoluteContainer,
  Image,
  FlexContainer,
  RelativeContainer,
} from "../styled/Container.styled";
import { HiddenInput } from "../styled/Form.styled";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { downloadFile } from "../../../data/configAPI";
import { PillButton } from "../styled/Button.styled";
import ImageGuidelinesTooltip from "./ImageGuidelinesTooltip";
import { CLIENT_ADMIN_ROLE } from "../../constants/Roles.constants";
import { UPLOAD_SIZE_EXCEEDED } from "../../constants/StatementsDocuments.constants";
import ActionSnackbar from "../ActionSnackbar";

const MarketingBanner = ({
  elementKey = "",
  hyperlinkElementKey = "",
  size = 5,
}) => {
  const theme = useTheme();
  const { token, userRoles, visibleRole, impersonatedUser } =
    useContext(AuthContext);
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [renderedImage, setRenderedImage] = useState();

  const [hyperlinkPopupOpen, setHyperlinkPopupOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [formImageURL, setFormImageURL] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const getBannerData = async () => {
      if (token) {
        const currentBannerURL = edittedFields.find(
          (item) => item.key === hyperlinkElementKey,
        );
        if (currentBannerURL) {
          setImageURL(currentBannerURL.value);
          setFormImageURL(currentBannerURL.value);
        }

        const currentBannerImage = edittedFields.find(
          (item) => item.key === elementKey,
        );
        if (currentBannerImage) {
          setSelectedFile(currentBannerImage.value);
        } else {
          callImageApi(token);
        }
      }
    };
    getBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!selectedFile) {
      setRenderedImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setRenderedImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const callImageApi = async (token) => {
    const imageData = await downloadFile(token, elementKey, impersonatedUser);
    if (imageData?.type?.includes("image")) {
      addNewEdittedField({
        key: elementKey,
        value: imageData,
        modified: false,
      });
      setSelectedFile(imageData);
    }
  };

  const uploadBanner = (event) => {
    const newFile = event?.target?.files?.[0];
    if (newFile) {
      if (newFile.size <= size * 1e6) {
        addNewEdittedField({
          key: elementKey,
          value: event.target.files[0],
          modified: true,
        });
        setSelectedFile(event?.target?.files?.[0]);
      } else {
        setSnackbarMessage(UPLOAD_SIZE_EXCEEDED(size));
        setSnackbarOpen(true);
      }
    }
  };

  const openHyperlinkForm = (e) => {
    setAnchorElement(e.currentTarget);
    setHyperlinkPopupOpen(true);
  };

  const handleHyperlinkFormClose = (e) => {
    if (anchorElement.current && anchorElement.current.contains(e.target)) {
      return;
    }

    setHyperlinkPopupOpen(false);
  };

  const submitImageHyperLinkForm = (e) => {
    if (e.key === "Enter") {
      addImageHyperlink();
    }
  };

  const addImageHyperlink = () => {
    setHyperlinkPopupOpen(false);
    addNewEdittedField({
      key: hyperlinkElementKey,
      value: formImageURL,
      modified: true,
    });
    setImageURL(formImageURL);
  };

  const bannerAction = () => {
    if (imageURL) {
      const nativeWindow = window.open(
        `${imageURL.substring(0, 4) !== "http" ? "http://" : ""}${imageURL}`,
        "_blank",
      );
      nativeWindow.focus();
    }
  };

  return selectedFile ||
    (userRoles?.includes(CLIENT_ADMIN_ROLE) &&
      visibleRole === CLIENT_ADMIN_ROLE) ? (
    <>
      <RelativeContainer
        width="100%"
        border={`1px solid ${theme.palette.text.hint}`}
        aspectratio="10/2"
      >
        {selectedFile ? (
          <Image
            src={renderedImage}
            onClick={(e) => bannerAction()}
            sx={{ cursor: imageURL ? "pointer" : "default" }}
            alt="banner"
          />
        ) : (
          <FlexContainer
            flexdirection="column"
            height="100%"
            width="100%"
            gap="5px"
            justifycontent="center"
            alignitems="center"
          >
            <Typography fontSize={20}>Banner Image guidelines</Typography>
            <Typography>
              Recommended ratio: <b>10:2</b>
            </Typography>
            <Typography>
              Maximum file size: <b>5MB</b>
            </Typography>
            <Typography>
              Extensions allowed: <b>APNG, AVIF, GIF, JPEG, PNG, SVG ,WEBP</b>
            </Typography>
          </FlexContainer>
        )}
        {userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE ? (
          <>
            <AbsoluteContainer sx={{ top: -10, right: -35, zIndex: 4 }}>
              <Tooltip title="Select Banner Image" placement="left">
                <label htmlFor="icon-button-file">
                  <HiddenInput
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    onChange={uploadBanner}
                    value=""
                  />
                  <Fab
                    color="primary"
                    size="small"
                    sx={{ width: 30, height: 30, minHeight: 30 }}
                    component="span"
                  >
                    <FileUploadRoundedIcon />
                  </Fab>
                </label>
              </Tooltip>
            </AbsoluteContainer>
            {selectedFile && (
              <>
                <AbsoluteContainer sx={{ top: -10, left: -25, zIndex: 4 }}>
                  <ImageGuidelinesTooltip ratio="10:2" placement="right" />
                </AbsoluteContainer>
                <AbsoluteContainer sx={{ top: 25, right: -35, zIndex: 4 }}>
                  <Tooltip title="Add Redirect URL" placement="left">
                    <Fab
                      color="secondary"
                      size="small"
                      sx={{ width: 30, height: 30, minHeight: 30 }}
                      onClick={openHyperlinkForm}
                    >
                      <LinkIcon />
                    </Fab>
                  </Tooltip>
                  <Popper
                    open={hyperlinkPopupOpen}
                    anchorEl={anchorElement}
                    placement="bottom-start"
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ padding: 2 }}>
                          <ClickAwayListener
                            onClickAway={handleHyperlinkFormClose}
                          >
                            <Stack spacing={2}>
                              <TextField
                                value={formImageURL}
                                onChange={(e) =>
                                  setFormImageURL(e.target.value)
                                }
                                onKeyUp={submitImageHyperLinkForm}
                                label="Link Address (URL)"
                                variant="standard"
                              />
                              <PillButton
                                variant="contained"
                                onClick={addImageHyperlink}
                                onKeyUp={submitImageHyperLinkForm}
                              >
                                Add
                              </PillButton>
                            </Stack>
                          </ClickAwayListener>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </AbsoluteContainer>
                <AbsoluteContainer sx={{ bottom: 5, right: 5, zIndex: 4 }}>
                  <Chip
                    color={imageURL ? "success" : "error"}
                    size="small"
                    sx={{
                      backgroundColor: imageURL
                        ? theme.palette.success.light
                        : theme.palette.error.light,
                    }}
                    label={
                      imageURL ? (
                        <LinkIcon
                          fontSize="small"
                          sx={{ verticalAlign: "bottom" }}
                        />
                      ) : (
                        <LinkOffIcon
                          fontSize="small"
                          sx={{ verticalAlign: "bottom" }}
                        />
                      )
                    }
                  />
                </AbsoluteContainer>
              </>
            )}
          </>
        ) : (
          ""
        )}
      </RelativeContainer>
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={() => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </>
  ) : (
    ""
  );
};

export default MarketingBanner;
