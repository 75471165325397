import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PillButton } from "./styled/Button.styled";

export default function ConfirmationDialog({
  open = false,
  dismiss = () => {},
  close = () => {},
  title = "",
  body = "",
  hasDismissButton = true,
  hasActions = true,
  hasTitle = true,
  hasComponent = false,
  dismissButtonLabel = "Cancel",
  confirmButtonLabel = "Confirm",
  closeButtonDisabled = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={dismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xl"}
    >
      {hasTitle ? (
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      ) : null}
      <DialogContent>
        {hasComponent ? (
          body
        ) : (
          <DialogContentText id="alert-dialog-description" marginTop={"10px"}>
            {body}
          </DialogContentText>
        )}
      </DialogContent>
      {hasActions ? (
        <DialogActions>
          {hasDismissButton ? (
            <PillButton variant="outlined" onClick={dismiss}>
              {dismissButtonLabel}
            </PillButton>
          ) : (
            ""
          )}
          <PillButton
            color="primary"
            variant="contained"
            onClick={close}
            disabled={closeButtonDisabled}
          >
            {confirmButtonLabel}
          </PillButton>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
