import { API_BASE } from "../shared/config/config";

export async function getDocumentsByType(
  accessToken,
  loanId,
  documentType,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve) => resolve([]));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    const queryParamString = `?documentType=${documentType}&loanId=${loanId}`;

    return fetch(`${API_BASE}/documents${queryParamString}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function downloadDocument(
  accessToken,
  loanId,
  documentId,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve) => resolve([]));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    const queryParamString = `?documentId=${documentId}&loanId=${loanId}`;
    let filename = "";

    return fetch(`${API_BASE}/documents/download${queryParamString}`, options)
      .then((response) => {
        const disposition = response.headers.get("content-disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        return response.blob();
      })
      .then((blob) => {
        if (window && document) {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => console.log(error));
  }
}

export async function viewDocument(
  accessToken,
  loanId,
  documentId,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve) => resolve([]));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    const queryParamString = `?documentId=${documentId}&loanId=${loanId}`;
    let filename = "";

    return fetch(`${API_BASE}/documents/download${queryParamString}`, options)
      .then((response) => {
        const disposition = response.headers.get("content-disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        return response.blob();
      })
      .then((blob) => {
        if (window) {
          const href = window.URL.createObjectURL(blob);
          const pdfWindow = window.open();
          pdfWindow.document.title = filename;
          pdfWindow.location.href = href;
        }
      })
      .catch((error) => console.log(error));
  }
}

export async function uploadDocument(
  accessToken,
  loanId,
  documentName,
  file,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, _) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const body = new FormData();
    body.append("file", file, file.name);

    const options = {
      method: "POST",
      headers: headers,
      body: body,
    };

    const queryParamString = `?loanId=${loanId}&documentName=${documentName}`;

    return fetch(`${API_BASE}/documents/upload${queryParamString}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function requestPayoffQuote(
  accessToken,
  loanId,
  requestBody,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, _) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    };

    return fetch(`${API_BASE}/loans/${loanId}/payOffRequest`, options)
      .then((response) => response)
      .catch((error) => console.log(error));
  }
}
