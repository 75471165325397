import { Dialog, DialogContent, DialogTitle, Slide } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Image } from "../../../shared/components/styled/Container.styled";
import React, { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  ADVERT_HYPERLINK_KEY,
  ADVERT_IMAGE_KEY,
  ADVERT_MESSAGE_KEY,
} from "../../../shared/constants/Configurator.constants";
import { downloadFile } from "../../../data/configAPI";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginAdvertPopup = ({ open = false, setOpen = () => {}}) => {

  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);
  const { token } =
    useContext(AuthContext);
  const [imageAdvert, setImageAdvert] = useState(undefined);
  const [renderedImageAdvert, setRenderedImageAdvert] =
    useState(undefined);
  const [messageAdvert, setMessageAdvert] = useState(undefined);
  const [hyperlinkAdvert, setHyperlinkAdvert] = useState(undefined);
  const [imageAdvertRequestLoading, setImageAdvertRequestLoading] =
    useState(false);

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
  }, [edittedFields]);

  useEffect(() => {
    const getAdvertData = async () => {
      if (token) {
        const currentAdvertImage = edittedFields.find(
          (item) => item.key === ADVERT_IMAGE_KEY,
        );
        if (currentAdvertImage) {
          setImageAdvert(currentAdvertImage.value);
        } else {
          if (!imageAdvertRequestLoading) {
            callImageApi(token);
          }
        }
      }
    };
    getAdvertData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, edittedFields]);

  useEffect(() => {
    if (!imageAdvert) {
      setRenderedImageAdvert(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(imageAdvert);
    setRenderedImageAdvert(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageAdvert]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case ADVERT_IMAGE_KEY:
          setImageAdvert(item.value);
          return;
        case ADVERT_MESSAGE_KEY:
          setMessageAdvert(item.value);
          return;
        case ADVERT_HYPERLINK_KEY:
          setHyperlinkAdvert(item.value);
          return;
        default:
          return;
      }
    });
  };

  const callImageApi = async (token) => {
    setImageAdvertRequestLoading(true);
    const imageData = await downloadFile(token, ADVERT_IMAGE_KEY);
    if (imageData?.type?.includes("image")) {
      addNewEdittedField({
        key: ADVERT_IMAGE_KEY,
        value: imageData,
        modified: false,
      });
      setImageAdvert(imageData);
    }
    setImageAdvertRequestLoading(false);
  };

  const handleCloseLoginAdvert = () => {
    setOpen(false);
  };

  return (
    <>
    {messageAdvert !== "" || imageAdvert != null ? (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseLoginAdvert}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: `${messageAdvert !== "" ? "space-between" : "flex-end"}`,
            }}
          >
            {hyperlinkAdvert !== "" ? (
              <a
                href={hyperlinkAdvert}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                {messageAdvert}
              </a>
            ) : (
              messageAdvert
            )}

            <CloseOutlinedIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={handleCloseLoginAdvert}
            />
          </DialogTitle>
          <DialogContent>
            {hyperlinkAdvert !== "" ? (
              <a href={hyperlinkAdvert} target="_blank" rel="noreferrer">
                <Image src={renderedImageAdvert} alt="Image Advert" />
              </a>
            ) : (
              <Image src={renderedImageAdvert} alt="Image Advert" />
            )}
          </DialogContent>
        </Dialog>
      ) : ("")}
    </>
  )
}

export default LoginAdvertPopup;