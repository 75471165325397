import React, { useContext } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { FlexContainer, PillContainer } from "../styled/Container.styled";
import { hex2rgba } from "../../utils/utility-functions";
import { determineRoleLabel } from "../../utils/msal-functions";
import { AuthContext } from "../../../contexts/AuthContext";

function UserManagementCell({
  cellAttribute,
  cellData,
  children,
  rowInfo,
  cellLogicCallbacks,
}) {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);

  const renderInputItem = () => {
    if (cellAttribute === "roles") {
      if (rowInfo.isEditing) {
        return (
          <FlexContainer>
            <FormControl variant="standard" sx={{ width: "125px", margin: 0 }}>
              <Select
                value={rowInfo.newRole}
                onChange={(e) => cellLogicCallbacks.setRole(e.target.value)}
                sx={{ minWidth: "125px", textAlign: "start" }}
                MenuProps={{
                  sx: { maxHeight: 350 },
                }}
              >
                {rowInfo.roles.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              color="error"
              size="small"
              onClick={cellLogicCallbacks.cancelChangeRole}
            >
              <CloseIcon sx={{ fontSize: 12 }} />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={cellLogicCallbacks.changeRole}
            >
              <CheckIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </FlexContainer>
        );
      } else {
        return (
          <PillContainer background={hex2rgba(theme.palette.primary.main, 0.2)}>
            {determineRoleLabel(rowInfo.role)}
          </PillContainer>
        );
      }
    }
    if (cellAttribute === "actions") {
      if (
        rowInfo.email.toLowerCase() !==
        userDetails?.idTokenClaims?.signInName?.toLowerCase()
      ) {
        return (
          <>
            <IconButton onClick={cellLogicCallbacks.openPopper}>
              <MoreHorizIcon />
            </IconButton>
            <Popper
              open={rowInfo.isMoreActionPopperOpen}
              anchorEl={rowInfo.anchorEl}
              placement="bottom-end"
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <ClickAwayListener
                      onClickAway={cellLogicCallbacks.handlePopperClose}
                    >
                      <Stack>
                        <MenuItem onClick={cellLogicCallbacks.toggleRoleEdit}>
                          <ListItemIcon>
                            <FaceOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Change Role</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={cellLogicCallbacks.deleteUser}>
                          <ListItemIcon>
                            <DeleteOutlinedIcon
                              color="error"
                              fontSize="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: theme.palette.error.main }}
                          >
                            Delete User
                          </ListItemText>
                        </MenuItem>
                      </Stack>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </>
        );
      } else {
        return <></>;
      }
    }
    return <>{cellData}</>;
  };
  return renderInputItem();
}

export default UserManagementCell;
