import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { useIsMount } from "../../../shared/hooks/useIsMount";

const MultipleSelectionSetting = ({
  elementKey = "",
  list = [],
  selection = [],
  setSelection = () => {},
  background = "transparent",
  padding = "25px",
  listDirection = "column",
  sx = {},
}) => {
  const isFirstRender = useIsMount();
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  useEffect(() => {
    if (!isFirstRender) {
      addNewEdittedField({
        key: elementKey,
        value: selection.join(";"),
        modified: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelection([...list]);
      return;
    }
    setSelection([]);
  };

  const handleClick = (event, item) => {
    const selectedIndex = selection.indexOf(item);
    let newSelection = [];

    if (selectedIndex === -1) {
      newSelection = newSelection.concat(selection, item);
    } else if (selectedIndex === 0) {
      newSelection = newSelection.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelection = newSelection.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelection = newSelection.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1),
      );
    }

    setSelection(newSelection);
  };

  return (
    <FlexContainer
      background={background}
      padding={padding}
      width="100%"
      flexdirection="column"
      sx={sx}
    >
      <FlexContainer
        width="100%"
        justifycontent="flex-start"
        gap="10px"
        padding="0 0 15px 0"
      >
        <Checkbox
          color="primary"
          indeterminate={selection.length > 0 && selection.length < list.length}
          checked={list.length > 0 && selection.length === list.length}
          onChange={handleSelectAllClick}
          inputProps={{
            "aria-label": "select multiple types",
          }}
        />
        <Typography fontWeight="bold" fontSize={16} color="inherit">
          {selection.length} selected
        </Typography>
      </FlexContainer>
      <FlexContainer
        width="100%"
        gap="10px"
        flexwrap="wrap"
        flexdirection={listDirection}
        justifycontent="flex-start"
        alignitems="flex-start"
        sx={{ maxHeight: "calc(100% - 54px)" }}
      >
        {list.map((item) => (
          <FormControlLabel
            key={`checkbox-${item}`}
            control={
              <Checkbox
                checked={selection.includes(item)}
                onChange={(e) => {
                  handleClick(e, item);
                }}
              />
            }
            label={
              <Typography
                fontWeight={selection.includes(item) ? "bold" : "normal"}
              >
                {item}
              </Typography>
            }
            sx={{ margin: 0 }}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default MultipleSelectionSetting;
