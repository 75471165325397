import React, { useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PillButton } from "../../shared/components/styled/Button.styled";
import { FlexContainer } from "../../shared/components/styled/Container.styled";
import { LogoNav } from "../../shared/components/styled/Nav.styled";
import Logo from "../../shared/components/Logo";
import {
  LOGIN_BUTTON_LABEL,
  LOGOUT_LOGIN_HELP_MESSAGE,
  LOGOUT_MESSAGE,
} from "../../shared/constants/Login.constants";
import { hex2rgba } from "../../shared/utils/utility-functions";

const Logout = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const hasMarketingMessage = sessionStorage.getItem("_hm");
  const logoutMessage = sessionStorage.getItem("_m");
  const logoutSubtitle = sessionStorage.getItem("_s");
  const logoutLogo = sessionStorage.getItem("_l");
  const backgroundColor = sessionStorage.getItem("_bc");

  useEffect(() => {
    if (sessionStorage.getItem("_ir")) {
      sessionStorage.removeItem("_ir");
      sessionStorage.removeItem("_hm");
      sessionStorage.removeItem("_m");
      sessionStorage.removeItem("_s");
      sessionStorage.removeItem("_l");
      sessionStorage.removeItem("_bc");
      navigate("/", { replace: true });
    } else {
      sessionStorage.setItem("_ir", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      navigate("/", { replace: true });
    }
  }, [inProgress, isAuthenticated, navigate]);

  const triggerAuthGuard = () => {
    navigate("/", { replace: true });
  };

  return (
    <FlexContainer
      padding="25px 0"
      position="absolute"
      width="100%"
      height="100%"
      borderradius="0"
      background={backgroundColor}
    >
      <FlexContainer
        width="100%"
        height="100%"
        alignitems="flex-start"
        flexdirection="column"
        padding="0 25px"
      >
        <LogoNav>
          <Logo height="auto" src={logoutLogo || "/MB-no-padding-white.png"} />
        </LogoNav>
        <FlexContainer
          flexdirection="column"
          alignitems="flex-start"
          height="100%"
          width="100%"
          padding="25px"
        >
          {hasMarketingMessage === "true" ? (
            <>
              <Typography
                variant="h1"
                component="h1"
                color="secondary.contrastText"
                fontSize="3rem"
              >
                {logoutMessage}
              </Typography>
              <Typography
                variant="subtitle2"
                color={hex2rgba(theme.palette.secondary.contrastText, 0.6)}
              >
                {logoutSubtitle}
              </Typography>
            </>
          ) : null}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer padding="50px 25px" width="100%" height="100%">
        <FlexContainer
          flexdirection="column"
          padding="50px 25px"
          gap="25px"
          background="#fff"
          width="400px"
        >
          <Typography variant="h4">{LOGOUT_MESSAGE}</Typography>
          <Typography>{LOGOUT_LOGIN_HELP_MESSAGE}</Typography>
          <PillButton
            variant="contained"
            color="primary"
            width="100%"
            onClick={() => triggerAuthGuard()}
          >
            {LOGIN_BUTTON_LABEL}
          </PillButton>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Logout;
