import React, { useEffect, useRef } from "react";
import { Input, InputAdornment, Typography } from "@mui/material";
import { FlexContainer } from "../styled/Container.styled";

const FormItemInput = ({
  elementWidth = "200px",
  wrapperPadding = "0",
  setRef = () => {},
  value = "",
  setValue = () => {},
  placeholder = "",
  blurHandler = () => {},
  blurOnWheel = false,
  adornment = false,
  adornmentPosition = "start",
  adornmentSymbol = "$",
  inputProps = "",
  disabled = false,
  errorMessage = "",
  onKeyUp = () => {},
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setRef(ref);
    }
  }, [ref, setRef]);

  return (
    <FlexContainer
      flexdirection="column"
      alignitems="flex-end"
      width={elementWidth}
      padding={wrapperPadding}
    >
      <Input
        inputRef={ref}
        variant="standard"
        value={value}
        placeholder={placeholder}
        onChange={setValue}
        onBlur={blurHandler}
        onWheel={(e) => (blurOnWheel ? e.target.blur() : {})}
        startAdornment={
          adornment ? (
            <InputAdornment position={adornmentPosition}>
              {adornmentSymbol}
            </InputAdornment>
          ) : (
            ""
          )
        }
        error={!!errorMessage}
        inputProps={inputProps}
        sx={{
          minWidth: elementWidth.includes("%") ? "100%" : elementWidth,
        }}
        disabled={disabled}
        onKeyUp={onKeyUp}
      />
      <Typography
        variant="caption"
        color="error"
        maxWidth={elementWidth.includes("%") ? "100%" : elementWidth}
        textAlign="end"
      >
        {errorMessage}
      </Typography>
    </FlexContainer>
  );
};

export default FormItemInput;
