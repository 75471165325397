import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { useMsal } from "@azure/msal-react";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import { findElementIncludedInArray } from "../../../shared/utils/utility-functions";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import EditableText from "../../../shared/components/configurator/EditableText";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import { CHANGE_ADDRESS_PAGE_DESCRIPTION_KEY } from "../../../shared/constants/Configurator.constants";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import ChangeAddressForm from "./components/ChangeAddressForm";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import { putChangedAddress } from "../../../data/loanAPI";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import { NO_LOAN_REGISTERED_WARNING_MESSAGE } from "../../../shared/constants/UserProfile.constants";
import {
  CHANGE_ADDRESS_REQUEST_FAILED_MESSAGE,
  CHANGE_ADDRESS_REQUEST_SUCCEEDED_MESSAGE,
} from "../../../shared/constants/Notifications.constants";

const ChangeAddress = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { instance, accounts } = useMsal();
  const {
    userRoles,
    visibleRole,
    token,
    activeLoan,
    activeLoanDetails,
    impersonatedUser,
  } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);

  const [description, setDescription] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  //Form Inputs States
  const [address, setAddress] = useState("");
  const [addressAdditionalDetails, setAddressAdditionalDetails] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("");

  //Snackbar States
  const [noLoanSnackbarOpen, setNoLoanSnackbarOpen] = useState(false);
  const [confirmationSnackbarSeverity, setConfirmationSnackbarSeverity] =
    useState("success");
  const [confirmationSnackbarOpen, setConfirmationSnackbarOpen] =
    useState(false);
  const [confirmationSnackbarMessage, setConfirmationSnackbarMessage] =
    useState("");

  useEffect(() => {
    if (
      token &&
      (activeLoan === "no-loan" ||
        activeLoan === activeLoanDetails?.loanDetails?.loanId)
    ) {
      fillConfiguratorFields(edittedFields);
      if (activeLoan === "no-loan") {
        setNoLoanSnackbarOpen(true);
      } else {
        setNoLoanSnackbarOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance, token, activeLoan, activeLoanDetails]);

  useEffect(() => {
    const mandatoryFields = [address, city, state, zipCode, phone];
    if (activeLoanDetails) {
      setSubmitDisabled(
        mandatoryFields.some((item) => item === "") ||
          zipCodeErrorMessage !== "",
      );
    } else {
      setSubmitDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    addressAdditionalDetails,
    city,
    state,
    zipCode,
    phone,
    zipCodeErrorMessage,
  ]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case CHANGE_ADDRESS_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        default:
          return;
      }
    });
  };

  const buildChangeAddressRequestBody = () => {
    return {
      addressLine1: address,
      addressLine2: addressAdditionalDetails,
      city: city,
      state: state,
      postalCode: zipCode,
      phoneNumber: phone,
    };
  };

  const handleConfirmationClose = async () => {
    const requestBody = buildChangeAddressRequestBody();
    const response = await putChangedAddress(
      token,
      activeLoanDetails?.loanDetails?.loanId,
      requestBody,
    );
    if (response.success) {
      setConfirmationSnackbarOpen(true);
      setConfirmationSnackbarMessage(CHANGE_ADDRESS_REQUEST_SUCCEEDED_MESSAGE);
      setConfirmationSnackbarSeverity("success");
    } else {
      setConfirmationSnackbarOpen(true);
      setConfirmationSnackbarMessage(CHANGE_ADDRESS_REQUEST_FAILED_MESSAGE);
      setConfirmationSnackbarSeverity("error");
    }
    setConfirmationOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNoLoanSnackbarOpen(false);
  };

  return (
    <FlexContainer flexdirection="column" alignitems="center" width="100%">
      <SectionTitle variant="h2" color="primary">
        Change of Address Request
      </SectionTitle>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={CHANGE_ADDRESS_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            CHANGE_ADDRESS_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <ChangeAddressForm
        address={address}
        setAddress={setAddress}
        addressAdditionalDetails={addressAdditionalDetails}
        setAddressAdditionalDetails={setAddressAdditionalDetails}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zipCode={zipCode}
        setZipCode={setZipCode}
        zipCodeErrorMessage={zipCodeErrorMessage}
        setZipCodeErrorMessage={setZipCodeErrorMessage}
        phone={phone}
        setPhone={setPhone}
      ></ChangeAddressForm>
      <PillButton
        color="primary"
        variant="contained"
        width="33%"
        customfontsize="1.25rem"
        onClick={(e) => setConfirmationOpen(true)}
        disabled={
          submitDisabled ||
          !!impersonatedUser ||
          userRoles?.includes(CLIENT_ADMIN_ROLE)
        }
      >
        Submit request
      </PillButton>
      <ConfirmationDialog
        open={confirmationOpen}
        close={handleConfirmationClose}
        dismiss={(e) => setConfirmationOpen(false)}
        title="Confirm update"
        body="Do you want to save these communication preferences?"
      />
      <ActionSnackbar
        severity="warning"
        borderColor={theme.palette.warning.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={noLoanSnackbarOpen}
        closeFn={handleSnackbarClose}
        snackbarLabel={NO_LOAN_REGISTERED_WARNING_MESSAGE}
        hasAction={true}
        actionLabel="Register Loan"
        actionFn={() => navigate("/profile/loan")}
      />
      <ActionSnackbar
        severity={confirmationSnackbarSeverity}
        borderColor={theme.palette[confirmationSnackbarSeverity].main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={confirmationSnackbarOpen}
        closeFn={() => {
          setConfirmationSnackbarOpen(false);
        }}
        snackbarLabel={confirmationSnackbarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </FlexContainer>
  );
};

export default ChangeAddress;
