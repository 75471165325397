import React, { useContext, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import { NOTIFICATION_CHANGE_NOT_ALLOWED } from "../../../../shared/constants/UserProfile.constants";
import { BorderedTypography } from "../../../../shared/components/styled/Form.styled";
import { AuthContext } from "../../../../contexts/AuthContext";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
} from "../../../../shared/constants/Roles.constants";

const NotificationsPreferencesOption = ({
  checked = false,
  typeLabel,
  handleChange = () => {},
  communicationMethod,
  setCommunicationMethod = () => {},
  isDisabled = true,
  allowedOptions = 3,
}) => {
  const { edittedFields } = useContext(ConfiguratorContext);
  const { impersonatedUser, userRoles } = useContext(AuthContext);
  const { accounts, instance } = useMsal();

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance]);

  useEffect(() => {
    if (checked) {
      if (allowedOptions === 1) {
        setCommunicationMethod("sms");
      } else if (allowedOptions === 2) {
        setCommunicationMethod("email");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedOptions, checked]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        default:
          return;
      }
    });
  };

  return (
    <FlexContainer justifycontent="space-between" width="100%" gap="10px">
      <FlexContainer justifycontent="flex-start" width="70%" gap="10px">
        <Tooltip
          title={
            !impersonatedUser &&
            userRoles?.includes(PAYMENTS_BORROWER_ROLE) &&
            isDisabled
              ? NOTIFICATION_CHANGE_NOT_ALLOWED
              : ""
          }
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  disabled={
                    isDisabled ||
                    !!impersonatedUser ||
                    userRoles?.includes(CLIENT_ADMIN_ROLE)
                  }
                />
              }
              label={typeLabel}
            />
          </FormGroup>
        </Tooltip>
      </FlexContainer>
      <FlexContainer justifycontent="flex-start" width="30%" gap="10px">
        <Tooltip
          title={
            !impersonatedUser &&
            userRoles?.includes(PAYMENTS_BORROWER_ROLE) &&
            isDisabled
              ? NOTIFICATION_CHANGE_NOT_ALLOWED
              : ""
          }
        >
          {!checked || allowedOptions === 3 ? (
            <FormControl variant="standard" fullWidth>
              <Select
                fullWidth
                value={communicationMethod}
                onChange={(e) => setCommunicationMethod(e.target.value)}
                disabled={
                  isDisabled ||
                  !checked ||
                  !!impersonatedUser ||
                  userRoles?.includes(CLIENT_ADMIN_ROLE)
                }
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="sms">Text/SMS</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <BorderedTypography>
              {allowedOptions === 1 ? "Text/SMS" : "Email"}
            </BorderedTypography>
          )}
        </Tooltip>
      </FlexContainer>
    </FlexContainer>
  );
};

export default NotificationsPreferencesOption;
