import { useIsAuthenticated } from "@azure/msal-react";
import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeContext } from "./contexts/ThemeContext";
import { ConfiguratorContext } from "./contexts/ConfiguratorContext";
import { AuthContext } from "./contexts/AuthContext";
import ProtectedRoute from "./shared/components/guards/ProtectedRoute";
import Dashboard from "./modules/dashboard/Dashboard";
import Logout from "./modules/logout/Logout";
import NewLoan from "./modules/profile/new-loan/NewLoan";
import NewPayment from "./modules/payments/new-payment/NewPayment";
import PaymentActivity from "./modules/payments/payment-activity/PaymentActivity";
import NewPaymentMethod from "./modules/payments/payment-methods/new-payment-method/NewPaymentMethod";
import PaymentMethods from "./modules/payments/payment-methods/PaymentMethods";
import Payments from "./modules/payments/Payments";
import UserProfile from "./modules/profile/UserProfile";
import StatementsAndDocuments from "./modules/statements-documents/StatementsAndDocuments";
import ClientConfigurations from "./modules/client-config/ClientConfig";
import Navbar from "./shared/components/Navbar";
import VerifyPaymentMethod from "./modules/payments/payment-methods/verify-payment-method/VerifyPaymentMethod";
import Footer from "./shared/components/Footer";
import TaxDocuments from "./modules/statements-documents/tax-documents/TaxDocuments";
import OtherDocuments from "./modules/statements-documents/other-documents/OtherDocuments";
import CommunicationPreferences from "./modules/profile/communication-preferences/CommunicationPreferences";
import ChangeAddress from "./modules/profile/change-address/ChangeAddress";
import RequestPayoffQuote from "./modules/statements-documents/request-payoff-quote/RequestPayoffQuote";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "./shared/constants/Roles.constants";
import UploadDocuments from "./modules/statements-documents/upload/UploadDocuments";
import ScrollToTop from "./shared/components/ScrollToTop";
import {
  DOCUMENTS_ALLOW_ACCESS_KEY,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  DOCUMENTS_ALLOW_UPLOAD_KEY,
} from "./shared/constants/Configurator.constants";
import { findInArray, isAllowed } from "./shared/utils/utility-functions";
import StatementDelivery from "./modules/profile/statement-delivery/StatementDelivery";
import Support from "./modules/support/Support";

const RouteConfig = () => {
  const isAuthenticated = useIsAuthenticated();

  const { requestPendingCount } = useContext(ThemeContext);
  const { edittedFields, hasRetrievedConfig } = useContext(ConfiguratorContext);
  const { userRoles, impersonatedUser } = useContext(AuthContext);

  const isBorrower =
    userRoles?.includes(PAYMENTS_BORROWER_ROLE) ||
    userRoles?.includes(CLIENT_ADMIN_ROLE) ||
    impersonatedUser;

  const configuratorDocumentsAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_ACCESS_KEY,
  );
  const configuratorDocumentsUploadAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_UPLOAD_KEY,
  );
  const configuratorPayoffQuoteAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  );

  const canViewDocuments = isAllowed(
    configuratorDocumentsAllowField,
    "value",
    "true",
    true,
  );
  const canUploadDocuments = isAllowed(
    configuratorDocumentsUploadAllowField,
    "value",
    "true",
    true,
  );
  const canPayoffQuote = isAllowed(
    configuratorPayoffQuoteAllowField,
    "value",
    "true",
    true,
  );

  return (
    <Router history={History}>
      {isAuthenticated && hasRetrievedConfig ? <Navbar /> : ""}
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.dark,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={requestPendingCount > 0}
      >
        <CircularProgress color="inherit" size={100} thickness={3} />
      </Backdrop>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="payments"
          element={
            <ProtectedRoute isAllowed={isBorrower} redirectPath={"/support"}>
              <Payments />
            </ProtectedRoute>
          }
        >
          <Route path="new" element={<NewPayment />} />
          <Route path="activity" element={<PaymentActivity />} />
          <Route path="methods" element={<PaymentMethods />}>
            <Route path="new" element={<NewPaymentMethod />} />
            <Route path="verify" element={<VerifyPaymentMethod />} />
          </Route>
        </Route>
        <Route
          path="profile"
          element={
            <ProtectedRoute isAllowed={isBorrower} redirectPath={"/support"}>
              <UserProfile />
            </ProtectedRoute>
          }
        >
          <Route path="communication" element={<CommunicationPreferences />} />
          <Route path="change-address" element={<ChangeAddress />} />
          <Route path="loan" element={<NewLoan />} />
          <Route path="statement-delivery" element={<StatementDelivery />} />
        </Route>
        <Route
          path="statements-documents"
          element={
            <ProtectedRoute isAllowed={isBorrower} redirectPath={"/support"}>
              <StatementsAndDocuments />
            </ProtectedRoute>
          }
        >
          <Route
            path="tax"
            element={
              <ProtectedRoute
                isAllowed={canViewDocuments}
                redirectPath={
                  canUploadDocuments
                    ? "/statements-documents/upload"
                    : canPayoffQuote
                    ? "/statements-documents/request"
                    : "/"
                }
              >
                <TaxDocuments />
              </ProtectedRoute>
            }
          />
          <Route
            path="other"
            element={
              <ProtectedRoute
                isAllowed={canViewDocuments}
                redirectPath={
                  canUploadDocuments
                    ? "/statements-documents/upload"
                    : canPayoffQuote
                    ? "/statements-documents/request"
                    : "/"
                }
              >
                <OtherDocuments />
              </ProtectedRoute>
            }
          />
          <Route
            path="request"
            element={
              <ProtectedRoute
                isAllowed={canPayoffQuote}
                redirectPath={
                  canViewDocuments
                    ? "/statements-documents"
                    : canUploadDocuments
                    ? "/statements-documents/upload"
                    : "/"
                }
              >
                <RequestPayoffQuote />
              </ProtectedRoute>
            }
          />
          <Route
            path="upload"
            element={
              <ProtectedRoute
                isAllowed={canUploadDocuments}
                redirectPath={
                  canViewDocuments
                    ? "/statements-documents"
                    : canPayoffQuote
                    ? "/statements-documents/request"
                    : "/"
                }
              >
                <UploadDocuments />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/client-configurations"
          element={
            <ProtectedRoute isAllowed={userRoles?.includes(CLIENT_ADMIN_ROLE)}>
              <ClientConfigurations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute
              isAllowed={
                userRoles?.includes(CLIENT_ADMIN_ROLE) ||
                userRoles?.includes(PAYMENTS_SUPPORT_ROLE)
              }
            >
              <Support />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Dashboard />} />
      </Routes>
      {isAuthenticated && hasRetrievedConfig ? <Footer /> : ""}
    </Router>
  );
};

export default RouteConfig;
