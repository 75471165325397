import StatementsAndDocumentsComponent from "../components/StatementsAndDocuments.component";
import {
  OTHER_DOCUMENTS_PAGE_DESCRIPTION_KEY,
} from "../../../shared/constants/Configurator.constants";
import {
  OTHER_DOCUMENTS_NO_DATA_MESSAGE,
  STATEMENTS_AND_DOCUMENTS_FIELD_TYPES,
  STATEMENTS_AND_DOCUMENTS_HEADER_NAMES,
} from "../../../shared/constants/StatementsDocuments.constants";
import StatementsAndDocumentsCell from "../../../shared/components/table/StatementsAndDocumentsCell";
import React from "react";

export default function OtherDocuments(){

  return (
    <StatementsAndDocumentsComponent
      pageDescriptionKey={OTHER_DOCUMENTS_PAGE_DESCRIPTION_KEY}
      documentType={"other"}
      title={"Other documents"}
      headerNames={STATEMENTS_AND_DOCUMENTS_HEADER_NAMES}
      noDataMessage={OTHER_DOCUMENTS_NO_DATA_MESSAGE}
      fieldTypes={STATEMENTS_AND_DOCUMENTS_FIELD_TYPES}
      cellDisplayComponent={StatementsAndDocumentsCell}
    />
  )
}