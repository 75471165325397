import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import PaymentMethodsList from "./payment-methods-list/PaymentMethodsList";

const PaymentMethods = () => {
  const location = useLocation();
  const pathNames = location.pathname.split("/");

  return (
    <>
      {pathNames[pathNames.length - 1] === "methods" ? (
        <PaymentMethodsList />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PaymentMethods;
