import React, { useContext, useEffect, useState } from "react";
import { Box, Fab, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import FooterItemsEditDialog from "./FooterItemsEditDialog";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  AbsoluteContainer,
  RelativeContainer,
} from "../styled/Container.styled";
import { NavLink } from "react-router-dom";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { CLIENT_ADMIN_ROLE } from "../../constants/Roles.constants";

const FooterItems = ({
  elementKey = "",
  wasModified = false,
  padding = 0,
  columnTitle = "",
}) => {
  const theme = useTheme();
  const { userRoles, visibleRole } = useContext(AuthContext);
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [footerEditDialogOpen, setFooterEditDialogOpen] = useState(false);
  const [footerItemList, setFooterItemList] = useState([]);

  useEffect(() => {
    const itemListDefinition = edittedFields.find(
      (item) => item.key === elementKey,
    );
    if (itemListDefinition) {
      const itemListStringSplit = itemListDefinition.value.split("|");
      const itemList = itemListStringSplit.map((item, index) => {
        const itemAttributes = item.split(">");
        return {
          key: `${elementKey}-item-${index + 1}`,
          label: itemAttributes[0],
          url: itemAttributes[1] === "0" ? itemAttributes[2] : "",
          internalURL: itemAttributes[1] === "1" ? itemAttributes[2] : "/",
          internalNavigation: itemAttributes[1] === "1",
        };
      });
      setFooterItemList(itemList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edittedFields]);

  const saveNewEdittedFooterItems = (newItems) => {
    const mappedNewItems = newItems.map((item) => {
      return {
        key: item.key,
        label: item.label,
        url: item.url,
        internalURL: item.internalURL,
        internalNavigation: item.internalNavigation,
      };
    });

    const newItemsStringified = mappedNewItems
      .map(
        (item) =>
          `${item.label}>${item.internalNavigation ? 1 : 0}>${
            item.internalNavigation ? item.internalURL : item.url
          }`,
      )
      .join("|");

    setFooterItemList(mappedNewItems);
    setFooterEditDialogOpen(false);

    addNewEdittedField({
      key: elementKey,
      value: newItemsStringified,
      modified: true,
    });
  };

  return (
    <RelativeContainer
      width="100%"
      padding={
        userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE
          ? 0
          : padding
      }
      margin={
        userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE
          ? "5px 0"
          : 0
      }
      border={
        userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE
          ? `2px dashed ${
              wasModified ? theme.palette.warning.main : theme.palette.text.hint
            }`
          : "none"
      }
      borderradius="5px"
    >
      {footerItemList.length > 0 ? (
        footerItemList.map((item) => (
          <Typography
            fontSize={12}
            padding={
              userRoles?.includes(CLIENT_ADMIN_ROLE) &&
              visibleRole === CLIENT_ADMIN_ROLE
                ? "5px 8px"
                : "5px 0"
            }
            key={item.key}
          >
            {item.internalNavigation ? (
              <NavLink
                to={item.internalURL}
                style={{
                  color: theme.palette.text.hint,
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                {item.label}
              </NavLink>
            ) : (
              <Link
                color="text.hint"
                fontWeight="bold"
                underline="none"
                href={`${
                  item.url && item.url.substring(0, 4) !== "http"
                    ? "http://"
                    : ""
                }${item.url}`}
                target={"_blank"}
                rel="noopener"
                sx={{ cursor: "pointer" }}
              >
                {item.label}
              </Link>
            )}
          </Typography>
        ))
      ) : (
        <Box
          padding={
            userRoles?.includes(CLIENT_ADMIN_ROLE) &&
            visibleRole === CLIENT_ADMIN_ROLE
              ? 1
              : 0
          }
        />
      )}
      {userRoles?.includes(CLIENT_ADMIN_ROLE) &&
      visibleRole === CLIENT_ADMIN_ROLE ? (
        <AbsoluteContainer sx={{ top: -5, right: -35 }}>
          <Fab
            color="primary"
            size="small"
            sx={{ width: 30, height: 30, minHeight: 30 }}
            onClick={() => setFooterEditDialogOpen(true)}
          >
            <EditIcon sx={{ fontSize: 16 }} />
          </Fab>
        </AbsoluteContainer>
      ) : (
        ""
      )}
      <FooterItemsEditDialog
        elementKey={elementKey}
        open={footerEditDialogOpen}
        dismiss={(e) => setFooterEditDialogOpen(false)}
        close={saveNewEdittedFooterItems}
        title={columnTitle}
        listElements={footerItemList}
      />
    </RelativeContainer>
  );
};

export default FooterItems;
