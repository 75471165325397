export const STATEMENTS_AND_DOCUMENTS_HEADER_NAMES = [
  "Date",
  "Title",
  "Type",
  "",
];

export const STATEMENTS_AND_DOCUMENTS_FIELD_TYPES = [
  "date",
  "title",
  "type",
  "actions",
];

export const STATEMENTS_NO_DATA_MESSAGE = "There are no statements to display";
export const TAX_DOCUMENTS_NO_DATA_MESSAGE =
  "There are no tax documents to display";
export const OTHER_DOCUMENTS_NO_DATA_MESSAGE =
  "There are no other documents to display";

export const EXTENSION_NOT_ALLOWED =
  "File extension not allowed. Please try again!";
export const UPLOAD_SUCCESS = "File uploaded successfully!";
export const UPLOAD_FAILED = "Upload document failed. Please try again!";
export const UPLOAD_NOT_ALLOWED = "You are not allowed to upload documents";
export const VIEW_DOCUMENT_NOT_ALLOWED_MESSAGE =
  "You are not allowed to view documents";
export const REQUEST_PAYOFF_NOT_ALLOWED_MESSAGE =
  "You are not allowed to request payoff quotes";
export const REQUEST_PAYOFF_SUCCESS =
  "Requested Payoff Quote successfully";
export const REQUEST_PAYOFF_FAILED =
  "Requested Payoff Quote failed. Please try again!";

export const UPLOAD_SIZE_EXCEEDED = (size) =>
  `The uploaded document should not exceed ${size}MB`;

export const DRAG_DROP_PLACEHOLDER =
  "Drag & Drop your file here or Click to browse file";

export const DOCUMENT_DOWNLOAD_TYPES = [
  "Statements",
  "BK Statements",
  "HELOC Statement",
  "HELOC BK Statement",
  "Short Year Statement",
  "Escrow Analysis",
  "PMI Notice",
  "MIP Notice",
  "1098",
  "1099A",
  "1099C",
  "1099Int",
  "1099Misc",
  "1099Nec",
  "Breach Letter",
  "ARM Rate/Payment Letter",
  "Late Notice",
  "Transfer Of Servicing Letter",
  "Welcome Letter",
  "Forbearance Agreement",
  "Loan Modification Agreement",
];

export const DOCUMENT_UPLOAD_TYPES = [
  "Tax",
  "Insurance",
  "Correspondence",
  "Insurance Claim",
  "ACH Authorization",
  "Mortgage Assistance",
  "Legal Documents",
];

export const PAYOFF_REQUEST_REASONS = [
  "Refinance with current lender",
  "Refinance with another lender",
  "Sale of home",
  "Final payment",
];

export const NO_CONTENT_STATUS = 204;
export const NO_EMAIL_SET = "No Email Set";
