import React from "react";
import { AbsoluteContainer } from "../../../shared/components/styled/Container.styled";

const WaveContainer = () => {
  return (
    <AbsoluteContainer
      sx={{
        width: "100%",
        bottom: 0,
        left: 0,
        transform: "translateY(5%)",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        height="320px"
        width="100%"
      >
        <path
          fill="#ffffff"
          d="M 0 0 C 172 -1 253 25 339 74 C 477 150 600 192 720 229.3 C 840 267 1031 271 1188 235 C 1301 209 1382 152 1440 112 L 1440 320 L 1380 320 C 1320 320 1200 320 1080 320 C 960 320 840 320 720 320 C 600 320 480 320 360 320 C 240 320 120 320 60 320 L 0 320 Z"
        ></path>
      </svg>
    </AbsoluteContainer>
  );
};

export default WaveContainer;
