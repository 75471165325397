import React from "react";
import { Box } from "@mui/material";

export default function Logo({
  height = "100%",
  src = "",
  clickAction = () => {},
  cursor = "default",
}) {
  return (
    <Box
      component="img"
      sx={{
        width: "auto",
        height: height,
        maxHeight: 75,
        maxWidth: 150,
        cursor: cursor,
      }}
      alt="Logo"
      src={src}
      onClick={clickAction}
    />
  );
}
