export const PLACEHOLDER_DESCRIPTION =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius mauris quis fermentum eleifend. Phasellus et interdum est, non commodo libero. Maecenas bibendum gravida nisi, sit amet finibus nisl porta at. Nunc ut pellentesque eros. Duis in laoreet felis.";
export const NOT_APPLICABLE = "N/A";
export const DEFAULT_HYPERLINK_DISPLAY_TEXT = "Link Title";
export const DEFAULT_HYPERLINK_URL = "http://example.com";
export const MISSING_TENANT_MESSAGE =
  "You have used the incorrect URL to reach this site. Please obtain the correct URL from your mortgage servicer.";
export const ERROR = "error";
export const SUCCESS = "success";

export const GENERIC_ERROR_MESSAGE = "An unexpected error has occured";
export const DEFAULT_MORNING_GREETING = "Good Morning";
export const DEFAULT_AFTERNOON_GREETING = "Good Afternoon";
export const DEFAULT_EVENING_GREETING = "Good Evening";
export const DEFAULT_USER_NAME = "Dear Customer";

export const NON_NEGATIVE_AMOUNT_ERROR_MESSAGE = "Amount cannot be negative";
