import React, { useContext, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormItemInput from "../../../../shared/components/form/FormItemInput";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { useIsMount } from "../../../../shared/hooks/useIsMount";

const ThresholdFeeOption = ({
  elementKey = "",
  selected = false,
  onChangeHandler = () => {},
  validateInputValue = () => {},
  threshold = "",
  setThreshold = () => {},
}) => {
  const firstMount = useIsMount();
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [underThresholdFee, setUnderThresholdFee] = useState("");
  const [aboveThresholdFee, setAboveThresholdFee] = useState("");

  const [thresholdErrorMessage, setThresholdErrorMessage] = useState("");
  const [underThresholdFeeErrorMessage, setUnderThresholdFeeErrorMessage] =
    useState("");
  const [aboveThresholdFeeErrorMessage, setAboveThresholdFeeErrorMessage] =
    useState("");

  const [fromConfig, setFromConfig] = useState(true);

  useEffect(() => {
    const thresholdField = edittedFields.find(
      (item) => item.key === elementKey,
    );
    if (thresholdField) {
      if (thresholdField.value) {
        const thresholdValues = thresholdField.value.split(";");
        setFromConfig(true);
        setThreshold(thresholdValues[0]);
        setUnderThresholdFee(thresholdValues[1]);
        setAboveThresholdFee(thresholdValues[2]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstMount) {
      if (!fromConfig) {
        let value = "";
        if (
          underThresholdFeeErrorMessage ||
          aboveThresholdFeeErrorMessage ||
          threshold.length === 0
        ) {
          value = "";
        } else {
          value = `${+threshold};${
            underThresholdFee.length > 0 ? +underThresholdFee : 0
          };${aboveThresholdFee.length > 0 ? +aboveThresholdFee : 0}`;
        }
        addNewEdittedField({
          key: elementKey,
          value: value,
          modified: true,
        });
      }
      if (fromConfig) {
        setFromConfig(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    threshold,
    underThresholdFee,
    aboveThresholdFee,
    underThresholdFeeErrorMessage,
    aboveThresholdFeeErrorMessage,
  ]);

  return (
    <>
      <FormItemInput
        elementWidth="100%"
        value={threshold}
        setValue={(e) =>
          onChangeHandler(e, setThreshold, setThresholdErrorMessage)
        }
        placeholder="0"
        blurHandler={(e) => validateInputValue(e, setThresholdErrorMessage)}
        blurOnWheel
        adornment
        adornmentPosition="start"
        adornmentSymbol="$"
        inputProps={{
          step: 0.01,
          min: 0,
          type: "number",
          style: {
            textAlign: "end",
          },
        }}
        errorMessage={thresholdErrorMessage}
      />
      {threshold ? (
        <>
          <FlexContainer
            flexdirection="column"
            width="100%"
            height={drawerOpen ? "175px" : "0"}
            alignitems="flex-start"
            gap="10px"
            borderradius="0"
            sx={{ overflow: "hidden", transition: "all 0.3s ease-in-out" }}
          >
            <Typography
              color={selected ? "primary" : "text.hint"}
              fontWeight="bold"
            >
              Fee Under Threshold
            </Typography>
            <FormItemInput
              elementWidth="100%"
              value={underThresholdFee}
              setValue={(e) =>
                onChangeHandler(
                  e,
                  setUnderThresholdFee,
                  setUnderThresholdFeeErrorMessage,
                )
              }
              placeholder="0"
              blurHandler={(e) =>
                validateInputValue(e, setUnderThresholdFeeErrorMessage)
              }
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
              errorMessage={underThresholdFeeErrorMessage}
            />
            <Typography
              color={selected ? "primary" : "text.hint"}
              fontWeight="bold"
            >
              Fee Above Threshold
            </Typography>
            <FormItemInput
              elementWidth="100%"
              value={aboveThresholdFee}
              setValue={(e) =>
                onChangeHandler(
                  e,
                  setAboveThresholdFee,
                  setAboveThresholdFeeErrorMessage,
                )
              }
              placeholder="0"
              blurHandler={(e) =>
                validateInputValue(e, setAboveThresholdFeeErrorMessage)
              }
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
              errorMessage={aboveThresholdFeeErrorMessage}
            />
          </FlexContainer>
          <Button
            fullWidth
            onClick={(e) => setDrawerOpen(!drawerOpen)}
            sx={{ alignSelf: "center", marginTop: !drawerOpen ? "8px" : 0 }}
          >
            {drawerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ThresholdFeeOption;
