import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { Divider, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import BankInformationForm from "./components/BankInformationForm";
import BankInformationHelper from "./components/BankInformationHelper";
import PaymentMethodInstructions from "./components/PaymentMethodInstructions";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { postPaymentMethod } from "../../../../data/paymentsAPI";
import ConfirmationDialog from "../../../../shared/components/ConfirmationDialog";
import ActionSnackbar from "../../../../shared/components/ActionSnackbar";
import EditableText from "../../../../shared/components/configurator/EditableText";
import { PillButton } from "../../../../shared/components/styled/Button.styled";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import {
  PAYMENT_ACCOUNT_TYPE,
  PAYMENT_METHOD_FOOTER_NOTE,
  PAYMENT_METHOD_STEP_1,
  PAYMENT_METHOD_STEP_2,
} from "../../../../shared/constants/Payments.constants";
import {
  ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY,
  ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY,
} from "../../../../shared/constants/Configurator.constants";
import { findElementIncludedInArray } from "../../../../shared/utils/utility-functions";
import { CLIENT_ADMIN_ROLE } from "../../../../shared/constants/Roles.constants";

export default function NewPaymentMethod() {
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { token, userRoles, visibleRole, activeLoan, impersonatedUser } =
    useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const navigate = useNavigate();
  const theme = useTheme();

  // Input Field States
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState(PAYMENT_ACCOUNT_TYPE[0].value);
  const [bankName, setBankName] = useState("");
  const [nickname, setNickname] = useState("");

  // Error Message States
  const [routingNumberErrorMessage, setRoutingNumberErrorMessage] =
    useState("");
  const [accountNumberErrorMessage, setAccountNumberErrorMessage] =
    useState("");
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState("");

  // Field Disable States
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Dialog States
  const [confirmationDialogBody, setConfirmationDialogBody] = useState("");

  // Snackbar States
  const [
    paymentMethodNicknameErrorMessage,
    setPaymentMethodNicknameErrorMessage,
  ] = useState("");
  const [
    paymentMethodNicknameSnackbarOpen,
    setPaymentMethodNicknameSnackbarOpen,
  ] = useState(false);

  // Configurator States
  const [instructionText, setInstructionText] = useState(
    `${PAYMENT_METHOD_STEP_1}\n\n${PAYMENT_METHOD_STEP_2}`,
  );
  const [footerNote, setFooterNote] = useState(PAYMENT_METHOD_FOOTER_NOTE);

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mandatoryFields = [
      routingNumber,
      accountNumber,
      accountType,
      bankName,
      nickname,
    ];
    const dialogBody = `Are you sure you want to add a payment method named "${nickname}"?`;
    setSubmitDisabled(
      mandatoryFields.some((item) => item === "") ||
        routingNumberErrorMessage !== "" ||
        accountNumberErrorMessage !== "",
    );
    setConfirmationDialogBody(dialogBody);
  }, [
    routingNumber,
    accountNumber,
    accountType,
    bankName,
    nickname,
    routingNumberErrorMessage,
    accountNumberErrorMessage,
  ]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY:
          setInstructionText(item.value);
          return;
        case ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY:
          setFooterNote(item.value);
          return;
        default:
          return;
      }
    });
  };

  const handleConfirmationClose = () => {
    const requestBody = {
      bankRoutingNumber: routingNumber,
      bankAccountNumber: accountNumber,
      bankName: bankName,
      bankAccountType: accountType,
      loanId: activeLoan,
      nickname: nickname,
    };
    savePaymentMethod(requestBody);
    setConfirmationOpen(false);
  };

  const handleNicknameErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      setPaymentMethodNicknameSnackbarOpen(false);
      return;
    }
  };

  const savePaymentMethod = async (requestBody) => {
    addPendingRequest();
    const savePaymentMethodResponse = await postPaymentMethod(
      token,
      requestBody,
      impersonatedUser,
    );
    removePendingRequest();
    if (savePaymentMethodResponse.message) {
      setPaymentMethodNicknameErrorMessage(savePaymentMethodResponse.message);
      setPaymentMethodNicknameSnackbarOpen(true);
      // TODO: Handle future messages
      return;
    }
    navigate("/payments/methods", { replace: true });
  };

  return (
    <FlexContainer flexdirection="column" alignitems="center" width="100%">
      <FlexContainer alignself="flex-start" padding="0 0 1.25rem 0">
        <IconButton
          color="primary"
          onClick={(e) => navigate("/payments/methods", { replace: true })}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h2" color="primary">
          Add Payment Method
        </Typography>
      </FlexContainer>
      <BankInformationForm
        routingNumber={routingNumber}
        setRoutingNumber={setRoutingNumber}
        accountNumber={accountNumber}
        setAccountNumber={setAccountNumber}
        accountType={accountType}
        setAccountType={setAccountType}
        bankName={bankName}
        setBankName={setBankName}
        nickname={nickname}
        setNickname={setNickname}
        routingNumberErrorMessage={routingNumberErrorMessage}
        setRoutingNumberErrorMessage={setRoutingNumberErrorMessage}
        accountNumberErrorMessage={accountNumberErrorMessage}
        setAccountNumberErrorMessage={setAccountNumberErrorMessage}
        nicknameErrorMessage={nicknameErrorMessage}
        setNicknameErrorMessage={setNicknameErrorMessage}
      />
      <PillButton
        color="primary"
        variant="contained"
        width="33%"
        customfontsize="1.25rem"
        onClick={(e) => setConfirmationOpen(true)}
        disabled={
          submitDisabled ||
          impersonatedUser ||
          userRoles?.includes(CLIENT_ADMIN_ROLE)
        }
      >
        Submit
      </PillButton>
      <PaymentMethodInstructions
        instructionText={instructionText}
        setInstructionText={setInstructionText}
      />
      <BankInformationHelper />
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      footerNote ? (
        <>
          <Divider sx={{ paddingTop: "50px" }} flexItem />
          <EditableText
            renderedText={footerNote}
            setRenderedText={setFooterNote}
            elementKey={ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY,
            )}
            padding="5px 0 0 0"
            fontSize={12}
            withHyperlink
          />
        </>
      ) : (
        ""
      )}
      <ConfirmationDialog
        open={confirmationOpen}
        close={handleConfirmationClose}
        dismiss={(e) => setConfirmationOpen(false)}
        title="Confirm payment method"
        body={confirmationDialogBody}
      />
      <ActionSnackbar
        severity="warning"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={paymentMethodNicknameSnackbarOpen}
        closeFn={handleNicknameErrorSnackbarClose}
        snackbarLabel={paymentMethodNicknameErrorMessage}
      />
    </FlexContainer>
  );
}
