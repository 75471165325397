import React from "react";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import FormItem from "../../../../shared/components/form/FormItem";
import { handleInputChange } from "../../../../shared/utils/utility-functions";
import { stateList } from "../../../../shared/constants/States.constants";
import {
  USER_ADDRESS_ZIP_CODE_LENGTH_ERROR_MESSAGE,
  USER_ADDRESS_ZIP_CODE_NUMERIC_ERROR_MESSAGE,
} from "../../../../shared/constants/UserProfile.constants";

const onlyNumbersAllowed = /^[0-9]+$/;

const ChangeAddressForm = ({
  address = "",
  setAddress = () => {},
  addressAdditionalDetails = "",
  setAddressAdditionalDetails = () => {},
  city = "",
  setCity = () => {},
  state = "",
  setState = () => {},
  zipCode = "",
  setZipCode = () => {},
  zipCodeErrorMessage = "",
  setZipCodeErrorMessage = () => {},
  phone = "",
  setPhone = () => {},
}) => {
  const checkZipCodeValidity = () => {
    if (!onlyNumbersAllowed.test(zipCode)) {
      setZipCodeErrorMessage(USER_ADDRESS_ZIP_CODE_NUMERIC_ERROR_MESSAGE);
    } else if (zipCode.length !== 5) {
      setZipCodeErrorMessage(USER_ADDRESS_ZIP_CODE_LENGTH_ERROR_MESSAGE);
    } else {
      setZipCodeErrorMessage("");
    }
  };

  return (
    <FlexContainer
      padding="25px 0 35px 0"
      flexdirection="column"
      gap="10px"
      width="100%"
    >
      <FormItem
        elementWidth="200px"
        label="Address"
        inputType="input"
        value={address}
        setValue={(e) => handleInputChange(e, setAddress)}
        inputProps={{
          type: "text",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="200px"
        label="Address 2"
        inputType="input"
        value={addressAdditionalDetails}
        setValue={(e) => handleInputChange(e, setAddressAdditionalDetails)}
        placeholder="(Optional)"
        inputProps={{
          type: "text",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="200px"
        label="City"
        inputType="input"
        value={city}
        setValue={(e) => handleInputChange(e, setCity)}
        inputProps={{
          type: "text",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="200px"
        label="State"
        inputType="dropdown"
        value={state}
        setValue={setState}
        dropdownOptions={stateList}
        dropdownKey="code"
        dropdownValue="code"
        dropdownLabel="name"
      />
      <FormItem
        elementWidth="200px"
        label="Zip Code"
        inputType="input"
        value={zipCode}
        setValue={(e) => handleInputChange(e, setZipCode)}
        blurHandler={(e) => checkZipCodeValidity()}
        errorMessage={zipCodeErrorMessage}
        inputProps={{
          type: "text",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="200px"
        label="Phone"
        inputType="input"
        value={phone}
        setValue={(e) => handleInputChange(e, setPhone)}
        inputProps={{
          type: "tel",
          style: {
            textAlign: "start",
          },
        }}
      />
    </FlexContainer>
  );
};

export default ChangeAddressForm;
