export const PRIVACY_POLICY_LABEL =
  "We've updated our Privacy Policies. Please read them out and see what we have changed.";
export const MARKETING_LABEL = "Don't miss out on the greatest interest rates";
export const MARKETING_ADDITIONAL_LABEL =
  "You may be able to take advantage of low rates in more ways than you think.";
export const ERROR_EMAIL_MISSING = "Email is required";
export const ERROR_EMAIL_INVALID = "Not a valid email";
export const ERROR_PASSWORD_MISSING = "Password is required";
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LOGOUT_MESSAGE = "You have been logged out";
export const LOGOUT_LOGIN_HELP_MESSAGE =
  "You can log back in by pressing the button below, or by refreshing the page.";
export const LOGIN_BUTTON_LABEL = "Login";
