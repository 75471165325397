import React from "react";
import { FormControl, Input } from "@mui/material";
import DatePicker from "react-datepicker";

const FormItemTimepicker = ({
  elementWidth,
  startTime,
  setStartTime,
  timepickerOpen,
  setTimepickerOpen,
  openOnInput = false,
}) => {
  return (
    <FormControl onClick={openOnInput ? (e) => setTimepickerOpen(true) : ""}>
      <DatePicker
        open={timepickerOpen}
        selected={startTime}
        onChange={setStartTime}
        onClickOutside={(e) => setTimepickerOpen(false)}
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="hh:mm aa"
        customInput={
          <Input variant="standard" sx={{ minWidth: elementWidth }} />
        }
        showTimeSelect
        showTimeSelectOnly
        disabled
      />
    </FormControl>
  );
};

export default FormItemTimepicker;
