import React, { useContext, useState } from "react";
import RequireAuth from "../../shared/components/guards/RequireAuth";
import ConfigurationBrandingCategory from "./branding-config/ConfigurationBrandingCategory";
import { Box, Paper, Typography } from "@mui/material";
import { FlexContainer } from "../../shared/components/styled/Container.styled";
import {
  CLIENT_CONFIG_BRANDING_VIEW,
  CLIENT_CONFIG_PAYMENTS_VIEW,
  CLIENT_CONFIG_RECURRING_PAYMENTS_VIEW,
  CLIENT_CONFIG_USER_SETTINGS_VIEW,
  PUBLISH_CHANGES_CONFIRMATION_BODY,
} from "../../shared/constants/Configurator.constants";
import ConfigurationPaymentsCategory from "./payments-config/ConfigurationPaymentsCategory";
import ConfigurationRecurringPaymentsCategory from "./recurring-payments-config/ConfigurationRecurringPaymentsCategory";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useTheme } from "@mui/styles";
import { PillButton } from "../../shared/components/styled/Button.styled";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import { postBatchConfig, uploadFile } from "../../data/configAPI";
import ConfirmationDialog from "../../shared/components/ConfirmationDialog";
import ActionSnackbar from "../../shared/components/ActionSnackbar";
import { AuthContext } from "../../contexts/AuthContext";
import ConfigurationUserCategory from "./user-config/ConfigurationUserCategory";
import { GENERIC_ERROR_MESSAGE } from "../../shared/constants/Defaults.constants";

const ClientConfigurations = () => {
  const theme = useTheme();
  const { edittedFields, addBatchOfEdittedFields } =
    useContext(ConfiguratorContext);
  const { token, impersonatedUser } = useContext(AuthContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);

  const [selectedView, setSelectedView] = useState(CLIENT_CONFIG_PAYMENTS_VIEW);
  const [confirmPublishDialogOpen, setConfirmPublishDialogOpen] =
    useState(false);

  // Snackbar States
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(GENERIC_ERROR_MESSAGE);

  const renderConfigComponent = () => {
    switch (selectedView) {
      case CLIENT_CONFIG_PAYMENTS_VIEW:
        return <ConfigurationPaymentsCategory />;
      case CLIENT_CONFIG_RECURRING_PAYMENTS_VIEW:
        return <ConfigurationRecurringPaymentsCategory />;
      case CLIENT_CONFIG_BRANDING_VIEW:
        return <ConfigurationBrandingCategory />;
      case CLIENT_CONFIG_USER_SETTINGS_VIEW:
        return <ConfigurationUserCategory />;
      default:
        return <ConfigurationPaymentsCategory />;
    }
  };

  const publishConfiguratorChanges = () => {
    const requestBody = edittedFields
      .filter((item) => item.modified && !item.key.includes("IMAGE"))
      .map((item) => {
        return {
          operation: "POST",
          property: {
            key: item.key,
            value: item.value,
          },
        };
      });
    const fileRequestBody = edittedFields
      .filter((item) => item.modified && item.key.includes("IMAGE"))
      .map((item) => {
        return {
          id: item.key,
          file: item.value,
        };
      });
    callPostConfigApi(token, requestBody, fileRequestBody);
    setConfirmPublishDialogOpen(false);
  };

  const callPostConfigApi = async (token, requestBody, fileRequestBody) => {
    addPendingRequest();
    await postBatchConfig(token, requestBody, impersonatedUser);

    fileRequestBody.forEach(async (item) => {
      const uploadResponse = await uploadFile(token, item, impersonatedUser);
      if (uploadResponse?.message) {
        setSnackbarMessage(uploadResponse.message);
        setSnackbarOpen(true);
      }
    });

    removePendingRequest();
    const modifiedElements = edittedFields.map((item) => {
      return { ...item, modified: false };
    });
    addBatchOfEdittedFields(modifiedElements);
  };

  return (
    <RequireAuth>
      <FlexContainer
        width="100"
        padding="35px 100px"
        justifycontent="space-between"
        gap="25px"
      >
        <Typography
          variant="h1"
          component="h1"
          color="primary.dark"
          fontSize="3rem"
        >
          Client Configurations
        </Typography>
        <FlexContainer gap="25px">
          <Typography
            onClick={() => setSelectedView(CLIENT_CONFIG_PAYMENTS_VIEW)}
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            color={
              selectedView === CLIENT_CONFIG_PAYMENTS_VIEW
                ? "primary"
                : "text.hint"
            }
          >
            Payment Default Calculations
          </Typography>
          <Typography
            onClick={() =>
              setSelectedView(CLIENT_CONFIG_RECURRING_PAYMENTS_VIEW)
            }
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            color={
              selectedView === CLIENT_CONFIG_RECURRING_PAYMENTS_VIEW
                ? "primary"
                : "text.hint"
            }
          >
            Auto Payments
          </Typography>
          <Typography
            onClick={() => setSelectedView(CLIENT_CONFIG_BRANDING_VIEW)}
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            color={
              selectedView === CLIENT_CONFIG_BRANDING_VIEW
                ? "primary"
                : "text.hint"
            }
          >
            Branding Settings
          </Typography>
          <Typography
            onClick={() => setSelectedView(CLIENT_CONFIG_USER_SETTINGS_VIEW)}
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            color={
              selectedView === CLIENT_CONFIG_USER_SETTINGS_VIEW
                ? "primary"
                : "text.hint"
            }
          >
            User Settings
          </Typography>
        </FlexContainer>
      </FlexContainer>
      <Paper elevation={0}>
        <FlexContainer padding="25px">{renderConfigComponent()}</FlexContainer>
      </Paper>
      {edittedFields.some((item) => item.modified) ? (
        <Box sx={{ width: "100%", padding: "25px 125px" }}>
          <FlexContainer
            width="100%"
            justifycontent="space-between"
            gap="25px"
            background={theme.palette.background.default}
            sx={{ borderRadius: 0 }}
          >
            <Typography variant="h4" fontWeight="bold">
              Ready to publish?
            </Typography>
            <PillButton
              variant="contained"
              color="primary"
              width="250px"
              onClick={(e) => setConfirmPublishDialogOpen(true)}
            >
              Yes, Publish!
            </PillButton>
          </FlexContainer>
        </Box>
      ) : (
        ""
      )}
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={(e) => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
      />
      <ConfirmationDialog
        open={confirmPublishDialogOpen}
        close={(e) => publishConfiguratorChanges()}
        dismiss={(e) => setConfirmPublishDialogOpen(false)}
        title="Publish changes"
        body={PUBLISH_CHANGES_CONFIRMATION_BODY}
        confirmButtonLabel="Yes"
        dismissButtonLabel="No"
      />
    </RequireAuth>
  );
};

export default ClientConfigurations;
