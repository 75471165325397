import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableHeaderCell,
  StyledTableRow,
} from "./styled/Table.styled";

const DataTable = ({
  noHeader = false,
  withBorders = false,
  size = "medium",
  headers = [],
  rows = [],
  emptyDataMessage = "",
  rowAttributes = [],
  CellDisplayComponent = <></>,
  cellLogicCallbacks = {},
  minWidth = "700px",
  sorting = false,
}) => {
  const [valueToOrderBy, setValueToOrderBy] = useState("date");
  const [orderDirection, setOrderDirection] = useState("desc");

  const determineCellAlignment = (index) => {
    return index === 0
      ? "left"
      : index === headers.length - 1
      ? "right"
      : "center";
  };

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function stableSort(array, comparator) {
    if (!sorting) {
      return rows;
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <TableContainer>
      <Table
        size={size}
        sx={{ minWidth: minWidth }}
        aria-label="customized table"
      >
        {noHeader ? (
          <></>
        ) : (
          <TableHead>
            <TableRow>
              {headers.map((headerLabel, index) => (
                <StyledTableHeaderCell
                  key={headerLabel}
                  align={determineCellAlignment(index)}
                >
                  {sorting && headerLabel !== "" ? (
                    <TableSortLabel
                      active={valueToOrderBy === headerLabel}
                      direction={
                        valueToOrderBy === headerLabel ? orderDirection : "asc"
                      }
                      onClick={(event) => handleRequestSort(event, headerLabel)}
                    >
                      {headerLabel}
                    </TableSortLabel>
                  ) : (
                    <>{headerLabel}</>
                  )}
                </StyledTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {stableSort(
            rows,
            getComparator(orderDirection, valueToOrderBy.toLowerCase()),
          ).map((row, i) => (
            <StyledTableRow
              key={`${row[rowAttributes[0]]}-${i}`}
              withborders={withBorders ? 1 : 0}
            >
              {rowAttributes.map((cell, index) => (
                <StyledTableCell
                  key={`${row[rowAttributes[0]]}-${i}-${index}`}
                  disabled={row.disabled}
                  align={determineCellAlignment(index)}
                >
                  <CellDisplayComponent
                    cellAttribute={cell}
                    cellData={row[cell]}
                    cellLogicCallbacks={cellLogicCallbacks}
                    rowInfo={row}
                  />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length === 0 ? (
        <Typography
          fontSize="1.25rem"
          fontWeight="bold"
          color="text.secondary"
          paddingTop="10px"
          align="center"
        >
          {emptyDataMessage}
        </Typography>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export default DataTable;
