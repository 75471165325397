import React, { useContext, useState } from "react";
import {
  ClickAwayListener,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  downloadDocument,
  viewDocument,
} from "../../../data/statementsDocumentsAPI";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { determineRoleLabel } from "../../utils/msal-functions";
import {
  BORROWER,
  PAYMENTS_BORROWER_ROLE,
} from "../../constants/Roles.constants";

const StatementsAndDocumentsCell = ({ cellAttribute, cellData, rowInfo }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { token, userRoles, visibleRole, activeLoan, impersonatedUser } =
    useContext(AuthContext);
  const role = determineRoleLabel(visibleRole);
  const isBorrower = role === BORROWER;

  const handleDownload = async (documentId) => {
    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        await downloadDocument(token, activeLoan, documentId, impersonatedUser);
      }
    }
  };

  const handleView = async (documentId) => {
    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        await viewDocument(token, activeLoan, documentId, impersonatedUser);
      }
    }
  };

  const openPopper = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const closePopper = () => {
    setOpen(false);
  };

  const renderInputItem = () => {
    if (
      cellAttribute === "actions" &&
      !impersonatedUser &&
      userRoles?.includes(PAYMENTS_BORROWER_ROLE)
    ) {
      return (
        <>
          <IconButton onClick={openPopper}>
            <MoreHorizIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
                  <ClickAwayListener onClickAway={closePopper}>
                    <Stack>
                      <MenuItem
                        disabled={!isBorrower}
                        onClick={() => handleView(rowInfo.documentId)}
                      >
                        <ListItemIcon>
                          <VisibilityOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>View Document</ListItemText>
                      </MenuItem>
                      <MenuItem
                        disabled={!isBorrower}
                        onClick={() => handleDownload(rowInfo.documentId)}
                      >
                        <ListItemIcon>
                          <FileDownloadOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Download Document</ListItemText>
                      </MenuItem>
                    </Stack>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      );
    }
    return <>{cellData}</>;
  };
  return renderInputItem();
};

export default StatementsAndDocumentsCell;
