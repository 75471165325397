import React from "react";
import { Typography, useTheme } from "@mui/material";
import FloodIcon from "@mui/icons-material/Flood";
import HouseIcon from "@mui/icons-material/House";
import DashboardInfoItem from "../../../shared/components/dashboard/DashboardInfoItem";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { NOT_APPLICABLE } from "../../../shared/constants/Defaults.constants";
import { hex2rgba } from "../../../shared/utils/utility-functions";

const TaxInsuranceDetails = ({
  realEstateCurrent = NOT_APPLICABLE,
  hazardCurrent = NOT_APPLICABLE,
}) => {
  const theme = useTheme();

  return (
    <FlexContainer width="70%" padding="0 0 0 50px" gap="50px" flexwrap="wrap">
      <FlexContainer
        flexdirection="row"
        alignitems="flex-start"
        gap="10px"
        background={hex2rgba(theme.palette.primary.main, 0.1)}
        padding="25px"
      >
        <HouseIcon color="primary" sx={{ fontSize: "64px" }} />
        <FlexContainer flexdirection="column" alignitems="flex-start">
          <Typography fontWeight="bold">Real Estate Taxes</Typography>
          <FlexContainer gap="50px">
            <DashboardInfoItem
              label="Current Year"
              data={realEstateCurrent}
              prefix={realEstateCurrent === NOT_APPLICABLE ? "" : "$"}
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        flexdirection="row"
        alignitems="flex-start"
        gap="10px"
        background={hex2rgba(theme.palette.primary.main, 0.1)}
        padding="25px"
      >
        <FloodIcon color="primary" sx={{ fontSize: "64px" }} />
        <FlexContainer flexdirection="column" alignitems="flex-start">
          <Typography fontWeight="bold">Hazard Insurance</Typography>
          <FlexContainer gap="50px">
            <DashboardInfoItem
              label="Policy Premium"
              data={hazardCurrent}
              prefix={hazardCurrent === NOT_APPLICABLE ? "" : "$"}
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TaxInsuranceDetails;
