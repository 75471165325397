import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Divider,
  Fab,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FormItemInput from "../../../shared/components/form/FormItemInput";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { ColouredFab } from "../../../shared/components/styled/Button.styled";
import { useTheme } from "@mui/styles";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { useIsMount } from "../../../shared/hooks/useIsMount";
import { stateList } from "../../../shared/constants/States.constants";
import { NON_NEGATIVE_AMOUNT_ERROR_MESSAGE } from "../../../shared/constants/Defaults.constants";

const AutoCompleteInputSetting = ({
  elementKey = "",
  elementPadding = "0",
  label = "",
  labelColor = "text",
  labelFontSize = "14px",
  padding = "0",
  background = "transparent",
  listElements = [],
  setListElements = () => {},
  onChangeHandler = () => {},
  validateInputValue = () => {},
}) => {
  const firstMount = useIsMount();
  const theme = useTheme();
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [selectedElement, setSelectedElement] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [definedElements, setDefinedElements] = useState([]);
  const [definedElementsInputValues, setDefinedElementsInputValues] = useState(
    {},
  );
  const [fromConfig, setFromConfig] = useState(false);

  useEffect(() => {
    const currentField = edittedFields.find((item) => item.key === elementKey);
    if (currentField) {
      if (currentField.value) {
        setFromConfig(true);
        const elementsToBeDefined = [];
        const elementInputsToBeDefined = {};
        const listElementsCopy = [...listElements];
        const items = currentField.value.split(";");
        items.forEach((item) => {
          const [state, value] = item.split("=");
          const stateIndex = listElementsCopy.findIndex(
            (item) => item.code === state,
          );
          elementsToBeDefined.push({
            name: listElementsCopy[stateIndex]?.name,
            code: state,
            value: value,
          });
          elementInputsToBeDefined[state] = {
            value: value,
            errorMessage: "",
          };
          listElementsCopy.splice(stateIndex, 1);
        });
        setDefinedElements(elementsToBeDefined);
        setDefinedElementsInputValues(elementInputsToBeDefined);
        setListElements(listElementsCopy);
      }
    }

    return () => {
      setListElements([...stateList]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstMount) {
      if (!fromConfig) {
        let configuratorValue = "";
        for (const code in definedElementsInputValues) {
          if (!definedElementsInputValues[code].errorMessage) {
            configuratorValue += `${code}=${+definedElementsInputValues[code]
              .value};`;
          }
        }
        if (configuratorValue.length > 0) {
          configuratorValue = configuratorValue.substring(
            0,
            configuratorValue.length - 1,
          );
        }
        addNewEdittedField({
          key: elementKey,
          value: configuratorValue,
          modified: true,
        });
      }
      if (fromConfig) {
        setFromConfig(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definedElementsInputValues]);

  const addElementInList = () => {
    const currentElement = { ...selectedElement };
    const definedElementsCopy = [...definedElements];
    const definedElementsInputValuesCopy = { ...definedElementsInputValues };
    const listElementsCopy = [...listElements];
    const indexOfCurrentElement = listElements.indexOf(selectedElement);

    currentElement.value = inputValue;
    definedElementsCopy.push(currentElement);
    definedElementsInputValuesCopy[currentElement.code] = {
      value: inputValue,
      errorMessage: "",
    };

    setDefinedElements(
      definedElementsCopy.sort((a, b) => a.name.localeCompare(b.name)),
    );
    setDefinedElementsInputValues(definedElementsInputValuesCopy);

    listElementsCopy.splice(indexOfCurrentElement, 1);
    setListElements(listElementsCopy);

    setSelectedElement(null);
    setInputValue("");
    setAutocompleteInputValue("");
  };

  const handleListInputChange = (e, code) => {
    const definedElementsInputValuesCopy = { ...definedElementsInputValues };
    definedElementsInputValuesCopy[code].value = e.target.value;
    setDefinedElementsInputValues(definedElementsInputValuesCopy);
    validateListInputValue(e, code);
  };

  const validateListInputValue = (e, code) => {
    const definedElementsInputValuesCopy = { ...definedElementsInputValues };
    if (+e.target.value < 0) {
      definedElementsInputValuesCopy[code].errorMessage =
        NON_NEGATIVE_AMOUNT_ERROR_MESSAGE;
      return;
    }
    if (e?.target?.validity?.valid === false) {
      definedElementsInputValuesCopy[code].errorMessage =
        e.target.validationMessage;
      return;
    }
    definedElementsInputValuesCopy[code].errorMessage = "";
    setDefinedElementsInputValues(definedElementsInputValuesCopy);
  };

  const removeItemFromList = (code) => {
    const itemIndex = definedElements.findIndex((item) => item.code === code);

    const listElementsCopy = [...listElements];
    listElementsCopy.push({
      name: definedElements[itemIndex].name,
      code: definedElements[itemIndex].code,
    });
    setListElements(
      listElementsCopy.sort((a, b) => a.name.localeCompare(b.name)),
    );

    const definedElementsCopy = [...definedElements];
    definedElementsCopy.splice(itemIndex, 1);

    const definedElementsInputValuesCopy = { ...definedElementsInputValues };
    delete definedElementsInputValuesCopy[code];

    setDefinedElements(definedElementsCopy);
    setDefinedElementsInputValues(definedElementsInputValuesCopy);
  };

  const submitValue = (e) => {
    if (e.key === "Enter") {
      addElementInList();
    }
  };

  return (
    <FlexContainer
      flexdirection="column"
      width="100%"
      justifycontent="flex-start"
      gap="10px"
      padding={elementPadding}
    >
      <Typography
        color={labelColor}
        fontWeight="bold"
        fontSize={labelFontSize}
        sx={{ alignSelf: "flex-start" }}
      >
        {label}
      </Typography>
      <FlexContainer
        width="100%"
        flexdirection="column"
        gap="10px"
        padding={padding}
        background={background}
      >
        <FlexContainer width="100%" gap="25px" justifycontent="space-between">
          <Autocomplete
            value={selectedElement}
            onChange={(event, newValue) => {
              setSelectedElement(newValue);
            }}
            inputValue={autocompleteInputValue}
            onInputChange={(event, newInputValue) => {
              setAutocompleteInputValue(newInputValue);
            }}
            options={listElements}
            getOptionLabel={(option) => option.name}
            sx={{ maxWidth: "300px", width: "100%" }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} label="Select State" />
            )}
          />
          <FlexContainer gap="25px">
            <FormItemInput
              elementWidth="200px"
              value={inputValue}
              setValue={(e) =>
                onChangeHandler(e, setInputValue, setErrorMessage)
              }
              placeholder="0"
              blurHandler={(e) => validateInputValue(e, setErrorMessage)}
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
              errorMessage={errorMessage}
              onKeyUp={(e) => submitValue(e)}
            />
            <Fab
              disabled={
                !inputValue || !autocompleteInputValue || !!errorMessage
              }
              color="primary"
              size="small"
              sx={{ width: 30, height: 30, minHeight: 30 }}
              onClick={(e) => addElementInList()}
            >
              <AddIcon />
            </Fab>
          </FlexContainer>
        </FlexContainer>
        {definedElements.length > 0 ? <Divider flexItem /> : ""}
        {definedElements.map((item) => (
          <FlexContainer
            width="100%"
            flexdirection="column"
            gap="10px"
            key={item.code}
          >
            <FlexContainer
              width="100%"
              gap="25px"
              justifycontent="space-between"
            >
              <Typography color="primary" fontWeight="bold" fontSize={14}>
                {item.name}
              </Typography>
              <FlexContainer gap="25px">
                <FormItemInput
                  elementWidth="200px"
                  value={definedElementsInputValues[item.code].value}
                  setValue={(e) => handleListInputChange(e, item.code)}
                  placeholder="0"
                  blurHandler={(e) => validateListInputValue(e, item.code)}
                  blurOnWheel
                  adornment
                  adornmentPosition="start"
                  adornmentSymbol="$"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    type: "number",
                    style: {
                      textAlign: "end",
                    },
                  }}
                  errorMessage={
                    definedElementsInputValues[item.code].errorMessage
                  }
                />
                <ColouredFab
                  color={theme.palette.error.main}
                  size="small"
                  iconfontsize="1.125rem"
                  sx={{ width: 30, height: 30, minHeight: 30 }}
                  onClick={(e) => {
                    removeItemFromList(item.code);
                  }}
                >
                  <DeleteIcon />
                </ColouredFab>
              </FlexContainer>
            </FlexContainer>
            <Divider flexItem />
          </FlexContainer>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default AutoCompleteInputSetting;
