import React, { useContext, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Counter from "../../../../shared/components/form/Counter";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import FormItemDropdown from "../../../../shared/components/form/FormItemDropdown";
import FormItemInput from "../../../../shared/components/form/FormItemInput";
import {
  addIndividualOrdinalSuffix,
  createDaysDropdownValues,
} from "../../../../shared/utils/utility-functions";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { useIsMount } from "../../../../shared/hooks/useIsMount";

const DateRangeFeeOption = ({
  elementKey = "",
  selected = false,
  numberOfDateRanges = 0,
  setNumberOfDateRanges = () => {},
  onChangeHandler = () => {},
  validateInputValue = () => {},
}) => {
  const firstMount = useIsMount();
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [firstRangeFee, setFirstRangeFee] = useState("");
  const [firstRangeFeeErrorMessage, setFirstRangeFeeErrorMessage] =
    useState("");
  const [secondRangeFee, setSecondRangeFee] = useState("");
  const [secondRangeFeeErrorMessage, setSecondRangeFeeErrorMessage] =
    useState("");
  const [thirdRangeFee, setThirdRangeFee] = useState("");
  const [thirdRangeFeeErrorMessage, setThirdRangeFeeErrorMessage] =
    useState("");

  const [firstRange, setFirstRange] = useState({
    value: 1,
    options: createDaysDropdownValues(1, 28, "client-config-first-range-day"),
  });
  const [secondRange, setSecondRange] = useState({
    value: 14,
    options: createDaysDropdownValues(1, 28, "client-config-second-range-day"),
  });
  const [thirdRange, setThirdRange] = useState({
    value: 21,
    options: createDaysDropdownValues(1, 28, "client-config-third-range-day"),
  });

  const [fromConfig, setFromConfig] = useState(true);

  useEffect(() => {
    const dateField = edittedFields.find((item) => item.key === elementKey);
    if (dateField) {
      if (dateField.value) {
        const dateValues = dateField.value.split(";");
        setFromConfig(true);
        setNumberOfDateRanges(dateValues.length);
        setInitialRangeValues(dateValues.length, dateValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstMount) {
      if (!fromConfig) {
        setInitialRangeValues(numberOfDateRanges, []);
      }
      if (fromConfig) {
        setFromConfig(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfDateRanges]);

  useEffect(() => {
    if (!fromConfig) {
      if (firstRange.value) {
        const newSecondRangeDateStart = +firstRange.value + 1;
        let newSecondRangeDateEnd = 28;
        if (thirdRange.value) {
          newSecondRangeDateEnd = +thirdRange.value - 1;
        }
        setSecondRange({
          ...secondRange,
          options: createDaysDropdownValues(
            newSecondRangeDateStart,
            newSecondRangeDateEnd,
            "client-config-second-range-day",
          ),
        });
        if (!secondRange.value) {
          setThirdRange({
            ...thirdRange,
            options: createDaysDropdownValues(
              newSecondRangeDateStart,
              28,
              "client-config-third-range-day",
            ),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRange.value]);

  useEffect(() => {
    if (!fromConfig) {
      if (secondRange.value) {
        setFirstRange({
          ...firstRange,
          options: createDaysDropdownValues(
            1,
            +secondRange.value - 1,
            "client-config-first-range-day",
          ),
        });
        setThirdRange({
          ...thirdRange,
          options: createDaysDropdownValues(
            +secondRange.value + 1,
            28,
            "client-config-third-range-day",
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondRange.value]);

  useEffect(() => {
    if (!fromConfig) {
      if (thirdRange.value) {
        const newSecondRangeDateEnd = +thirdRange.value - 1;
        let newSecondRangeDateStart = 1;
        if (firstRange.value) {
          newSecondRangeDateStart = +firstRange.value + 1;
        }
        setSecondRange({
          ...secondRange,
          options: createDaysDropdownValues(
            newSecondRangeDateStart,
            newSecondRangeDateEnd,
            "client-config-second-range-day",
          ),
        });
        if (!secondRange.value) {
          setFirstRange({
            ...firstRange,
            options: createDaysDropdownValues(
              1,
              newSecondRangeDateEnd,
              "client-config-first-range-day",
            ),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdRange.value]);

  useEffect(() => {
    if (!fromConfig) {
      let value = "";
      if (
        firstRangeFeeErrorMessage ||
        secondRangeFeeErrorMessage ||
        thirdRangeFeeErrorMessage ||
        numberOfDateRanges === 0
      ) {
        value = "";
      } else {
        if (numberOfDateRanges > 0) {
          value += `${firstRange.value}-${
            numberOfDateRanges === 1 ? "31" : secondRange.value
          }=${firstRangeFee.length > 0 ? +firstRangeFee : 0}${
            numberOfDateRanges === 1 ? "" : ";"
          }`;
        }
        if (numberOfDateRanges > 1) {
          value += `${secondRange.value + 1}-${
            numberOfDateRanges === 2 ? "31" : thirdRange.value
          }=${secondRangeFee.length > 0 ? +secondRangeFee : 0}${
            numberOfDateRanges === 2 ? "" : ";"
          }`;
        }
        if (numberOfDateRanges > 2) {
          value += `${thirdRange.value + 1}-31=${
            thirdRangeFee.length > 0 ? +thirdRangeFee : 0
          }`;
        }
      }
      addNewEdittedField({
        key: elementKey,
        value: value,
        modified: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    numberOfDateRanges,
    firstRange.value,
    secondRange.value,
    thirdRange.value,
    firstRangeFee,
    secondRangeFee,
    thirdRangeFee,
    firstRangeFeeErrorMessage,
    secondRangeFeeErrorMessage,
    thirdRangeFeeErrorMessage,
  ]);

  const setFirstRangeValue = (value) => {
    setFirstRange({ ...firstRange, value });
  };

  const setSecondRangeValue = (value) => {
    setSecondRange({ ...secondRange, value });
  };

  const setThirdRangeValue = (value) => {
    setThirdRange({ ...thirdRange, value });
  };

  const setInitialRangeValues = (dateRanges, config) => {
    let firstDate = 1;
    let secondDate = 14;
    let thirdDate = 21;
    if (dateRanges === 1) {
      if (config.length > 0) {
        const firstInterval = config[0].split("-");
        const firstRangeFee = firstInterval[1].split("=")[1];
        firstDate = +firstInterval[0];
        setFirstRangeFee(firstRangeFee);
      }
      setFirstRange({
        value: firstDate,
        options: createDaysDropdownValues(
          1,
          28,
          "client-config-first-range-day",
        ),
      });
      setSecondRange({ value: "", options: [] });
      setThirdRange({ value: "", options: [] });
    }
    if (dateRanges === 2) {
      if (config.length > 0) {
        const firstInterval = config[0].split("-");
        const secondInterval = config[1].split("-");
        const firstRangeFee = firstInterval[1].split("=")[1];
        const secondRangeFee = secondInterval[1].split("=")[1];
        firstDate = +firstInterval[0];
        secondDate = +firstInterval[1].split("=")[0];
        setFirstRangeFee(firstRangeFee);
        setSecondRangeFee(secondRangeFee);
      }
      setFirstRange({
        value: firstDate,
        options: createDaysDropdownValues(
          1,
          secondDate - 1,
          "client-config-first-range-day",
        ),
      });
      setSecondRange({
        value: secondDate,
        options: createDaysDropdownValues(
          firstDate + 1,
          28,
          "client-config-second-range-day",
        ),
      });
      setThirdRange({ value: "", options: [] });
    }
    if (dateRanges === 3) {
      if (config.length > 0) {
        const firstInterval = config[0].split("-");
        const secondInterval = config[1].split("-");
        const thirdInterval = config[2].split("-");
        const firstRangeFee = firstInterval[1].split("=")[1];
        const secondRangeFee = secondInterval[1].split("=")[1];
        const thirdRangeFee = thirdInterval[1].split("=")[1];
        firstDate = +firstInterval[0];
        secondDate = +firstInterval[1].split("=")[0];
        thirdDate = +secondInterval[1].split("=")[0];
        setFirstRangeFee(firstRangeFee);
        setSecondRangeFee(secondRangeFee);
        setThirdRangeFee(thirdRangeFee);
      }
      setFirstRange({
        value: firstDate,
        options: createDaysDropdownValues(
          1,
          secondDate - 1,
          "client-config-first-range-day",
        ),
      });
      setSecondRange({
        value: secondDate,
        options: createDaysDropdownValues(
          firstDate + 1,
          thirdDate - 1,
          "client-config-second-range-day",
        ),
      });
      setThirdRange({
        value: thirdDate,
        options: createDaysDropdownValues(
          secondDate + 1,
          28,
          "client-config-third-range-day",
        ),
      });
    }
  };

  return (
    <>
      <Counter
        counter={numberOfDateRanges}
        setCounter={setNumberOfDateRanges}
        minValue={0}
        maxValue={3}
        width="100%"
      ></Counter>
      {numberOfDateRanges > 0 ? (
        <>
          <FlexContainer
            flexdirection="column"
            width="100%"
            height={drawerOpen ? `${numberOfDateRanges * 90}px` : "0"}
            alignitems="flex-start"
            gap="10px"
            borderradius="0"
            sx={{ overflow: "hidden", transition: "all 0.3s ease-in-out" }}
          >
            <FlexContainer gap="3px">
              <Typography
                color={selected ? "primary" : "text.hint"}
                fontWeight="bold"
              >
                From{" "}
              </Typography>
              <FormItemDropdown
                isSmall
                elementWidth="75px"
                value={firstRange.value}
                setValue={setFirstRangeValue}
                options={firstRange.options}
                keyAttribute="key"
                valueAttribute="value"
                labelAttribute="label"
              />
              <Typography
                color={selected ? "primary" : "text.hint"}
                fontWeight="bold"
              >
                until
              </Typography>
              {numberOfDateRanges > 1 ? (
                <FormItemDropdown
                  isSmall
                  elementWidth="75px"
                  value={secondRange.value}
                  setValue={setSecondRangeValue}
                  options={secondRange.options}
                  keyAttribute="key"
                  valueAttribute="value"
                  labelAttribute="label"
                />
              ) : (
                <Typography
                  color={selected ? "primary" : "text.hint"}
                  fontWeight="bold"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  month end
                </Typography>
              )}
            </FlexContainer>
            <FormItemInput
              elementWidth="100%"
              value={firstRangeFee}
              setValue={(e) =>
                onChangeHandler(
                  e,
                  setFirstRangeFee,
                  setFirstRangeFeeErrorMessage,
                )
              }
              placeholder="0"
              blurHandler={(e) =>
                validateInputValue(e, setFirstRangeFeeErrorMessage)
              }
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
              errorMessage={firstRangeFeeErrorMessage}
              disabled={
                !firstRange.value ||
                (numberOfDateRanges === 2 && !secondRange.value)
              }
            />
            {numberOfDateRanges > 1 ? (
              <>
                <FlexContainer gap="3px">
                  <Typography
                    color={selected ? "primary" : "text.hint"}
                    fontWeight="bold"
                  >
                    From
                  </Typography>
                  {secondRange.value ? (
                    <Typography
                      color={selected ? "primary" : "text.hint"}
                      fontWeight="bold"
                    >
                      {addIndividualOrdinalSuffix(secondRange.value + 1)}
                    </Typography>
                  ) : (
                    <FormItemDropdown
                      isSmall
                      elementWidth="75px"
                      value={secondRange.value}
                      setValue={setSecondRangeValue}
                      options={secondRange.options}
                      keyAttribute="key"
                      valueAttribute="value"
                      labelAttribute="label"
                    />
                  )}
                  <Typography
                    color={selected ? "primary" : "text.hint"}
                    fontWeight="bold"
                  >
                    until
                  </Typography>
                  {numberOfDateRanges > 2 ? (
                    <FormItemDropdown
                      isSmall
                      elementWidth="75px"
                      value={thirdRange.value}
                      setValue={setThirdRangeValue}
                      options={thirdRange.options}
                      keyAttribute="key"
                      valueAttribute="value"
                      labelAttribute="label"
                    />
                  ) : (
                    <Typography
                      color={selected ? "primary" : "text.hint"}
                      fontWeight="bold"
                    >
                      month end
                    </Typography>
                  )}
                </FlexContainer>
                <FormItemInput
                  elementWidth="100%"
                  value={secondRangeFee}
                  setValue={(e) =>
                    onChangeHandler(
                      e,
                      setSecondRangeFee,
                      setSecondRangeFeeErrorMessage,
                    )
                  }
                  placeholder="0"
                  blurHandler={(e) =>
                    validateInputValue(e, setSecondRangeFeeErrorMessage)
                  }
                  blurOnWheel
                  adornment
                  adornmentPosition="start"
                  adornmentSymbol="$"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    type: "number",
                    style: {
                      textAlign: "end",
                    },
                  }}
                  errorMessage={secondRangeFeeErrorMessage}
                  disabled={
                    !secondRange.value ||
                    (numberOfDateRanges === 3 && !thirdRange.value)
                  }
                />
              </>
            ) : (
              ""
            )}
            {numberOfDateRanges === 3 ? (
              <>
                <FlexContainer gap="3px">
                  <Typography
                    color={selected ? "primary" : "text.hint"}
                    fontWeight="bold"
                  >
                    From
                  </Typography>
                  {thirdRange.value ? (
                    <Typography
                      color={selected ? "primary" : "text.hint"}
                      fontWeight="bold"
                    >
                      {addIndividualOrdinalSuffix(thirdRange.value + 1)}
                    </Typography>
                  ) : (
                    <FormItemDropdown
                      isSmall
                      elementWidth="75px"
                      value={thirdRange.value}
                      setValue={setThirdRangeValue}
                      options={thirdRange.options}
                      keyAttribute="key"
                      valueAttribute="value"
                      labelAttribute="label"
                    />
                  )}
                  <Typography
                    color={selected ? "primary" : "text.hint"}
                    fontWeight="bold"
                  >
                    until month end
                  </Typography>
                </FlexContainer>
                <FormItemInput
                  elementWidth="100%"
                  value={thirdRangeFee}
                  setValue={(e) =>
                    onChangeHandler(
                      e,
                      setThirdRangeFee,
                      setThirdRangeFeeErrorMessage,
                    )
                  }
                  placeholder="0"
                  blurHandler={(e) =>
                    validateInputValue(e, setThirdRangeFeeErrorMessage)
                  }
                  blurOnWheel
                  adornment
                  adornmentPosition="start"
                  adornmentSymbol="$"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    type: "number",
                    style: {
                      textAlign: "end",
                    },
                  }}
                  errorMessage={thirdRangeFeeErrorMessage}
                  disabled={!thirdRange.value}
                />
              </>
            ) : (
              ""
            )}
          </FlexContainer>
          <Button
            fullWidth
            onClick={(e) => setDrawerOpen(!drawerOpen)}
            sx={{ alignSelf: "center" }}
          >
            {drawerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DateRangeFeeOption;
