import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import EditableText from "../../../shared/components/configurator/EditableText";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import {
  DOCUMENTS_ALLOW_PAPER_STATEMENTS_KEY,
  DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY,
  DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY,
  DOCUMENTS_PAPER_STATEMENT_ATTN_KEY,
  DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY,
  STATEMENT_DELIVERY_ESTATEMENT_ONLY_KEY,
  STATEMENT_DELIVERY_PAGE_DESCRIPTION_KEY,
  STATEMENT_DELIVERY_PAPER_STATEMENT_KEY,
} from "../../../shared/constants/Configurator.constants";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import {
  findElementIncludedInArray,
  findInArray,
  hex2rgba,
  isAllowed,
} from "../../../shared/utils/utility-functions";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import { ERROR, SUCCESS } from "../../../shared/constants/Defaults.constants";
import {
  PAPER_STATEMENT_UPDATE_PREFERENCES_FAILED,
  PAPER_STATEMENT_UPDATE_PREFERENCES_SUCCESS,
} from "../../../shared/constants/UserProfile.constants";
import { getLoanData, updatePrefereneces } from "../../../data/loanAPI";
import { ThemeContext } from "../../../contexts/ThemeContext";

const StatementDelivery = () => {
  const theme = useTheme();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const {
    userRoles,
    token,
    visibleRole,
    impersonatedUser,
    activeLoan,
    activeLoanDetails,
    setActiveLoanDetails,
  } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);

  const configuratorPaperStatementsAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_PAPER_STATEMENTS_KEY,
  );

  const optInPaperAllowed = isAllowed(
    configuratorPaperStatementsAllowField,
    "value",
    "true",
    true,
  );

  const [description, setDescription] = useState("");
  const [descriptionEStatementOnly, setDescriptionEStatementOnly] = useState("");
  const [descriptionPaperStatement, setDescriptionPaperStatement] =
    useState(`To begin receiving paper statements again through the mail, 
    ${optInPaperAllowed
      ? "confirm by clicking the button below"
      : "please contact us at the following:"}`);
  const [severity, setSeverity] = useState("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [paperless, setPaperless] = useState(false);

  useEffect(() => {
    setPaperless(activeLoanDetails?.loanDetails?.paperless);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyName =
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY,
    )?.value || "Not provided";
  const attn =
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_ATTN_KEY,
    )?.value || "Not provided";
  const addressLine1 =
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY,
    )?.value || "Not provided";
  const addressLine2 =
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY,
    )?.value || null;

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case STATEMENT_DELIVERY_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case STATEMENT_DELIVERY_ESTATEMENT_ONLY_KEY:
          setDescriptionEStatementOnly(item.value);
          return;
        case STATEMENT_DELIVERY_PAPER_STATEMENT_KEY:
          setDescriptionPaperStatement(item.value);
          return;
        default:
          return;
      }
    });
  };

  const handlePaperStatement = async () => {
    let status;
    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        addPendingRequest();
        status = await updatePrefereneces(token, activeLoan, impersonatedUser, false);
        const updatedLoanDetails = await getLoanData(token, activeLoan, impersonatedUser);
        setActiveLoanDetails(updatedLoanDetails);
        setPaperless(updatedLoanDetails?.loanDetails?.paperless);
        removePendingRequest();
      }
    }
    displayStatusAPICall(status);
  };

  const displayStatusAPICall = (status) => {
    if (status && status.success) {
      setSnackbarMessage(PAPER_STATEMENT_UPDATE_PREFERENCES_SUCCESS);
      setSeverity(SUCCESS);
    } else {
      setSnackbarMessage(PAPER_STATEMENT_UPDATE_PREFERENCES_FAILED);
      setSeverity(ERROR);
    }
    setSnackbarOpen(true);
  };

  return (
    <FlexContainer
      flexdirection="column"
      justifycontent="flex-start"
      alignitems="flex-start"
      height="100%"
      width="100%"
    >
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={STATEMENT_DELIVERY_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            STATEMENT_DELIVERY_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      {descriptionEStatementOnly || (userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ? (
        <SectionTitle variant="h2" color="primary">
          e-Statement Only
        </SectionTitle>
      ) : (
        ""
      )}
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      descriptionEStatementOnly ? (
        <EditableText
          renderedText={descriptionEStatementOnly}
          setRenderedText={setDescriptionEStatementOnly}
          elementKey={STATEMENT_DELIVERY_ESTATEMENT_ONLY_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            STATEMENT_DELIVERY_ESTATEMENT_ONLY_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <SectionTitle variant="h2" color="primary">
        Opt Back In to Paper Statements
      </SectionTitle>
      <FlexContainer
        flexdirection="column"
        gap="25px"
        width="100%"
        alignitems="flex-start"
      >
        {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
          visibleRole === CLIENT_ADMIN_ROLE) ||
        descriptionPaperStatement ? (
          <EditableText
            renderedText={descriptionPaperStatement}
            setRenderedText={setDescriptionPaperStatement}
            elementKey={STATEMENT_DELIVERY_PAPER_STATEMENT_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              STATEMENT_DELIVERY_PAPER_STATEMENT_KEY,
            )}
            padding="0 0 25px 0"
            fontSize={12}
            withHyperlink
          />
        ) : (
          ""
        )}
        {optInPaperAllowed ? (
          <PillButton
            onClick={handlePaperStatement}
            variant="contained"
            color="primary"
            width="250px"
            alignself="center"
            disabled={
              !!impersonatedUser || userRoles?.includes(CLIENT_ADMIN_ROLE) ||
              !paperless
            }
          >
            Paper Statements
          </PillButton>
        ) : (
          <FlexContainer
            flexdirection="column"
            alignitems="flex-start"
            gap="10px"
            padding="10px"
            width="100%"
            background={hex2rgba(theme.palette.primary.main, 0.1)}
          >
            <Typography>
              <b>Company Name:</b> {companyName}
            </Typography>
            <Typography>
              <b>Attn:</b> {attn}
            </Typography>
            <Typography>
              <b>Address:</b> {addressLine1}
            </Typography>
            {addressLine2 ? (
              <Typography>
                <b>Address:</b> {addressLine2}
              </Typography>
            ) : null}
          </FlexContainer>
        )}
      </FlexContainer>
      <ActionSnackbar
        severity={severity}
        borderColor={theme.palette[severity].main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={() => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </FlexContainer>
  );
};

export default StatementDelivery;
