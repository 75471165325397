import React, { useContext } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const RadioSetting = ({
  elementKey = "",
  label = "",
  settingId = "",
  settingValue = "No",
  setValue = () => {},
  options = [],
}) => {
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  const handleChange = (e) => {
    setValue(e);
    addNewEdittedField({
      key: elementKey,
      value: e.target.value,
      modified: true,
    });
  };
  return (
    <FlexContainer
      flexdirection="row"
      width="100%"
      padding="0 25px 0 0"
      gap="25px"
      justifycontent="space-between"
    >
      <Typography id={settingId} fontWeight="bold" fontSize={16}>
        {label}
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby={settingId}
          value={settingValue}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              key={`${settingId}-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </FlexContainer>
  );
};

export default RadioSetting;
