import React, { useContext, useEffect, useState } from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { postPaymentMethodVerification } from "../../../../data/paymentsAPI";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import EditableText from "../../../../shared/components/configurator/EditableText";
import FormItem from "../../../../shared/components/form/FormItem";
import ActionSnackbar from "../../../../shared/components/ActionSnackbar";
import { PillButton } from "../../../../shared/components/styled/Button.styled";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import {
  VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY,
  VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY,
} from "../../../../shared/constants/Configurator.constants";
import {
  checkValidity,
  findElementIncludedInArray,
  handleInputChange,
} from "../../../../shared/utils/utility-functions";
import { CLIENT_ADMIN_ROLE } from "../../../../shared/constants/Roles.constants";
import { GENERIC_ERROR_MESSAGE } from "../../../../shared/constants/Defaults.constants";
import { TRANSACTION_AMOUNTS_MATCH_ERROR_MESSAGE } from "../../../../shared/constants/Payments.constants";

const VerifyPaymentMethod = () => {
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { token, userRoles, visibleRole, impersonatedUser } =
    useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const { state } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  // Input Field States
  const [transactionAmountOne, setTransactionAmountOne] = useState("");
  const [transactionAmountTwo, setTransactionAmountTwo] = useState("");

  // Error States
  const [
    transactionAmountOneErrorMessage,
    setTransactionAmountOneErrorMessage,
  ] = useState("");
  const [
    transactionAmountTwoErrorMessage,
    setTransactionAmountTwoErrorMessage,
  ] = useState("");

  // Disabled States
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // Snackbar States
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(GENERIC_ERROR_MESSAGE);

  // Configurator States
  const [description, setDescription] = useState("");
  const [footerNote, setFooterNote] = useState("");

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mandatoryFields = [transactionAmountOne, transactionAmountTwo];
    setSubmitDisabled(
      mandatoryFields.some((item) => item === "") ||
        transactionAmountOneErrorMessage !== "" ||
        transactionAmountTwoErrorMessage !== "",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transactionAmountOne,
    transactionAmountTwo,
    transactionAmountOneErrorMessage,
    transactionAmountTwoErrorMessage,
  ]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY:
          setFooterNote(item.value);
          return;
        default:
          return;
      }
    });
  };

  const verifyPayment = async () => {
    addPendingRequest();
    const requestBody = {
      transactionAmountOne: transactionAmountOne,
      transactionAmountTwo: transactionAmountTwo,
      nickname: state?.nickname,
    };
    const verifyPaymentMethodResponse = await postPaymentMethodVerification(
      token,
      requestBody,
      impersonatedUser,
    );
    removePendingRequest();

    if (verifyPaymentMethodResponse?.success === true) {
      navigate("/payments/methods", { replace: true });
    } else if (verifyPaymentMethodResponse?.success === false) {
      setSnackbarMessage(TRANSACTION_AMOUNTS_MATCH_ERROR_MESSAGE);
      setSnackbarOpen(true);
    }
    if (verifyPaymentMethodResponse.message) {
      setSnackbarMessage(verifyPaymentMethodResponse.message);
      setSnackbarOpen(true);
    }
  };

  return (
    <FlexContainer flexdirection="column" alignitems="center" width="100%">
      <FlexContainer alignself="flex-start" padding="0 0 1.25rem 0">
        <IconButton
          color="primary"
          onClick={(e) => navigate("/payments/methods", { replace: true })}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h2" color="primary">
          Verify {state?.nickname || "Payment Method"}
        </Typography>
      </FlexContainer>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <FlexContainer
        flexdirection="column"
        width="100%"
        gap="10px"
        padding="25px 0 35px 0"
      >
        <FormItem
          elementWidth="25%"
          label="Transaction 1"
          inputType="input"
          value={transactionAmountOne}
          setValue={(e) =>
            handleInputChange(
              e,
              setTransactionAmountOne,
              setTransactionAmountOneErrorMessage,
            )
          }
          inputPlaceholder="0"
          blurHandler={(e) =>
            checkValidity(e.target, setTransactionAmountOneErrorMessage)
          }
          blurOnWheel
          adornment
          adornmentPosition="start"
          adornmentSymbol="$"
          errorMessage={transactionAmountOneErrorMessage}
          inputProps={{
            step: 0.01,
            min: 0,
            type: "number",
            style: {
              textAlign: "end",
            },
          }}
        />
        <FormItem
          elementWidth="25%"
          label="Transaction 2"
          inputType="input"
          value={transactionAmountTwo}
          setValue={(e) =>
            handleInputChange(
              e,
              setTransactionAmountTwo,
              setTransactionAmountTwoErrorMessage,
            )
          }
          inputPlaceholder="0"
          blurHandler={(e) =>
            checkValidity(e.target, setTransactionAmountTwoErrorMessage)
          }
          blurOnWheel
          adornment
          adornmentPosition="start"
          adornmentSymbol="$"
          errorMessage={transactionAmountTwoErrorMessage}
          inputProps={{
            step: 0.01,
            min: 0,
            type: "number",
            style: {
              textAlign: "end",
            },
          }}
        />
      </FlexContainer>
      <PillButton
        color="primary"
        variant="contained"
        width="33%"
        customfontsize="1.25rem"
        onClick={verifyPayment}
        disabled={
          submitDisabled ||
          !!impersonatedUser ||
          userRoles?.includes(CLIENT_ADMIN_ROLE)
        }
      >
        Submit
      </PillButton>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      footerNote ? (
        <>
          <Divider sx={{ paddingTop: "50px" }} flexItem />
          <EditableText
            renderedText={footerNote}
            setRenderedText={setFooterNote}
            elementKey={VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY,
            )}
            padding="5px 0 0 0"
            fontSize={12}
            withHyperlink
          />
        </>
      ) : (
        ""
      )}
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={(e) => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
      />
    </FlexContainer>
  );
};

export default VerifyPaymentMethod;
