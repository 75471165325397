import {
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import RequireAuth from "../../shared/components/guards/RequireAuth";
import {
  FlexContainer,
  RelativeContainer,
  SemiCircleContainer,
} from "../../shared/components/styled/Container.styled";
import { hex2rgba } from "../../shared/utils/utility-functions";
import WaveContainer from "./components/WaveContainer";
import { PillButton } from "../../shared/components/styled/Button.styled";
import {
  USER_MANAGEMENT_SEARCH_FAIL_MESSAGE,
  USER_MANAGEMENT_SELF_SEARCH_MESSAGE,
} from "../../shared/constants/Configurator.constants";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { getUserByEmail } from "../../data/tenantAPI";
import ActionSnackbar from "../../shared/components/ActionSnackbar";
import {
  PAYMENTS_BORROWER_ROLE,
  USER_IMPERSONATION_NOT_ALLOWED,
  USER_IMPERSONATION_NOT_BORROWER,
} from "../../shared/constants/Roles.constants";
import { getLoans } from "../../data/loanAPI";

const Support = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    token,
    userRoles,
    setVisibleRole,
    impersonatedUser,
    setLoans,
    setActiveLoan,
    setImpersonatedUser,
    setImpersonatedUserDetails,
  } = useContext(AuthContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);

  const [email, setEmail] = useState("");
  const [borrowerDetails, setBorrowerDetails] = useState(null);
  const [borrowerLoans, setBorrowerLoans] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [loanSelectionOpacity, setLoanSelectionOpacity] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const onKeyUpHandle = (e) => {
    if (e.key === "Enter" && email) {
      searchUser();
    }
  };

  const buttonHandler = () => {
    if (selectedLoan) {
      setImpersonatedUser(borrowerDetails.id);
      setImpersonatedUserDetails(borrowerDetails);
      setLoans(borrowerLoans);
      setActiveLoan(selectedLoan);
      setVisibleRole(PAYMENTS_BORROWER_ROLE);
      navigate("/", { replace: true });
    } else {
      searchUser();
    }
  };

  const searchUser = async () => {
    addPendingRequest();
    const userResponse = await getUserByEmail(token, email, impersonatedUser);
    if (userResponse && userResponse?.id?.length > 0) {
      if (userResponse.roles.includes(PAYMENTS_BORROWER_ROLE)) {
        setBorrowerDetails(userResponse);
        const impersonatedUserLoans = await getLoans(
          token,
          userRoles,
          userResponse.id,
        );
        if (impersonatedUserLoans?.ok === false) {
          setSnackbarMessage(USER_IMPERSONATION_NOT_ALLOWED);
          setSnackbarOpen(true);
          removePendingRequest();
          return;
        }
        setBorrowerLoans(impersonatedUserLoans);
        setSelectedLoan(
          impersonatedUserLoans.length > 0
            ? impersonatedUserLoans[0]
            : "no-loan",
        );
        setTimeout(() => {
          setLoanSelectionOpacity(1);
        }, 200);
      } else {
        setSnackbarMessage(USER_IMPERSONATION_NOT_BORROWER);
        setSnackbarOpen(true);
      }
    } else if (userResponse && userResponse.status) {
      switch (userResponse.status) {
        case 400:
          if (userResponse.userMessage.includes("self")) {
            setSnackbarMessage(USER_MANAGEMENT_SELF_SEARCH_MESSAGE);
            setSnackbarOpen(true);
          }
          break;
        case 404:
          setSnackbarMessage(`User with email ${email} could not be found.`);
          setSnackbarOpen(true);
          break;
        case 500:
          setSnackbarMessage(USER_MANAGEMENT_SEARCH_FAIL_MESSAGE);
          setSnackbarOpen(true);
          break;
        default:
          break;
      }
    }
    removePendingRequest();
  };

  const changeUser = () => {
    setEmail("");
    setBorrowerDetails(null);
    setLoanSelectionOpacity(0);
    setTimeout(() => {
      setBorrowerLoans(null);
      setSelectedLoan(null);
    }, 500);
  };

  return (
    <RequireAuth>
      <RelativeContainer
        sx={{
          height: "705px",
          width: "100%",
          background: `linear-gradient(90deg, ${
            theme.palette.background.default
          } 0%, ${hex2rgba(theme.palette.primary.main, 0.1)} 50%)`,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            height: "220px",
            marginBottom: "150px",
            overflow: "visible",
          }}
        >
          <FlexContainer flexdirection="column" sx={{ position: "relative" }}>
            <Typography
              variant="h1"
              color="primary"
              fontWeight="bold"
              sx={{ position: "absolute", top: 120, zIndex: 1060 }}
            >
              Start impersonating any borrower in your application
            </Typography>
            <TextField
              variant="standard"
              label={borrowerDetails ? "" : "Email address"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={onKeyUpHandle}
              disabled={!!borrowerDetails}
              InputProps={{
                startAdornment: borrowerDetails ? (
                  <InputAdornment position="start">
                    <CheckIcon color="success" />
                  </InputAdornment>
                ) : null,
                endAdornment: borrowerDetails ? (
                  <InputAdornment position="end">
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      onClick={() => changeUser()}
                      sx={{ cursor: "pointer" }}
                    >
                      Change
                    </Typography>
                  </InputAdornment>
                ) : null,
              }}
              sx={[
                {
                  width: "300px",
                  position: "absolute",
                  top: 170,
                  zIndex: 1060,
                  transition: "all 0.3s ease-in-out",

                  // ISSUE: Browser default autofill background color disrupts primary color background and cannot be overwritten to transparent
                  // WORKAROUND: Delay the autofill background color by a huge amount of time, not to disrupt the primary color container
                  "& .MuiInput-input:-webkit-autofill": {
                    transition: "background-color 50000s",
                  },
                },
                borrowerDetails && {
                  background: hex2rgba(theme.palette.primary.main, 0.1),
                  borderRadius: 2,
                  padding: "10px",
                  "& .MuiInput-underline::after, & .MuiInput-underline::before":
                    {
                      borderBottom: "none",
                    },
                  "& .MuiInput-input:disabled": {
                    WebkitTextFillColor: theme.palette.text.primary,
                  },
                },
              ]}
            ></TextField>
            {borrowerLoans && selectedLoan ? (
              selectedLoan === "no-loan" ? (
                <Typography
                  sx={{
                    position: "absolute",
                    opacity: loanSelectionOpacity,
                    transition: "opacity 0.3s ease-in-out 0.2s",
                    top: 240,
                    zIndex: 1060,
                  }}
                >
                  Borrower has no loans registered
                </Typography>
              ) : (
                <FormControl
                  variant="standard"
                  sx={{
                    width: "300px",
                    position: "absolute",
                    opacity: loanSelectionOpacity,
                    transition: "opacity 0.3s ease-in-out 0.2s",
                    top: 240,
                    zIndex: 1060,
                  }}
                >
                  <Select
                    value={selectedLoan}
                    onChange={(e) => setSelectedLoan(e.target.value)}
                  >
                    {borrowerLoans.map((loan) => (
                      <MenuItem key={loan} value={loan}>
                        {loan}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            ) : null}
            <PillButton
              variant="contained"
              color="primary"
              width="300px"
              onClick={(e) => buttonHandler()}
              disabled={!email || selectedLoan === "no-loan"}
              sx={{
                position: "absolute",
                top: selectedLoan ? 290 : 240,
                transition: "top 0.3s ease-in-out",
                zIndex: 1060,
              }}
            >
              {selectedLoan ? "Proceed" : "Search User"}
            </PillButton>
          </FlexContainer>
          <SemiCircleContainer />
        </Paper>
        <WaveContainer />
      </RelativeContainer>
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={(e) => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
      />
    </RequireAuth>
  );
};

export default Support;
