import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import {
  getPaymentMethods,
  putPaymentMethod,
} from "../../../../data/paymentsAPI";
import EditableText from "../../../../shared/components/configurator/EditableText";
import PaymentMethodsCell from "../../../../shared/components/table/PaymentMethodsCell";
import DataTable from "../../../../shared/components/DataTable";
import {
  AbsoluteContainer,
  FlexContainer,
  RelativeContainer,
} from "../../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../../shared/components/styled/Typography.styled";
import { PLACEHOLDER_DESCRIPTION } from "../../../../shared/constants/Defaults.constants";
import {
  PAYMENT_METHODS_DESCRIPTION_KEY,
  PAYMENT_METHODS_FOOTER_NOTE_KEY,
} from "../../../../shared/constants/Configurator.constants";
import {
  DELETE_PAYMENT_METHOD_CONFIRMATION_BODY,
  PAYMENT_METHODS_ACTIONS,
  PAYMENT_METHODS_FIELD_TYPES,
  PAYMENT_METHODS_HEADER_NAMES,
  PAYMENT_METHODS_NO_DATA_MESSAGE,
  RENAME_PAYMENT_METHOD_CONFIRMATION_BODY,
} from "../../../../shared/constants/Payments.constants";
import { findElementIncludedInArray } from "../../../../shared/utils/utility-functions";
import ConfirmationDialog from "../../../../shared/components/ConfirmationDialog";
import { deleteMethod } from "../../../../shared/utils/payment-method-functions";
import ActionSnackbar from "../../../../shared/components/ActionSnackbar";
import { useTheme } from "@mui/styles";
import { CLIENT_ADMIN_ROLE } from "../../../../shared/constants/Roles.constants";

const PaymentMethodsList = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { token, userRoles, visibleRole, impersonatedUser } =
    useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const theme = useTheme();

  const [description, setDescription] = useState(PLACEHOLDER_DESCRIPTION);
  const [footerNote, setFooterNote] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [rowHasBeenDeleted, setRowHasBeenDeleted] = useState(false);
  const [currentRowName, setCurrentRowName] = useState(null);
  const [currentRow, setCurrentRow] = useState({});

  const [deleteMethodConfirmationOpen, setDeleteMethodConfirmationOpen] =
    useState(false);

  const [updateNicknameConfirmationOpen, setUpdateNicknameConfirmationOpen] =
    useState(false);

  const [
    paymentMethodNicknameErrorMessage,
    setPaymentMethodNicknameErrorMessage,
  ] = useState("");
  const [
    paymentMethodNicknameSnackbarOpen,
    setPaymentMethodNicknameSnackbarOpen,
  ] = useState(false);

  useEffect(() => {
    if (rowHasBeenDeleted === true) {
      const dataCopy = [...paymentMethods];
      const rowIndex = dataCopy.findIndex(
        (item) => item.paymentMethodId === currentRow.paymentMethodId,
      );

      const updatedRowList = [
        ...dataCopy.slice(0, rowIndex),
        ...dataCopy.slice(rowIndex + 1),
      ];
      setPaymentMethods(updatedRowList);
      setRowHasBeenDeleted(false);
      setCurrentRow({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowHasBeenDeleted]);

  useEffect(() => {
    setRowHasBeenDeleted(false);
    fillConfiguratorFields(edittedFields);
    if (token) {
      callPaymentMethodsApi(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts, token]);

  const callPaymentMethodsApi = async (token) => {
    addPendingRequest();
    const methods = await getPaymentMethods(token, impersonatedUser);
    const mappedMethods = methods?.length
      ? methods.map((method) => {
          return {
            paymentMethodId: method.paymentMethodId,
            name: method.nickname,
            actions: [...PAYMENT_METHODS_ACTIONS],
            valid: method.paymentMethodId ? true : false,
            isInput: false,
          };
        })
      : [];

    mappedMethods.sort((a, b) => {
      const compareValid = b.valid - a.valid;
      const compareName = a.name.localeCompare(b.name);
      return compareValid || compareName;
    });

    setPaymentMethods(mappedMethods);
    removePendingRequest();
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case PAYMENT_METHODS_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case PAYMENT_METHODS_FOOTER_NOTE_KEY:
          setFooterNote(item.value);
          return;
        default:
          return;
      }
    });
  };

  //Delete Payment Method

  const handleDeleteMethodConfirmationClose = (rowInfo, accounts, instance) => {
    deleteMethod(
      rowInfo.name,
      token,
      impersonatedUser,
      addPendingRequest,
      removePendingRequest,
      {
        delete: setRowHasBeenDeleted,
      },
    );
    setDeleteMethodConfirmationOpen(false);
  };

  const triggerDelete = (rowInfo) => {
    setDeleteMethodConfirmationOpen(true);
    setCurrentRow(rowInfo);
  };

  //Update Payment Method Nickname

  const rowInputToggle = (rowInfo) => {
    setCurrentRowName(rowInfo.name);
    const dataCopy = [...paymentMethods];
    const rowIndex = dataCopy.findIndex(
      (item) =>
        item.paymentMethodId === rowInfo.paymentMethodId &&
        item.name === rowInfo.name,
    );

    const updatedRowList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        isInput: !dataCopy[rowIndex].isInput,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];
    setPaymentMethods(updatedRowList);
  };

  const updatePaymentMethodNickname = (newNickname, rowInfo) => {
    setCurrentRowName(newNickname);
    updateMethodNickname(rowInfo);
    setUpdateNicknameConfirmationOpen(false);
  };

  const updateMethodNickname = (rowInfo) => {
    rowInfo.isInput = false;
    callPutMethodPaymentsApi(token, rowInfo.name, currentRowName);
  };

  const callPutMethodPaymentsApi = (token, nickname, currentRowName) => {
    addPendingRequest();
    const requestBody = {
      nickname: currentRowName,
    };
    putPaymentMethod(token, nickname, requestBody, impersonatedUser).then(
      (data) => {
        if (data?.message) {
          setPaymentMethodNicknameErrorMessage(data.message);
          setPaymentMethodNicknameSnackbarOpen(true);
          removePendingRequest();
          // TODO: Handle future messages
          return;
        }
        if (data?.success === true) {
          const dataCopy = [...paymentMethods];
          const rowIndex = dataCopy.findIndex(
            (item) =>
              item.paymentMethodId === currentRow.paymentMethodId &&
              item.name === currentRow.name,
          );

          const updatedRowList = [
            ...dataCopy.slice(0, rowIndex),
            {
              ...dataCopy[rowIndex],
              name: currentRowName,
            },
            ...dataCopy.slice(rowIndex + 1),
          ];
          setPaymentMethods(updatedRowList);
          setCurrentRow({});
          setCurrentRowName(null);
        }
        removePendingRequest();
      },
    );
  };

  const checkOpenUpdateConfirmationDialog = (e, rowInfo) => {
    if (e.key === "Enter") {
      setCurrentRow(rowInfo);
      setUpdateNicknameConfirmationOpen(true);
    }
  };

  const handleNicknameErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      setPaymentMethodNicknameSnackbarOpen(false);
      return;
    }
  };

  const closeUpdateNicknameConfirmationDialog = () => {
    currentRow.isInput = false;
    setUpdateNicknameConfirmationOpen(false);
  };

  return (
    <FlexContainer flexdirection="column" width="100%">
      <SectionTitle variant="h2" color="primary">
        Manage Payment Methods
      </SectionTitle>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={PAYMENT_METHODS_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            PAYMENT_METHODS_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <RelativeContainer width="100%" padding="0 0 25px 0">
        <DataTable
          headers={PAYMENT_METHODS_HEADER_NAMES}
          rows={paymentMethods}
          emptyDataMessage={
            paymentMethods.length === 0 ? PAYMENT_METHODS_NO_DATA_MESSAGE : ""
          }
          rowAttributes={PAYMENT_METHODS_FIELD_TYPES}
          CellDisplayComponent={PaymentMethodsCell}
          minWidth="350px"
          cellLogicCallbacks={{
            renameToggle: rowInputToggle,
            delete: setRowHasBeenDeleted,
            onChangeInput: setCurrentRowName,
            triggerDelete: triggerDelete,
            triggerUpdate: checkOpenUpdateConfirmationDialog,
          }}
        />
        <AbsoluteContainer sx={{ top: 8, right: 16 }}>
          <Fab
            color="primary"
            size="small"
            sx={{ width: 30, height: 30, minHeight: 30 }}
            onClick={(e) => navigate("new")}
          >
            <AddIcon />
          </Fab>
        </AbsoluteContainer>
      </RelativeContainer>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      footerNote ? (
        <EditableText
          renderedText={footerNote}
          setRenderedText={setFooterNote}
          elementKey={PAYMENT_METHODS_FOOTER_NOTE_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            PAYMENT_METHODS_FOOTER_NOTE_KEY,
          )}
          padding="5px 0 0 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <ConfirmationDialog
        open={deleteMethodConfirmationOpen}
        close={(e) =>
          handleDeleteMethodConfirmationClose(currentRow, accounts, instance)
        }
        dismiss={(e) => setDeleteMethodConfirmationOpen(false)}
        title="Confirm deleting payment method"
        body={DELETE_PAYMENT_METHOD_CONFIRMATION_BODY}
      />
      <ConfirmationDialog
        open={updateNicknameConfirmationOpen}
        close={(e) => updatePaymentMethodNickname(currentRowName, currentRow)}
        dismiss={(e) => closeUpdateNicknameConfirmationDialog(currentRow)}
        title="Confirm renaming payment method"
        body={RENAME_PAYMENT_METHOD_CONFIRMATION_BODY}
      />
      <ActionSnackbar
        severity="warning"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={paymentMethodNicknameSnackbarOpen}
        closeFn={handleNicknameErrorSnackbarClose}
        snackbarLabel={paymentMethodNicknameErrorMessage}
      />
    </FlexContainer>
  );
};

export default PaymentMethodsList;
