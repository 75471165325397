import { TextField, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import {
  LOGOUT_ALLOW_MESSAGE_LABEL,
  LOGOUT_HAS_MESSAGE_KEY,
  LOGOUT_MESSAGE_KEY,
  LOGOUT_MESSAGE_LABEL,
  LOGOUT_SUBTITLE_KEY,
  LOGOUT_SUBTITLE_LABEL,
} from "../../../../shared/constants/Configurator.constants";
import { useDebouncedEffect } from "../../../../shared/hooks/useDebouncedEffect";
import { useIsMount } from "../../../../shared/hooks/useIsMount";
import { hex2rgba } from "../../../../shared/utils/utility-functions";
import ExpandableSetting from "../../shared/ExpandableSetting";

const LogoutMessage = () => {
  const theme = useTheme();
  const isFirstRender = useIsMount();
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [logoutMessage, setLogoutMessage] = useState(
    edittedFields.find((item) => item.key === LOGOUT_MESSAGE_KEY)?.value || "",
  );
  const [logoutSubtitle, setLogoutSubtitle] = useState(
    edittedFields.find((item) => item.key === LOGOUT_SUBTITLE_KEY)?.value || "",
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: LOGOUT_MESSAGE_KEY,
          value: logoutMessage,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [logoutMessage],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: LOGOUT_SUBTITLE_KEY,
          value: logoutSubtitle,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [logoutSubtitle],
    200,
  );

  return (
    <ExpandableSetting
      elementKey={LOGOUT_HAS_MESSAGE_KEY}
      mainLabel={LOGOUT_ALLOW_MESSAGE_LABEL}
      expandCondition="true"
      expandedComponent={
        <FlexContainer
          width="100%"
          flexdirection="column"
          gap="15px"
          padding="15px"
          background={hex2rgba(theme.palette.primary.main, 0.1)}
        >
          <FlexContainer width="100%" gap="25px" justifycontent="space-between">
            <Typography
              color="primary"
              fontWeight="bold"
              fontSize={16}
              sx={{ flexGrow: 3 }}
            >
              {LOGOUT_MESSAGE_LABEL}
            </Typography>
            <TextField
              multiline
              sx={{ width: "50%" }}
              variant="standard"
              value={logoutMessage}
              onChange={(e) => setLogoutMessage(e.target.value)}
            />
          </FlexContainer>
          <FlexContainer width="100%" gap="25px" justifycontent="space-between">
            <Typography
              color="primary"
              fontWeight="bold"
              fontSize={16}
              sx={{ flexGrow: 3 }}
            >
              {LOGOUT_SUBTITLE_LABEL}
            </Typography>
            <TextField
              multiline
              sx={{ width: "50%" }}
              variant="standard"
              value={logoutSubtitle}
              onChange={(e) => setLogoutSubtitle(e.target.value)}
            />
          </FlexContainer>
        </FlexContainer>
      }
    />
  );
};

export default LogoutMessage;
