import React from "react";
import { Typography } from "@mui/material";
import { FlexContainer } from "../styled/Container.styled";

const TwoToneItem = ({
  breakpoint = "&nbsp;",
  data = "",
  toneOne = "primary",
  toneTwo = "text",
  prefix = "",
  fontSize = "1rem",
  prefixFontSize = "0.8571rem",
  alignSelf = "auto",
}) => {
  const brokenString = data.split(breakpoint);
  return (
    <FlexContainer alignself={alignSelf}>
      <Typography
        alignSelf="flex-start"
        fontSize={prefixFontSize}
        fontWeight="bold"
        color={toneOne}
      >
        {prefix}
      </Typography>
      <Typography fontSize={fontSize} fontWeight="bold" color={toneOne}>
        {brokenString[0]}
      </Typography>
      <Typography fontSize={fontSize} fontWeight="bold" color={toneTwo}>
        {breakpoint}
        {brokenString[1]}
      </Typography>
    </FlexContainer>
  );
};

export default TwoToneItem;
