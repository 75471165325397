import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/styles";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import EditableText from "../../../shared/components/configurator/EditableText";
import ChangeEmailMobileForm from "./components/ChangeEmailMobileForm";
import {
  CHANGE_ACCOUNT_DETAILS_DESCRIPTION_KEY,
  NOTIFICATIONS_ALLOW_KEY,
  NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY,
} from "../../../shared/constants/Configurator.constants";
import {
  findElementIncludedInArray,
  findInArray,
  formatPhoneNumber,
  removeNonDigits,
} from "../../../shared/utils/utility-functions";
import {
  deleteUserAttributes,
  getUserAttributes,
  postUserAttributes,
} from "../../../data/tenantAPI";
import { useMsal } from "@azure/msal-react";
import NotificationsPreferencesOption from "./components/NotificationsPreferencesOption";
import {
  DEFAULT_NOTIFICATION_CONFIGURATION,
  COMMUNICATION_PREFERENCES_UPDATE_FAILED_MESSAGE,
  COMMUNICATION_PREFERENCES_UPDATE_SUCCESSFUL_MESSAGE,
  ELECTRONIC_STATEMENT_NOTIFICATION_KEY,
  HAZARD_RENEWAL_NOTIFICATION_KEY,
  NOTIFICATIONS_DISABLED_UPDATE_FAILED_MESSAGE,
  NOTIFICATIONS_DISABLED_UPDATE_SUCCESSFUL_MESSAGE,
  NOTIFICATION_DEFAULT_VALUE,
  PAYMENT_DUE_NOTIFICATION_KEY,
  PAYMENT_PROCESSED_NOTIFICATION_KEY,
  PAYMENT_RETURNED_NOTIFICATION_KEY,
  PREFERENCES_NOT_UPDATED_MESSAGE,
  PREFERENCES_UPDATED_MESSAGE,
  REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY,
  USER_EMAIL_ADDRESS_KEY,
  USER_MOBILE_NUMBER_KEY,
} from "../../../shared/constants/Notifications.constants";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import { ThemeContext } from "../../../contexts/ThemeContext";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import { NOTIFICATIONS_NOT_ALLOWED } from "../../../shared/constants/UserProfile.constants";
import { Typography } from "@mui/material";

const CommunicationPreferences = () => {
  const { accounts } = useMsal();
  const theme = useTheme();
  const { userRoles, visibleRole, token, impersonatedUser } =
    useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);

  const [description, setDescription] = useState("");
  const [loaded, setLoaded] = useState(false);

  // Input Field States
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  // Notification Options States
  const [paymentDueNotification, setPaymentDueNotification] = useState("");
  const [paymentDueNotificationChecked, setPaymentDueNotificationChecked] =
    useState(false);
  const [paymentProcessedNotification, setPaymentProcessedNotification] =
    useState("");
  const [
    paymentProcessedNotificationChecked,
    setPaymentProcessedNotificationChecked,
  ] = useState(false);
  const [paymentReturnedNotification, setPaymentReturnedNotification] =
    useState("");
  const [
    paymentReturnedNotificationChecked,
    setPaymentReturnedNotificationChecked,
  ] = useState(false);
  const [hazardRenewalNotification, setHazardRenewalNotification] =
    useState("");
  const [
    hazardRenewalNotificationChecked,
    setHazardRenewalNotificationChecked,
  ] = useState(false);
  const [electronicStatementNotification, setElectronicStatementNotification] =
    useState("");
  const [
    electronicStatementNotificationChecked,
    setElectronicStatementNotificationChecked,
  ] = useState(false);
  const [realEstateTaxPaidNotification, setRealEstateTaxPaidNotification] =
    useState("");
  const [
    realEstateTaxPaidNotificationChecked,
    setRealEstateTaxPaidNotificationChecked,
  ] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Error Message States
  const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState("");
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState("");

  const [currentPreferences, setCurrentPreferences] = useState([]);
  const [updatedPreferences, setUpdatedPreferences] = useState([]);

  const [notificationsAllowed, setNotificationsAllowed] = useState(false);
  const [notificationsConfiguration, setNotificationsConfiguration] = useState(
    DEFAULT_NOTIFICATION_CONFIGURATION,
  );

  // Snackbar Message States
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    if (token) {
      callTenantApi(token);
      fillConfiguratorFields(edittedFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (loaded) {
      if (
        updatedPreferences !== currentPreferences &&
        mobileNumberErrorMessage === "" &&
        emailAddressErrorMessage === ""
      ) {
        setSubmitDisabled(false);
      } else setSubmitDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPreferences, mobileNumberErrorMessage, emailAddressErrorMessage]);

  useEffect(() => {
    if (loaded) {
      updateOption(
        PAYMENT_DUE_NOTIFICATION_KEY,
        paymentDueNotification,
        paymentDueNotificationChecked,
        setPaymentDueNotification,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDueNotification, paymentDueNotificationChecked]);

  useEffect(() => {
    if (loaded) {
      updateOption(
        PAYMENT_PROCESSED_NOTIFICATION_KEY,
        paymentProcessedNotification,
        paymentProcessedNotificationChecked,
        setPaymentProcessedNotification,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProcessedNotification, paymentProcessedNotificationChecked]);

  useEffect(() => {
    if (loaded) {
      updateOption(
        PAYMENT_RETURNED_NOTIFICATION_KEY,
        paymentReturnedNotification,
        paymentReturnedNotificationChecked,
        setPaymentReturnedNotification,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentReturnedNotification, paymentReturnedNotificationChecked]);

  useEffect(() => {
    if (loaded) {
      updateOption(
        HAZARD_RENEWAL_NOTIFICATION_KEY,
        hazardRenewalNotification,
        hazardRenewalNotificationChecked,
        setHazardRenewalNotification,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hazardRenewalNotification, hazardRenewalNotificationChecked]);

  useEffect(() => {
    if (loaded) {
      updateOption(
        ELECTRONIC_STATEMENT_NOTIFICATION_KEY,
        electronicStatementNotification,
        electronicStatementNotificationChecked,
        setElectronicStatementNotification,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electronicStatementNotification, electronicStatementNotificationChecked]);

  useEffect(() => {
    if (loaded) {
      updateOption(
        REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY,
        realEstateTaxPaidNotification,
        realEstateTaxPaidNotificationChecked,
        setRealEstateTaxPaidNotification,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realEstateTaxPaidNotification, realEstateTaxPaidNotificationChecked]);

  useEffect(() => {
    if (loaded) {
      updateOption(USER_EMAIL_ADDRESS_KEY, emailAddress, true, setEmailAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress]);

  useEffect(() => {
    if (loaded) {
      updateOption(USER_MOBILE_NUMBER_KEY, mobileNumber, true, setMobileNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber]);

  const updateOption = (key, value, checked, setValue) => {
    const dataCopy = [...updatedPreferences];
    const rowIndex = dataCopy.findIndex((item) => item.attribute.name === key);
    if (key === USER_MOBILE_NUMBER_KEY) {
      value = removeNonDigits(mobileNumber);
    }
    if (rowIndex >= 0) {
      const tempValue = checked ? value : "";
      const updatedPreferencesCopy = [
        ...dataCopy.slice(0, rowIndex),
        {
          ...dataCopy[rowIndex],
          operation: "",
          attribute: {
            name: key,
            value: tempValue,
          },
        },
        ...dataCopy.slice(rowIndex + 1),
      ];

      setUpdatedPreferences(updatedPreferencesCopy);
    }

    if (key !== USER_EMAIL_ADDRESS_KEY && key !== USER_MOBILE_NUMBER_KEY) {
      if (checked && !value && notificationsConfiguration[key] === 3) {
        setValue(NOTIFICATION_DEFAULT_VALUE);
      }
      if (!checked && value) {
        setValue("");
      }
    }
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case CHANGE_ACCOUNT_DETAILS_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case NOTIFICATIONS_ALLOW_KEY:
          getNotificationConfiguration(item.value === "true");
          return;
        default:
          return;
      }
    });
  };

  const getNotificationConfiguration = (isAllowed) => {
    if (isAllowed) {
      setNotificationsAllowed(true);
      const notificationConfigField = findInArray(
        edittedFields,
        "key",
        NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY,
      );
      if (notificationConfigField && notificationConfigField.value) {
        const notificationConfig = {};
        notificationConfigField.value.split(";").forEach((item) => {
          const notificationValues = item.split("=");
          notificationConfig[notificationValues[0]] = +notificationValues[1];
        });
        setNotificationsConfiguration(notificationConfig);
      }
    }
  };

  const createUserPreferencesPostBody = () => {
    if (updatedPreferences !== currentPreferences) {
      let userPreferencesPostBody = updatedPreferences
        .filter(
          (item, index) =>
            item.attribute.value !== "" &&
            item.attribute.value !== currentPreferences[index].attribute.value,
        )
        .map((item) => {
          return {
            operation: "POST",
            attribute: {
              name: item.attribute.name,
              value: item.attribute.value,
            },
          };
        });

      return userPreferencesPostBody;
    }
  };

  const createUserPreferencesDeleteBody = () => {
    if (updatedPreferences !== currentPreferences) {
      let userPreferencesDeleteBody = updatedPreferences
        .filter(
          (item, index) =>
            item.attribute.value === "" &&
            item.attribute.value !== currentPreferences[index].attribute.value,
        )
        .map((item) => {
          return {
            operation: "DELETE",
            attribute: {
              name: item.attribute.name,
            },
          };
        });
      return userPreferencesDeleteBody;
    }
  };

  const callTenantApi = async (token) => {
    addPendingRequest();
    let paymentDue = paymentDueNotification;
    let paymentProcessed = paymentProcessedNotification;
    let paymentReturned = paymentReturnedNotification;
    let hazardRenewal = hazardRenewalNotification;
    let realEstateTaxPaid = realEstateTaxPaidNotification;
    let electronicStatement = electronicStatementNotification;
    let userEmail = emailAddress;
    let userMobile = mobileNumber;

    const communicationPreferencesData = await getUserAttributes(
      token,
      impersonatedUser ? impersonatedUser : accounts[0]?.localAccountId,
      userRoles,
      impersonatedUser,
    );
    communicationPreferencesData
      .filter((item) => item.httpStatus === "OK")
      .forEach((item) => {
        switch (item.attribute.name) {
          case PAYMENT_DUE_NOTIFICATION_KEY:
            paymentDue = item.attribute.value;
            setPaymentDueNotification(item.attribute.value);
            setPaymentDueNotificationChecked(true);
            break;
          case PAYMENT_PROCESSED_NOTIFICATION_KEY:
            paymentProcessed = item.attribute.value;
            setPaymentProcessedNotification(item.attribute.value);
            setPaymentProcessedNotificationChecked(true);
            break;
          case PAYMENT_RETURNED_NOTIFICATION_KEY:
            paymentReturned = item.attribute.value;
            setPaymentReturnedNotification(item.attribute.value);
            setPaymentReturnedNotificationChecked(true);
            break;
          case HAZARD_RENEWAL_NOTIFICATION_KEY:
            hazardRenewal = item.attribute.value;
            setHazardRenewalNotification(item.attribute.value);
            setHazardRenewalNotificationChecked(true);
            break;
          case ELECTRONIC_STATEMENT_NOTIFICATION_KEY:
            electronicStatement = item.attribute.value;
            setElectronicStatementNotification(item.attribute.value);
            setElectronicStatementNotificationChecked(true);
            break;
          case REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY:
            realEstateTaxPaid = item.attribute.value;
            setRealEstateTaxPaidNotification(item.attribute.value);
            setRealEstateTaxPaidNotificationChecked(true);
            break;
          case USER_EMAIL_ADDRESS_KEY:
            userEmail = item.attribute.value;
            setEmailAddress(item.attribute.value);
            break;
          case USER_MOBILE_NUMBER_KEY:
            userMobile = formatPhoneNumber(item.attribute.value);
            setMobileNumber(formatPhoneNumber(item.attribute.value));
            break;
          default:
            break;
        }
      });

    const temp = [
      {
        operation: "",
        attribute: {
          name: PAYMENT_DUE_NOTIFICATION_KEY,
          value: paymentDue,
        },
      },
      {
        operation: "",
        attribute: {
          name: PAYMENT_PROCESSED_NOTIFICATION_KEY,
          value: paymentProcessed,
        },
      },
      {
        operation: "",
        attribute: {
          name: PAYMENT_RETURNED_NOTIFICATION_KEY,
          value: paymentReturned,
        },
      },
      {
        operation: "",
        attribute: {
          name: HAZARD_RENEWAL_NOTIFICATION_KEY,
          value: hazardRenewal,
        },
      },
      {
        operation: "",
        attribute: {
          name: ELECTRONIC_STATEMENT_NOTIFICATION_KEY,
          value: electronicStatement,
        },
      },
      {
        operation: "",
        attribute: {
          name: REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY,
          value: realEstateTaxPaid,
        },
      },
      {
        operation: "",
        attribute: {
          name: USER_EMAIL_ADDRESS_KEY,
          value: userEmail,
        },
      },
      {
        operation: "",
        attribute: {
          name: USER_MOBILE_NUMBER_KEY,
          value: userMobile,
        },
      },
    ];

    setCurrentPreferences(temp);
    setUpdatedPreferences(temp);
    removePendingRequest();
    setLoaded(true);
  };

  const handleConfirmationClose = async () => {
    const userPreferencesPostBody = createUserPreferencesPostBody();
    const userPreferencesDeleteBody = createUserPreferencesDeleteBody();

    let postResponse = undefined;
    let deleteResponse = undefined;

    if (userPreferencesDeleteBody.length > 0) {
      deleteResponse = await deleteUserAttributes(
        token,
        accounts[0].localAccountId,
        userPreferencesDeleteBody,
        impersonatedUser,
      );
    }
    if (userPreferencesPostBody.length > 0) {
      postResponse = await postUserAttributes(
        token,
        accounts[0].localAccountId,
        userPreferencesPostBody,
        impersonatedUser,
      );
    }

    if (deleteResponse && postResponse) {
      if (deleteResponse.ok && postResponse.ok) {
        setSnackBarMessage(PREFERENCES_UPDATED_MESSAGE);
        setSnackbarSeverity("success");
        setSnackBarOpen(true);
      } else if (!deleteResponse.ok && !postResponse.ok) {
        setSnackBarMessage(PREFERENCES_NOT_UPDATED_MESSAGE);
        setSnackbarSeverity("error");
        setSnackBarOpen(true);
      } else if (postResponse.ok) {
        setSnackBarMessage(
          COMMUNICATION_PREFERENCES_UPDATE_SUCCESSFUL_MESSAGE +
            " " +
            NOTIFICATIONS_DISABLED_UPDATE_FAILED_MESSAGE,
        );
        setSnackbarSeverity("warning");
        setSnackBarOpen(true);
      } else if (deleteResponse.ok) {
        setSnackBarMessage(
          NOTIFICATIONS_DISABLED_UPDATE_SUCCESSFUL_MESSAGE +
            " " +
            COMMUNICATION_PREFERENCES_UPDATE_FAILED_MESSAGE,
        );
        setSnackbarSeverity("warning");
        setSnackBarOpen(true);
      }
    } else if (deleteResponse) {
      if (deleteResponse.ok) {
        setSnackBarMessage(NOTIFICATIONS_DISABLED_UPDATE_SUCCESSFUL_MESSAGE);
        setSnackbarSeverity("success");
        setSnackBarOpen(true);
      } else {
        setSnackBarMessage(COMMUNICATION_PREFERENCES_UPDATE_SUCCESSFUL_MESSAGE);
        setSnackbarSeverity("error");
        setSnackBarOpen(true);
      }
    } else if (postResponse) {
      if (postResponse.ok) {
        setSnackBarMessage(COMMUNICATION_PREFERENCES_UPDATE_SUCCESSFUL_MESSAGE);
        setSnackbarSeverity("success");
        setSnackBarOpen(true);
      } else {
        setSnackBarMessage(COMMUNICATION_PREFERENCES_UPDATE_FAILED_MESSAGE);
        setSnackbarSeverity("error");
        setSnackBarOpen(true);
      }
    }

    callTenantApi(token);
    setConfirmationOpen(false);
  };

  return (
    <FlexContainer flexdirection="column" alignitems="center" width="100%">
      <FlexContainer
        flexdirection="column"
        alignitems="center"
        width="100%"
        padding="0 0 40px 0"
      >
        <SectionTitle variant="h2" color="primary">
          Notifications
        </SectionTitle>
        {notificationsAllowed ? (
          <FlexContainer
            flexdirection="column"
            justifycontent="flex-start"
            height="100%"
            width="100%"
          >
            <FlexContainer
              width="100%"
              flexdirection="column"
              alignself="flex-start"
              alignitems="flex-end"
            >
              <NotificationsPreferencesOption
                typeLabel="Payment Due Notification"
                communicationMethod={
                  paymentDueNotification === "" ? "" : paymentDueNotification
                }
                setCommunicationMethod={setPaymentDueNotification}
                checked={paymentDueNotificationChecked}
                handleChange={(e) =>
                  setPaymentDueNotificationChecked(e.target.checked)
                }
                allowedOptions={
                  notificationsConfiguration[PAYMENT_DUE_NOTIFICATION_KEY]
                }
                isDisabled={
                  !notificationsAllowed ||
                  notificationsConfiguration[PAYMENT_DUE_NOTIFICATION_KEY] === 0
                }
              ></NotificationsPreferencesOption>
              <NotificationsPreferencesOption
                typeLabel="Payment Processed Notification"
                communicationMethod={
                  paymentProcessedNotification === ""
                    ? ""
                    : paymentProcessedNotification
                }
                setCommunicationMethod={setPaymentProcessedNotification}
                checked={paymentProcessedNotificationChecked}
                handleChange={(e) =>
                  setPaymentProcessedNotificationChecked(e.target.checked)
                }
                allowedOptions={
                  notificationsConfiguration[PAYMENT_PROCESSED_NOTIFICATION_KEY]
                }
                isDisabled={
                  !notificationsAllowed ||
                  notificationsConfiguration[
                    PAYMENT_PROCESSED_NOTIFICATION_KEY
                  ] === 0
                }
              ></NotificationsPreferencesOption>
              <NotificationsPreferencesOption
                typeLabel="Payment Returned Notification"
                communicationMethod={
                  paymentReturnedNotification === ""
                    ? ""
                    : paymentReturnedNotification
                }
                setCommunicationMethod={setPaymentReturnedNotification}
                checked={paymentReturnedNotificationChecked}
                handleChange={(e) =>
                  setPaymentReturnedNotificationChecked(e.target.checked)
                }
                allowedOptions={
                  notificationsConfiguration[PAYMENT_RETURNED_NOTIFICATION_KEY]
                }
                isDisabled={
                  !notificationsAllowed ||
                  notificationsConfiguration[
                    PAYMENT_RETURNED_NOTIFICATION_KEY
                  ] === 0
                }
              ></NotificationsPreferencesOption>
              <NotificationsPreferencesOption
                typeLabel="Hazard Renewal Notification"
                communicationMethod={
                  hazardRenewalNotification === ""
                    ? ""
                    : hazardRenewalNotification
                }
                setCommunicationMethod={setHazardRenewalNotification}
                checked={hazardRenewalNotificationChecked}
                handleChange={(e) =>
                  setHazardRenewalNotificationChecked(e.target.checked)
                }
                allowedOptions={
                  notificationsConfiguration[HAZARD_RENEWAL_NOTIFICATION_KEY]
                }
                isDisabled={
                  !notificationsAllowed ||
                  notificationsConfiguration[
                    HAZARD_RENEWAL_NOTIFICATION_KEY
                  ] === 0
                }
              ></NotificationsPreferencesOption>
              <NotificationsPreferencesOption
                typeLabel="Electronic Statement Notification"
                communicationMethod={
                  electronicStatementNotification === ""
                    ? ""
                    : electronicStatementNotification
                }
                setCommunicationMethod={setElectronicStatementNotification}
                checked={electronicStatementNotificationChecked}
                handleChange={(e) =>
                  setElectronicStatementNotificationChecked(e.target.checked)
                }
                allowedOptions={
                  notificationsConfiguration[
                    ELECTRONIC_STATEMENT_NOTIFICATION_KEY
                  ]
                }
                isDisabled={
                  !notificationsAllowed ||
                  notificationsConfiguration[
                    ELECTRONIC_STATEMENT_NOTIFICATION_KEY
                  ] === 0
                }
              ></NotificationsPreferencesOption>
              <NotificationsPreferencesOption
                typeLabel="Real Estate Tax Paid Notification"
                communicationMethod={
                  realEstateTaxPaidNotification === ""
                    ? ""
                    : realEstateTaxPaidNotification
                }
                setCommunicationMethod={setRealEstateTaxPaidNotification}
                checked={realEstateTaxPaidNotificationChecked}
                handleChange={(e) =>
                  setRealEstateTaxPaidNotificationChecked(e.target.checked)
                }
                allowedOptions={
                  notificationsConfiguration[
                    REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY
                  ]
                }
                isDisabled={
                  !notificationsAllowed ||
                  notificationsConfiguration[
                    REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY
                  ] === 0
                }
              ></NotificationsPreferencesOption>
            </FlexContainer>
          </FlexContainer>
        ) : (
          <Typography>{NOTIFICATIONS_NOT_ALLOWED}</Typography>
        )}
      </FlexContainer>
      <FlexContainer flexdirection="column" alignitems="center" width="100%">
        <SectionTitle variant="h2" color="primary">
          Change Email & Mobile
        </SectionTitle>
        {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
          visibleRole === CLIENT_ADMIN_ROLE) ||
        description ? (
          <EditableText
            renderedText={description}
            setRenderedText={setDescription}
            elementKey={CHANGE_ACCOUNT_DETAILS_DESCRIPTION_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              CHANGE_ACCOUNT_DETAILS_DESCRIPTION_KEY,
            )}
            padding="0 0 25px 0"
            fontSize={12}
            withHyperlink
          />
        ) : (
          ""
        )}
        <ChangeEmailMobileForm
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          emailAddressErrorMessage={emailAddressErrorMessage}
          setEmailAddressErrorMessage={setEmailAddressErrorMessage}
          mobileNumberErrorMessage={mobileNumberErrorMessage}
          setMobileNumberErrorMessage={setMobileNumberErrorMessage}
        />
      </FlexContainer>
      <PillButton
        color="primary"
        variant="contained"
        width="33%"
        customfontsize="1.25rem"
        onClick={(e) => setConfirmationOpen(true)}
        disabled={
          submitDisabled ||
          !!impersonatedUser ||
          userRoles?.includes(CLIENT_ADMIN_ROLE)
        }
      >
        Update preferences
      </PillButton>
      <ConfirmationDialog
        open={confirmationOpen}
        close={handleConfirmationClose}
        dismiss={(e) => setConfirmationOpen(false)}
        title="Confirm update"
        body="Do you want to save these communication preferences?"
      />
      <ActionSnackbar
        severity={snackbarSeverity}
        borderColor={theme.palette[snackbarSeverity].main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackBarOpen}
        closeFn={() => {
          setSnackBarOpen(false);
        }}
        snackbarLabel={snackBarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </FlexContainer>
  );
};

export default CommunicationPreferences;
