import React, { createContext, useState } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userRoles, setuserRoles] = useState(null);
  const [visibleRole, setVisibleRole] = useState(null);
  const [loans, setLoans] = useState(null);
  const [activeLoan, setActiveLoan] = useState(null);
  const [activeLoanDetails, setActiveLoanDetails] = useState(null);
  const [impersonatedUser, setImpersonatedUser] = useState(null);
  const [impersonatedUserDetails, setImpersonatedUserDetails] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        token,
        userDetails,
        userRoles,
        visibleRole,
        loans,
        activeLoan,
        activeLoanDetails,
        impersonatedUser,
        impersonatedUserDetails,
        setToken,
        setUserDetails,
        setuserRoles,
        setVisibleRole,
        setLoans,
        setActiveLoan,
        setActiveLoanDetails,
        setImpersonatedUser,
        setImpersonatedUserDetails,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
