import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FlexContainer } from "../styled/Container.styled";
import { ActionText } from "../styled/Typography.styled";
import FormItemDatepicker from "./FormItemDatepicker";
import FormItemDropdown from "./FormItemDropdown";
import FormItemInput from "./FormItemInput";
import FormItemRadio from "./FormItemRadio";
import { ONE_TIME_PAYMENT } from "../../constants/Payments.constants";

const FormItem = ({
  elementWidth = "auto",
  label = "",
  inputType = "",
  highlightedValue = "",
  value = "",
  setValue = () => {},
  hasAction = false,
  actionLabel = "",
  actionCallback = () => {},
  actionCallbackArguments = [],
  hasComponent = false,
  component = <></>,
  selectedType = ONE_TIME_PAYMENT,
  datepickerOpen = false,
  setDatepickerOpen = () => {},
  minDate = "",
  maxDate = "",
  filterDate = () => {
    return true;
  },
  excludedDates = [],
  disabledOptions = [],
  dropdownOptions = [],
  dropdownKey = "",
  dropdownValue = "",
  dropdownLabel = "",
  inputPlaceholder = "",
  blurHandler = () => {},
  blurOnWheel = false,
  adornment = false,
  adornmentPosition = "",
  adornmentSymbol = "",
  radioOptions = [],
  inputProps = {},
  disabled = false,
  errorMessage = "",
}) => {
  const [inputRef, setInputRef] = useState(null);

  useEffect(() => {
    if (inputRef?.current && actionCallbackArguments[0] !== inputRef?.current) {
      actionCallbackArguments.unshift(inputRef.current);
    }
  }, [inputRef, actionCallbackArguments]);

  const renderInputItem = () => {
    switch (inputType) {
      case "input":
        return (
          <FormItemInput
            elementWidth={elementWidth}
            setRef={setInputRef}
            value={value}
            setValue={setValue}
            placeholder={inputPlaceholder}
            blurHandler={blurHandler}
            blurOnWheel={blurOnWheel}
            adornment={adornment}
            adornmentPosition={adornmentPosition}
            adornmentSymbol={adornmentSymbol}
            inputProps={inputProps}
            disabled={disabled}
            errorMessage={errorMessage}
          />
        );
      case "dropdown":
        return (
          <FormItemDropdown
            elementWidth={elementWidth}
            highlightedValue={highlightedValue}
            value={value}
            setValue={setValue}
            disabledOptions={disabledOptions}
            options={dropdownOptions}
            keyAttribute={dropdownKey}
            valueAttribute={dropdownValue}
            labelAttribute={dropdownLabel}
            disabled={disabled}
            errorMessage={errorMessage}
          />
        );
      case "datepicker":
        return (
          <FormItemDatepicker
            elementWidth={elementWidth}
            startDate={value}
            setStartDate={setValue}
            datepickerOpen={datepickerOpen}
            setDatepickerOpen={setDatepickerOpen}
            minDate={minDate}
            maxDate={maxDate}
            filterDate={filterDate}
            excludedDates={excludedDates}
          />
        );
      case "radio":
        return (
          <FormItemRadio
            elementWidth={elementWidth}
            options={radioOptions}
            value={value}
            setValue={setValue}
          />
        );
      default:
        return <Box minWidth={elementWidth} />;
    }
  };

  return (
    <FlexContainer gap="25px" width="100%">
      <Typography fontWeight="bold" width={elementWidth} textAlign="end">
        {label}
      </Typography>
      {renderInputItem()}
      {hasAction ? (
        <ActionText>
          <Typography
            onClick={(e) => actionCallback(...actionCallbackArguments)}
            minWidth={elementWidth}
          >
            {actionLabel}
          </Typography>
        </ActionText>
      ) : hasComponent ? (
        component
      ) : (
        <Box minWidth={elementWidth} />
      )}
    </FlexContainer>
  );
};

export default FormItem;
