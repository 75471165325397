import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import FormItem from "../../../../shared/components/form/FormItem";
import {
  handleInputChange,
  removeNonDigits,
} from "../../../../shared/utils/utility-functions";
import InfoText from "../../../../shared/components/InfoText";
import {
  EMAIL_REGEX,
  ERROR_EMAIL_INVALID,
} from "../../../../shared/constants/Login.constants";
import { formatPhoneNumber } from "../../../../shared/utils/utility-functions";

const onlyNumbersAllowed = /^[0-9]+$/;

const ChangeEmailMobileForm = ({
  emailAddress = "",
  setEmailAddress = () => {},
  mobileNumber = "",
  setMobileNumber = () => {},
  emailAddressErrorMessage = "",
  setEmailAddressErrorMessage = () => {},
  mobileNumberErrorMessage = "",
  setMobileNumberErrorMessage = () => {},
}) => {
  const checkEmailAddressValidity = () => {
    if (emailAddress) {
      if (!EMAIL_REGEX.test(emailAddress)) {
        setEmailAddressErrorMessage(ERROR_EMAIL_INVALID);
      } else {
        setEmailAddressErrorMessage("");
      }
    } else {
      setEmailAddressErrorMessage("");
    }
  };

  const handleMobileChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setMobileNumber(formattedPhoneNumber);
  };

  const checkMobileNumberValidity = (e, errorMessageSetter) => {
    if (mobileNumber) {
      if (!onlyNumbersAllowed.test(removeNonDigits(mobileNumber))) {
        setMobileNumberErrorMessage("Mobile Number must only contain numbers");
      } else {
        setMobileNumberErrorMessage("");
      }
    } else {
      setMobileNumberErrorMessage("");
    }
  };

  return (
    <FlexContainer
      padding="25px 0 35px 0"
      flexdirection="column"
      gap="10px"
      width="100%"
    >
      <FormItem
        elementWidth="200px"
        label="Email Address"
        inputType="input"
        value={emailAddress}
        setValue={(e) => handleInputChange(e, setEmailAddress)}
        blurHandler={(e) => checkEmailAddressValidity()}
        errorMessage={emailAddressErrorMessage}
        inputProps={{
          type: "email",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="200px"
        label="Mobile"
        inputType="input"
        value={mobileNumber}
        setValue={(e) => handleMobileChange(e)}
        blurHandler={(e) =>
          checkMobileNumberValidity(e, setMobileNumberErrorMessage)
        }
        errorMessage={mobileNumberErrorMessage}
        hasComponent
        component={
          <InfoText
            elementWidth="200px"
            Icon={InfoOutlinedIcon}
            iconColor="primary"
            text="No spaces or dashes"
            textColor="text.hint"
            textSize={10}
            textWeight="bold"
          />
        }
        inputProps={{
          type: "tel",
          style: {
            textAlign: "start",
          },
        }}
      />
    </FlexContainer>
  );
};

export default ChangeEmailMobileForm;
