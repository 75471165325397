import React, { useContext } from "react";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Box, Typography } from "@mui/material";
import {
  CustomDivider,
  FlexContainer,
} from "../../../../shared/components/styled/Container.styled";
import { AuthContext } from "../../../../contexts/AuthContext";
import { NOT_APPLICABLE } from "../../../../shared/constants/Defaults.constants";
import {
  ESCROW_SHORTAGE,
  ONE_TIME_PAYMENT,
} from "../../../../shared/constants/Payments.constants";

export default function PaymentTotal({
  amountTotal,
  amount,
  additionalPrincipal,
  transactionFee,
  maxTransaction = null,
  type = ONE_TIME_PAYMENT,
}) {
  const { activeLoanDetails } = useContext(AuthContext);
  return (
    <FlexContainer
      padding="25px 75px"
      background="#d8d8d8"
      margin="0 0 20px 0"
      gap="100px"
    >
      <FlexContainer flexdirection="column" height="100%" gap="10px">
        <FlexContainer justifycontent="space-between" width="100%" gap="25px">
          <Typography fontWeight="bold" minWidth="200px" textAlign="end">
            Transaction Fee
          </Typography>
          <Typography fontWeight="bold" minWidth="200px" textAlign="end">
            $ {transactionFee}
          </Typography>
        </FlexContainer>
        <CustomDivider flexItem />
        <FlexContainer justifycontent="space-between" width="100%" gap="25px">
          <Typography
            fontWeight="bold"
            color="primary"
            minWidth="200px"
            textAlign="end"
          >
            Total Payment Amount
          </Typography>
          <Typography
            fontWeight="bold"
            color="primary"
            minWidth="200px"
            textAlign="end"
          >
            $ {amountTotal}
          </Typography>
        </FlexContainer>
        {type === ONE_TIME_PAYMENT &&
        maxTransaction &&
        +maxTransaction < +amountTotal ? (
          <FlexContainer justifycontent="space-between" width="100%" gap="25px">
            <Typography
              fontWeight="bold"
              color="error"
              minWidth="200px"
              textAlign="end"
            >
              Maximum Permitted Amount
            </Typography>
            <Typography
              fontWeight="bold"
              color="error"
              minWidth="200px"
              textAlign="end"
            >
              $ {maxTransaction}
            </Typography>
          </FlexContainer>
        ) : (
          ""
        )}
        {+amount + +additionalPrincipal >
        activeLoanDetails?.loanDetails?.mortgageBalance ? (
          <FlexContainer justifycontent="space-between" width="100%" gap="25px">
            <Typography
              fontWeight="bold"
              color="error"
              minWidth="200px"
              maxWidth="200px"
              textAlign="end"
            >
              Amount + Additional Principal must not exceed
            </Typography>
            <Typography
              fontWeight="bold"
              color="error"
              minWidth="200px"
              textAlign="end"
            >
              $ {activeLoanDetails?.loanDetails?.mortgageBalance}
            </Typography>
          </FlexContainer>
        ) : (
          ""
        )}
      </FlexContainer>
      <FlexContainer height="100%" gap="15px">
        {type !== ESCROW_SHORTAGE ? (
          <>
            <MonetizationOnOutlinedIcon fontSize="large" />
            <FlexContainer
              width="100%"
              height="100%"
              flexdirection="column"
              alignitems="flex-start"
            >
              <Typography color="text.hint" fontSize="small" fontWeight="bold">
                Interest Rate
              </Typography>
              <Typography fontWeight="bold" fontSize={24}>
                {activeLoanDetails?.loanDetails?.interestRate
                  ? `${activeLoanDetails.loanDetails.interestRate}%`
                  : NOT_APPLICABLE}
              </Typography>
            </FlexContainer>
          </>
        ) : (
          <Box width="120px" />
        )}
      </FlexContainer>
    </FlexContainer>
  );
}
