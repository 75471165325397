import React, { createContext, useState } from "react";

export const ConfiguratorContext = createContext();

const ConfiguratorContextProvider = (props) => {
  const [edittedFields, setEdittedFields] = useState([]);
  const [hasRetrievedConfig, setHasRetrievedConfig] = useState(false);

  const updateEdittedFieldsArray = (allFields, currentField, setter) => {
    const existingFieldIndex = allFields.findIndex(
      (item) => item.key === currentField.key,
    );
    if (existingFieldIndex !== -1) {
      const updatedEdittedFieldList = [
        ...allFields.slice(0, existingFieldIndex),
        {
          ...allFields[existingFieldIndex],
          value: currentField.value,
          modified: currentField.modified,
        },
        ...allFields.slice(existingFieldIndex + 1),
      ];
      setter(updatedEdittedFieldList);
    } else {
      setter([...allFields, currentField]);
    }
  };

  const addNewEdittedField = (field) => {
    updateEdittedFieldsArray(edittedFields, field, setEdittedFields);
  };

  const addBatchOfEdittedFields = (fields) => {
    let edittedFieldsCopy = [...edittedFields];
    const updateEdittedFieldsCopy = (newFields) => {
      edittedFieldsCopy = newFields;
    };
    fields.forEach((field) => {
      updateEdittedFieldsArray(
        edittedFieldsCopy,
        field,
        updateEdittedFieldsCopy,
      );
    });
    setEdittedFields(edittedFieldsCopy);
  };

  const clearEdittedFieldList = () => {
    setEdittedFields([]);
  };

  return (
    <ConfiguratorContext.Provider
      value={{
        edittedFields,
        hasRetrievedConfig,
        addNewEdittedField,
        addBatchOfEdittedFields,
        clearEdittedFieldList,
        setHasRetrievedConfig,
      }}
    >
      {props.children}
    </ConfiguratorContext.Provider>
  );
};

export default ConfiguratorContextProvider;
