import React, { useContext } from "react";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import FormItemInput from "../../../../shared/components/form/FormItemInput";

const InputFeeOption = ({
  elementKey = "",
  inputValue = "",
  setInputValue = () => {},
  onChangeHandler = () => {},
  validateInputValue = () => {},
  adornmentSymbol = "$",
  errorMessage = "",
  setErrorMessage = () => {},
  inputProps = "",
}) => {
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  const handleChange = (e) => {
    onChangeHandler(e, setInputValue, setErrorMessage);
    addNewEdittedField({
      key: elementKey,
      value: e.target.value.length > 0 ? e.target.value : 0,
      modified: true,
    });
  };
  return (
    <FormItemInput
      elementWidth="100%"
      value={inputValue}
      setValue={(e) => handleChange(e)}
      placeholder="0"
      blurHandler={(e) => validateInputValue(e, setErrorMessage)}
      blurOnWheel
      adornment
      adornmentPosition="start"
      adornmentSymbol={adornmentSymbol}
      inputProps={inputProps}
      errorMessage={errorMessage}
    />
  );
};

export default InputFeeOption;
