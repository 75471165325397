import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { AuthContext } from "../../../contexts/AuthContext";
import EditableText from "../configurator/EditableText";
import { FlexContainer } from "../styled/Container.styled";
import { ActionText, SectionTitle } from "../styled/Typography.styled";
import { CLIENT_ADMIN_ROLE } from "../../constants/Roles.constants";

const DescriptionSection = ({
  title = "",
  body = "",
  setBody = () => {},
  bodyKey = "",
  wasModified = false,
  hasAction = false,
  actionLabel = "",
  actionCallback = () => {},
  actionCallbackArguments,
  width = "auto",
  inActiveOrDeadLoan = false,
}) => {
  const { userRoles, visibleRole } = useContext(AuthContext);
  return (
    <FlexContainer
      flexdirection="column"
      gap="25px"
      alignitems="flex-start"
      width={width}
    >
      <SectionTitle variant="h2" color="primary" sx={{ paddingBottom: 0 }}>
        {title}
      </SectionTitle>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      body ? (
        <EditableText
          renderedText={body}
          setRenderedText={setBody}
          elementKey={bodyKey}
          wasModified={wasModified}
          padding="0"
          fontSize="0.8571rem"
          fontColor="text.secondary"
          withHyperlink
        />
      ) : (
        ""
      )}
      {hasAction && !inActiveOrDeadLoan? (
        <ActionText>
          <Typography
            onClick={(e) => actionCallback(...actionCallbackArguments)}
          >
            {actionLabel}
          </Typography>
        </ActionText>
      ) : (
        ""
      )}
    </FlexContainer>
  );
};

export default DescriptionSection;
