import React, { useContext, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";

const ColorPicker = ({ paletteOption = "", elementKey = "default" }) => {
  const { theme, setTheme } = useContext(ThemeContext);
  const { addNewEdittedField } = useContext(ConfiguratorContext);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const setInitialColor = (paletteOption) => {
    switch (paletteOption) {
      case "primary":
      case "secondary":
      case "info":
      case "success":
      case "warning":
      case "error":
        return theme.palette[paletteOption].main;
      case "background":
        return theme.palette[paletteOption].default;
      case "paper":
        return theme.palette.background.paper;
      default:
        return "";
    }
  };

  const [color, setColor] = useState(setInitialColor(paletteOption));

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const updateTheme = (color) => {
    const newTheme = { ...theme };

    switch (paletteOption) {
      case "primary":
        newTheme.palette[paletteOption].main = color.hex;
        break;
      case "secondary":
        newTheme.palette[paletteOption].main = color.hex;
        break;
      case "info":
        newTheme.palette[paletteOption].main = color.hex;
        break;
      case "success":
        newTheme.palette[paletteOption].main = color.hex;
        break;
      case "warning":
        newTheme.palette[paletteOption].main = color.hex;
        break;
      case "error":
        newTheme.palette[paletteOption].main = color.hex;
        break;
      case "background":
        newTheme.palette[paletteOption].default = color.hex;
        break;
      case "paper":
        newTheme.palette.background.paper = color.hex;
        break;
      default:
        return;
    }
    setTheme(newTheme);
    addNewEdittedField({
      key: elementKey,
      value: color.hex,
      modified: true,
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "50px",
        height: "50px",
        borderRadius: "10px",
        border: "1px solid gray",
        background: `${color}`,
      },
      swatch: {
        background: "transparent",
        borderRadius: "2px",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={handleChange}
            onChangeComplete={updateTheme}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
