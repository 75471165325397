import { deletePaymentMethod } from "../../data/paymentsAPI";

const verifyMethod = (rowInfo, navigate) => {
  navigate("verify", {
    state: {
      paymentMethodId: rowInfo.paymentMethodId,
      nickname: rowInfo.name,
    },
  });
};

export const deleteMethod = (
  nickname,
  token,
  impersonatedUser,
  addPendingRequest,
  removePendingRequest,
  cellLogicCallbacks,
) => {
  addPendingRequest();
  deletePaymentMethod(token, nickname, impersonatedUser).then((data) => {
    if (data?.message?.toLowerCase().includes("success")) {
      cellLogicCallbacks.delete(true);
    }
    removePendingRequest();
  });
};

export const switchAction = (
  actionType,
  rowInfo,
  navigate,
  cellLogicCallbacks,
) => {
  switch (actionType) {
    case "Verify":
      return verifyMethod(rowInfo, navigate);
    case "Delete":
      return cellLogicCallbacks.triggerDelete(rowInfo);
    case "Rename":
      return cellLogicCallbacks.renameToggle(rowInfo);
    default:
      return;
  }
};
