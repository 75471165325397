import React, { useContext, useEffect } from "react";
import { Paper } from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AsideNavigation,
  FlexContainer,
} from "../../shared/components/styled/Container.styled";
import { PageTitle } from "../../shared/components/styled/Typography.styled";
import RequireAuth from "../../shared/components/guards/RequireAuth";
import { AuthContext } from "../../contexts/AuthContext";

const UserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { activeLoanDetails } = useContext(AuthContext);

  useEffect(() => {
    if (location.pathname === "/profile") {
      navigate("/profile/communication", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <RequireAuth>
      <PageTitle
        variant="h1"
        component="h1"
        color="primary.dark"
        fontSize="3rem"
      >
        Manage My Account
      </PageTitle>
      <Paper elevation={0}>
        <FlexContainer padding="25px 100px" alignitems="flex-start">
          <AsideNavigation sx={{ maxWidth: "min-content" }}>
            <Link
              to="communication"
              className={
                location.pathname.includes("/communication") ? "active" : ""
              }
            >
              Communication
            </Link>
            <Link
              to="change-address"
              className={
                location.pathname.includes("/change-address") ? "active" : ""
              }
            >
              Change Address
            </Link>
            <Link
              to="loan"
              className={location.pathname.includes("/loan") ? "active" : ""}
            >
              Link Account
            </Link>
            <Link
              to="statement-delivery"
              className={
                location.pathname.includes("/statement-delivery")
                  ? "active"
                  : `${activeLoanDetails?.loanDetails?.paperless ? "" : "disabled"}`
              }
            >
              Change Statement Delivery
            </Link>
          </AsideNavigation>
          <FlexContainer padding="25px" flexgrow="2">
            <Outlet />
          </FlexContainer>
        </FlexContainer>
      </Paper>
    </RequireAuth>
  );
};

export default UserProfile;
