import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { getLoans } from "../../data/loanAPI";
import {
  BORROWER,
  CLIENT,
  CLIENT_ADMIN,
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "../constants/Roles.constants";

export const acquireToken = (
  instance,
  requestParams,
  loanInfo,
  addPendingRequest,
  removePendingRequest,
  setMissingTenant,
  userRoles,
  impersonatedUser,
  saveToken = false,
  tokenSetter = () => {},
) => {
  if (sessionStorage.getItem("co")) {
    setMissingTenant(false);
    addPendingRequest();
    instance
      .acquireTokenSilent(requestParams)
      .then((tokenResponse) => {
        removePendingRequest();
        if (saveToken) {
          tokenSetter(tokenResponse.accessToken);
        }
        if (loanInfo.checkLoans) {
          callLoansApi(
            tokenResponse.accessToken,
            loanInfo.loans,
            loanInfo.setLoans,
            loanInfo.setActiveLoan,
            addPendingRequest,
            removePendingRequest,
            userRoles,
            impersonatedUser,
          );
        }
      })
      .catch((error) => {
        if (sessionStorage.getItem("co")) {
          setMissingTenant(false);
          addPendingRequest();
          if (
            error instanceof InteractionRequiredAuthError ||
            error instanceof BrowserAuthError
          ) {
            instance
              .acquireTokenRedirect(requestParams)
              .then((tokenResponse) => {
                removePendingRequest();
                if (saveToken) {
                  tokenSetter(tokenResponse.accessToken);
                }
                if (loanInfo.checkLoans) {
                  callLoansApi(
                    tokenResponse.accessToken,
                    loanInfo.loans,
                    loanInfo.setLoans,
                    loanInfo.setActiveLoan,
                    addPendingRequest,
                    removePendingRequest,
                    userRoles,
                    impersonatedUser,
                  );
                }
              })
              .catch((error) => {
                removePendingRequest();
                console.log(error);
              });
          } else {
            removePendingRequest();
            console.log(error);
          }
        } else {
          setMissingTenant(true);
        }
      });
  } else {
    setMissingTenant(true);
  }
};

export const callLoansApi = async (
  token,
  loans,
  setLoans,
  setActiveLoan,
  addPendingRequest,
  removePendingRequest,
  userRoles,
  impersonatedUser,
) => {
  let loanDetails = null;
  if (loans === null) {
    addPendingRequest();
    loanDetails = await getLoans(token, userRoles, impersonatedUser);
    setLoans(loanDetails);
    removePendingRequest();
    if (loanDetails?.length > 0) {
      setActiveLoan(loanDetails[0]);
    } else {
      setActiveLoan("no-loan");
    }
  }
};

export const determineRoleLabel = (roleValue) => {
  switch (roleValue) {
    case PAYMENTS_BORROWER_ROLE:
      return BORROWER;
    case PAYMENTS_SUPPORT_ROLE:
      return CLIENT;
    case CLIENT_ADMIN_ROLE:
      return CLIENT_ADMIN;
    default:
      return roleValue;
  }
};
