import { useNavigate } from "react-router-dom";
import EditableText from "../../../shared/components/configurator/EditableText";
import {
  capitalizeFirstLetterOnly,
  findElementIncludedInArray,
} from "../../../shared/utils/utility-functions";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  ERROR,
  PLACEHOLDER_DESCRIPTION,
  SUCCESS,
} from "../../../shared/constants/Defaults.constants";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import {
  DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY,
  DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY,
  DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY,
  REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY,
} from "../../../shared/constants/Configurator.constants";
import FormItem from "../../../shared/components/form/FormItem";
import { Box, useTheme } from "@mui/material";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import {
  NO_CONTENT_STATUS,
  NO_EMAIL_SET,
  PAYOFF_REQUEST_REASONS,
  REQUEST_PAYOFF_FAILED,
  REQUEST_PAYOFF_SUCCESS,
} from "../../../shared/constants/StatementsDocuments.constants";
import { requestPayoffQuote } from "../../../data/statementsDocumentsAPI";
import { ThemeContext } from "../../../contexts/ThemeContext";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import { getUserAttributes } from "../../../data/tenantAPI";
import { USER_EMAIL_ADDRESS_KEY } from "../../../shared/constants/Notifications.constants";
import { useMsal } from "@azure/msal-react";

const allRequestOptions = PAYOFF_REQUEST_REASONS.map((item, index) => {
  return {
    id: index,
    title: item,
  };
});

export default function RequestPayoffQuote() {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const theme = useTheme();
  const {
    userRoles,
    userDetails,
    visibleRole,
    impersonatedUser,
    token,
    activeLoan,
    activeLoanDetails,
  } = useContext(AuthContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const [description, setDescription] = useState(PLACEHOLDER_DESCRIPTION);
  const { edittedFields } = useContext(ConfiguratorContext);
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const minDate = today;
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [requestReasons, setRequestReasons] = useState([]);
  const [requestReason, setRequestReason] = useState(null);

  const [emailCost, setEmailCost] = useState(0);
  const [mailCost, setMailCost] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const mailAddress = `${activeLoanDetails?.borrowerDetails?.mailAddress}, 
  ${capitalizeFirstLetterOnly(
    activeLoanDetails?.borrowerDetails?.city?.trim(),
  )}, 
  ${activeLoanDetails?.borrowerDetails?.state?.trim()},
  ${activeLoanDetails?.borrowerDetails?.postalCode?.trim()}`;

  //Snackbar
  const [severity, setSeverity] = useState("success");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);

  const radioOptions = [
    {
      id: 1,
      title: `Email address ($${emailCost.toFixed(2)})`,
      subtitle: emailAddress,
      value: "Email",
    },
    {
      id: 2,
      title: `Mailing address ($${mailCost.toFixed(2)})`,
      subtitle: mailAddress,
      value: "Mail",
    },
  ];

  const [sendToValue, setSendToValue] = useState(radioOptions[0].value);

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    getEmailAddressFromUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmailAddressFromUser = async () => {
    const communicationPreferencesData = await getUserAttributes(
      token,
      impersonatedUser ? impersonatedUser : accounts[0]?.localAccountId,
      userRoles,
      impersonatedUser,
    );

    communicationPreferencesData.forEach((item) => {
      switch (item.attribute.name) {
        case USER_EMAIL_ADDRESS_KEY:
          if (item.httpStatus === "OK") {
            setEmailAddress(item.attribute.value);
          } else if (userDetails?.idTokenClaims?.signInName) {
            setEmailAddress(userDetails.idTokenClaims.signInName);
          } else {
            setEmailAddress(NO_EMAIL_SET);
          }
          break;
        default:
          break;
      }
    });
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY:
          getRequestPayoffReasons(item.value);
          return;
        case DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY:
          setEmailCost(+item.value);
          return;
        case DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY:
          setMailCost(+item.value);
          return;
        default:
          return;
      }
    });

    if (
      !fields.find((item) => item.key === DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY)
    ) {
      setRequestReasons(allRequestOptions);
      setRequestReason(allRequestOptions[0]);
    }
  };

  const getRequestPayoffReasons = (configReasons) => {
    if (configReasons) {
      const mappedReasons = configReasons.split(";").map((item, index) => {
        return {
          id: index,
          title: item,
        };
      });
      setRequestReasons(mappedReasons);
      setRequestReason(mappedReasons[0].title);
    }
  };

  const handleSubmitRequest = async () => {
    addPendingRequest();
    let status = null;
    if (token && activeLoan) {
      if (activeLoan !== "no-loan") {
        const dateString = `${startDate.getFullYear()}-${`0${
          startDate.getMonth() + 1
        }`.slice(-2)}-${`0${startDate.getDate()}`.slice(-2)}`;
        const requestBody = {
          goodThroughDate: dateString,
          reason: requestReason !== null ? requestReason : "N/A",
          sendToType: sendToValue,
        };
        status = await requestPayoffQuote(
          token,
          activeLoan,
          requestBody,
          impersonatedUser,
        );
      }
    }
    removePendingRequest();
    displayStatusAPICall(status);
  };

  const displayStatusAPICall = (status) => {
    if (status && status.ok && status.status === NO_CONTENT_STATUS) {
      setSnackBarMessage(REQUEST_PAYOFF_SUCCESS);
      setSeverity(SUCCESS);
    } else {
      setSnackBarMessage(REQUEST_PAYOFF_FAILED);
      setSeverity(ERROR);
    }
    setSnackBarOpen(true);
  };

  const handleRedirectConfigureSettings = () => {
    navigate("/profile/communication");
  };

  return (
    <FlexContainer
      flexdirection="column"
      justifycontent="flex-start"
      height="100%"
      width="100%"
    >
      <SectionTitle variant="h2" color="primary">
        Request Pay-Off Quote
      </SectionTitle>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <FlexContainer flexdirection="column" alignitems="center" width="100%">
        <FlexContainer padding="35px 0 25px 0" gap="25px" width="100%">
          <FormItem
            elementWidth="250px"
            label="Good Through Date (GTD)"
            inputType="datepicker"
            value={startDate}
            setValue={setStartDate}
            datepickerOpen={datepickerOpen}
            setDatepickerOpen={setDatepickerOpen}
            minDate={minDate}
            hasComponent
            component={
              <Box width="250px">
                <PillButton
                  variant="contained"
                  width="90px"
                  color="primary"
                  onClick={() => setDatepickerOpen(true)}
                  sx={{ fontSize: "10px", padding: "5px 15px" }}
                >
                  Change GTD
                </PillButton>
              </Box>
            }
          />
        </FlexContainer>
        {requestReasons.length > 0 ? (
          <FlexContainer padding="0 0 25px 0" gap="25px" width="100%">
            <FormItem
              elementWidth="250px"
              label="Reason for Request"
              inputType="dropdown"
              value={requestReason}
              setValue={setRequestReason}
              dropdownOptions={requestReasons}
              dropdownKey="id"
              dropdownValue="title"
              dropdownLabel="title"
            />
          </FlexContainer>
        ) : null}
        <FlexContainer flexdirection="column" padding="0 0 45px 0" width="100%">
          <FormItem
            elementWidth="250px"
            label="Send to"
            inputType={"radio"}
            radioOptions={radioOptions}
            value={sendToValue}
            setValue={setSendToValue}
          />
          <FlexContainer width="250px" justifycontent="flex-start">
            <PillButton
              color="primary"
              variant="contained"
              onClick={handleRedirectConfigureSettings}
              width="150px"
              sx={{ fontSize: "10px", padding: "5px 15px" }}
            >
              Edit Contact Information
            </PillButton>
          </FlexContainer>
        </FlexContainer>
        <PillButton
          color="primary"
          variant="contained"
          width="33%"
          customfontsize="1.25rem"
          onClick={handleSubmitRequest}
          disabled={
            !!impersonatedUser || userRoles?.includes(CLIENT_ADMIN_ROLE)
          }
        >
          Request Document
        </PillButton>
      </FlexContainer>
      <ActionSnackbar
        severity={severity}
        borderColor={theme.palette[severity].main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackBarOpen}
        closeFn={() => {
          setSnackBarOpen(false);
        }}
        snackbarLabel={snackBarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </FlexContainer>
  );
}
