import { API_BASE } from "../shared/config/config";

export async function getHolidays(accessToken, numberOfDays, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve([]));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(
      `${API_BASE}/holidays?calendarDayCount=${numberOfDays}`,
      options,
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}
