import {
  PaymentInfo,
  PaymentMethods,
  PaymentActivityResponse,
  AutoPaymentInfo,
} from "../mocks/Payments.mock";
import { API_BASE } from "../shared/config/config";

// Payments
export async function getPaymentActivity(
  accessToken,
  impersonatedUser,
  loanId,
  noOfPages,
  pageSize,
  skipToken,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(PaymentActivityResponse));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    const queryParamString = `?loanId=${loanId}${
      pageSize !== null
        ? `&pageSize=${pageSize}`
        : `&skipToken=${skipToken}${noOfPages > 1 ? `&pages=${noOfPages}` : ""}`
    }`;

    return fetch(`${API_BASE}/payments${queryParamString}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function postPayment(accessToken, body, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/payments`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function getPaymentInfo(accessToken, loanId, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(PaymentInfo));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const body = {
      loanId,
    };

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/payments/prevalidate`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function deletePayment(
  accessToken,
  confirmationNumber,
  loanId,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(PaymentMethods));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "DELETE",
      headers: headers,
    };

    return fetch(
      `${API_BASE}/payments/${confirmationNumber}?loanId=${loanId}`,
      options,
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

// Auto-Payments
export async function getAutoPaymentInfo(
  accessToken,
  loanId,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(AutoPaymentInfo));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const body = {
      loanId,
    };

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/autoPayments/prevalidate`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function postRecurringPayment(
  accessToken,
  body,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/autoPayments`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function putRecurringPayment(
  accessToken,
  loanId,
  body,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/autoPayments/${loanId}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function deleteRecurringPayment(
  accessToken,
  loanId,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve({ success: "true" }));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "DELETE",
      headers: headers,
    };

    return fetch(`${API_BASE}/autoPayments/${loanId}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

// Payment Methods
export async function getPaymentMethods(accessToken, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(PaymentMethods));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${API_BASE}/paymentMethods`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function postPaymentMethod(accessToken, body, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/paymentMethods`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function postPaymentMethodVerification(
  accessToken,
  body,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/paymentMethods/verify`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function deletePaymentMethod(
  accessToken,
  nickname,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(PaymentMethods));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "DELETE",
      headers: headers,
    };

    return fetch(`${API_BASE}/paymentMethods/${nickname}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function putPaymentMethod(
  accessToken,
  nickname,
  body,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(PaymentMethods));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/paymentMethods/${nickname}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}
