import {
  GetUserAttributes,
  GetUserByEmailResponse,
} from "../mocks/Config.mocks";
import { API_BASE } from "../shared/config/config";
import { COMMUNICATION_PREFERENCES_ATTRIBUTES_GET_BODY } from "../shared/constants/Notifications.constants";
import { CLIENT_ADMIN_ROLE } from "../shared/constants/Roles.constants";

export async function getUserByEmail(accessToken, email, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(GetUserByEmailResponse));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${API_BASE}/users?email=${email}`, options)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });
  }
}

export async function putNewUserRole(
  accessToken,
  userId,
  newRole,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const body = {
      newRole: newRole,
    };

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/users/${userId}/roles`, options)
      .then((response) => {
        if (response.status === 204) {
          return response;
        }
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export async function deleteUser(accessToken, userId, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "DELETE",
      headers: headers,
    };

    return fetch(`${API_BASE}/users/${userId}`, options)
      .then((response) => {
        if (response.status === 204) {
          return response;
        }
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export async function getUserAttributes(
  accessToken,
  userId,
  userRoles,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(GetUserAttributes));
  } else if (!impersonatedUser && userRoles?.includes(CLIENT_ADMIN_ROLE)) {
    return new Promise((resolve, reject) => resolve([]));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(COMMUNICATION_PREFERENCES_ATTRIBUTES_GET_BODY),
    };

    return fetch(`${API_BASE}/users/${userId}/attributes`, options)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });
  }
}

export async function postUserAttributes(
  accessToken,
  userId,
  body,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(GetUserAttributes));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/users/${userId}/attributes`, options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
}

export async function deleteUserAttributes(
  accessToken,
  userId,
  body,
  impersonatedUser,
) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(GetUserAttributes));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/users/${userId}/attributes`, options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
}
