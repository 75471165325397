import { forwardRef } from "react";
import { Button, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PillButton = styled((props) => <Button {...props} />)`
  width: ${(props) => props.width || "inherit"};
  padding: 5px 30px;
  border-radius: 25px;
  text-transform: none;
  align-self: ${(props) => props.alignself || "auto"};
  font-size: ${(props) => props.customfontsize || "1rem"};
  font-weight: ${(props) => props.customfontweight || "normal"};
`;

export const ColouredFab = styled(
  forwardRef((props, ref) => <Fab ref={ref} {...props} />),
)`
  transition: filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${(props) =>
    props.color || props.theme.palette.primary.main};

  &:hover {
    background-color: ${(props) =>
      props.color || props.theme.palette.primary.main};
    filter: brightness(0.75);
  }

  & svg {
    font-size: ${(props) => props.iconfontsize || "1rem"};
    color: ${(props) => props.color || props.theme.palette.primary.main};
    filter: invert(1) grayscale(1) contrast(100);
  }
`;
