import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export const MONTHS = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];

export const PAYMENT_ACCOUNT_TYPE = [
  {
    label: "Checking",
    value: "C",
  },
  {
    label: "Savings",
    value: "S",
  },
];

export const PAYMENT_METHOD_STEP_1 =
  "Step One: Enter your bank account information above. After you submit your bank information 2 more transactions of less than 51.00 will be credited to your bank account and later withdrawn. The deposits may take several days to appear in your account. Please note that these small deposits may be withdrawn or combined into a single small withdeawal amount.";

export const PAYMENT_METHOD_STEP_2 =
  "Step Two: Once you see the micro transactions credited to your bank account return to the Payment Management page and select verify for the new payment method.";

export const PAYMENT_METHOD_FOOTER_NOTE =
  "Note. If 3 days have passed and you have not received the micro transactions in your account, please delete the account and start over by again adding account information above. Confirm you are accurately entering your banking information.";

export const EMPTY_PAYMENT_METHODS_FALLBACK = [
  {
    paymentMethodId: "empty",
    nickname: "No Payment Method",
  },
];

export const ONE_TIME_PAYMENT = "OneTime";
export const RECURRING_PAYMENT = "Recurring";
export const ESCROW_SHORTAGE = "escrowShortage";
export const PAYMENT_TYPE_RECURRING = "Recurring Payment";

export const PAYMENT_ACTIVITY_HEADER_NAMES = [
  "Type",
  "Date",
  "Status",
  "Amount",
  "",
];
export const PAYMENT_ACTIVITY_FIELD_TYPES = [
  "type",
  "date",
  "status",
  "amount",
  "actions",
];

export const PAYMENT_ACTIVITY_NO_DATA_MESSAGE =
  "There are no payments in the history to display";

export const PAYMENT_METHODS_HEADER_NAMES = ["Name", ""];
export const PAYMENT_METHODS_FIELD_TYPES = ["name", "actions"];
export const PAYMENT_METHODS_ACTIONS = ["Verify", "Rename", "Delete"];
export const PAYMENT_METHODS_NO_DATA_MESSAGE =
  "There are no payment methods set";
export const PAYMENT_METHODS_ICONS_ACTION_OBJECT = {
  Verify: {
    icon: <DoneIcon />,
    color: "warning",
    tooltip: "Verify Payment Method",
  },
  Delete: {
    icon: <DeleteIcon />,
    color: "error",
    tooltip: "Delete Payment Method",
  },
  Rename: {
    icon: <EditIcon />,
    color: "primary",
    tooltip: "Rename Payment Method",
  },
};

export const TRANSACTION_AMOUNTS_MATCH_ERROR_MESSAGE =
  "Transaction amounts do not match";
export const ADD_PAYMENT_METHOD_ROUTING_NUMBER_NUMERIC_ERROR_MESSAGE =
  "Routing Number must be numeric";
export const ADD_PAYMENT_METHOD_ROUTING_NUMBER_LENGTH_ERROR_MESSAGE =
  "Routing Number must be 9 digits";
export const ADD_PAYMENT_METHOD_ACCOUNT_NUMBER_NUMERIC_ERROR_MESSAGE =
  "Account Number must be numeric";
export const ADD_PAYMENT_METHOD_ACCOUNT_NUMBER_MAX_LENGTH_ERROR_MESSAGE =
  "Account Number must be up to 17 digits long";
export const ADD_PAYMENT_METHOD_ALPHANUMERIC_ERROR_MESSAGE =
  "Only letters, numbers, spaces allowed for name";

export const NEW_PAYMENT_ONLINE_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE =
  "Online payments are not allowed at this moment. Contact your mortgage company for additional information";
export const NEW_PAYMENT_RECURRING_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE =
  "Recurring payments are not allowed at this moment. Contact your mortgage company for additional information.";
export const NEW_PAYMENT_DELINQUENT_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE =
  "A payment cannot be accepted on your loan at this time, please contact your servicer";
export const NEW_PAYMENT_PREVALIDATE_ONE_TIME_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE =
  "A payment is not allowed on your loan at this time, please contact your servicer";
export const NEW_PAYMENT_PREVALIDATE_RECURRING_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE =
  "A recurring payment is not allowed on your loan at this time, please contact your servicer";

export const RENAME_PAYMENT_METHOD_HELP_TOOLTIP =
  "Press 'Enter' to save method name.";
export const PAYMENT_ACTIVITY_CANCEL_PAYMENT_TOOLTIP = "Cancel Payment";
export const PAYMENT_ACTIVITY_CANCEL_RECURRING_PAYMENT_TOOLTIP =
  "Cancel Recurring Payment";
export const PAYMENT_ACTIVITY_EDIT_RECURRING_PAYMENT_TOOLTIP =
  "Edit Recurring Payment";

export const RENAME_PAYMENT_METHOD_CONFIRMATION_BODY =
  "Are you sure you want to rename this payment method?";
export const DELETE_PAYMENT_METHOD_CONFIRMATION_BODY =
  "Are you sure you want to delete this payment method?";

export const ADD_PAYMENT_METHOD_CHECK_INFO =
  "Some checks may have Bank Account number first";
export const NEW_PAYMENT_ADDITIONAL_ESCROW_INFO =
  "No escrow audit shortages allowed";

export const PAYMENT_CONFIRMATION = (confirmationNumber) =>
  `Your payment confirmation number is: ${confirmationNumber}`;
export const PAYMENT_SUCCESSFUL = "Payment Successful";
