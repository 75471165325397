import { AdminLoan, AdminLoansResponse } from "../mocks/Admin.mock";
import { DashboardDataResponse, LoansResponse } from "../mocks/Loans.mock";
import { API_BASE } from "../shared/config/config";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "../shared/constants/Roles.constants";

export async function getLoans(accessToken, userRoles, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(LoansResponse));
  } else if (!impersonatedUser && userRoles?.includes(CLIENT_ADMIN_ROLE)) {
    return new Promise((resolve, reject) => resolve(AdminLoansResponse));
  } else if (!impersonatedUser && userRoles?.includes(PAYMENTS_SUPPORT_ROLE)) {
    return new Promise((resolve, reject) => resolve([]));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${API_BASE}/loans`, options)
      .then((response) => {
        if (response.status === 204) {
          return new Promise((resolve, reject) => resolve([]));
        }
        if (response.status === 200) {
          return response.json();
        }
        return response;
      })
      .catch((error) => console.log(error));
  }
}

export async function postNewLoan(accessToken, body, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(""));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/loans`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function getLoanData(accessToken, loanId, impersonatedUser) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(DashboardDataResponse));
  } else if (loanId === "AdminLoan") {
    return new Promise((resolve, reject) => resolve(AdminLoan));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${API_BASE}/loans/${loanId}`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function putChangedAddress(accessToken, loanId, body) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(DashboardDataResponse));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    };

    return fetch(`${API_BASE}/loans/${loanId}/changeAddress`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

export async function updatePrefereneces(accessToken, loanId, impersonatedUser, paperless) {
  if (
    process.env.REACT_APP_API_INTEGRATION === "0" &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
  ) {
    return new Promise((resolve, reject) => resolve(DashboardDataResponse));
  } else {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    if (impersonatedUser) {
      headers.append("X-Impersonated-User", impersonatedUser);
    }

    const options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ paperless: paperless }),
    };

    return fetch(`${API_BASE}/loans/${loanId}/updatePreferences`, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}
