import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import WebFont from "webfontloader";
import RouteConfig from "./App.routing";
import { ThemeContext } from "./contexts/ThemeContext";

const App = () => {
  const { theme } = useContext(ThemeContext);
  const materialTheme = createTheme(theme);
  WebFont.load({
    google: {
      families: [
        "Nunito Sans:n2,n3,n4,n5,n6,n7,n8,n9,i2,i3,i4,i5,i6,i7,i8,i9",
        "sans-serif",
      ],
    },
  });
  return (
    <ThemeProvider theme={materialTheme}>
      <CssBaseline />
      <RouteConfig />
    </ThemeProvider>
  );
};

export default App;
