import { Container, AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LogoNav = styled((props) => <Container {...props} />)`
  width: 100%;
  max-width: unset;
`;

export const CustomNavbar = styled((props) => <AppBar {...props} />)`
  background-color: ${({ theme }) => theme.palette.secondary.main};

  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
`;

export const CustomFooter = styled((props) => <footer {...props} />)`
  padding: 25px 100px;
  background: ${(props) => props.background || "transparent"};
`;
