import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FlexContainer } from "../styled/Container.styled";
import { IconButton, Typography } from "@mui/material";

const Counter = ({
  counter = 0,
  setCounter = () => {},
  minValue = 0,
  maxValue = -1,
  width = "auto",
}) => {
  const increment = () => {
    if (maxValue !== -1) {
      if (counter + 1 <= maxValue) {
        setCounter(counter + 1);
      }
    } else {
      setCounter(counter + 1);
    }
  };

  const decrement = () => {
    if (counter - 1 >= minValue) {
      setCounter(counter - 1);
    }
  };

  return (
    <FlexContainer width={width} justifycontent="space-between" gap="10px">
      <IconButton onClick={decrement}>
        <RemoveIcon />
      </IconButton>
      <Typography>{counter}</Typography>
      <IconButton onClick={increment}>
        <AddIcon />
      </IconButton>
    </FlexContainer>
  );
};

export default Counter;
