import React, { useContext } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../../../contexts/ConfiguratorContext";
import EditableText from "../../../../../shared/components/configurator/EditableText";
import { FlexContainer } from "../../../../../shared/components/styled/Container.styled";
import { ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY } from "../../../../../shared/constants/Configurator.constants";
import { CLIENT_ADMIN_ROLE } from "../../../../../shared/constants/Roles.constants";
import { findElementIncludedInArray } from "../../../../../shared/utils/utility-functions";

const PaymentMethodInstructions = ({
  instructionText = "",
  setInstructionText = () => {},
}) => {
  const { userRoles, visibleRole } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  return (
    <FlexContainer
      flexdirection="column"
      alignitems="flex-start"
      width="100%"
      padding="25px 0"
      gap="25px"
    >
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      instructionText ? (
        <EditableText
          renderedText={instructionText}
          setRenderedText={setInstructionText}
          elementKey={ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY,
          )}
          padding="0"
          fontSize={14}
          fontColor="text.secondary"
          withHyperlink
        />
      ) : (
        ""
      )}
    </FlexContainer>
  );
};

export default PaymentMethodInstructions;
