import { dateDiffInDays } from "./utility-functions";

export const calculatePercentageBasedFee = (
  totalAmount,
  percentage,
  stateMaxFee,
  setter,
) => {
  const percentageFee = (totalAmount * percentage) / 100;

  setter(
    stateMaxFee && percentageFee > stateMaxFee
      ? stateMaxFee
      : percentageFee.toFixed(2),
  );
};

export const calculateThresholdFee = (
  totalAmount,
  thresholdString,
  stateMaxFee,
  setter,
) => {
  if (thresholdString) {
    const thresholdValues = thresholdString.split(";");
    if (totalAmount <= +thresholdValues[0]) {
      setter(
        stateMaxFee && +thresholdValues[1] > stateMaxFee
          ? stateMaxFee
          : +thresholdValues[1],
      );
    } else {
      setter(
        stateMaxFee && +thresholdValues[2] > stateMaxFee
          ? stateMaxFee
          : +thresholdValues[2],
      );
    }
  } else {
    setter(0);
  }
};

export const calculateDateRangeFee = (
  currentDate,
  dateString,
  stateMaxFee,
  setter,
) => {
  if (dateString) {
    let dateFoundInInterval = false;
    const dateRanges = dateString.split(";");
    dateRanges.some((item) => {
      const [dateInterval, feeValue] = item.split("=");
      const [firstDate, secondDate] = dateInterval.split("-");
      const currentDay = dateDiffInDays(currentDate, new Date());
      if (currentDay >= firstDate && currentDay <= secondDate) {
        dateFoundInInterval = true;
        setter(
          stateMaxFee && +feeValue > stateMaxFee ? stateMaxFee : +feeValue,
        );
        return true;
      } else {
        return false;
      }
    });
    if (!dateFoundInInterval) {
      setter(0);
    }
  } else {
    setter(0);
  }
};
