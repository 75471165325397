import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentAmount from "./components/PaymentAmount";
import PaymentTotal from "./components/PaymentTotal";
import PaymentRecurrence from "./components/PaymentRecurrence";
import {
  getAutoPaymentInfo,
  getPaymentInfo,
  getPaymentMethods,
  postPayment,
  postRecurringPayment,
  putRecurringPayment,
} from "../../../data/paymentsAPI";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import FormItem from "../../../shared/components/form/FormItem";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import EditableText from "../../../shared/components/configurator/EditableText";
import EditableTermsAndConditions from "../../../shared/components/configurator/EditableTermsAndConditions";
import {
  EMPTY_PAYMENT_METHODS_FALLBACK,
  ESCROW_SHORTAGE,
  MONTHS,
  NEW_PAYMENT_DELINQUENT_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
  NEW_PAYMENT_ONLINE_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
  NEW_PAYMENT_PREVALIDATE_ONE_TIME_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
  NEW_PAYMENT_PREVALIDATE_RECURRING_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
  NEW_PAYMENT_RECURRING_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
  ONE_TIME_PAYMENT,
  PAYMENT_CONFIRMATION,
  PAYMENT_METHOD_FOOTER_NOTE,
  PAYMENT_SUCCESSFUL,
  RECURRING_PAYMENT,
} from "../../../shared/constants/Payments.constants";
import {
  AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY,
  AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY,
  NEW_ESCROW_SHORTAGE_PAGE_TERMS_AND_CONDITIONS_KEY,
  NEW_ONE_TIME_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY,
  NEW_PAYMENT_PAGE_DESCRIPTION_KEY,
  NEW_PAYMENT_PAGE_FOOTER_NOTE_KEY,
  NEW_RECURRING_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY,
  PAYMENTS_MANDATE_LATE_FEE_KEY,
  PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY,
  PAYMENTS_ONLINE_ALLOWED_KEY,
  PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY,
  PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_KEY,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_CUTOFF_TIME_KEY,
  PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY,
  PAYMENTS_ONLINE_FEE_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_2_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_3_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_4_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_KEY,
  PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY,
  PAYMENTS_ONLINE_LATE_FEE_FILL_KEY,
  PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY,
  PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY,
  PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY,
  PAYMENTS_ONLINE_POSTING_DATE_KEY,
  PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_STATE_FEE_MAX_KEY,
  PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY,
} from "../../../shared/constants/Configurator.constants";
import {
  capitalizeFirstLetterOnly,
  convertTwentyFourToTwelveHourFormat,
  createDaysDropdownValues,
  findElementIncludedInArray,
} from "../../../shared/utils/utility-functions";
import {
  calculateDateRangeFee,
  calculatePercentageBasedFee,
  calculateThresholdFee,
} from "../../../shared/utils/payment-functions";
import { useIsMount } from "../../../shared/hooks/useIsMount";
import {
  addBusinessDays,
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  isSameDay,
  isWeekend,
  startOfMonth,
} from "date-fns";
import { getHolidays } from "../../../data/holidayAPI";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import { NO_LOAN_REGISTERED_WARNING_MESSAGE } from "../../../shared/constants/UserProfile.constants";
import { GENERIC_ERROR_MESSAGE } from "../../../shared/constants/Defaults.constants";
import {
  AdminAutoPaymentPrevalidateResponse,
  AdminPaymentPrevalidateResponse,
} from "../../../mocks/Admin.mock";

export default function NewPayment() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isFirstRender = useIsMount();
  const { instance, accounts } = useMsal();
  const { state } = useLocation();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const {
    token,
    userRoles,
    visibleRole,
    activeLoan,
    activeLoanDetails,
    impersonatedUser,
  } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const escrowShortage = activeLoanDetails?.loanDetails?.escrowShortage;
  const escrowShortageAmount =
    activeLoanDetails?.loanDetails?.escrowShortageAmount;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = new Date();

  // Dropdown States
  const [type, setType] = useState(ONE_TIME_PAYMENT);
  const [mode, setMode] = useState("new");
  const [availableDays, setAvailableDays] = useState([]);
  const [unavailableDays, setUnavailableDays] = useState({});
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);

  // Due Amounts States
  const [amountDue, setAmountDue] = useState(0);
  const [lateFeesDue, setLateFeesDue] = useState(0);
  const [feesDue, setFeesDue] = useState(0);
  const [dueDate, setDueDate] = useState(today);

  // Field States
  const [amount, setAmount] = useState("");
  const [lateFees, setLateFees] = useState("");
  const [fees, setFees] = useState("");
  const [additionalPrincipal, setAdditionalPrincipal] = useState("");
  const [additionalEscrow, setAdditionalEscrow] = useState("");

  // Total States
  const [hasTransactionFee, setHasTransactionFee] = useState(false);
  const [transactionFeeOption, setTransactionFeeOption] = useState("1");
  const [transactionFee, setTransactionFee] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);

  // Allow Payment States
  const [paymentsOnlineAllowed, setPaymentsOnlineAllowed] = useState(true);
  const [cutoffHourPaymentAllowed, setCutoffHourPaymentAllowed] =
    useState(true);
  const [delinquentLoanPaymentAllowed, setDelinquentLoanPaymentAllowed] =
    useState(true);
  const [delinquentLoanPrincipalAllowed, setDelinquentLoanPrincipalAllowed] =
    useState(true);
  const [additionalEscrowAmountAllowed, setAdditionalEscrowAmountAllowed] =
    useState(true);
  const [autoPaymentDueDate, setAutoPaymentDueDate] = useState(new Date());

  // Configurator States
  const [footerNote, setFooterNote] = useState(PAYMENT_METHOD_FOOTER_NOTE);
  const [description, setDescription] = useState("");
  const [termsAndConditionsText, setTermsAndConditionsText] = useState("");
  const [termsAndConditionsElementKey, setTermsAndConditionsElementKey] =
    useState("");
  const [
    oneTimePaymentTermsAndConditionsText,
    setOneTimePaymentTermsAndConditionsText,
  ] = useState("");
  const [
    recurringPaymentTermsAndConditionsText,
    setRecurringPaymentTermsAndConditionsText,
  ] = useState("");
  const [
    escrowShortagePaymentTermsAndConditionsText,
    setEscrowShortageTermsAndConditionsText,
  ] = useState("");
  const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] =
    useState(false);
  const [recurringPaymentsOnlineAllowed, setRecurringPaymentsOnlineAllowed] =
    useState(false);
  const [paymentAmountFill, setPaymentAmountFill] = useState(0);
  const [lateFeeAmountFill, setLateFeeAmountFill] = useState(0);
  const [feeAmountFill, setFeeAmountFill] = useState(0);
  const [customerMaxTransaction, setCustomerMaxTransaction] = useState(null);
  const [principalMaxTransaction, setPrincipalMaxTransaction] = useState(null);
  const [stateMaxTransaction, setStateMaxTransaction] = useState(null);
  const [stateMaxFee, setStateMaxFee] = useState(null);
  const [mandateLateFee, setMandateLateFee] = useState(false);
  const [
    allowAutoDebitForDelinquentLoans,
    setAllowAutoDebitForDelinquentLoans,
  ] = useState(true);
  const [delinquentLoanAutoDebitAllowed, setDelinquentLoanAutoDebitAllowed] =
    useState(true);
  const [isPrincipalOnlyPayment, setIsPrincipalOnlyPayment] = useState(false);
  const [cutoffTime, setCutoffTime] = useState("20:00");
  const [postingDateDays, setPostingDateDays] = useState(0);
  const [delinquentDays, setDelinquentDays] = useState("");
  const [lateChargeThreshold, setLateChargeThreshold] = useState(0);
  const [principalIfDelinquent, setPrincipalIfDelinquent] = useState(true);
  const [maxDaysIntoFutureForPayment, setMaxDaysIntoFutureForPayment] =
    useState(35);
  const [chargeEscrowShortageOnline, setChargeEscrowShortageOnline] =
    useState(false);
  const [amountChargedForEscrowShortage, setAmountChargedForEscrowShortage] =
    useState(0);
  const [paymentFeeRoutingNo, setPaymentFeeRoutingNo] = useState("");

  // Error States
  const [amountErrorMessage, setAmountErrorMessage] = useState("");
  const [lateFeesErrorMessage, setLateFeesErrorMessage] = useState("");
  const [feesErrorMessage, setFeesErrorMessage] = useState("");
  const [additionalPrincipalErrorMessage, setAdditionalPrincipalErrorMessage] =
    useState("");
  const [additionalEscrowErrorMessage, setAdditionalEscrowErrorMessage] =
    useState("");

  // Warning States
  const [warningMessage, setWarningMessage] = useState("");

  // Datepicker States
  const [startDate, setStartDate] = useState(today);
  const [minDate, setMinDate] = useState(today);
  const [maxDate, setMaxDate] = useState(today);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [excludedDates, setExcludedDates] = useState([]);

  // Transaction Date States
  const [earliestAutoDebitDate, setEarliestAutoDebitDate] = useState(1);
  const [latestAutoDebitDate, setLatestAutoDebitDate] = useState(23);

  // Dialog States
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationDialogBody, setConfirmationDialogBody] = useState("");

  // Snackbar States
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    NO_LOAN_REGISTERED_WARNING_MESSAGE,
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

  // Disabled States
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [oneTimePaymentAllowed, setOneTimePaymentAllowed] = useState(true);
  const [recurringPaymentAllowed, setRecurringPaymentAllowed] = useState(true);
  const [disabledPaymentMethod, setDisabledPaymentMethod] = useState(true);
  const [helocOrConstructionLoan, setHelocOrConstructionLoan] = useState(false);

  //Payment Confirmation
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [paymentConfirmationNumber, setPaymentConfirmationNumber] = useState(0);

  useEffect(() => {
    const determineMaxDate = async () => {
      if (token && !isNaN(maxDaysIntoFutureForPayment)) {
        const holidayArray = await getHolidays(
          token,
          +maxDaysIntoFutureForPayment + 1,
          impersonatedUser,
        );
        const holidayDates = holidayArray.map((item) =>
          utcToZonedTime(item, userTimeZone),
        );
        setExcludedDates(holidayDates);
        setMaxDate(addDays(today, maxDaysIntoFutureForPayment));
      }
    };
    determineMaxDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, maxDaysIntoFutureForPayment]);

  useEffect(() => {
    if (state?.type) {
      setType(state.type);
    }
    if (state?.from === "activity") {
      setMode("edit");
    }
  }, [state]);

  useEffect(() => {
    if (!isFirstRender) {
      setPaymentTypeAllowed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsOnlineAllowed, recurringPaymentsOnlineAllowed]);

  useEffect(() => {
    if (!paymentsOnlineAllowed) {
      setWarningMessage(NEW_PAYMENT_ONLINE_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE);
      return;
    }
    if (!recurringPaymentsOnlineAllowed && type === RECURRING_PAYMENT) {
      setWarningMessage(
        NEW_PAYMENT_RECURRING_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
      );
      return;
    }
    if (!oneTimePaymentAllowed && type !== RECURRING_PAYMENT) {
      setWarningMessage(
        NEW_PAYMENT_PREVALIDATE_ONE_TIME_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
      );
      return;
    }
    if (!recurringPaymentAllowed && type === RECURRING_PAYMENT) {
      setWarningMessage(
        NEW_PAYMENT_PREVALIDATE_RECURRING_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
      );
      return;
    }
    if (!delinquentLoanPaymentAllowed || !delinquentLoanAutoDebitAllowed) {
      setWarningMessage(
        NEW_PAYMENT_DELINQUENT_PAYMENT_NOT_ALLOWED_WARNING_MESSAGE,
      );
      return;
    }
    if (!cutoffHourPaymentAllowed) {
      setWarningMessage(
        `Payments made after ${convertTwentyFourToTwelveHourFormat(
          cutoffTime,
        )} will not be processed until the following business day`,
      );
    }
  }, [
    oneTimePaymentAllowed,
    recurringPaymentAllowed,
    paymentsOnlineAllowed,
    recurringPaymentsOnlineAllowed,
    delinquentLoanPaymentAllowed,
    delinquentLoanAutoDebitAllowed,
    cutoffHourPaymentAllowed,
    cutoffTime,
    type,
  ]);

  useEffect(() => {
    resetAdditionalFields();
  }, [type]);

  useEffect(() => {
    if (
      token &&
      (activeLoan === "no-loan" ||
        activeLoan === activeLoanDetails?.loanDetails?.loanId)
    ) {
      resetAdditionalFields();
      callPaymentInfoApi(token);

      fillConfiguratorFields(edittedFields);
      if (
        !edittedFields.find(
          (item) => item.key === PAYMENTS_ONLINE_CUTOFF_TIME_KEY,
        )
      ) {
        checkPaymentTime(cutoffTime);
      }
    }
    if (
      activeLoanDetails?.loanDetails?.mortgageBalance === 0 &&
      (activeLoanDetails?.loanDetails?.helocLoan ||
        activeLoanDetails?.loanDetails?.constructionLoan)
    ) {
      setHelocOrConstructionLoan(true);
    } else {
      setHelocOrConstructionLoan(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance, token, activeLoan, activeLoanDetails]);

  useEffect(() => {
    const configuratorCutoffTime = edittedFields.find(
      (item) => item.key === PAYMENTS_ONLINE_CUTOFF_TIME_KEY,
    );
    if (configuratorCutoffTime) {
      checkPaymentTime(configuratorCutoffTime.value);
    } else {
      checkPaymentTime(cutoffTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    setAvailableDays(
      createDaysDropdownValues(
        earliestAutoDebitDate,
        latestAutoDebitDate,
        "recurring-payment-starting-day",
      ),
    );

    let selectedDay = earliestAutoDebitDate;
    let selectedMonth =
      MONTHS[today.getMonth() !== 11 ? today.getMonth() + 1 : 0].value;
    if (state && state.postingDate) {
      const dateSplit = state.postingDate.split("/");
      selectedDay =
        +dateSplit[1] < earliestAutoDebitDate
          ? earliestAutoDebitDate
          : +dateSplit[1] > latestAutoDebitDate
          ? latestAutoDebitDate
          : +dateSplit[1];
      selectedMonth = MONTHS[dateSplit[0] - 1].value;
    }

    const threeDaysIntervalDates = eachDayOfInterval({
      start: today,
      end: addDays(today, 2),
    });

    const previousDaysIntervalDates = eachDayOfInterval({
      start: startOfMonth(today),
      end: today,
    });

    const unavailableDaysInterval = previousDaysIntervalDates.concat(
      threeDaysIntervalDates,
    );

    const unavailableDaysIntervalNoDupes = Array.from(
      new Set(unavailableDaysInterval.map((item) => item.valueOf())),
    ).map((item) => new Date(item));

    const recurringPaymentDisabledDates = {};

    unavailableDaysIntervalNoDupes.forEach((item) => {
      if (!recurringPaymentDisabledDates[item.getMonth() + 1]) {
        recurringPaymentDisabledDates[item.getMonth() + 1] = [];
      }
      recurringPaymentDisabledDates[item.getMonth() + 1].push(item.getDate());
    });

    setUnavailableDays(recurringPaymentDisabledDates);
    setSelectedDay(selectedDay);
    setSelectedMonth(selectedMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earliestAutoDebitDate, latestAutoDebitDate]);

  useEffect(() => {
    prefillPostingDate(postingDateDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingDateDays]);

  useEffect(() => {
    checkDelinquentPaymentAllowed();
    checkDelinquentPrincipalAllowed();
    checkDelinquentRecurringAllowed();
    checkAdditionalEscrowAmountAllowed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate, lateFeesDue, autoPaymentDueDate]);

  useEffect(() => {
    async function processInputs() {
      const actualAmount =
        type === RECURRING_PAYMENT
          ? amountDue
          : type === ESCROW_SHORTAGE
          ? escrowShortageAmount
          : amount;
      if ((!actualAmount || +actualAmount === 0) && +additionalPrincipal > 0) {
        setIsPrincipalOnlyPayment(true);
      } else {
        setIsPrincipalOnlyPayment(false);
      }
      const amountTotalWithoutTransactionFee =
        type === RECURRING_PAYMENT
          ? +actualAmount + +additionalPrincipal + +additionalEscrow
          : type === ONE_TIME_PAYMENT
          ? +actualAmount +
            +lateFees +
            +fees +
            +additionalPrincipal +
            +additionalEscrow
          : +actualAmount;
      await determineFeeOption(
        amountTotalWithoutTransactionFee.toFixed(2),
        stateMaxFee,
      );

      const totalAmount =
        type === RECURRING_PAYMENT
          ? +actualAmount +
            +additionalPrincipal +
            +additionalEscrow +
            +transactionFee
          : type === ONE_TIME_PAYMENT
          ? +actualAmount +
            +lateFees +
            +fees +
            +additionalPrincipal +
            +additionalEscrow +
            +transactionFee
          : +actualAmount + +amountChargedForEscrowShortage;

      const dialogBody = `Are you sure you want to ${
        type === RECURRING_PAYMENT
          ? mode === "new"
            ? "set a recurring payment of"
            : "edit the recurring payment of"
          : "pay"
      } $${totalAmount} ${
        type === RECURRING_PAYMENT
          ? mode === "new"
            ? "starting"
            : "to now start"
          : ""
      } on ${
        type === RECURRING_PAYMENT
          ? `${
              availableDays.find((item) => item.value === selectedDay)?.label
            } ${MONTHS[selectedMonth - 1]?.label}`
          : startDate.toDateString()
      }${
        mode === "new"
          ? ` using ${
              paymentMethods.find(
                (item) => item.paymentMethodId === selectedMethod,
              )?.nickname
            }`
          : ""
      }?`;

      setAmountTotal(totalAmount.toFixed(2));
      setConfirmationDialogBody(dialogBody);

      const mandatoryFields = [
        actualAmount,
        lateFees,
        fees,
        additionalPrincipal,
        additionalEscrow,
      ];
      const errorMessages = [
        amountErrorMessage,
        lateFeesErrorMessage,
        feesErrorMessage,
        additionalPrincipalErrorMessage,
        additionalEscrowErrorMessage,
      ];

      const emptyFields = !mandatoryFields.some((item) => item && +item !== 0);
      const hasErrorMessages = errorMessages.some((item) => item !== "");
      const aboveMaxTransaction = isAmountAboveMax(totalAmount);
      const paymentLessThanPrincipalBalance = isPaymentLessThanPrincipalBalance(
        amount,
        additionalPrincipal,
      );

      setSubmitDisabled(
        emptyFields ||
          hasErrorMessages ||
          (termsAndConditionsText && !termsAndConditionsCheckbox) ||
          (type === ONE_TIME_PAYMENT && aboveMaxTransaction) ||
          selectedMethod === "empty" ||
          (type === ONE_TIME_PAYMENT && !oneTimePaymentAllowed) ||
          (type === RECURRING_PAYMENT && !recurringPaymentAllowed) ||
          !paymentLessThanPrincipalBalance,
      );
    }
    processInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amount,
    amountDue,
    lateFees,
    fees,
    additionalPrincipal,
    additionalEscrow,
    transactionFee,
    type,
    availableDays,
    paymentMethods,
    selectedMethod,
    startDate,
    selectedDay,
    selectedMonth,
    amountErrorMessage,
    lateFeesErrorMessage,
    feesErrorMessage,
    additionalPrincipalErrorMessage,
    additionalEscrowErrorMessage,
    oneTimePaymentAllowed,
    recurringPaymentAllowed,
    termsAndConditionsCheckbox,
    termsAndConditionsText,
    customerMaxTransaction,
    stateMaxTransaction,
    stateMaxFee,
  ]);

  useEffect(() => {
    if (paymentAmountFill && !helocOrConstructionLoan) {
      setAmount(amountDue);
    } else {
      setAmount("");
    }
    if (lateFeeAmountFill || mandateLateFee) {
      setLateFees(lateFeesDue);
    } else {
      setLateFees("");
    }
    if (feeAmountFill) {
      setFees(feesDue);
    } else {
      setFees("");
    }
  }, [
    paymentAmountFill,
    lateFeeAmountFill,
    feeAmountFill,
    amountDue,
    lateFeesDue,
    feesDue,
    helocOrConstructionLoan,
    mandateLateFee,
  ]);

  useEffect(() => {
    switch (type) {
      case ONE_TIME_PAYMENT:
        setTermsAndConditionsText(oneTimePaymentTermsAndConditionsText);
        setTermsAndConditionsElementKey(
          NEW_ONE_TIME_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY,
        );
        return;
      case RECURRING_PAYMENT:
        setTermsAndConditionsText(recurringPaymentTermsAndConditionsText);
        setTermsAndConditionsElementKey(
          NEW_RECURRING_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY,
        );
        return;
      case ESCROW_SHORTAGE:
        setTermsAndConditionsText(escrowShortagePaymentTermsAndConditionsText);
        setTermsAndConditionsElementKey(
          NEW_ESCROW_SHORTAGE_PAGE_TERMS_AND_CONDITIONS_KEY,
        );
        return;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    oneTimePaymentTermsAndConditionsText,
    recurringPaymentTermsAndConditionsText,
    escrowShortagePaymentTermsAndConditionsText,
  ]);

  const isPaymentLessThanPrincipalBalance = (amount, additionalPrincipal) => {
    if (
      +amount + +additionalPrincipal >
      activeLoanDetails?.loanDetails?.mortgageBalance
    ) {
      return false;
    }
    return true;
  };

  const resetAdditionalFields = () => {
    setAdditionalPrincipal("");
    setAdditionalEscrow("");
  };

  const callPaymentInfoApi = async (token) => {
    addPendingRequest();

    if (activeLoan === "AdminLoan") {
      const paymentInfoData = { ...AdminPaymentPrevalidateResponse };
      setPaymentInfoDataStates(paymentInfoData);

      const autoPaymentInfoData = { ...AdminAutoPaymentPrevalidateResponse };
      setAutoPaymentInfoDataStates(autoPaymentInfoData);
    } else if (activeLoan !== "no-loan") {
      const paymentInfoData = await getPaymentInfo(
        token,
        activeLoan,
        impersonatedUser,
      );
      setPaymentInfoDataStates(paymentInfoData);

      const autoPaymentInfoData = await getAutoPaymentInfo(
        token,
        activeLoan,
        impersonatedUser,
      );
      setAutoPaymentInfoDataStates(autoPaymentInfoData);
    } else {
      setSnackbarMessage(NO_LOAN_REGISTERED_WARNING_MESSAGE);
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    }

    const paymentMethodsData = await getPaymentMethods(token, impersonatedUser);
    setPaymentMethodsDataStates(paymentMethodsData);

    removePendingRequest();
  };

  const setPaymentInfoDataStates = (data) => {
    if (!data.message) {
      setOneTimePaymentAllowed(data.paymentAllowed);
      setAmountDue(data.amountDue || 0);
      setLateFeesDue(data.lateFees || 0);
      setFeesDue(data.fees || 0);
      if (data.postingDate) {
        setDueDate(utcToZonedTime(data.postingDate, userTimeZone));
      }
    } else {
      setOneTimePaymentAllowed(false);
    }
  };

  const setAutoPaymentInfoDataStates = (data) => {
    if (!data.message) {
      setRecurringPaymentAllowed(data.paymentAllowed);
      setAutoPaymentDueDate(
        utcToZonedTime(data.autopaymentDueDate, userTimeZone),
      );
      if (state?.from === "activity") {
        setAdditionalEscrow(data.additionalEscrow);
        setAdditionalPrincipal(data.additionalPrincipal);
      }
    } else {
      setRecurringPaymentAllowed(false);
    }
  };

  const setPaymentMethodsDataStates = (data) => {
    const activePaymentMethods = data?.length
      ? data.filter((method) => method.paymentMethodId)
      : [];
    if (activePaymentMethods?.length > 0) {
      setPaymentMethods(activePaymentMethods);
      setSelectedMethod(activePaymentMethods[0].paymentMethodId);
      setDisabledPaymentMethod(false);
    } else {
      setPaymentMethods(EMPTY_PAYMENT_METHODS_FALLBACK);
      setSelectedMethod(EMPTY_PAYMENT_METHODS_FALLBACK[0].paymentMethodId);
      setDisabledPaymentMethod(true);
    }
  };

  const setPaymentTypeAllowed = () => {
    if (!paymentsOnlineAllowed) {
      setRecurringPaymentsOnlineAllowed(false);
    }
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case NEW_PAYMENT_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case NEW_PAYMENT_PAGE_FOOTER_NOTE_KEY:
          setFooterNote(item.value);
          return;
        case NEW_ONE_TIME_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY:
          setOneTimePaymentTermsAndConditionsText(item.value);
          return;
        case NEW_RECURRING_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY:
          setRecurringPaymentTermsAndConditionsText(item.value);
          return;
        case NEW_ESCROW_SHORTAGE_PAGE_TERMS_AND_CONDITIONS_KEY:
          setEscrowShortageTermsAndConditionsText(item.value);
          return;
        case PAYMENTS_ONLINE_ALLOWED_KEY:
          setPaymentsOnlineAllowed(item.value ? item.value === "true" : true);
          return;
        case PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY:
          setPaymentAmountFill(+item.value);
          return;
        case PAYMENTS_ONLINE_LATE_FEE_FILL_KEY:
          setLateFeeAmountFill(+item.value);
          return;
        case PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY:
          setFeeAmountFill(+item.value);
          return;
        case PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_KEY:
          applyStateMax(item.value, setStateMaxTransaction);
          return;
        case PAYMENTS_ONLINE_STATE_FEE_MAX_KEY:
          applyStateMax(item.value, setStateMaxFee);
          return;
        case PAYMENTS_ONLINE_FEE_KEY:
          setHasTransactionFee(item.value === "true");
          return;
        case PAYMENTS_ONLINE_FEE_OPTION_KEY:
          setTransactionFeeOption(item.value);
          return;
        case PAYMENTS_MANDATE_LATE_FEE_KEY:
          setMandateLateFee(item.value === "true");
          return;
        case AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY:
          setRecurringPaymentsOnlineAllowed(item.value === "true");
          return;
        case AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY:
          setLatestAutoDebitDate(parseInt(item.value, 10));
          return;
        case AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY:
          setEarliestAutoDebitDate(parseInt(item.value, 10));
          return;
        case AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY:
          setAllowAutoDebitForDelinquentLoans(item.value === "true");
          return;
        case PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_KEY:
          checkCustomerMax(item.value === "true");
          return;
        case PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY:
          setPrincipalMaxTransaction(item.value || null);
          return;
        case PAYMENTS_ONLINE_CUTOFF_TIME_KEY:
          checkPaymentTime(item.value);
          return;
        case PAYMENTS_ONLINE_POSTING_DATE_KEY:
          setPostingDateDays(item.value);
          return;
        case PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY:
          setDelinquentDays(item.value);
          return;
        case PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY:
          setLateChargeThreshold(item.value);
          return;
        case PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY:
          setPrincipalIfDelinquent(item.value === "true");
          return;
        case PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY:
          setMaxDaysIntoFutureForPayment(item.value);
          return;
        case PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY:
          setChargeEscrowShortageOnline(item.value === "true");
          return;
        case PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY:
          setAmountChargedForEscrowShortage(item.value);
          return;
        case PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY:
          setPaymentFeeRoutingNo(item.value);
          return;
        default:
          return;
      }
    });
  };

  const changeTermsAndConditionsText = (text) => {
    setTermsAndConditionsText(text);
    switch (type) {
      case ONE_TIME_PAYMENT:
        setOneTimePaymentTermsAndConditionsText(text);
        return;
      case RECURRING_PAYMENT:
        setRecurringPaymentTermsAndConditionsText(text);
        return;
      default:
        return;
    }
  };

  const determineFeeOption = (amountWithoutTransactionFee, stateMaxFee) => {
    if (type === ESCROW_SHORTAGE) {
      if (chargeEscrowShortageOnline) {
        setTransactionFee(amountChargedForEscrowShortage);
      } else {
        setTransactionFee(0);
        setAmountChargedForEscrowShortage(0);
      }
      return;
    }

    const currentMethod = paymentMethods.find(
      (method) => method.paymentMethodId === selectedMethod,
    );
    if (currentMethod) {
      if (currentMethod.routingNo === paymentFeeRoutingNo) {
        setTransactionFee(0);
        return;
      }
    }

    if (hasTransactionFee) {
      switch (transactionFeeOption) {
        case "1":
          const flatFeeValue = edittedFields.find(
            (item) => item.key === PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
          );
          const flatFee = +flatFeeValue?.value ?? 0;
          setTransactionFee(
            stateMaxFee && flatFee > stateMaxFee ? stateMaxFee : flatFee,
          );
          break;
        case "2":
          const percentageFeeValue = edittedFields.find(
            (item) => item.key === PAYMENTS_ONLINE_FEE_OPTION_2_KEY,
          );
          calculatePercentageBasedFee(
            amountWithoutTransactionFee,
            +percentageFeeValue?.value ?? 0.01,
            stateMaxFee,
            setTransactionFee,
          );
          break;
        case "3":
          const thresholdFeeValue = edittedFields.find(
            (item) => item.key === PAYMENTS_ONLINE_FEE_OPTION_3_KEY,
          );
          calculateThresholdFee(
            amountWithoutTransactionFee,
            thresholdFeeValue?.value,
            stateMaxFee,
            setTransactionFee,
          );
          break;
        case "4":
          const dateRangeFeeValue = edittedFields.find(
            (item) => item.key === PAYMENTS_ONLINE_FEE_OPTION_4_KEY,
          );
          calculateDateRangeFee(
            startDate,
            dateRangeFeeValue?.value,
            stateMaxFee,
            setTransactionFee,
          );
          break;
        default:
          setTransactionFee(0);
          break;
      }
    } else {
      setTransactionFee(0);
    }
  };

  const checkDelinquentRecurringAllowed = () => {
    if (differenceInCalendarDays(today, dueDate) < 0) {
      setDelinquentLoanAutoDebitAllowed(true);
    } else {
      if (
        !allowAutoDebitForDelinquentLoans ||
        differenceInCalendarDays(today, dueDate) > 30
      ) {
        setDelinquentLoanAutoDebitAllowed(false);
      }
    }
  };

  const checkDelinquentPrincipalAllowed = () => {
    if (
      !principalIfDelinquent &&
      differenceInCalendarDays(today, dueDate) > 0
    ) {
      setDelinquentLoanPrincipalAllowed(false);
      return;
    }
    setDelinquentLoanPrincipalAllowed(true);
  };

  const checkDelinquentPaymentAllowed = () => {
    if (
      (delinquentDays.toString().length > 0 &&
        differenceInCalendarDays(today, dueDate) >= +delinquentDays) ||
      (+lateChargeThreshold > 0 && +lateFeesDue > +lateChargeThreshold)
    ) {
      setDelinquentLoanPaymentAllowed(false);
      return;
    }
    setDelinquentLoanPaymentAllowed(true);
  };

  const checkAdditionalEscrowAmountAllowed = () => {
    if (activeLoanDetails?.loanDetails?.escrowLoan) {
      setAdditionalEscrowAmountAllowed(true);
    } else {
      setAdditionalEscrowAmountAllowed(false);
    }
  };
  const prefillPostingDate = async (dateSelection) => {
    if (dateSelection.length > 0) {
      const calendarDaysDifference = differenceInCalendarDays(
        addBusinessDays(today, +dateSelection),
        today,
      );
      const holidayArray = await getHolidays(
        token,
        calendarDaysDifference + 1,
        impersonatedUser,
      );
      setStartDate(
        addBusinessDays(today, +dateSelection + holidayArray.length),
      );
      setMinDate(addBusinessDays(today, +dateSelection + holidayArray.length));
    }
  };

  const checkPaymentTime = (configuratorTime) => {
    if (isSameDay(today, startDate)) {
      const todayAtCutoffTime = zonedTimeToUtc(
        `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")} ${
          configuratorTime || "20:00"
        }`,
        "America/New_York",
      );

      if (differenceInCalendarDays(todayAtCutoffTime, today) === 0) {
        const configuratorHour = todayAtCutoffTime.getHours();
        const configuratorMinutes = todayAtCutoffTime.getMinutes();
        setCutoffTime(
          `${configuratorHour.toString().padStart(2, "0")}:${configuratorMinutes
            .toString()
            .padStart(2, "0")}`,
        );
        if (
          today.getHours() > +configuratorHour ||
          (today.getHours() === +configuratorHour &&
            today.getMinutes() > configuratorMinutes)
        ) {
          setCutoffHourPaymentAllowed(false);
          return;
        }
      }
      setCutoffHourPaymentAllowed(true);
    } else {
      setCutoffHourPaymentAllowed(true);
    }
  };

  const isAmountAboveMax = (totalAmount) => {
    const lowestMax = [stateMaxTransaction, customerMaxTransaction]
      .filter((item) => item !== null)
      .sort((a, b) => +a - +b);
    if (lowestMax?.[0]) {
      return +lowestMax[0] < +totalAmount;
    }
    return false;
  };

  const checkCustomerMax = (hasMax) => {
    if (hasMax) {
      const maxValue = edittedFields.find(
        (item) => item.key === PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY,
      );
      if (maxValue && maxValue.value) {
        setCustomerMaxTransaction(+maxValue.value);
      } else {
        setCustomerMaxTransaction(null);
      }
    } else {
      setCustomerMaxTransaction(null);
    }
  };

  const applyStateMax = (stateConfig, setter) => {
    const stateConfigSplit = stateConfig.split(";");
    const currentStateConfigField = stateConfigSplit.find(
      (item) =>
        item.split("=")[0] === activeLoanDetails?.borrowerDetails?.state,
    );
    if (currentStateConfigField) {
      setter(currentStateConfigField.split("=")[1]);
    }
  };

  const buildNewPaymentRequestBody = async () => {
    let actualDate = startDate;
    if (!cutoffHourPaymentAllowed) {
      const calendarDaysDifference = differenceInCalendarDays(
        addBusinessDays(startDate, 1),
        startDate,
      );
      const holidayArray = await getHolidays(
        token,
        calendarDaysDifference + 1,
        impersonatedUser,
      );
      actualDate = addBusinessDays(startDate, 1 + holidayArray.length);
    }
    const dateString = `${actualDate.getFullYear()}-${`0${
      actualDate.getMonth() + 1
    }`.slice(-2)}-${`0${actualDate.getDate()}`.slice(-2)}`;
    return type === RECURRING_PAYMENT
      ? {
          loanId: activeLoan,
          paymentMethodId: selectedMethod,
          additionalPrincipalAmount: +additionalPrincipal,
          additionalEscrowAmount: +additionalEscrow,
          postingDay: selectedDay,
          postingStartMonth: selectedMonth,
        }
      : type === ONE_TIME_PAYMENT
      ? {
          loanId: activeLoan,
          paymentMethodId: selectedMethod,
          paymentType: type,
          postingDate: dateString,
          paymentAmount: +amount,
          lateFeesAmount: +lateFees,
          feesAmount: +fees,
          processingFeesAmount: +transactionFee,
          additionalPrincipalAmount: +additionalPrincipal,
          additionalEscrowAmount: +additionalEscrow,
        }
      : {
          loanId: activeLoan,
          paymentMethodId: selectedMethod,
          paymentType: type,
          postingDate: dateString,
          paymentAmount: +escrowShortageAmount,
          processingFeesAmount: +amountChargedForEscrowShortage,
        };
  };

  const sendPayment = (requestBody) => {
    addPendingRequest();
    if (type === ONE_TIME_PAYMENT || type === ESCROW_SHORTAGE) {
      postPayment(token, requestBody, impersonatedUser).then((data) => {
        removePendingRequest();
        if (data.message) {
          setSnackbarMessage(capitalizeFirstLetterOnly(data.message));
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (data?.paymentAccepted === false) {
          setSnackbarMessage(
            data.friendlyErrorMessage
              ? capitalizeFirstLetterOnly(data.friendlyErrorMessage)
              : GENERIC_ERROR_MESSAGE,
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (data?.paymentAccepted === true) {
          setPaymentConfirmationNumber(data?.confirmationNumber);
          setOpenPaymentConfirmation(true);
        }
      });
    } else {
      if (mode === "new") {
        postRecurringPayment(token, requestBody, impersonatedUser).then(
          (data) => {
            removePendingRequest();
            if (data.message) {
              setSnackbarMessage(capitalizeFirstLetterOnly(data.message));
              setSnackbarSeverity("error");
              setSnackbarOpen(true);
            } else if (data?.success === false) {
              setSnackbarMessage(
                data.friendlyErrorMessage
                  ? capitalizeFirstLetterOnly(data.friendlyErrorMessage)
                  : GENERIC_ERROR_MESSAGE,
              );
              setSnackbarSeverity("error");
              setSnackbarOpen(true);
            } else if (data?.success === true) {
              navigate("/payments/activity", { replace: true });
            }
          },
        );
      } else {
        const editBody = {
          postingDay: requestBody.postingDay,
          postingStartMonth: requestBody.postingStartMonth,
        };
        putRecurringPayment(
          token,
          requestBody.loanId,
          editBody,
          impersonatedUser,
        ).then((data) => {
          removePendingRequest();
          if (data.message) {
            setSnackbarMessage(capitalizeFirstLetterOnly(data.message));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          } else if (data?.success === false) {
            setSnackbarMessage(
              data.friendlyErrorMessage
                ? capitalizeFirstLetterOnly(data.friendlyErrorMessage)
                : GENERIC_ERROR_MESSAGE,
            );
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          } else if (data?.success === true) {
            navigate("/payments/activity", { replace: true });
          }
        });
      }
    }
  };

  const handleConfirmationClose = async () => {
    const requestBody = await buildNewPaymentRequestBody();
    sendPayment(requestBody);
    setConfirmationOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const disableFields = () => {
    if (paymentsOnlineAllowed) {
      if (type === RECURRING_PAYMENT) {
        if (recurringPaymentsOnlineAllowed) {
          if (recurringPaymentAllowed) {
            return !delinquentLoanAutoDebitAllowed;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (oneTimePaymentAllowed) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const closePaymentConfirmationDialog = () => {
    setOpenPaymentConfirmation(false);
    navigate("/payments/activity", { replace: true });
  };

  return (
    <FlexContainer flexdirection="column" alignitems="center" width="100%">
      {!paymentsOnlineAllowed ||
      (!recurringPaymentsOnlineAllowed && type === RECURRING_PAYMENT) ||
      (!delinquentLoanAutoDebitAllowed && type === RECURRING_PAYMENT) ||
      (!oneTimePaymentAllowed && type !== RECURRING_PAYMENT) ||
      (!recurringPaymentAllowed && type === RECURRING_PAYMENT) ||
      !cutoffHourPaymentAllowed ||
      !delinquentLoanPaymentAllowed ? (
        <Typography
          sx={{
            background: theme.palette.warning.light,
            width: "100%",
            padding: "5px 25px",
            marginBottom: "25px",
            border: `2px solid ${theme.palette.warning.main}`,
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          {warningMessage}
        </Typography>
      ) : (
        ""
      )}
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={NEW_PAYMENT_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            NEW_PAYMENT_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <FlexContainer padding="0 0 50px 0" gap="25px" alignself="flex-start">
        <Typography fontWeight="bold">Type of payment</Typography>
        <FormControl variant="standard">
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            disabled={mode === "edit"}
          >
            <MenuItem value={ONE_TIME_PAYMENT}>One time payment</MenuItem>
            <MenuItem value={RECURRING_PAYMENT}>Recurring payment</MenuItem>
            <MenuItem value={ESCROW_SHORTAGE} disabled={!escrowShortage}>
              Escrow Shortage
            </MenuItem>
          </Select>
        </FormControl>
      </FlexContainer>
      <SectionTitle variant="h2" color="primary">
        Payment Amount
      </SectionTitle>
      <PaymentAmount
        fieldsDisabled={
          (disableFields() && delinquentLoanPaymentAllowed) || mode === "edit"
        }
        selectedType={type}
        amountDue={type === ESCROW_SHORTAGE ? escrowShortageAmount : amountDue}
        lateFeesDue={lateFeesDue}
        feesDue={feesDue}
        amount={amount}
        setAmount={setAmount}
        lateFees={lateFees}
        setLateFees={setLateFees}
        fees={fees}
        setFees={setFees}
        additionalPrincipal={additionalPrincipal}
        setAdditionalPrincipal={setAdditionalPrincipal}
        additionalEscrow={additionalEscrow}
        setAdditionalEscrow={setAdditionalEscrow}
        amountErrorMessage={amountErrorMessage}
        setAmountErrorMessage={setAmountErrorMessage}
        lateFeesErrorMessage={lateFeesErrorMessage}
        setLateFeesErrorMessage={setLateFeesErrorMessage}
        feesErrorMessage={feesErrorMessage}
        setFeesErrorMessage={setFeesErrorMessage}
        additionalPrincipalErrorMessage={additionalPrincipalErrorMessage}
        setAdditionalPrincipalErrorMessage={setAdditionalPrincipalErrorMessage}
        additionalEscrowErrorMessage={additionalEscrowErrorMessage}
        setAdditionalEscrowErrorMessage={setAdditionalEscrowErrorMessage}
        mandateLateFee={mandateLateFee}
        isPrincipalOnlyPayment={isPrincipalOnlyPayment}
        principalMaxTransaction={principalMaxTransaction}
        principalDisabled={!delinquentLoanPrincipalAllowed}
        additionalEscrowAmountAllowed={additionalEscrowAmountAllowed}
        helocOrConstructionLoan={helocOrConstructionLoan}
      />
      {paymentsOnlineAllowed && delinquentLoanPaymentAllowed ? (
        <PaymentTotal
          transactionFee={transactionFee}
          amountTotal={amountTotal}
          maxTransaction={
            [stateMaxTransaction, customerMaxTransaction]
              .filter((item) => item !== null)
              .sort((a, b) => +a - +b)?.[0] ?? null
          }
          type={type}
          amount={amount}
          additionalPrincipal={additionalPrincipal}
        />
      ) : (
        ""
      )}
      <SectionTitle variant="h2" color="primary">
        {type === RECURRING_PAYMENT ? "First Payment" : ""} Posting Date
      </SectionTitle>
      {type === RECURRING_PAYMENT ? (
        <PaymentRecurrence
          availableDays={availableDays}
          unavailableDays={unavailableDays}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      ) : (
        <FlexContainer padding="0 0 25px 0" gap="25px">
          <FormItem
            elementWidth="200px"
            label="Current Posting Date"
            inputType="datepicker"
            value={startDate}
            setValue={setStartDate}
            datepickerOpen={datepickerOpen}
            setDatepickerOpen={setDatepickerOpen}
            minDate={minDate}
            maxDate={maxDate}
            filterDate={(date) => !isWeekend(date)}
            excludedDates={excludedDates}
            hasComponent={paymentsOnlineAllowed && delinquentLoanPaymentAllowed}
            component={
              <Box width="200px">
                <PillButton
                  variant="contained"
                  width="130px"
                  color="primary"
                  onClick={(e) => setDatepickerOpen(true)}
                  sx={{ fontSize: "10px", padding: "5px 15px" }}
                >
                  Change Posting Date
                </PillButton>
              </Box>
            }
          />
        </FlexContainer>
      )}
      {mode === "new" ? (
        <>
          <SectionTitle variant="h2" color="primary">
            Bank Information
          </SectionTitle>
          <FlexContainer padding="0 0 50px 0" gap="25px">
            <FormItem
              elementWidth="200px"
              label="Payment Method"
              inputType="dropdown"
              value={selectedMethod}
              setValue={setSelectedMethod}
              dropdownOptions={paymentMethods}
              dropdownKey="paymentMethodId"
              dropdownValue="paymentMethodId"
              dropdownLabel="nickname"
              disabled={
                !paymentsOnlineAllowed ||
                (type === RECURRING_PAYMENT &&
                  !recurringPaymentsOnlineAllowed) ||
                (type === RECURRING_PAYMENT &&
                  !delinquentLoanAutoDebitAllowed) ||
                !delinquentLoanPaymentAllowed ||
                disabledPaymentMethod
              }
              hasComponent
              component={
                <Box width="200px">
                  <PillButton
                    variant="contained"
                    width="150px"
                    color="primary"
                    onClick={(e) => navigate("/payments/methods")}
                    sx={{ fontSize: "10px", padding: "5px 15px" }}
                  >
                    Manage Payment Methods
                  </PillButton>
                </Box>
              }
            />
          </FlexContainer>
        </>
      ) : (
        ""
      )}
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      (paymentsOnlineAllowed &&
        delinquentLoanPaymentAllowed &&
        termsAndConditionsText) ? (
        <EditableTermsAndConditions
          renderedText={termsAndConditionsText}
          setRenderedText={changeTermsAndConditionsText}
          checkboxActive={termsAndConditionsCheckbox}
          setCheckboxActive={setTermsAndConditionsCheckbox}
          elementKey={termsAndConditionsElementKey}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            termsAndConditionsElementKey,
          )}
          padding="25px 0"
          fontSize={12}
        />
      ) : (
        ""
      )}
      <PillButton
        color="primary"
        variant="contained"
        width="33%"
        customfontsize="1.25rem"
        onClick={() => setConfirmationOpen(true)}
        disabled={
          !paymentsOnlineAllowed ||
          (type === RECURRING_PAYMENT && !recurringPaymentsOnlineAllowed) ||
          (type === RECURRING_PAYMENT && !delinquentLoanAutoDebitAllowed) ||
          !delinquentLoanPaymentAllowed ||
          submitDisabled ||
          impersonatedUser ||
          userRoles?.includes(CLIENT_ADMIN_ROLE)
        }
      >
        Submit Payment
      </PillButton>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      footerNote ? (
        <>
          <Divider sx={{ paddingTop: "50px" }} flexItem />
          <EditableText
            renderedText={footerNote}
            setRenderedText={setFooterNote}
            elementKey={NEW_PAYMENT_PAGE_FOOTER_NOTE_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              NEW_PAYMENT_PAGE_FOOTER_NOTE_KEY,
            )}
            padding="5px 0 0 0"
            fontSize={12}
            withHyperlink
          />
        </>
      ) : (
        ""
      )}
      <ConfirmationDialog
        open={confirmationOpen}
        close={handleConfirmationClose}
        dismiss={() => setConfirmationOpen(false)}
        title="Confirm payment"
        body={confirmationDialogBody}
      />
      <ActionSnackbar
        severity={snackbarSeverity}
        borderColor={theme.palette[snackbarSeverity].main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={handleSnackbarClose}
        snackbarLabel={snackbarMessage}
        hasAction={snackbarSeverity === "warning"}
        actionLabel="Register Loan"
        actionFn={() => navigate("/profile/loan")}
      />
      <ConfirmationDialog
        open={openPaymentConfirmation}
        close={closePaymentConfirmationDialog}
        dismiss={closePaymentConfirmationDialog}
        title={PAYMENT_SUCCESSFUL}
        body={PAYMENT_CONFIRMATION(paymentConfirmationNumber)}
        hasDismissButton={false}
      />
    </FlexContainer>
  );
}
