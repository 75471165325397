import { EMAIL_REGEX } from "../constants/Login.constants";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const addOrdinalSuffix = (arr) => {
  return arr.map((item) => {
    return addIndividualOrdinalSuffix(item);
  });
};

export const addIndividualOrdinalSuffix = (nr) => {
  const remainder = nr % 10;
  const twoDigitNumber = nr % 100;
  if (remainder === 1 && twoDigitNumber !== 11) {
    return `${nr}st`;
  }
  if (remainder === 2 && twoDigitNumber !== 12) {
    return `${nr}nd`;
  }
  if (remainder === 3 && twoDigitNumber !== 13) {
    return `${nr}rd`;
  }
  return `${nr}th`;
};

export const createDaysDropdownValues = (minDay, maxDay, key) => {
  const dayValues = [...Array(maxDay - minDay + 1).keys()].map(
    (i) => i + minDay,
  );
  const dayLabels = addOrdinalSuffix(dayValues);
  return dayValues.map((day, index) => {
    return {
      key: `${key}-${day}`,
      value: day,
      label: dayLabels[index],
    };
  });
};

export const checkValidity = (target, setErrorMessage) => {
  if (target.validity.valid) {
    setErrorMessage("");
    return;
  }
  setErrorMessage(target.validationMessage);
};

export const handleInputChange = (e, setter, setErrorMessage = null) => {
  e.preventDefault();
  setter(e.target.value);
  if (setErrorMessage !== null) {
    checkValidity(e.target, setErrorMessage);
  }
};

export const prefillInput = (target, value, setter, setErrorMessage) => {
  setter(value);
  // TODO: Find better async callback
  setTimeout(() => {
    checkValidity(target, setErrorMessage);
  }, 0);
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex
    .match(hex.length <= 4 ? /\w/g : /\w\w/g)
    .map((x) => parseInt(x.length < 2 ? `${x}${x}` : x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const padCents = (str) => {
  if (!str.includes(".")) {
    return str.concat(".00");
  }
  if (str.split(".")[1].length === 1) {
    return str.concat("0");
  }
  return str;
};

export const toDateStringNoDay = (dateLocaleString) => {
  const dateString = new Date(dateLocaleString).toDateString().split(" ");
  dateString.shift();
  return dateString.join(", ").replace(",", "");
};

export const createTableData = (row, rowAttributes) => {
  return Object.fromEntries(
    rowAttributes.map((_, i) => [rowAttributes[i], row[i]]),
  );
};

export function capitalizeFirstLetterOnly(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeFirstLetters(word: string) {
  return word
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
}

export function findElementIncludedInArray(
  arr,
  filterKey,
  filterValue,
  findKey,
  findValue,
) {
  return arr
    .filter((item) => item[filterKey] === filterValue)
    .some((item) => item[findKey] === findValue);
}

export const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const convertTwentyFourToTwelveHourFormat = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? "AM" : "PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const removeNonDigits = (value) => {
  if (!value) return value;

  return value.replace(/[^\d]/g, "");
};

export const formatPhoneNumber = (value) => {
  if (!value) return value;

  const phoneNumber = removeNonDigits(value);

  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you format the area code too early
  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatSocialSecurityNumber = (value) => {
  if (!value) return value;

  const socialSecurityNumber = removeNonDigits(value);

  const socialSecurityNumberLength = socialSecurityNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you format the area code too early
  if (socialSecurityNumberLength < 4) {
    return socialSecurityNumber;
  }

  if (socialSecurityNumberLength < 6) {
    return `${socialSecurityNumber.slice(0, 3)}-${socialSecurityNumber.slice(
      3,
    )}`;
  }

  return `${socialSecurityNumber.slice(0, 3)}-${socialSecurityNumber.slice(
    3,
    5,
  )}-${socialSecurityNumber.slice(5, 9)}`;
};

export const findInArray = (arr, key, value) => {
  return arr.find((item) => item[key] === value);
};

export const isAllowed = (element, key, value, defaultReturn) => {
  return element ? element[key] === value : defaultReturn;
};

export const validateEmail = (email) => {
  return String(email).toLowerCase().match(EMAIL_REGEX);
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
