import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled((props) => <TableCell {...props} />)`
  font-size: 12px;
  font-weight: bold;
  border: 0;
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.text.disabled : theme.palette.text.main};
`;
export const StyledTableHeaderCell = styled((props) => (
  <TableCell {...props} />
))`
  border-color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledTableRow = styled((props) => <TableRow {...props} />)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }

  ${({ withborders }) =>
    withborders &&
    `
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  `}
`;
