import React, { useContext } from "react";
import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import FormItemTimepicker from "../../../shared/components/form/FormItemTimepicker";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const TimePickerSetting = ({
  elementKey = "",
  label = "",
  value = "",
  setValue = () => {},
  timepickerOpen = false,
  setTimepickerOpen = () => {},
  hasInfo = false,
  Info = <></>,
  padding = "0",
  background = "transparent",
}) => {
  const theme = useTheme();
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  const handleChange = async (date) => {
    await setValue(date);
    setTimepickerOpen(false);

    const timeSplit = date.toTimeString().split(":");
    addNewEdittedField({
      key: elementKey,
      value: `${timeSplit[0]}:${timeSplit[1]}`,
      modified: true,
    });
  };
  return (
    <FlexContainer
      width="100%"
      gap="25px"
      justifycontent="space-between"
      padding={padding}
      background={background}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          color: theme.palette.text.primary,
          fontSize: 16,
          flexGrow: 3,
        }}
      >
        {label}
      </Typography>
      {hasInfo ? Info : ""}
      <FormItemTimepicker
        elementWidth="200px"
        startTime={value}
        setStartTime={handleChange}
        timepickerOpen={timepickerOpen}
        setTimepickerOpen={setTimepickerOpen}
        openOnInput
      />
    </FlexContainer>
  );
};

export default TimePickerSetting;
