import React from "react";
import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoText from "../../../../../shared/components/InfoText";
import { FlexContainer } from "../../../../../shared/components/styled/Container.styled";
import CheckHintOverlay from "./CheckHintOverlay";
import { ADD_PAYMENT_METHOD_CHECK_INFO } from "../../../../../shared/constants/Payments.constants";

const BankInformationHelper = () => {
  return (
    <FlexContainer
      width="100%"
      alignitems="flex-start"
      justifycontent="flex-start"
      padding="25px"
    >
      <FlexContainer
        flexdirection="column"
        alignitems="flex-start"
        gap="10px"
        padding="0 25px 0 0"
      >
        <Typography fontWeight="bold">Bank Information</Typography>
        <InfoText
          Icon={InfoOutlinedIcon}
          iconColor="primary"
          text={ADD_PAYMENT_METHOD_CHECK_INFO}
          textColor="text.hint"
          textSize={12}
          textWeight="bold"
        />
      </FlexContainer>
      <FlexContainer flexgrow="2">
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "auto",
            }}
            alt="Check Sample"
            src="/check-sample.png"
          />
          <CheckHintOverlay />
        </Box>
      </FlexContainer>
    </FlexContainer>
  );
};

export default BankInformationHelper;
