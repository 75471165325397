import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  ButtonGroup,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import {
  FOOTER_ACTIVE_COLUMN_COUNT_KEY,
  FOOTER_COMPANY_NAME_KEY,
  FOOTER_COPYRIGHT_TEXT_KEY,
  FOOTER_EQUAL_HOUSE_LENDING_KEY,
  FOOTER_FIFTH_COLUMN_ITEMS_KEY,
  FOOTER_FIFTH_COLUMN_TITLE_KEY,
  FOOTER_FOURTH_COLUMN_ITEMS_KEY,
  FOOTER_FOURTH_COLUMN_TITLE_KEY,
  FOOTER_HYPERLINKS_KEY,
  FOOTER_SECOND_COLUMN_ITEMS_KEY,
  FOOTER_SECOND_COLUMN_TITLE_KEY,
  FOOTER_SIXTH_COLUMN_ITEMS_KEY,
  FOOTER_SIXTH_COLUMN_TITLE_KEY,
  FOOTER_THIRD_COLUMN_ITEMS_KEY,
  FOOTER_THIRD_COLUMN_TITLE_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
} from "../constants/Configurator.constants";
import {
  findElementIncludedInArray,
  formatPhoneNumber,
} from "../utils/utility-functions";
import EditableText from "./configurator/EditableText";
import FooterItems from "./configurator/FooterItems";
import { FlexContainer } from "./styled/Container.styled";
import { CustomFooter } from "./styled/Nav.styled";
import Logo from "./Logo";
import { CLIENT_ADMIN_ROLE } from "../constants/Roles.constants";

const Footer = () => {
  const location = useLocation();
  const theme = useTheme();
  const { userRoles, visibleRole } = useContext(AuthContext);
  const { addNewEdittedField, edittedFields } = useContext(ConfiguratorContext);

  const [copyright, setCopyright] = useState("© 2022");
  const [companyName, setCompanyName] = useState(
    "Constellation Mortgage Solutions, Inc.",
  );
  const [externalLinks, setExternalLinks] = useState("");
  const [equalHouseLending, setEqualHouseLending] = useState("");
  const [secondColumnTitle, setSecondColumnTitle] = useState("");
  const [thirdColumnTitle, setThirdColumnTitle] = useState("");
  const [fourthColumnTitle, setFourthColumnTitle] = useState("");
  const [fifthColumnTitle, setFifthColumnTitle] = useState("");
  const [sixthColumnTitle, setSixthColumnTitle] = useState("");

  const [activeColumns, setActiveColumns] = useState(1);

  const footerColumnAttributes = [
    {
      titleKey: FOOTER_SECOND_COLUMN_TITLE_KEY,
      title: secondColumnTitle,
      titleSetter: setSecondColumnTitle,
      itemsKey: FOOTER_SECOND_COLUMN_ITEMS_KEY,
    },
    {
      titleKey: FOOTER_THIRD_COLUMN_TITLE_KEY,
      title: thirdColumnTitle,
      titleSetter: setThirdColumnTitle,
      itemsKey: FOOTER_THIRD_COLUMN_ITEMS_KEY,
    },
    {
      titleKey: FOOTER_FOURTH_COLUMN_TITLE_KEY,
      title: fourthColumnTitle,
      titleSetter: setFourthColumnTitle,
      itemsKey: FOOTER_FOURTH_COLUMN_ITEMS_KEY,
    },
    {
      titleKey: FOOTER_FIFTH_COLUMN_TITLE_KEY,
      title: fifthColumnTitle,
      titleSetter: setFifthColumnTitle,
      itemsKey: FOOTER_FIFTH_COLUMN_ITEMS_KEY,
    },
    {
      titleKey: FOOTER_SIXTH_COLUMN_TITLE_KEY,
      title: sixthColumnTitle,
      titleSetter: setSixthColumnTitle,
      itemsKey: FOOTER_SIXTH_COLUMN_ITEMS_KEY,
    },
  ];

  const customerSupportPhoneNumber =
    edittedFields.find(
      (item) => item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
    )?.value || null;
  const customerSupportContactUsURL =
    edittedFields.find(
      (item) => item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY,
    )?.value || null;
  const customerSupportHelpURL =
    edittedFields.find(
      (item) => item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY,
    )?.value || null;

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case FOOTER_COPYRIGHT_TEXT_KEY:
          setCopyright(item.value);
          return;
        case FOOTER_COMPANY_NAME_KEY:
          setCompanyName(item.value);
          return;
        case FOOTER_HYPERLINKS_KEY:
          setExternalLinks(item.value);
          return;
        case FOOTER_EQUAL_HOUSE_LENDING_KEY:
          setEqualHouseLending(item.value);
          return;
        case FOOTER_ACTIVE_COLUMN_COUNT_KEY:
          setActiveColumns(+item.value);
          return;
        case FOOTER_SECOND_COLUMN_TITLE_KEY:
          setSecondColumnTitle(item.value);
          return;
        case FOOTER_THIRD_COLUMN_TITLE_KEY:
          setThirdColumnTitle(item.value);
          return;
        case FOOTER_FOURTH_COLUMN_TITLE_KEY:
          setFourthColumnTitle(item.value);
          return;
        case FOOTER_FIFTH_COLUMN_TITLE_KEY:
          setFifthColumnTitle(item.value);
          return;
        case FOOTER_SIXTH_COLUMN_TITLE_KEY:
          setSixthColumnTitle(item.value);
          return;
        default:
          return;
      }
    });
  };

  const incrementColumns = () => {
    addNewEdittedField({
      key: FOOTER_ACTIVE_COLUMN_COUNT_KEY,
      value: activeColumns + 1,
      modified: true,
    });
    setActiveColumns((prevActiveColumns) => prevActiveColumns + 1);
  };

  const decrementColumns = () => {
    addNewEdittedField({
      key: FOOTER_ACTIVE_COLUMN_COUNT_KEY,
      value: activeColumns - 1,
      modified: true,
    });
    setActiveColumns((prevActiveColumns) => prevActiveColumns - 1);
  };

  return (
    <CustomFooter
      background={
        location.pathname === "/support"
          ? theme.palette.background.paper
          : "transparent"
      }
    >
      <FlexContainer width="100%" alignitems="space-between">
        <FlexContainer
          flexdirection="column"
          flexbasis="25%"
          alignitems="flex-start"
          justifycontent="flex-start"
          padding={
            userRoles?.includes(CLIENT_ADMIN_ROLE) &&
            visibleRole === CLIENT_ADMIN_ROLE
              ? "0 40px 0 0"
              : "0 25px 0 0"
          }
        >
          <EditableText
            renderedText={copyright}
            setRenderedText={setCopyright}
            elementKey={FOOTER_COPYRIGHT_TEXT_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              FOOTER_COPYRIGHT_TEXT_KEY,
            )}
            fontSize={12}
            fontColor="text"
            withHyperlink
            verticalButtons
          />
          <EditableText
            renderedText={companyName}
            setRenderedText={setCompanyName}
            elementKey={FOOTER_COMPANY_NAME_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              FOOTER_COMPANY_NAME_KEY,
            )}
            fontSize={12}
            fontColor="text"
            withHyperlink
            verticalButtons
          />
          {customerSupportPhoneNumber ||
          customerSupportContactUsURL ||
          customerSupportHelpURL ? (
            <FlexContainer
              flexdirection="column"
              gap="5px"
              width="100%"
              padding="10px 0 0 0"
            >
              {customerSupportPhoneNumber ? (
                <FlexContainer
                  width="100%"
                  justifycontent="space-between"
                  gap="15px"
                >
                  <PhoneIcon color="primary" />
                  <Typography fontWeight="bold">
                    {formatPhoneNumber(customerSupportPhoneNumber)}
                  </Typography>
                </FlexContainer>
              ) : null}
              {customerSupportContactUsURL ? (
                <FlexContainer
                  width="100%"
                  justifycontent="space-between"
                  gap="15px"
                >
                  <Tooltip title="Contact Us">
                    <ContactPageIcon color="primary" />
                  </Tooltip>
                  <Link
                    fontWeight="bold"
                    href={`${
                      customerSupportContactUsURL.substring(0, 4) !== "http"
                        ? "http://"
                        : ""
                    }${customerSupportContactUsURL}`}
                    target={"_blank"}
                    rel="noopener"
                    sx={{ cursor: "pointer" }}
                  >
                    {customerSupportContactUsURL}
                  </Link>
                </FlexContainer>
              ) : null}
              {customerSupportHelpURL ? (
                <FlexContainer
                  width="100%"
                  justifycontent="space-between"
                  gap="15px"
                >
                  <Tooltip title="Help Information">
                    <HelpOutlineIcon color="primary" />
                  </Tooltip>
                  <Link
                    fontWeight="bold"
                    href={`${
                      customerSupportHelpURL.substring(0, 4) !== "http"
                        ? "http://"
                        : ""
                    }${customerSupportHelpURL}`}
                    target={"_blank"}
                    rel="noopener"
                    sx={{ cursor: "pointer" }}
                  >
                    {customerSupportHelpURL}
                  </Link>
                </FlexContainer>
              ) : null}
            </FlexContainer>
          ) : null}
          <EditableText
            renderedText={externalLinks}
            setRenderedText={setExternalLinks}
            elementKey={FOOTER_HYPERLINKS_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              FOOTER_HYPERLINKS_KEY,
            )}
            padding={externalLinks ? "25px 0 0 0" : 0}
            fontSize={12}
            fontColor="text"
            withHyperlink
            verticalButtons
          />
          <FlexContainer width="100%" gap="5px" padding="10px 0">
            <Logo src="/TFC-EHL-Logo.png" height="96px" />
            <EditableText
              renderedText={equalHouseLending}
              setRenderedText={setEqualHouseLending}
              elementKey={FOOTER_EQUAL_HOUSE_LENDING_KEY}
              wasModified={findElementIncludedInArray(
                edittedFields,
                "modified",
                true,
                "key",
                FOOTER_EQUAL_HOUSE_LENDING_KEY,
              )}
              padding="0"
              fontSize={12}
              fontColor="text"
              withHyperlink
              verticalButtons
            />
          </FlexContainer>
        </FlexContainer>
        {footerColumnAttributes.map((column, index) =>
          index < activeColumns ? (
            <FlexContainer
              key={column.itemsKey}
              flexdirection="column"
              flexbasis={`${75 / activeColumns}%`}
              alignitems="flex-start"
              justifycontent="flex-start"
              padding={
                userRoles?.includes(CLIENT_ADMIN_ROLE) &&
                visibleRole === CLIENT_ADMIN_ROLE
                  ? "0 40px 0 0"
                  : "0 25px 0 0"
              }
            >
              <EditableText
                renderedText={column.title}
                setRenderedText={column.titleSetter}
                elementKey={column.titleKey}
                wasModified={findElementIncludedInArray(
                  edittedFields,
                  "modified",
                  true,
                  "key",
                  column.titleKey,
                )}
                fontSize={12}
                fontColor="text"
                verticalButtons
              />
              <FooterItems
                elementKey={column.itemsKey}
                wasModified={findElementIncludedInArray(
                  edittedFields,
                  "modified",
                  true,
                  "key",
                  column.itemsKey,
                )}
                columnTitle={column.title}
              />
            </FlexContainer>
          ) : (
            ""
          ),
        )}
        {userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE ? (
          <ButtonGroup
            orientation="vertical"
            sx={{ alignSelf: "stretch", margin: "5px 0" }}
          >
            {activeColumns < 5 ? (
              <Button
                color="success"
                variant="outlined"
                onClick={incrementColumns}
                sx={{
                  height: "100%",
                  padding: 0,
                  minWidth: "30px",
                }}
              >
                <AddIcon />
              </Button>
            ) : (
              ""
            )}
            {activeColumns > 1 ? (
              <Button
                color="error"
                variant="outlined"
                onClick={decrementColumns}
                sx={{
                  height: "100%",
                  padding: 0,
                  minWidth: "30px",
                }}
              >
                <RemoveIcon />
              </Button>
            ) : (
              ""
            )}
          </ButtonGroup>
        ) : (
          ""
        )}
      </FlexContainer>
    </CustomFooter>
  );
};

export default Footer;
