import React from "react";
import { FormControl, Input } from "@mui/material";
import DatePicker from "react-datepicker";

const FormItemDatepicker = ({
  elementWidth,
  startDate,
  setStartDate,
  datepickerOpen,
  setDatepickerOpen,
  minDate,
  maxDate,
  openOnInput = false,
  filterDate = () => {
    return true;
  },
  excludedDates = [],
}) => {
  const handleDateSelect = async (date) => {
    await setStartDate(date);
    setDatepickerOpen(false);
  };

  return (
    <FormControl
      onClick={openOnInput ? (e) => setDatepickerOpen(true) : () => {}}
    >
      <DatePicker
        selected={startDate}
        onChange={(date) => handleDateSelect(date)}
        onClickOutside={(e) => setDatepickerOpen(false)}
        minDate={minDate}
        maxDate={maxDate}
        customInput={
          <Input variant="standard" sx={{ minWidth: elementWidth }} />
        }
        open={datepickerOpen}
        filterDate={filterDate}
        excludeDates={excludedDates}
        disabled
        disabledKeyboardNavigation
      />
    </FormControl>
  );
};

export default FormItemDatepicker;
