import { Alert, Box, Container, Divider, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

export const FlexContainer = styled((props) => <Container {...props} />)`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  max-width: ${(props) => props.maxwidth || "unset"};
  min-width: ${(props) => props.minwidth || "auto"};
  background: ${(props) => props.background || "transparent"};
  opacity: ${(props) => props.opacity || "1"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderradius || "10px"};
  display: flex;
  flex-direction: ${(props) => props.flexdirection || "row"};
  justify-content: ${(props) => props.justifycontent || "center"};
  align-items: ${(props) => props.alignitems || "center"};
  align-self: ${(props) => props.alignself || "auto"};
  position: ${(props) => props.position || "initial"};
  gap: ${(props) => props.gap || "normal"};
  flex-shrink: ${(props) => props.flexshrink || "1"};
  flex-grow: ${(props) => props.flexgrow || "0"};
  flex-wrap: ${(props) => props.flexwrap || "nowrap"};
  flex-basis: ${(props) => props.flexbasis || "auto"};

  &:hover {
    opacity: ${(props) => props.opacity || "1"};
  }
`;

export const InfoContainer = styled((props) => <Container {...props} />)`
  width: 66.6%;
  padding: 0 25px 0 0;

  & svg {
    margin-right: 5px;
  }

  & .MuiTypography-caption {
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
`;

export const BackgroundImageBox = styled((props) => <Box {...props} />)`
  background: ${({ src }) => `url(${src})` || "transparent"};
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;

  ${({ flip }) =>
    flip &&
    `
  transform: scaleX(-1);
  `}
`;

export const AsideNavigation = styled((props) => <Container {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  margin: 0;
  padding: 25px;
  padding-left: 0;
  width: inherit;
  height: inherit;
  min-width: 165px;

  & a {
    text-decoration: none;
    font-size: 0.857rem;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.secondary};
    letter-spacing: normal;

    &.active {
      color: ${({ theme }) => theme.palette.primary.main};
      font-size: 1.142rem;
    }

    &.disabled {
      color: ${({ theme }) => theme.palette.text.disabled};
      pointer-events: none;
    }
  }
`;

export const CustomDivider = styled((props) => <Divider {...props} />)`
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-bottom-width: medium;
`;

export const DashboardCardMedium = styled((props) => <Paper {...props} />)`
  box-shadow: 0 22px 32px -12px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
`;

export const DashboardCardSmall = styled((props) => <Paper {...props} />)`
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
`;

export const PillContainer = styled((props) => <Container {...props} />)`
  display: initial;
  padding: 4px 15px;
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: ${({ background, theme }) =>
    background || theme.palette.background.default};
  color: ${({ color, theme }) => color || theme.palette.text.primary};
`;

export const AbsoluteContainer = styled((props) => <Container {...props} />)`
  position: absolute;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  max-width: ${(props) => props.maxwidth || "unset"};
  min-width: ${(props) => props.minwidth || "auto"};
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderradius || "none"};
`;

export const RelativeContainer = styled((props) => <Container {...props} />)`
  position: relative;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  max-width: ${(props) => props.maxwidth || "unset"};
  min-width: ${(props) => props.minwidth || "auto"};
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderradius || "none"};
  aspect-ratio: ${(props) => props.aspectratio || "auto"};
`;

export const CustomContainer = styled((props) => <Container {...props} />)`
  position: ${(props) => props.position || "absolute"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  max-width: ${(props) => props.maxwidth || "unset"};
  min-width: ${(props) => props.minwidth || "auto"};
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderradius || "none"};
  aspect-ratio: ${(props) => props.aspectratio || "auto"};
`;

export const CustomAlert = styled(
  forwardRef((props, ref) => <Alert ref={ref} {...props} />),
)`
  .MuiAlert-action {
    padding-top: 0;
  }
`;

export const Image = styled((props) => <img alt={props.alt} {...props} />)`
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxwidth || "unset"};
  object-fit: cover;
  vertical-align: middle;
`;

export const SemiCircleContainer = styled((props) => <Box {...props} />)`
  display: block;
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0;
  overflow: hidden;
  position: absolute;

  &:after {
    content: "";
    width: 106%;
    height: 300%;
    border-radius: 100%;
    background: ${({ theme }) => theme.palette.background.paper};
    position: absolute;
    border: 1px solid ${({ theme }) => theme.palette.background.paper};
    box-sizing: border-box;
    top: -200%;
    left: -3%;
  }
`;
