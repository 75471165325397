import { Chip, TextField, Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useTheme } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PAYMENT_METHODS_ICONS_ACTION_OBJECT,
  RENAME_PAYMENT_METHOD_HELP_TOOLTIP,
} from "../../constants/Payments.constants";
import { switchAction } from "../../utils/payment-method-functions";
import { ColouredFab } from "../styled/Button.styled";
import { FlexContainer } from "../styled/Container.styled";
import { AuthContext } from "../../../contexts/AuthContext";
import { CLIENT_ADMIN_ROLE } from "../../constants/Roles.constants";

const PaymentMethodsCell = ({
  cellAttribute,
  cellData,
  children,
  rowInfo,
  cellLogicCallbacks,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { impersonatedUser, userRoles } = useContext(AuthContext);
  const [currentNickname, setCurrentNickname] = useState(rowInfo.name);

  useEffect(() => {
    setCurrentNickname(rowInfo.name);
  }, [rowInfo]);

  const changeValue = (value) => {
    setCurrentNickname(value);
    cellLogicCallbacks.onChangeInput(value);
  };

  const sortPaymenthMethodAction = (
    action,
    rowInfo,
    navigate,
    cellLogicCallbacks,
  ) => {
    if (rowInfo.isInput && action === "Rename") {
      setCurrentNickname(rowInfo.name);
    }
    switchAction(action, rowInfo, navigate, cellLogicCallbacks);
  };

  const renderInputItem = () => {
    if (cellAttribute === "actions") {
      return (
        <FlexContainer justifycontent="flex-end" gap="10px">
          {cellData.map((action) => {
            return action === "Verify" && rowInfo.paymentMethodId ? (
              <span key={`${rowInfo.name}-${action.substring(0, 2)}`}></span>
            ) : (
              <Tooltip
                key={`${rowInfo.name}-${action.substring(0, 2)}`}
                title={
                  (!!impersonatedUser ||
                    userRoles?.includes(CLIENT_ADMIN_ROLE)) &&
                  action !== "Verify"
                    ? ""
                    : PAYMENT_METHODS_ICONS_ACTION_OBJECT[action].tooltip
                }
              >
                <ColouredFab
                  color={
                    theme.palette[
                      PAYMENT_METHODS_ICONS_ACTION_OBJECT[action].color
                    ].main
                  }
                  size="small"
                  iconfontsize="1.125rem"
                  disabled={
                    (!!impersonatedUser ||
                      userRoles?.includes(CLIENT_ADMIN_ROLE)) &&
                    action !== "Verify"
                  }
                  onClick={(e) =>
                    sortPaymenthMethodAction(
                      action,
                      rowInfo,
                      navigate,
                      cellLogicCallbacks,
                    )
                  }
                  sx={{ width: 30, height: 30, minHeight: 30 }}
                >
                  {PAYMENT_METHODS_ICONS_ACTION_OBJECT[action].icon}
                </ColouredFab>
              </Tooltip>
            );
          })}
        </FlexContainer>
      );
    }
    if (cellAttribute === "name") {
      return (
        <FlexContainer justifycontent="flex-start" gap="10px">
          {rowInfo.isInput ? (
            <Tooltip title={RENAME_PAYMENT_METHOD_HELP_TOOLTIP}>
              <TextField
                variant="standard"
                value={currentNickname}
                onKeyUp={(e) => cellLogicCallbacks.triggerUpdate(e, rowInfo)}
                onChange={(e) => changeValue(e.target.value)}
                padding="0 0 25px 0"
                fontSize={12}
              />
            </Tooltip>
          ) : (
            cellData
          )}
          {rowInfo.valid ? (
            <Chip
              label="Verified"
              color="success"
              size="small"
              sx={{
                backgroundColor: theme.palette.success.light,
              }}
              icon={<DoneIcon />}
            />
          ) : (
            ""
          )}
        </FlexContainer>
      );
    }
    return <>{cellData}</>;
  };
  return renderInputItem();
};

export default PaymentMethodsCell;
