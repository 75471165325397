export const PAYMENT_DUE_NOTIFICATION_KEY = "notificationPaymentDue";
export const PAYMENT_PROCESSED_NOTIFICATION_KEY =
  "notificationPaymentProcessed";
export const PAYMENT_RETURNED_NOTIFICATION_KEY = "notificationPaymentReturned";
export const HAZARD_RENEWAL_NOTIFICATION_KEY = "notificationHazardRenewal";
export const ELECTRONIC_STATEMENT_NOTIFICATION_KEY =
  "notificationElectronicStatement";
export const REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY =
  "notificationRealEstateTaxPaid";
export const USER_EMAIL_ADDRESS_KEY = "notificationEmail";
export const USER_MOBILE_NUMBER_KEY = "notificationMobile";

export const COMMUNICATION_PREFERENCES_UPDATE_SUCCESSFUL_MESSAGE =
  "Communication preferences were updated successfully.";
export const COMMUNICATION_PREFERENCES_UPDATE_FAILED_MESSAGE =
  "Communication preferences could not be updated. Try again later or contact your mortgage provider.";
export const NOTIFICATIONS_DISABLED_UPDATE_SUCCESSFUL_MESSAGE =
  "Disabled notifications were updated successfully.";
export const NOTIFICATIONS_DISABLED_UPDATE_FAILED_MESSAGE =
  "Disabled notifications could not be updated. Try again later or contact your mortgage provider.";
export const PREFERENCES_UPDATED_MESSAGE =
  "Your communication preferences have been updated.";
export const PREFERENCES_NOT_UPDATED_MESSAGE =
  "Your communication preferences could not be updated. Try again later or contact your mortgage provider.";
export const CHANGE_ADDRESS_REQUEST_SUCCEEDED_MESSAGE =
  "Your request has been sent.";
export const CHANGE_ADDRESS_REQUEST_FAILED_MESSAGE =
  "Your request could not be sent. Try again later or contact your mortgage provider.";

export const COMMUNICATION_PREFERENCES_ATTRIBUTES_GET_BODY = [
  {
    operation: "GET",
    attribute: {
      name: PAYMENT_DUE_NOTIFICATION_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: PAYMENT_PROCESSED_NOTIFICATION_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: PAYMENT_RETURNED_NOTIFICATION_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: HAZARD_RENEWAL_NOTIFICATION_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: ELECTRONIC_STATEMENT_NOTIFICATION_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: USER_EMAIL_ADDRESS_KEY,
    },
  },
  {
    operation: "GET",
    attribute: {
      name: USER_MOBILE_NUMBER_KEY,
    },
  },
];

export const NOTIFICATION_DEFAULT_VALUE = "email";
export const DEFAULT_NOTIFICATION_CONFIGURATION = {
  [PAYMENT_DUE_NOTIFICATION_KEY]: 3,
  [PAYMENT_PROCESSED_NOTIFICATION_KEY]: 3,
  [PAYMENT_RETURNED_NOTIFICATION_KEY]: 3,
  [HAZARD_RENEWAL_NOTIFICATION_KEY]: 3,
  [ELECTRONIC_STATEMENT_NOTIFICATION_KEY]: 3,
  [REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY]: 3,
};
