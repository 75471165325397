// Texts Config
export const NEW_PAYMENT_PAGE_DESCRIPTION_KEY = "NEW_PAYMENT_PAGE_DESCRIPTION";
export const NEW_PAYMENT_PAGE_FOOTER_NOTE_KEY = "NEW_PAYMENT_PAGE_FOOTER_NOTE";
export const NEW_ONE_TIME_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY =
  "NEW_ONE_TIME_PAYMENT_PAGE_TERMS_AND_CONDITIONS";
export const NEW_RECURRING_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY =
  "NEW_RECURRING_PAYMENT_PAGE_TERMS_AND_CONDITIONS";
export const NEW_ESCROW_SHORTAGE_PAGE_TERMS_AND_CONDITIONS_KEY =
  "NEW_ESCROW_SHORTAGE_PAGE_TERMS_AND_CONDITIONS";
export const PAYMENT_ACTIVITY_PAGE_DESCRIPTION_KEY =
  "PAYMENT_ACTIVITY_PAGE_DESCRIPTION";
export const PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE_KEY =
  "PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE";
export const ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY =
  "ADD_PAYMENT_METHOD_PAGE_DESCRIPTION";
export const ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY =
  "ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE";
export const VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY =
  "VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION";
export const VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY =
  "VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE";
export const DASHBOARD_TAX_AND_INSURANCE_CAPTION_KEY =
  "DASHBOARD_TAX_AND_INSURANCE_CAPTION";
export const DASHBOARD_PAYMENT_ACTIVITY_CAPTION_KEY =
  "DASHBOARD_PAYMENT_ACTIVITY_CAPTION";
export const PAYMENT_METHODS_DESCRIPTION_KEY = "PAYMENT_METHODS_DESCRIPTION";
export const PAYMENT_METHODS_FOOTER_NOTE_KEY = "PAYMENT_METHODS_FOOTER_NOTE";
export const LOAN_PAGE_DESCRIPTION_KEY = "LOAN_DESCRIPTION";
export const LOAN_PAGE_FOOTER_NOTE_KEY = "LOAN_FOOTER_NOTE";
export const LOAN_PAGE_DISCLOSURE_AGREEMENT_KEY = "LOAN_DISCLOSURE_AGREEMENT";
export const CONFIG_PAGE_DESCRIPTION_KEY = "CONFIG_PAGE_DESCRIPTION";
export const CHANGE_ACCOUNT_DETAILS_DESCRIPTION_KEY =
  "CHANGE_ACCOUNT_DETAILS_DESCRIPTION";
export const CHANGE_ADDRESS_PAGE_DESCRIPTION_KEY =
  "CHANGE_ADDRESS_PAGE_DESCRIPTION";
export const STATEMENTS_DOCUMENTS_ALL_DOCUMENTS_PAGE_DESCRIPTION_KEY =
  "STATEMENTS_DOCUMENTS_ALL_DOCUMENTS_PAGE_DESCRIPTION";
export const TAX_DOCUMENTS_PAGE_DESCRIPTION_KEY =
  "TAX_DOCUMENTS_PAGE_DESCRIPTION";
export const OTHER_DOCUMENTS_PAGE_DESCRIPTION_KEY =
  "OTHER_DOCUMENTS_PAGE_DESCRIPTION";
export const REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY =
  "REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION";
export const UPLOAD_DOCUMENT_PAGE_DESCRIPTION_KEY =
  "UPLOAD_DOCUMENT_PAGE_DESCRIPTION";
export const STATEMENT_DELIVERY_PAGE_DESCRIPTION_KEY =
  "STATEMENT_DELIVERY_PAGE_DESCRIPTION";
export const STATEMENT_DELIVERY_ESTATEMENT_ONLY_KEY =
  "STATEMENT_DELIVERY_ESTATEMENT_ONLY";
export const STATEMENT_DELIVERY_PAPER_STATEMENT_KEY =
  "STATEMENT_DELIVERY_PAPER_STATEMENT";

// Branding Config
export const THEME_PRIMARY_COLOR_KEY = "THEME_PRIMARY_COLOR";
export const THEME_SECONDARY_COLOR_KEY = "THEME_SECONDARY_COLOR";
export const THEME_INFO_COLOR_KEY = "THEME_INFO_COLOR";
export const THEME_SUCCESS_COLOR_KEY = "THEME_SUCCESS_COLOR";
export const THEME_WARNING_COLOR_KEY = "THEME_WARNING_COLOR";
export const THEME_ERROR_COLOR_KEY = "THEME_ERROR_COLOR";
export const THEME_BACKGROUND_COLOR_KEY = "THEME_BACKGROUND_COLOR";
export const THEME_PAPER_COLOR_KEY = "THEME_PAPER_COLOR";

// Banner Config
export const BANNER_IMAGE_DASHBOARD_KEY = "BANNER_IMAGE_DASHBOARD";
export const BANNER_URL_DASHBOARD_KEY = "BANNER_URL_DASHBOARD";
export const BANNER_IMAGE_PAYMENTS_KEY = "BANNER_IMAGE_PAYMENTS";
export const BANNER_URL_PAYMENTS_KEY = "BANNER_URL_PAYMENTS";
export const BANNER_IMAGE_STATEMENTS_KEY = "BANNER_IMAGE_STATEMENTS";
export const BANNER_URL_STATEMENTS_KEY = "BANNER_URL_STATEMENTS";

// Logo Config
export const LOGO_IMAGE_KEY = "LOGO_IMAGE";

// Advert Config
export const ADVERT_IMAGE_KEY = "ADVERT_IMAGE_KEY";
export const ADVERT_MESSAGE_KEY = "ADVERT_MESSAGE_KEY";
export const ADVERT_HYPERLINK_KEY = "ADVERT_HYPERLINK_KEY";
export const HAS_ADVERT_KEY = "HAS_ADVERT_KEY";
export const ADVERT_LABEL = "Do you want to setup an advert section?";
export const ADVERT_SECONDARY_LABEL = "Customize the advert section";

// Logout Config
export const LOGOUT_HAS_MESSAGE_KEY = "LOGOUT_HAS_MESSAGE";
export const LOGOUT_MESSAGE_KEY = "LOGOUT_MESSAGE";
export const LOGOUT_SUBTITLE_KEY = "LOGOUT_SUBTITLE";
export const LOGOUT_ALLOW_MESSAGE_LABEL =
  "Do you want to add a marketing message on the logout page?";
export const LOGOUT_MESSAGE_LABEL = "Marketing Message";
export const LOGOUT_SUBTITLE_LABEL = "Marketing Subtitle";

// Footer Config
export const FOOTER_ACTIVE_COLUMN_COUNT_KEY = "FOOTER_ACTIVE_COLUMN_COUNT";
// First Column
export const FOOTER_COPYRIGHT_TEXT_KEY = "FOOTER_COPYRIGHT_TEXT";
export const FOOTER_COMPANY_NAME_KEY = "FOOTER_COMPANY_NAME";
export const FOOTER_HYPERLINKS_KEY = "FOOTER_HYPERLINKS";
export const FOOTER_EQUAL_HOUSE_LENDING_KEY = "FOOTER_EQUAL_HOUSE_LENDING";
// Second Column
export const FOOTER_SECOND_COLUMN_TITLE_KEY = "FOOTER_SECOND_COLUMN_TITLE";
export const FOOTER_SECOND_COLUMN_ITEMS_KEY = "FOOTER_SECOND_COLUMN_ITEMS";
// Third Column
export const FOOTER_THIRD_COLUMN_TITLE_KEY = "FOOTER_THIRD_COLUMN_TITLE";
export const FOOTER_THIRD_COLUMN_ITEMS_KEY = "FOOTER_THIRD_COLUMN_ITEMS";
// Fourth Column
export const FOOTER_FOURTH_COLUMN_TITLE_KEY = "FOOTER_FOURTH_COLUMN_TITLE";
export const FOOTER_FOURTH_COLUMN_ITEMS_KEY = "FOOTER_FOURTH_COLUMN_ITEMS";
// Fifth Column
export const FOOTER_FIFTH_COLUMN_TITLE_KEY = "FOOTER_FIFTH_COLUMN_TITLE";
export const FOOTER_FIFTH_COLUMN_ITEMS_KEY = "FOOTER_FIFTH_COLUMN_ITEMS";
// Sixth Column
export const FOOTER_SIXTH_COLUMN_TITLE_KEY = "FOOTER_SIXTH_COLUMN_TITLE";
export const FOOTER_SIXTH_COLUMN_ITEMS_KEY = "FOOTER_SIXTH_COLUMN_ITEMS";

// Payments Config Logic
export const PAYMENTS_ONLINE_ALLOWED_KEY = "PAYMENTS_ONLINE_ALLOWED";
export const PAYMENTS_ONLINE_FEE_KEY = "PAYMENTS_ONLINE_FEE";
export const PAYMENTS_ONLINE_FEE_OPTION_KEY = "PAYMENTS_ONLINE_FEE_OPTION";
export const PAYMENTS_ONLINE_FEE_OPTION_1_KEY = "PAYMENTS_ONLINE_FEE_OPTION_1";
export const PAYMENTS_ONLINE_FEE_OPTION_2_KEY = "PAYMENTS_ONLINE_FEE_OPTION_2";
export const PAYMENTS_ONLINE_FEE_OPTION_3_KEY = "PAYMENTS_ONLINE_FEE_OPTION_3";
export const PAYMENTS_ONLINE_FEE_OPTION_4_KEY = "PAYMENTS_ONLINE_FEE_OPTION_4";
export const PAYMENTS_MANDATE_LATE_FEE_KEY = "PAYMENTS_MANDATE_LATE_FEE";
export const PAYMENTS_ONLINE_STATE_FEE_MAX_KEY =
  "PAYMENTS_ONLINE_STATE_FEE_MAX";
export const PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_KEY =
  "PAYMENTS_ONLINE_STATE_TRANSACTION_MAX";
export const PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY =
  "PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO";
export const PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_KEY =
  "PAYMENTS_ONLINE_CUSTOMER_TRANSACTION";
export const PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY =
  "PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX";
export const PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY =
  "PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX";
export const PAYMENTS_ONLINE_CUTOFF_TIME_KEY = "PAYMENTS_ONLINE_CUTOFF_TIME";
export const PAYMENTS_ONLINE_POSTING_DATE_KEY = "PAYMENTS_ONLINE_POSTING_DATE";
export const PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY =
  "PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT";
export const PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY =
  "PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD";
export const PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY =
  "PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT";
export const PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY =
  "PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE";
export const PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY =
  "PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE";
export const PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY =
  "PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE";
export const PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY =
  "PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT";

// Payments Config UI
export const PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY =
  "PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL";
export const PAYMENTS_ONLINE_LATE_FEE_FILL_KEY =
  "PAYMENTS_ONLINE_LATE_FEE_FILL";
export const PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY =
  "PAYMENTS_ONLINE_OTHER_FEE_FILL";
export const PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_KEY =
  "PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT";

// Auto Payments Config
export const AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY =
  "AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT";
export const AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY =
  "AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT";
export const AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY =
  "AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT";
export const AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY =
  "AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS";

// E-Statements
export const ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS_KEY =
  "ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS";

// Documents Config
export const DOCUMENTS_ALLOW_PAPER_STATEMENTS_KEY =
  "DOCUMENTS_ALLOW_PAPER_STATEMENTS";
export const DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY =
  "DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME";
export const DOCUMENTS_PAPER_STATEMENT_ATTN_KEY =
  "DOCUMENTS_PAPER_STATEMENT_ATTN";
export const DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY =
  "DOCUMENTS_PAPER_STATEMENT_ADDRESS_1";
export const DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY =
  "DOCUMENTS_PAPER_STATEMENT_ADDRESS_2";
export const DOCUMENTS_ALLOW_ACCESS_KEY = "DOCUMENTS_ALLOW_ACCESS";
export const DOCUMENTS_ALLOWED_TO_BE_DOWNLOADED_KEY =
  "DOCUMENTS_ALLOWED_TO_BE_DOWNLOADED";
export const DOCUMENTS_ALLOW_UPLOAD_KEY = "DOCUMENTS_ALLOW_UPLOAD";
export const DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY =
  "DOCUMENTS_ALLOWED_TO_BE_UPLOADED";
export const DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY =
  "DOCUMENTS_ALLOW_REQUEST_PAYOFF";
export const DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY =
  "DOCUMENTS_REQUEST_PAYOFF_REASONS";
export const DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY =
  "DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE";
export const DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY =
  "DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE";

// Notifications Config
export const NOTIFICATIONS_ALLOW_KEY = "NOTIFICATIONS_ALLOW";
//  Notification preferences separated by semicolons (;)
//  Each notification preference has the format key=value
//  Possible values are:
//    0 - No Access
//    1 - Text/SMS
//    2 - Email
//    3 - Both
export const NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY =
  "NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED";
export const NOTIFICATIONS_EMAIL_ADDRESS_CHANGE_KEY =
  "NOTIFICATIONS_EMAIL_ADDRESS_CHANGE";
export const NOTIFICATIONS_EMAIL_PAYOFF_QUOTE_KEY =
  "NOTIFICATIONS_EMAIL_PAYOFF_QUOTE";
export const NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_KEY =
  "NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD";
export const NOTIFICATIONS_CONFIRMATION_EMAIL_KEY =
  "NOTIFICATIONS_CONFIRMATION_EMAIL";
export const NOTIFICATIONS_APPARENT_SENDER_KEY =
  "NOTIFICATIONS_APPARENT_SENDER";
export const NOTIFICATIONS_PAYMENT_CANCELLED_KEY =
  "NOTIFICATIONS_PAYMENT_CANCELLED";
export const NOTIFICATIONS_RECURRING_PAYMENT_KEY =
  "NOTIFICATIONS_RECURRING_PAYMENT";
export const NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_KEY =
  "NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE";
export const NOTIFICATIONS_CONFIRMATION_EMAIL_LABEL =
  "Do you want a confirmation email sent to the borrower upon submission of a payment?";
export const NOTIFICATIONS_APPARENT_SENDER_LABEL =
  "Who do you want to be the apparent sender of the email (if blank, response@lookupmyloan.com)?";
export const NOTIFICATIONS_PAYMENT_CANCELLED_LABEL =
  "Do you want an email sent to the borrower upon canceling of a payment?";
export const NOTIFICATIONS_RECURRING_PAYMENT_LABEL =
  "Do you want an email sent to the borrower upon setting up a recurring payment?";
export const NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_LABEL =
  "Email notifies the borrower that they have opted out of receiving paper statements.";

// User Account Requirement Config
export const USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY =
  "USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER";
export const USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY =
  "USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL";
export const USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY =
  "USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL";
export const INACTIVE_LOAN_DASHBOARD_ALLOW_KEY = "INACTIVE_LOAN_DASHBOARD_ALLOW";
export const INACTIVE_LOAN_DASHBOARD_MESSAGE_KEY = "INACTIVE_LOAN_DASHBOARD_MESSAGE";

export const CONFIGURATOR_KEYS = [
  NEW_PAYMENT_PAGE_DESCRIPTION_KEY,
  NEW_PAYMENT_PAGE_FOOTER_NOTE_KEY,
  NEW_ONE_TIME_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY,
  NEW_RECURRING_PAYMENT_PAGE_TERMS_AND_CONDITIONS_KEY,
  NEW_ESCROW_SHORTAGE_PAGE_TERMS_AND_CONDITIONS_KEY,
  PAYMENT_ACTIVITY_PAGE_DESCRIPTION_KEY,
  PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE_KEY,
  ADD_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY,
  ADD_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY,
  VERIFY_PAYMENT_METHOD_PAGE_DESCRIPTION_KEY,
  VERIFY_PAYMENT_METHOD_PAGE_FOOTER_NOTE_KEY,
  DASHBOARD_TAX_AND_INSURANCE_CAPTION_KEY,
  DASHBOARD_PAYMENT_ACTIVITY_CAPTION_KEY,
  PAYMENT_METHODS_DESCRIPTION_KEY,
  PAYMENT_METHODS_FOOTER_NOTE_KEY,
  LOAN_PAGE_DESCRIPTION_KEY,
  LOAN_PAGE_FOOTER_NOTE_KEY,
  LOAN_PAGE_DISCLOSURE_AGREEMENT_KEY,
  CONFIG_PAGE_DESCRIPTION_KEY,
  CHANGE_ACCOUNT_DETAILS_DESCRIPTION_KEY,
  CHANGE_ADDRESS_PAGE_DESCRIPTION_KEY,
  THEME_PRIMARY_COLOR_KEY,
  THEME_SECONDARY_COLOR_KEY,
  THEME_INFO_COLOR_KEY,
  THEME_SUCCESS_COLOR_KEY,
  THEME_WARNING_COLOR_KEY,
  THEME_ERROR_COLOR_KEY,
  THEME_BACKGROUND_COLOR_KEY,
  THEME_PAPER_COLOR_KEY,
  PAYMENTS_ONLINE_ALLOWED_KEY,
  PAYMENTS_ONLINE_FEE_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_2_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_3_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_4_KEY,
  PAYMENTS_MANDATE_LATE_FEE_KEY,
  PAYMENTS_ONLINE_STATE_FEE_MAX_KEY,
  PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_KEY,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_CUTOFF_TIME_KEY,
  PAYMENTS_ONLINE_POSTING_DATE_KEY,
  PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY,
  PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY,
  PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY,
  PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY,
  PAYMENTS_ONLINE_LATE_FEE_FILL_KEY,
  PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY,
  PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY,
  PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY,
  PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY,
  PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY,
  AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY,
  BANNER_URL_DASHBOARD_KEY,
  BANNER_URL_PAYMENTS_KEY,
  FOOTER_ACTIVE_COLUMN_COUNT_KEY,
  FOOTER_COPYRIGHT_TEXT_KEY,
  FOOTER_COMPANY_NAME_KEY,
  FOOTER_HYPERLINKS_KEY,
  FOOTER_EQUAL_HOUSE_LENDING_KEY,
  FOOTER_SECOND_COLUMN_TITLE_KEY,
  FOOTER_SECOND_COLUMN_ITEMS_KEY,
  FOOTER_THIRD_COLUMN_TITLE_KEY,
  FOOTER_THIRD_COLUMN_ITEMS_KEY,
  FOOTER_FOURTH_COLUMN_TITLE_KEY,
  FOOTER_FOURTH_COLUMN_ITEMS_KEY,
  FOOTER_FIFTH_COLUMN_TITLE_KEY,
  FOOTER_FIFTH_COLUMN_ITEMS_KEY,
  FOOTER_SIXTH_COLUMN_TITLE_KEY,
  FOOTER_SIXTH_COLUMN_ITEMS_KEY,
  STATEMENTS_DOCUMENTS_ALL_DOCUMENTS_PAGE_DESCRIPTION_KEY,
  TAX_DOCUMENTS_PAGE_DESCRIPTION_KEY,
  OTHER_DOCUMENTS_PAGE_DESCRIPTION_KEY,
  REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY,
  UPLOAD_DOCUMENT_PAGE_DESCRIPTION_KEY,
  REQUEST_PAYOFF_QUOTE_PAGE_DESCRIPTION_KEY,
  DOCUMENTS_ALLOW_PAPER_STATEMENTS_KEY,
  DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY,
  DOCUMENTS_PAPER_STATEMENT_ATTN_KEY,
  DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY,
  DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY,
  DOCUMENTS_ALLOW_ACCESS_KEY,
  DOCUMENTS_ALLOWED_TO_BE_DOWNLOADED_KEY,
  DOCUMENTS_ALLOW_UPLOAD_KEY,
  DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  DOCUMENTS_REQUEST_PAYOFF_REASONS_KEY,
  DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_KEY,
  DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_KEY,
  NOTIFICATIONS_ALLOW_KEY,
  NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY,
  NOTIFICATIONS_EMAIL_ADDRESS_CHANGE_KEY,
  NOTIFICATIONS_EMAIL_PAYOFF_QUOTE_KEY,
  NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY,
  ADVERT_MESSAGE_KEY,
  ADVERT_HYPERLINK_KEY,
  HAS_ADVERT_KEY,
  STATEMENT_DELIVERY_PAGE_DESCRIPTION_KEY,
  NOTIFICATIONS_CONFIRMATION_EMAIL_KEY,
  NOTIFICATIONS_APPARENT_SENDER_KEY,
  NOTIFICATIONS_PAYMENT_CANCELLED_KEY,
  NOTIFICATIONS_RECURRING_PAYMENT_KEY,
  NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_KEY,
  ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS_KEY,
  NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_KEY,
  LOGOUT_HAS_MESSAGE_KEY,
  LOGOUT_MESSAGE_KEY,
  LOGOUT_SUBTITLE_KEY,
  STATEMENT_DELIVERY_ESTATEMENT_ONLY_KEY,
  STATEMENT_DELIVERY_PAPER_STATEMENT_KEY,
  INACTIVE_LOAN_DASHBOARD_ALLOW_KEY,
  INACTIVE_LOAN_DASHBOARD_MESSAGE_KEY
];

export const CLIENT_CONFIG_BRANDING_VIEW = "CLIENT_CONFIG_BRANDING_VIEW";
export const CLIENT_CONFIG_PAYMENTS_VIEW = "CLIENT_CONFIG_PAYMENTS_VIEW";
export const CLIENT_CONFIG_RECURRING_PAYMENTS_VIEW =
  "CLIENT_CONFIG_RECURRING_PAYMENTS_VIEW";
export const CLIENT_CONFIG_USER_SETTINGS_VIEW =
  "CLIENT_CONFIG_USER_SETTINGS_VIEW";

export const AUTO_PAYMENTS_ONLINE_EARLIEST_NOT_AFTER_LATEST_ERROR_MESSAGE =
  "Earliest Auto Debit day must not be later than Latest Auto Debit day.";

export const USER_MANAGEMENT_HEADER_NAMES = [
  "First Name",
  "Last Name",
  "Email Address",
  "Roles",
  "",
];

export const USER_MANAGEMENT_FIELD_TYPES = [
  "firstName",
  "lastName",
  "email",
  "roles",
  "actions",
];

export const USER_MANAGEMENT_NO_ACTION_MESSAGE =
  "Use the search field to find users by email";
export const USER_MANAGEMENT_SELF_SEARCH_MESSAGE =
  "This user is you. You can't perform actions on yourself.";
export const USER_MANAGEMENT_SEARCH_FAIL_MESSAGE =
  "Something went wrong, please try again later.";

export const USER_MANAGEMENT_DEFAULT_DELETE_MESSAGE =
  "Are you sure you want to delete this user? This action is permanent and cannot be reversed.";

export const USER_MANAGEMENT_CHANGE_ROLE_ERROR_MESSAGE =
  "Could not change user role, please try again later.";
export const USER_MANAGEMENT_DELETE_ERROR_MESSAGE =
  "Could not delete user, please try again later.";

export const NOTIFICATION_PREFERENCE_OPTIONS = [
  {
    value: 0,
    label: "No access",
  },
  {
    value: 1,
    label: "Text",
  },
  {
    value: 2,
    label: "Email",
  },
  {
    value: 3,
    label: "Both",
  },
];

export const PUBLISH_CHANGES_CONFIRMATION_BODY =
  "Published changes will be visible to everyone. Are you sure you want to publish?";

export const PAYMENTS_ONLINE_ALLOWED_LABEL =
  "Do you want to allow borrowers access to make payments online?";
export const PAYMENTS_ONLINE_FEE_LABEL =
  "Do you want to charge your borrowers a fee for making payments online?";
export const PAYMENTS_ONLINE_FEE_SECONDARY_LABEL =
  "Select a preferred option to charge for payments online:";
export const PAYMENTS_ONLINE_STATE_FEE_MAX_LABEL =
  "Maximum fee amount in a specific state";
export const PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_LABEL =
  "Maximum dollar amount a borrower can make in a specific state";
export const PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_LABEL =
  "If applicable, waive transaction fee if routing number is";
export const PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_LABEL =
  "Default setting for making an online payment:";
export const PAYMENTS_ONLINE_LATE_FEE_FILL_LABEL =
  "What is the default setting for late fees?";
export const PAYMENTS_MANDATE_LATE_FEE_LABEL =
  "Do you want to mandate that the current late fee be included in the payment?";
export const PAYMENTS_ONLINE_OTHER_FEE_FILL_LABEL =
  "What is the default setting for other fees?";
export const PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_LABEL =
  "Do you want to limit the total dollar amount of a single payment?";
export const PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_SECONDARY_LABEL =
  "Please provide a limit amount";
export const PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_LABEL = "Dollar amount";
export const PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_INFO =
  "Standard Payment + Late Charge + Other Fees + Additional Principal";
export const PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_LABEL =
  "Maximum dollar amount a borrower can make on a principal only payment?";
export const PAYMENTS_ONLINE_CUTOFF_TIME_LABEL = "Portal payment cut-off time?";
export const PAYMENTS_ONLINE_CUTOFF_TIME_INFO =
  "Important! The Portal Payment must be prior to cut-off time.";
export const PAYMENTS_ONLINE_POSTING_DATE_LABEL =
  "Payment posting date in relation to portal payment receipt?";
export const PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_LABEL =
  "Do you want to restrict payments if the loan is delinquent?";
export const PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_LABEL =
  "Number of days a loan can be delinquent and still allow payments?";
export const PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_LABEL =
  "Late charge threshold?";
export const PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_INFO =
  "The amount of late charges a borrower can have until online payments will be denied";
export const PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_LABEL =
  "Do you want to allow additional principal payments if the loan is either not current or making the current months payment online?";
export const PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_LABEL =
  "How many days into the future can the borrower schedule a payment?";
export const PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_LABEL =
  "Do you want to charge a fee if the borrower is paying their escrow shortage online?";
export const PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_LABEL =
  "Provide the fee amount";
export const PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_INFO =
  "THIS IS SEPARATE FROM THE REGULAR PAYMENT TRANSACTION FEE, it applies only to Escrow shortage payments and it should be a flat fee option.";
export const PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_LABEL =
  "Do you want to allow the borrower to delete pending payments?";

export const AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_LABEL =
  "Do you want to allow your borrowers access to manage (add/change/delete) their auto debit information?";
export const AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_LABEL =
  "Latest day in the month an auto debit can be set up:";
export const AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_LABEL =
  "Earliest day in the month an auto debit can be set up:";
export const AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_LABEL =
  "Do you want to allow delinquent loans the ability to set up auto debit?";

export const DOCUMENTS_ALLOW_PAPER_STATEMENTS_LABEL =
  "Do you want to allow the borrower to go back to paper statements from paperless using the portal?";
export const DOCUMENTS_ALLOW_PAPER_STATEMENTS_SECONDARY_LABEL =
  "We provide a notation that the borrower should send a request in writing to your company. Provide the address as you want it to appear:";
export const DOCUMENTS_ALLOW_ACCESS_LABEL =
  "Do you want to allow the borrower access to stored documents?";
export const DOCUMENTS_ALLOW_ACCESS_SECONDARY_LABEL =
  "Select the categories allowed for viewing:";
export const DOCUMENTS_ALLOW_UPLOAD_LABEL =
  "Do you want to allow the borrower to upload documents?";
export const DOCUMENTS_ALLOW_UPLOAD_SECONDARY_LABEL =
  "Select the categories allowed for uploading:";
export const DOCUMENTS_ALLOW_REQUEST_PAYOFF_LABEL =
  "Do you want to allow the borrower to request a payoff?";
export const DOCUMENTS_ALLOW_REQUEST_PAYOFF_SECONDARY_LABEL =
  "Provide a list of reasons you want them to select from, so you know what their intentions are:";
export const DOCUMENTS_EMAIL_PAYOFF_STATEMENT_FEE_LABEL =
  "Provide the amount of the fee you want to charge to email the payoff statement";
export const DOCUMENTS_MAIL_PAYOFF_STATEMENT_FEE_LABEL =
  "Provide the amount of the fee you want to charge to mail the payoff statement";

export const NOTIFICATIONS_ALLOW_LABEL =
  "Do you want to allow the borrower to sign up for notifications?";
export const NOTIFICATIONS_ALLOW_SECONDARY_LABEL =
  "Please choose text, email, both, and no access for the following:";
export const NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_LABEL =
  "Provide the company email address that you want to be used for notifying borrowers about document uploads:";

export const USER_ACCOUNT_REQUIREMENTS_FORM_LABEL =
  "In the event your borrower runs into a problem during the registration process they will need to be able to contact you.";
export const USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_LABEL =
  "Provide the phone number they should call to reach the appropriate department";
export const USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_LABEL =
  'Provide the "Contact us" internet address link for your contact information';
export const USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_LABEL =
  'Provide the "Help" internet address link for your Help information';
export const INACTIVE_LOAN_DASHBOARD_MESSAGE_ALLOW_LABEL =
  "Do you want to display a message on the Dashboard when loan is Inactive?";
export const INACTIVE_LOAN_DASHBOARD_MESSAGE_LABEL =
  "Message:";
