import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ADD_PAYMENT_METHOD_ACCOUNT_NUMBER_MAX_LENGTH_ERROR_MESSAGE,
  ADD_PAYMENT_METHOD_ACCOUNT_NUMBER_NUMERIC_ERROR_MESSAGE,
  ADD_PAYMENT_METHOD_ALPHANUMERIC_ERROR_MESSAGE,
  ADD_PAYMENT_METHOD_ROUTING_NUMBER_LENGTH_ERROR_MESSAGE,
  ADD_PAYMENT_METHOD_ROUTING_NUMBER_NUMERIC_ERROR_MESSAGE,
  PAYMENT_ACCOUNT_TYPE,
} from "../../../../../shared/constants/Payments.constants";
import {
  checkValidity,
  handleInputChange,
} from "../../../../../shared/utils/utility-functions";
import { FlexContainer } from "../../../../../shared/components/styled/Container.styled";
import FormItem from "../../../../../shared/components/form/FormItem";
import InfoText from "../../../../../shared/components/InfoText";

const onlyNumbersAllowed = /^[0-9]+$/;

const BankInformationForm = ({
  routingNumber = "",
  setRoutingNumber = () => {},
  accountNumber = "",
  setAccountNumber = () => {},
  accountType = PAYMENT_ACCOUNT_TYPE[0].value,
  setAccountType = () => {},
  bankName = "",
  setBankName = () => {},
  nickname = "",
  setNickname = () => {},
  routingNumberErrorMessage = "",
  setRoutingNumberErrorMessage = () => {},
  accountNumberErrorMessage = "",
  setAccountNumberErrorMessage = () => {},
  nicknameErrorMessage = "",
  setNicknameErrorMessage = () => {},
}) => {
  const checkRoutingNumberValidity = () => {
    if (!onlyNumbersAllowed.test(routingNumber)) {
      setRoutingNumberErrorMessage(
        ADD_PAYMENT_METHOD_ROUTING_NUMBER_NUMERIC_ERROR_MESSAGE,
      );
    } else if (routingNumber.length !== 9) {
      setRoutingNumberErrorMessage(
        ADD_PAYMENT_METHOD_ROUTING_NUMBER_LENGTH_ERROR_MESSAGE,
      );
      // Add another validation after we request routing number from a database and it is not valid
    } else {
      setRoutingNumberErrorMessage("");
    }
  };

  const checkAccountNumberValidity = () => {
    if (!onlyNumbersAllowed.test(accountNumber)) {
      setAccountNumberErrorMessage(
        ADD_PAYMENT_METHOD_ACCOUNT_NUMBER_NUMERIC_ERROR_MESSAGE,
      );
    } else if (accountNumber.length > 17) {
      setAccountNumberErrorMessage(
        ADD_PAYMENT_METHOD_ACCOUNT_NUMBER_MAX_LENGTH_ERROR_MESSAGE,
      );
    } else {
      setAccountNumberErrorMessage("");
    }
  };

  return (
    <FlexContainer
      padding="25px 0 35px 0"
      flexdirection="column"
      gap="10px"
      width="100%"
    >
      <FormItem
        elementWidth="25%"
        label="Routing Number"
        inputType="input"
        value={routingNumber}
        setValue={(e) => handleInputChange(e, setRoutingNumber)}
        blurHandler={(e) => checkRoutingNumberValidity()}
        errorMessage={routingNumberErrorMessage}
        inputProps={{
          type: "tel",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="25%"
        label="Account Number"
        inputType="input"
        value={accountNumber}
        setValue={(e) => handleInputChange(e, setAccountNumber)}
        blurHandler={(e) => checkAccountNumberValidity()}
        errorMessage={accountNumberErrorMessage}
        inputProps={{
          type: "tel",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="25%"
        label="Account Type"
        inputType="dropdown"
        value={accountType}
        setValue={setAccountType}
        dropdownOptions={PAYMENT_ACCOUNT_TYPE}
        dropdownKey="value"
        dropdownValue="value"
        dropdownLabel="label"
      />
      <FormItem
        elementWidth="25%"
        label="Bank Name"
        inputType="input"
        value={bankName}
        setValue={(e) => handleInputChange(e, setBankName)}
        inputProps={{
          type: "text",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="25%"
        label="Name for Payment Method"
        inputType="input"
        value={nickname}
        setValue={(e) =>
          handleInputChange(e, setNickname, setNicknameErrorMessage)
        }
        blurHandler={(e) => checkValidity(e.target, setNicknameErrorMessage)}
        errorMessage={nicknameErrorMessage}
        hasComponent
        component={
          <InfoText
            elementWidth="25%"
            justifyContent="flex-start"
            Icon={InfoOutlinedIcon}
            iconColor="primary"
            text={ADD_PAYMENT_METHOD_ALPHANUMERIC_ERROR_MESSAGE}
            textColor="text.hint"
            textSize={10}
            textWeight="bold"
          />
        }
        inputProps={{
          type: "text",
          style: {
            textAlign: "start",
          },
        }}
      />
    </FlexContainer>
  );
};

export default BankInformationForm;
