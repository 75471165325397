import { TextField, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { ConfiguratorContext } from "../../../../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../../../../shared/components/styled/Container.styled";
import {
  DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY,
  DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY,
  DOCUMENTS_PAPER_STATEMENT_ATTN_KEY,
  DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY,
} from "../../../../../../shared/constants/Configurator.constants";
import { useDebouncedEffect } from "../../../../../../shared/hooks/useDebouncedEffect";
import { useIsMount } from "../../../../../../shared/hooks/useIsMount";
import { hex2rgba } from "../../../../../../shared/utils/utility-functions";

const PaperStatementAddressForm = () => {
  const theme = useTheme();
  const isFirstRender = useIsMount();

  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [companyName, setCompanyName] = useState(
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY,
    )?.value || "",
  );
  const [attn, setAttn] = useState(
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_ATTN_KEY,
    )?.value || "",
  );
  const [addressLineOne, setAddressLineOne] = useState(
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY,
    )?.value || "",
  );
  const [addressLineTwo, setAddressLineTwo] = useState(
    edittedFields.find(
      (item) => item.key === DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY,
    )?.value || "",
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: DOCUMENTS_PAPER_STATEMENT_COMPANY_NAME_KEY,
          value: companyName,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [companyName],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: DOCUMENTS_PAPER_STATEMENT_ATTN_KEY,
          value: attn,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [attn],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: DOCUMENTS_PAPER_STATEMENT_ADDRESS_1_KEY,
          value: addressLineOne,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [addressLineOne],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: DOCUMENTS_PAPER_STATEMENT_ADDRESS_2_KEY,
          value: addressLineTwo,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [addressLineTwo],
    200,
  );

  return (
    <FlexContainer
      background={hex2rgba(theme.palette.primary.main, 0.1)}
      padding="10px 25px 25px 25px"
      width="100%"
      flexdirection="column"
      gap="10px"
    >
      <FlexContainer width="100%" gap="10px">
        <TextField
          fullWidth
          variant="standard"
          label="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          fullWidth
          variant="standard"
          label="Attn"
          value={attn}
          onChange={(e) => setAttn(e.target.value)}
        />
      </FlexContainer>
      <TextField
        fullWidth
        variant="standard"
        label="Address 1"
        value={addressLineOne}
        onChange={(e) => setAddressLineOne(e.target.value)}
      />
      <TextField
        fullWidth
        variant="standard"
        label="Address 2"
        value={addressLineTwo}
        onChange={(e) => setAddressLineTwo(e.target.value)}
      />
    </FlexContainer>
  );
};

export default PaperStatementAddressForm;
