import React, { useContext } from "react";
import DateRangeFeeOption from "./DateRangeFeeOption";
import InputFeeOption from "./InputFeeOption";
import ThresholdFeeOption from "./ThresholdFeeOption";
import SelectedOption from "../../shared/SelectedOption";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import {
  PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
  PAYMENTS_ONLINE_STATE_FEE_MAX_KEY,
  PAYMENTS_ONLINE_STATE_FEE_MAX_LABEL,
} from "../../../../shared/constants/Configurator.constants";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { useTheme } from "@mui/styles";
import AutoCompleteInputSetting from "../../shared/AutoCompleteInputSetting";
import { hex2rgba } from "../../../../shared/utils/utility-functions";

const FeeOptions = ({
  elementKey = "",
  elementKeys = {},
  selectedFeeOption = PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
  setSelectedFeeOption = () => {},
  onChangeHandler = () => {},
  validateInputValue = () => {},
  flatValueFee = "",
  setFlatValueFee = () => {},
  percentageFee = "",
  setPercentageFee = () => {},
  threshold = "",
  setThreshold = () => {},
  numberOfDateRanges = "",
  setNumberOfDateRanges = () => {},
  flatValueFeeErrorMessage = "",
  setFlatValueFeeErrorMessage = () => {},
  percentageFeeErrorMessage = "",
  setPercentageFeeErrorMessage = () => {},
  feeListElements = [],
  setFeeListElements = () => {},
}) => {
  const { addNewEdittedField } = useContext(ConfiguratorContext);
  const theme = useTheme();

  const handleSelectedFee = (selectedFeeKey) => {
    setSelectedFeeOption(selectedFeeKey);
    addNewEdittedField({
      key: elementKey,
      value: selectedFeeKey.match(/\d/g)[0],
      modified: true,
    });
  };
  return (
    <FlexContainer
      width="100%"
      gap="10px"
      alignitems="flex-start"
      flexwrap="wrap"
    >
      <FlexContainer
        alignitems="flex-start"
        gap="10px"
        sx={{ flexBasis: "49%" }}
      >
        <SelectedOption
          elementKey={elementKeys?.flatValue}
          label="Flat Value"
          selected={selectedFeeOption === elementKeys?.flatValue}
          setSelected={handleSelectedFee}
          InputItem={
            <InputFeeOption
              elementKey={elementKeys?.flatValue}
              inputValue={flatValueFee}
              setInputValue={setFlatValueFee}
              onChangeHandler={onChangeHandler}
              validateInputValue={validateInputValue}
              adornmentSymbol="$"
              errorMessage={flatValueFeeErrorMessage}
              setErrorMessage={setFlatValueFeeErrorMessage}
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
            />
          }
        />
        <SelectedOption
          elementKey={elementKeys?.percentage}
          label="Percentage"
          selected={selectedFeeOption === elementKeys?.percentage}
          setSelected={handleSelectedFee}
          InputItem={
            <InputFeeOption
              elementKey={elementKeys?.percentage}
              inputValue={percentageFee}
              setInputValue={setPercentageFee}
              onChangeHandler={onChangeHandler}
              validateInputValue={validateInputValue}
              adornmentSymbol="%"
              errorMessage={percentageFeeErrorMessage}
              setErrorMessage={setPercentageFeeErrorMessage}
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
            />
          }
        />
      </FlexContainer>
      <FlexContainer
        alignitems="flex-start"
        gap="10px"
        sx={{ flexBasis: "49%" }}
      >
        <SelectedOption
          elementKey={elementKeys?.threshold}
          label="Threshold"
          selected={selectedFeeOption === elementKeys?.threshold}
          setSelected={handleSelectedFee}
          padding={threshold ? "25px 25px 0 25px" : "25px"}
          InputItem={
            <ThresholdFeeOption
              elementKey={elementKeys?.threshold}
              selected={selectedFeeOption === elementKeys?.threshold}
              onChangeHandler={onChangeHandler}
              validateInputValue={validateInputValue}
              threshold={threshold}
              setThreshold={setThreshold}
            />
          }
        />
        <SelectedOption
          elementKey={elementKeys?.dates}
          label="Date Range"
          selected={selectedFeeOption === elementKeys?.dates}
          setSelected={handleSelectedFee}
          padding={
            numberOfDateRanges > 0 ? "25px 25px 0 25px" : "25px 25px 17px 25px"
          }
          InputItem={
            <DateRangeFeeOption
              elementKey={elementKeys?.dates}
              selected={selectedFeeOption === elementKeys?.dates}
              onChangeHandler={onChangeHandler}
              validateInputValue={validateInputValue}
              numberOfDateRanges={numberOfDateRanges}
              setNumberOfDateRanges={setNumberOfDateRanges}
            />
          }
        />
      </FlexContainer>
      <AutoCompleteInputSetting
        elementKey={PAYMENTS_ONLINE_STATE_FEE_MAX_KEY}
        elementPadding="0 10px"
        label={PAYMENTS_ONLINE_STATE_FEE_MAX_LABEL}
        labelColor="text.hint"
        labelFontSize="14px"
        listElements={feeListElements}
        setListElements={setFeeListElements}
        padding="25px"
        background={hex2rgba(theme.palette.primary.main, 0.1)}
        onChangeHandler={onChangeHandler}
        validateInputValue={validateInputValue}
      />
    </FlexContainer>
  );
};

export default FeeOptions;
