import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import FormItemDropdown from "../../../shared/components/form/FormItemDropdown";
import { createDaysDropdownValues } from "../../../shared/utils/utility-functions";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const DateSelectSetting = ({
  elementKey = "",
  label = "",
  settingValue = "",
  setValue = () => {},
  errorMessage = "",
  dropdownMargin = "0",
}) => {
  const { addNewEdittedField } = useContext(ConfiguratorContext);
  const [rangeValues, setRangeValues] = useState(
    createDaysDropdownValues(1, 28),
  );

  const handleChange = (value) => {
    setValue(value);
    addNewEdittedField({
      key: elementKey,
      value: value,
      modified: true,
    });
  };

  return (
    <FlexContainer
      flexdirection="row"
      width="100%"
      padding="0 25px 0 0"
      gap="25px"
      justifycontent="space-between"
    >
      <Typography fontWeight="bold" fontSize={16}>
        {label}
      </Typography>
      <FormItemDropdown
        elementWidth="200px"
        value={settingValue}
        setValue={handleChange}
        options={rangeValues}
        keyAttribute="key"
        errorMessage={errorMessage}
        valueAttribute="value"
        labelAttribute="label"
        margin={dropdownMargin}
      />
    </FlexContainer>
  );
};

export default DateSelectSetting;
