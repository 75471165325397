import React, { useContext, useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import RadioSetting from "../../client-config/shared/RadioSetting";
import DateSelectSetting from "../shared/DateSelectSetting";
import {
  AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_LABEL,
  AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY,
  AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_LABEL,
  AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_LABEL,
  AUTO_PAYMENTS_ONLINE_EARLIEST_NOT_AFTER_LATEST_ERROR_MESSAGE,
  AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY,
  AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_LABEL,
} from "../../../shared/constants/Configurator.constants";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const ConfigurationRecurringPaymentsCategory = () => {
  const { edittedFields } = useContext(ConfiguratorContext);
  const [manageAutoDebit, setManageAutoDebit] = useState(true);
  const [latestAutoDebitDay, setLatestAutoDebitDay] = useState("23");
  const [earliestAutoDebitDay, setEarliestAutoDebitDay] = useState("1");
  const [delinquentAutoDebit, setDelinquentAutoDebit] = useState(true);
  const [earliestLatestDayErrorMessage, setEarliestLatestDayErrorMessage] =
    useState("");

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLatestAutoDebitDay = (value) => {
    setLatestAutoDebitDay(value);

    if (earliestAutoDebitDay > value) {
      setEarliestLatestDayErrorMessage(
        AUTO_PAYMENTS_ONLINE_EARLIEST_NOT_AFTER_LATEST_ERROR_MESSAGE,
      );
    } else {
      setEarliestLatestDayErrorMessage("");
    }
  };

  const changeEarliestAutoDebitDay = (value) => {
    setEarliestAutoDebitDay(value);
    if (value > latestAutoDebitDay) {
      setEarliestLatestDayErrorMessage(
        AUTO_PAYMENTS_ONLINE_EARLIEST_NOT_AFTER_LATEST_ERROR_MESSAGE,
      );
    } else {
      setEarliestLatestDayErrorMessage("");
    }
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY:
          setManageAutoDebit(item.value);
          return;
        case AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY:
          setLatestAutoDebitDay(item.value);
          return;
        case AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY:
          setEarliestAutoDebitDay(item.value);
          return;
        case AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY:
          setDelinquentAutoDebit(item.value);
          return;
        default:
          return;
      }
    });
  };

  return (
    <FlexContainer
      flexdirection="column"
      width="100%"
      maxwidth="1720px"
      alignitems="center"
      padding="25px 100px"
      gap="25px"
    >
      <Divider flexItem />
      <RadioSetting
        elementKey={AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY}
        label={AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_LABEL}
        settingId="row-manage-auto-debit-group-label"
        settingValue={manageAutoDebit}
        setValue={(e) => setManageAutoDebit(e.target.value)}
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      ></RadioSetting>
      <Divider flexItem />
      <DateSelectSetting
        elementKey={AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_KEY}
        label={AUTO_PAYMENTS_ONLINE_LATEST_DAY_AUTO_DEBIT_LABEL}
        settingValue={latestAutoDebitDay}
        setValue={changeLatestAutoDebitDay}
        errorMessage={earliestLatestDayErrorMessage}
        dropdownMargin="0 10px 0 0"
      ></DateSelectSetting>
      <Divider flexItem />
      <DateSelectSetting
        elementKey={AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_KEY}
        label={AUTO_PAYMENTS_ONLINE_EARLIEST_DAY_AUTO_DEBIT_LABEL}
        setValue={changeEarliestAutoDebitDay}
        settingValue={earliestAutoDebitDay}
        errorMessage={earliestLatestDayErrorMessage}
        dropdownMargin="0 10px 0 0"
      ></DateSelectSetting>
      <Divider flexItem />
      <RadioSetting
        elementKey={AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_KEY}
        label={AUTO_PAYMENTS_ONLINE_ALLOW_DELINQUENT_LOANS_LABEL}
        settingId="row-delinquent-auto-debit-group-label"
        settingValue={delinquentAutoDebit}
        setValue={(e) => setDelinquentAutoDebit(e.target.value)}
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      ></RadioSetting>
    </FlexContainer>
  );
};

export default ConfigurationRecurringPaymentsCategory;
