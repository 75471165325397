import React, { useEffect } from "react";
import { Paper } from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AsideNavigation,
  FlexContainer,
  RelativeContainer,
} from "../../shared/components/styled/Container.styled";
import { PageTitle } from "../../shared/components/styled/Typography.styled";
import RequireAuth from "../../shared/components/guards/RequireAuth";
import MarketingBanner from "../../shared/components/configurator/MarketingBanner";
import {
  BANNER_IMAGE_PAYMENTS_KEY,
  BANNER_URL_PAYMENTS_KEY,
} from "../../shared/constants/Configurator.constants";
import LoanDetailsExpandable from "../../shared/components/LoanDetailsExpandable";

export default function Payments() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/payments") {
      navigate("/payments/new", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <RequireAuth>
      <RelativeContainer width="100%">
        <PageTitle
          variant="h1"
          component="h1"
          color="primary.dark"
          fontSize="3rem"
        >
          Payments
        </PageTitle>
        <LoanDetailsExpandable />
      </RelativeContainer>
      <Paper elevation={0}>
        <FlexContainer padding="25px 100px" alignitems="flex-start">
          <AsideNavigation>
            <Link
              to="new"
              className={
                location.pathname.includes("/payments/new") ? "active" : ""
              }
            >
              Make a payment
            </Link>
            <Link
              to="activity"
              className={
                location.pathname.includes("/activity") ? "active" : ""
              }
            >
              Payment Activity
            </Link>
            <Link
              to="methods"
              className={location.pathname.includes("/methods") ? "active" : ""}
            >
              Payment Methods
            </Link>
          </AsideNavigation>
          <FlexContainer padding="25px" flexgrow="2">
            <FlexContainer width="100%" flexdirection="column" gap="25px">
              <Outlet />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer padding="25px 100px" alignitems="flex-start">
          <MarketingBanner
            elementKey={BANNER_IMAGE_PAYMENTS_KEY}
            hyperlinkElementKey={BANNER_URL_PAYMENTS_KEY}
          />
        </FlexContainer>
      </Paper>
    </RequireAuth>
  );
}
