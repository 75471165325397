import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { Box, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { PillContainer } from "../styled/Container.styled";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { ColouredFab } from "../styled/Button.styled";
import {
  AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY,
  PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY,
} from "../../constants/Configurator.constants";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { useMsal } from "@azure/msal-react";
import { PAYMENT_TYPE_RECURRING } from "../../constants/Payments.constants";
import { AuthContext } from "../../../contexts/AuthContext";
import { CLIENT_ADMIN_ROLE } from "../../constants/Roles.constants";

const PaymentActivityCell = ({
  cellAttribute,
  cellData,
  rowInfo,
  children,
}) => {
  const theme = useTheme();
  const { edittedFields } = useContext(ConfiguratorContext);
  const { impersonatedUser, userRoles } = useContext(AuthContext);
  const { accounts, instance } = useMsal();
  const [recurringPaymentsOnlineAllowed, setRecurringPaymentsOnlineAllowed] =
    useState(false);
  const [allowDeletePendingPayment, setAllowDeletePendingPayment] =
    useState(true);

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case AUTO_PAYMENTS_ONLINE_ALLOW_AUTO_DEBIT_KEY:
          setRecurringPaymentsOnlineAllowed(item.value === "true");
          return;
        case PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY:
          setAllowDeletePendingPayment(item.value === "true");
          return;
        default:
          return;
      }
    });
  };

  const renderInputItem = () => {
    if (cellAttribute === "status" && cellData?.toUpperCase() === "PENDING") {
      return (
        <PillContainer background="#9b9b9b" color="#ffffff">
          {cellData}
        </PillContainer>
      );
    } else if (cellAttribute === "actions") {
      if (
        rowInfo.status.toUpperCase() === "PENDING" &&
        rowInfo.confirmation &&
        cellData[0].actionType === "cancel"
      ) {
        return (
          <FlexContainer justifycontent="flex-end" width="100%" gap="10px">
            <Box width="30px" />
            <Tooltip title={cellData[0].actionTooltip}>
              <span>
                <ColouredFab
                  color={theme.palette.error.main}
                  size="small"
                  disabled={
                    (rowInfo.type === PAYMENT_TYPE_RECURRING &&
                      !recurringPaymentsOnlineAllowed) ||
                    !allowDeletePendingPayment ||
                    !!impersonatedUser ||
                    userRoles?.includes(CLIENT_ADMIN_ROLE)
                  }
                  iconfontsize="1.125rem"
                  sx={{ width: 30, height: 30, minHeight: 30 }}
                  onClick={(e) => cellData[0].actionCallback(rowInfo)}
                >
                  {<ClearIcon color="primary" />}
                </ColouredFab>
              </span>
            </Tooltip>
          </FlexContainer>
        );
      }
      if (
        rowInfo.status.toUpperCase() === "PENDING" &&
        !rowInfo.confirmation &&
        cellData?.length === 2
      ) {
        return (
          <FlexContainer justifycontent="flex-end" width="100%" gap="10px">
            <Tooltip title={cellData[0].actionTooltip}>
              <span>
                <ColouredFab
                  color={theme.palette.secondary.main}
                  size="small"
                  disabled={
                    (rowInfo.type === PAYMENT_TYPE_RECURRING &&
                      !recurringPaymentsOnlineAllowed) ||
                    !!impersonatedUser ||
                    userRoles?.includes(CLIENT_ADMIN_ROLE)
                  }
                  iconfontsize="1.125rem"
                  sx={{ width: 30, height: 30, minHeight: 30 }}
                  onClick={(e) => cellData[0].actionCallback(rowInfo)}
                >
                  {<EditIcon color="primary" />}
                </ColouredFab>
              </span>
            </Tooltip>
            <Tooltip title={cellData[1].actionTooltip}>
              <span>
                <ColouredFab
                  color={theme.palette.error.main}
                  size="small"
                  disabled={
                    (rowInfo.type === PAYMENT_TYPE_RECURRING &&
                      !recurringPaymentsOnlineAllowed) ||
                    !allowDeletePendingPayment ||
                    !!impersonatedUser ||
                    userRoles?.includes(CLIENT_ADMIN_ROLE)
                  }
                  iconfontsize="1.125rem"
                  sx={{ width: 30, height: 30, minHeight: 30 }}
                  onClick={(e) => cellData[1].actionCallback(rowInfo)}
                >
                  {<ClearIcon color="primary" />}
                </ColouredFab>
              </span>
            </Tooltip>
          </FlexContainer>
        );
      }
      return <></>;
    }
    return <>{cellData}</>;
  };
  return renderInputItem();
};

export default PaymentActivityCell;
