import React from "react";
import { Box } from "@mui/material";

const CheckHintOverlay = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {/* Rectangles over account numbers */}
      <Box
        sx={{
          position: "absolute",
          border: "2px solid #fb6400",
          borderRadius: "5px",
          width: "25%",
          height: "12%",
          bottom: "17%",
          left: "6%",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          border: "2px solid #fb6400",
          borderRadius: "5px",
          width: "21%",
          height: "12%",
          bottom: "17%",
          left: "44%",
        }}
      />
      {/* Lines next to the rectangles */}
      <Box
        sx={{
          position: "absolute",
          borderBottom: "2px solid #fb6400",
          width: "25%",
          height: 0,
          bottom: "23%",
          left: "-19%",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          borderLeft: "2px solid #fb6400",
          width: 0,
          height: "15%",
          bottom: "2%",
          left: "54.5%",
        }}
      />
      {/* Text and Icon for Routing Number */}
      <svg
        viewBox="0 0 58 17"
        fill="#fb6400"
        style={{
          width: "27%",
          height: "6%",
          position: "absolute",
          bottom: "20.5%",
          left: "-45%",
          fontWeight: "bold",
        }}
      >
        <text x="-5" y="13">
          Routing Number
        </text>
      </svg>
      {/* Text and Icon for Bank Account Number */}
      <svg
        viewBox="0 0 58 17"
        fill="#fb6400"
        style={{
          width: "35%",
          height: "6%",
          position: "absolute",
          bottom: "-5%",
          left: "33%",
          fontWeight: "bold",
        }}
      >
        <text x="-25" y="13">
          Bank Account Number
        </text>
      </svg>
    </Box>
  );
};

export default CheckHintOverlay;
