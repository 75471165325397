import React, { useContext, useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import {
  Button,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  Fab,
  Fade,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import {
  AbsoluteContainer,
  FlexContainer,
  RelativeContainer,
} from "../styled/Container.styled";
import { PillButton } from "../styled/Button.styled";
import { TypographyWithMarkdown } from "../styled/Typography.styled";
import {
  DEFAULT_HYPERLINK_DISPLAY_TEXT,
  DEFAULT_HYPERLINK_URL,
} from "../../constants/Defaults.constants";
import { CLIENT_ADMIN_ROLE } from "../../constants/Roles.constants";

const EditableText = ({
  renderedText = "",
  setRenderedText = () => {},
  elementKey = "default",
  padding = "0",
  fontSize = "1rem",
  fontColor = "text.hint",
  wasModified = false,
  checkboxActive = false,
  setCheckboxActive = () => {},
  withHyperlink = false,
  withCheckbox = false,
  verticalButtons = false,
  width = "100%"
}) => {
  const { userRoles, visibleRole } = useContext(AuthContext);
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  const theme = useTheme();
  const inputRef = useRef(null);

  // Editable Text Input States
  const [editing, setEditing] = useState(false);
  const [localRenderedText, setLocalRenderedText] = useState("");

  // Editable Text Cursor Selection States
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);

  // Hyperlink Popup Form States
  const [hyperlinkPopupOpen, setHyperlinkPopupOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [hyperlinkDisplayText, setHyperlinkDisplayText] = useState(
    DEFAULT_HYPERLINK_DISPLAY_TEXT,
  );
  const [hyperlinkURL, setHyperlinkURL] = useState(DEFAULT_HYPERLINK_URL);

  useEffect(() => {
    setLocalRenderedText(renderedText);
  }, [renderedText]);

  const toggleEdit = () => {
    if (editing) {
      setLocalRenderedText(renderedText);
    }
    setEditing(!editing);
  };

  const saveEditedText = () => {
    setRenderedText(localRenderedText);
    addNewEdittedField({
      key: elementKey,
      value: localRenderedText,
      modified: true,
    });
    setEditing(false);
  };

  const updateSelection = () => {
    if (
      selectionEnd - selectionStart !== 0 &&
      inputRef.current.selectionEnd - inputRef.current.selectionEnd === 0
    ) {
      setHyperlinkDisplayText(DEFAULT_HYPERLINK_DISPLAY_TEXT);
      setHyperlinkURL(DEFAULT_HYPERLINK_URL);
    }
    setSelectionStart(inputRef.current.selectionStart);
    setSelectionEnd(inputRef.current.selectionEnd);
  };

  const openHyperlinkForm = (e) => {
    if (selectionEnd - selectionStart !== 0) {
      setHyperlinkDisplayText(
        localRenderedText.substring(selectionStart, selectionEnd),
      );
    }
    setAnchorElement(e.currentTarget);
    setHyperlinkPopupOpen(true);
  };

  const submitHyperLinkForm = (e) => {
    if (e.key === "Enter") {
      addHyperlink();
    }
  };

  const addHyperlink = () => {
    const textWithHyperlink = `${localRenderedText.slice(
      0,
      selectionStart,
    )}[${hyperlinkDisplayText}](${
      hyperlinkURL.substring(0, 4) !== "http" ? "http://" : ""
    }${hyperlinkURL})${localRenderedText.slice(selectionEnd)}`;
    const addedCharacters = textWithHyperlink.length - localRenderedText.length;

    setLocalRenderedText(textWithHyperlink);
    setHyperlinkPopupOpen(false);

    setHyperlinkDisplayText(DEFAULT_HYPERLINK_DISPLAY_TEXT);
    setHyperlinkURL(DEFAULT_HYPERLINK_URL);

    setSelectionStart(selectionEnd + addedCharacters);
    setSelectionEnd(selectionEnd + addedCharacters);
  };

  const handleHyperlinkFormClose = (e) => {
    if (anchorElement.current && anchorElement.current.contains(e.target)) {
      return;
    }

    setHyperlinkPopupOpen(false);
  };

  return (
    <RelativeContainer
      width={width}
      padding={
        userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE
          ? 0
          : padding
      }
      margin={
        userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE
          ? verticalButtons && editing
            ? "5px 0 15px 0"
            : "5px 0"
          : 0
      }
      border={
        userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE &&
        !editing
          ? `2px dashed ${
              wasModified ? theme.palette.warning.main : theme.palette.text.hint
            }`
          : "none"
      }
      borderradius="5px"
    >
      {userRoles?.includes(CLIENT_ADMIN_ROLE) &&
      visibleRole === CLIENT_ADMIN_ROLE &&
      editing ? (
        <>
          <TextField
            fullWidth
            multiline
            hiddenLabel
            inputRef={inputRef}
            variant="filled"
            value={localRenderedText}
            onSelect={updateSelection}
            onChange={(e) => setLocalRenderedText(e.target.value)}
          />
          <ButtonGroup variant="text">
            {editing && withHyperlink ? (
              <Button onClick={openHyperlinkForm}>
                <InsertLinkIcon />
              </Button>
            ) : (
              ""
            )}
          </ButtonGroup>
          <Popper
            open={hyperlinkPopupOpen}
            anchorEl={anchorElement}
            placement="bottom-start"
            transition
            sx={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ padding: 2 }}>
                  <ClickAwayListener onClickAway={handleHyperlinkFormClose}>
                    <Stack spacing={2}>
                      <TextField
                        autoFocus
                        value={
                          hyperlinkDisplayText ===
                          DEFAULT_HYPERLINK_DISPLAY_TEXT
                            ? ""
                            : hyperlinkDisplayText
                        }
                        onChange={(e) =>
                          setHyperlinkDisplayText(e.target.value)
                        }
                        onKeyUp={submitHyperLinkForm}
                        label="Display Text"
                        variant="standard"
                      />
                      <TextField
                        value={
                          hyperlinkURL === DEFAULT_HYPERLINK_URL
                            ? ""
                            : hyperlinkURL
                        }
                        onChange={(e) => setHyperlinkURL(e.target.value)}
                        onKeyUp={submitHyperLinkForm}
                        label="Link Address (URL)"
                        variant="standard"
                      />
                      <PillButton
                        variant="contained"
                        onClick={addHyperlink}
                        onKeyUp={submitHyperLinkForm}
                      >
                        Add
                      </PillButton>
                    </Stack>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      ) : (
        <FlexContainer justifycontent="flex-start" alignitems="flex-start">
          {withCheckbox ? (
            <Checkbox
              checked={checkboxActive}
              size="small"
              onChange={() => setCheckboxActive(!checkboxActive)}
              inputProps={{ "aria-label": "controlled" }}
              disabled={
                userRoles?.includes(CLIENT_ADMIN_ROLE) &&
                visibleRole === CLIENT_ADMIN_ROLE &&
                !renderedText
              }
            />
          ) : (
            ""
          )}
          <TypographyWithMarkdown
            alignSelf="flex-start"
            variant="body"
            color={fontColor}
            padding={
              userRoles?.includes(CLIENT_ADMIN_ROLE) &&
              visibleRole === CLIENT_ADMIN_ROLE
                ? 1
                : 0
            }
            fontSize={fontSize}
            fontWeight="bold"
            component="div"
            sx={{
              whiteSpace: "pre-line",
              paddingTop:
                (userRoles?.includes(CLIENT_ADMIN_ROLE) &&
                  visibleRole === CLIENT_ADMIN_ROLE) ||
                (visibleRole !== CLIENT_ADMIN_ROLE && withCheckbox)
                  ? 1
                  : 0,
            }}
          >
            <ReactMarkdown className="markdown" linkTarget="_blank">
              {renderedText}
            </ReactMarkdown>
            {withCheckbox && localRenderedText.length > 0 ? (
              <Typography color="error" sx={{ display: "inline" }}>
                *
              </Typography>
            ) : (
              ""
            )}
          </TypographyWithMarkdown>
        </FlexContainer>
      )}
      {userRoles?.includes(CLIENT_ADMIN_ROLE) &&
      visibleRole === CLIENT_ADMIN_ROLE ? (
        <AbsoluteContainer
          sx={{
            top: -10,
            right: !verticalButtons && editing ? -70 : -35,
            zIndex: 4,
          }}
        >
          <Fab
            color={editing ? "inherit" : "primary"}
            size="small"
            sx={{ width: 30, height: 30, minHeight: 30 }}
            onClick={toggleEdit}
          >
            {editing ? (
              <CloseIcon sx={{ fontSize: 16 }} />
            ) : (
              <EditIcon sx={{ fontSize: 16 }} />
            )}
          </Fab>
          {!verticalButtons && editing ? (
            <Fab
              color="primary"
              size="small"
              sx={{ width: 30, height: 30, minHeight: 30, marginLeft: "5px" }}
              onClick={saveEditedText}
            >
              <CheckIcon sx={{ fontSize: 16 }} />
            </Fab>
          ) : (
            ""
          )}
        </AbsoluteContainer>
      ) : (
        ""
      )}
      {verticalButtons && editing ? (
        <AbsoluteContainer sx={{ top: 25, right: -35, zIndex: 4 }}>
          <Fab
            color="primary"
            size="small"
            sx={{ width: 30, height: 30, minHeight: 30, marginLeft: "5px" }}
            onClick={saveEditedText}
          >
            <CheckIcon sx={{ fontSize: 16 }} />
          </Fab>
        </AbsoluteContainer>
      ) : (
        ""
      )}
    </RelativeContainer>
  );
};

export default EditableText;
