import React from "react";
import EditableText from "./EditableText";

const EditableTermsAndConditions = ({
  checkboxActive = false,
  setCheckboxActive = () => {},
  renderedText = "",
  setRenderedText = () => {},
  elementKey = "default",
  padding = "0",
  fontSize = "1rem",
  fontColor = "text.hint",
  wasModified = false,
  width = "100%"
}) => {
  return (
    <EditableText
      renderedText={renderedText}
      setRenderedText={setRenderedText}
      elementKey={elementKey}
      padding={padding}
      fontSize={fontSize}
      fontColor={fontColor}
      checkboxActive={checkboxActive}
      setCheckboxActive={setCheckboxActive}
      wasModified={wasModified}
      withHyperlink
      withCheckbox
      width={width}
    />
  );
};

export default EditableTermsAndConditions;
