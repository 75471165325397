export const LOAN_DESCRIPTION =
  "Sed euismod in risus vitae tincidunt. Donec pulvinar cursus faucibus. Aenean sapien metus, malesuada a pretium vel, pellentesque bibendum dui. Ut sed placerat massa.";
export const LOAN_FOOTER_NOTE =
  "Pellentesque quis euismod ante. In vehicula arcu quis ligula congue elementum vel nec nisl. Donec dignissim bibendum mi vel dapibus.";
export const LOAN_DISCLOSURE_AGREEMENT = "Disclosure Agreement";

export const NO_LOAN_REGISTERED_WARNING_MESSAGE =
  "You don't have any loans registered yet.";

export const LOAN_NOT_VALID_ERROR_MESSAGE =
  "Information provided does not match our database.";
export const LOAN_NUMBER_NUMERIC_ONLY_ERROR_MESSAGE =
  "Loan Number must be numeric";
export const LOAN_NUMBER_ALREADY_EXISTS_ERROR_MESSAGE =
  "Loan Number already registered";
export const SSN_NUMBERS_DASHES_ERROR_MESSAGE =
  "Social Security Number must only contain numbers and dashes";

export const USER_ADDRESS_ZIP_CODE_NUMERIC_ERROR_MESSAGE =
  "Zip Code must be numeric";
export const USER_ADDRESS_ZIP_CODE_LENGTH_ERROR_MESSAGE =
  "Zip Code must be 5 digits";
export const NOTIFICATIONS_NOT_ALLOWED =
  "You are not allowed to set up notifications";
export const NOTIFICATION_CHANGE_NOT_ALLOWED =
  "You are not allowed to modify this option";

export const PAPER_STATEMENT_UPDATE_PREFERENCES_SUCCESS =
  "Paper Statement updated successfully";
export const PAPER_STATEMENT_UPDATE_PREFERENCES_FAILED =
  "Unable to update preferences. Please try again!";
