import React, { useContext } from "react";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import FormItem from "../../../../shared/components/form/FormItem";
import {
  formatSocialSecurityNumber,
  handleInputChange,
  removeNonDigits,
} from "../../../../shared/utils/utility-functions";
import {
  LOAN_NUMBER_ALREADY_EXISTS_ERROR_MESSAGE,
  LOAN_NUMBER_NUMERIC_ONLY_ERROR_MESSAGE,
  SSN_NUMBERS_DASHES_ERROR_MESSAGE,
} from "../../../../shared/constants/UserProfile.constants";
import { AuthContext } from "../../../../contexts/AuthContext";

const onlyNumbersAllowed = /^[0-9]+$/;
const onlyNumbersAndDashesAllowed = /^[\d-]+$/;

const AddLoanForm = ({
  loanNumber = "",
  setLoanNumber = () => {},
  socialSecurityNumber = "",
  setSocialSecurityNumber = () => {},
  loanNumberErrorMessage = "",
  setLoanNumberErrorMessage = () => {},
  socialSecurityNumberErrorMessage = "",
  setSocialSecurityNumberErrorMessage = () => {},
}) => {
  const { loans } = useContext(AuthContext);
  const digitOnlyLoans = loans.map((item) => removeNonDigits(item));

  const checkLoanNumberValidity = () => {
    if (!onlyNumbersAllowed.test(loanNumber)) {
      setLoanNumberErrorMessage(LOAN_NUMBER_NUMERIC_ONLY_ERROR_MESSAGE);
    } else if (digitOnlyLoans.includes(loanNumber)) {
      setLoanNumberErrorMessage(LOAN_NUMBER_ALREADY_EXISTS_ERROR_MESSAGE);
    } else {
      setLoanNumberErrorMessage("");
    }
  };

  const checkSocialSecurityNumberValidity = () => {
    if (!onlyNumbersAndDashesAllowed.test(socialSecurityNumber)) {
      setSocialSecurityNumberErrorMessage(SSN_NUMBERS_DASHES_ERROR_MESSAGE);
    } else {
      setSocialSecurityNumberErrorMessage("");
    }
  };

  const handleSSNChange = (e) => {
    const formattedSocialSecurityNumber = formatSocialSecurityNumber(
      e.target.value,
    );
    setSocialSecurityNumber(formattedSocialSecurityNumber);
  };

  return (
    <FlexContainer
      padding="25px 0 35px 0"
      flexdirection="column"
      gap="10px"
      width="100%"
    >
      <FormItem
        elementWidth="25%"
        label="Loan Number"
        inputType="input"
        value={loanNumber}
        setValue={(e) => handleInputChange(e, setLoanNumber)}
        blurHandler={(e) => checkLoanNumberValidity()}
        errorMessage={loanNumberErrorMessage}
        inputProps={{
          type: "tel",
          style: {
            textAlign: "start",
          },
        }}
      />
      <FormItem
        elementWidth="25%"
        label="Social Security Number"
        inputType="input"
        value={socialSecurityNumber}
        setValue={(e) => handleSSNChange(e)}
        blurHandler={(e) => checkSocialSecurityNumberValidity()}
        errorMessage={socialSecurityNumberErrorMessage}
        inputProps={{
          type: "tel",
          style: {
            textAlign: "start",
          },
        }}
      />
    </FlexContainer>
  );
};

export default AddLoanForm;
