const thisMonth = new Date().getMonth();
const thisYear = new Date().getFullYear();

export const AdminLoansResponse = ["AdminLoan"];

export const AdminLoan = {
  loanDetails: {
    loanId: "AdminLoan",
    loanType: "P",
    amortizationType: " ",
    paperless: true,
    closingDate: `${thisYear + 10}-10-25`,
    maturityDate: `${thisYear + 30}-12-01`,
    originalBalance: 999999,
    interestRate: 2.99,
    amountInterestLastYear: 9999.9,
    lastPaymentReceived: `${thisYear}-${thisMonth + 1 < 10 ? "0" : ""}${
      thisMonth + 1
    }-01`,
    amountDue: 999.9,
    lateFees: 9,
    fees: 99,
    dueDate: `${thisMonth === 11 ? thisYear + 1 : thisYear}-${
      thisMonth + 2 < 10 ? "0" : ""
    }${thisMonth + 2}-01`,
    amountPrincipalInterest: 999.9,
    escrowBalance: 9999.9,
    currentEscrowPayment: 999.99,
    mortgageBalance: 949999,
    escrowLoan: true,
    fullEscrowLoan: true,
    escrowShortage: true,
    escrowShortageAmount: 499.9,
    helocLoan: false,
    constructionLoan: false,
    autoPaymentDate: `${thisMonth === 11 ? thisYear + 1 : thisYear}-${
      thisMonth + 2 < 10 ? "0" : ""
    }${thisMonth + 2}-01`,
    armIndex: null,
    armIndexDescription: null,
    armRateNextChangeDate: null,
    armPaymentNextChangeDate: null,
    hcAvailable: null,
    hcRemaining: null,
    autoPayActive: true,
  },
  borrowerDetails: {
    fullName: "Admin Admin",
    firstName: "Admin",
    lastName: "Admin",
    mailAddress: "9999 Admin Address",
    email: "admin@mortgagebuilder.com",
    phone1: "999-999-9999",
    phone2: "",
    state: "TX",
    city: "ATLANTIC CITY",
    address: "9999 ADMIN ADDRESS",
    postalCode: "99999",
  },
  realEstateTax: 9999.9,
  insuranceDetails: [
    {
      amount: 9999.0,
      type: "Hazard",
    },
  ],
};

export const AdminPaymentPrevalidateResponse = {
  amountDue: 999.9,
  lateFees: 9,
  fees: 99,
  dueDate: `${thisMonth === 11 ? thisYear + 1 : thisYear}-${
    thisMonth + 2 < 10 ? "0" : ""
  }${thisMonth + 2}-01`,
  paymentAllowed: true,
  escrowLoan: true,
  escrowShortage: true,
  escrowShortageAmount: 499.9,
};

export const AdminAutoPaymentPrevalidateResponse = {
  paymentAllowed: true,
  additionalPrincipal: null,
  additionalEscrow: null,
  autopaymentDueDate: null,
  escrowLoan: true,
};
