import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { hex2rgba } from "../../utils/utility-functions";
import React from "react";
import { useTheme } from "@mui/styles";

const FormItemRadio = ({
  elementWidth = "200px",
  options = [],
  value = {},
  setValue = () => {},
}) => {
  const theme = useTheme();
  const activeColor = hex2rgba(theme.palette.primary.main, 0.1);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{ marginLeft: "25px" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.id}
              sx={{
                width: elementWidth,
                minHeight: "65px",
                borderRadius: "7px",
                marginBottom: "10px",
                backgroundColor:
                  value === option.value ? activeColor : "#F0F0F0",
              }}
              value={option.value}
              control={<Radio />}
              label={
                <Stack padding={"9px"} paddingLeft="0">
                  <Typography fontWeight={"bold"}>{option.title}</Typography>
                  <Typography
                    color={"#808080"}
                    sx={{
                      maxWidth: "200px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {option.subtitle}
                  </Typography>
                </Stack>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default FormItemRadio;
