import React, { useContext, useEffect, useState } from "react";
import DropFileInput from "../../../shared/components/form/DropFileInput";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import FormItem from "../../../shared/components/form/FormItem";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { uploadDocument } from "../../../data/statementsDocumentsAPI";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import { Typography, useTheme } from "@mui/material";
import {
  DOCUMENT_UPLOAD_TYPES,
  EXTENSION_NOT_ALLOWED,
  UPLOAD_FAILED,
  UPLOAD_NOT_ALLOWED,
  UPLOAD_SUCCESS,
} from "../../../shared/constants/StatementsDocuments.constants";
import {
  ERROR,
  PLACEHOLDER_DESCRIPTION,
  SUCCESS,
} from "../../../shared/constants/Defaults.constants";
import {
  BORROWER,
  CLIENT_ADMIN_ROLE,
} from "../../../shared/constants/Roles.constants";
import { determineRoleLabel } from "../../../shared/utils/msal-functions";
import EditableText from "../../../shared/components/configurator/EditableText";
import { findElementIncludedInArray } from "../../../shared/utils/utility-functions";
import {
  DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY,
  UPLOAD_DOCUMENT_PAGE_DESCRIPTION_KEY,
} from "../../../shared/constants/Configurator.constants";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const allDocumentTypes = DOCUMENT_UPLOAD_TYPES.map((item, index) => {
  return {
    id: index,
    type: item,
  };
});

const fileTypes = [
  "jpg",
  "jpeg",
  "png",
  "vnd.openxmlformats-officedocument.wordprocessingml.document", //DOCX extension
  "pdf",
];

export default function UploadDocuments() {
  const theme = useTheme();
  const { userRoles, visibleRole, token, activeLoan, impersonatedUser } =
    useContext(AuthContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const [description, setDescription] = useState(PLACEHOLDER_DESCRIPTION);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [file, setFile] = useState(null);
  const role = determineRoleLabel(visibleRole);
  const isBorrower = role === BORROWER;

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoan, token]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case UPLOAD_DOCUMENT_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY:
          getAllowedDocumentTypes(item.value);
          return;
        default:
          return;
      }
    });

    if (
      !fields.find((item) => item.key === DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY)
    ) {
      setDocumentTypes(allDocumentTypes);
      setDocumentType(allDocumentTypes[0]);
    }
  };

  const getAllowedDocumentTypes = (configDocuments) => {
    if (configDocuments) {
      const documentTypes = configDocuments.split(";").map((item, index) => {
        return {
          id: index,
          type: item,
        };
      });
      setDocumentTypes(documentTypes);
      setDocumentType(documentTypes[0].type);
    }
  };

  const checkFileExtensionAllowed = () => {
    return fileTypes.indexOf(file.type.split("/")[1]) > -1;
  };

  const handleSubmitUploadDocument = () => {
    if (token && file) {
      if (checkFileExtensionAllowed()) {
        uploadFile(token);
      } else {
        setSnackBarMessage(EXTENSION_NOT_ALLOWED);
        setSeverity(ERROR);
        setSnackBarOpen(true);
      }
    }
  };

  const displayStatusUploadFile = (status) => {
    if (status && status.success === true) {
      setSnackBarMessage(UPLOAD_SUCCESS);
      setSeverity(SUCCESS);
    } else {
      setSnackBarMessage(UPLOAD_FAILED);
      setSeverity(ERROR);
    }
    setSnackBarOpen(true);
  };

  const uploadFile = async (token) => {
    let status = null;
    addPendingRequest();
    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        status = await uploadDocument(
          token,
          activeLoan,
          documentType,
          file,
          impersonatedUser,
        );
      }
    }
    removePendingRequest();
    displayStatusUploadFile(status);
    setDocumentType(documentTypes[0].type);
    setFile(null);
  };

  return (
    <>
      <SectionTitle variant="h2" color="primary">
        Upload Documents
      </SectionTitle>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={UPLOAD_DOCUMENT_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            UPLOAD_DOCUMENT_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      {isBorrower && documentTypes.length > 0 ? (
        <>
          <FlexContainer padding="0 0 25px 0" gap="25px" width="100%">
            <FormItem
              elementWidth="200px"
              label="Document Type"
              inputType="dropdown"
              value={documentType}
              setValue={setDocumentType}
              dropdownOptions={documentTypes}
              dropdownKey="id"
              dropdownValue="type"
              dropdownLabel="type"
            />
          </FlexContainer>
          <DropFileInput file={file} setFile={setFile} fileTypes={fileTypes} />
          <PillButton
            color="primary"
            variant="contained"
            width="33%"
            customfontsize="1.25rem"
            disabled={
              !(documentType && file) ||
              !!impersonatedUser ||
              userRoles?.includes(CLIENT_ADMIN_ROLE)
            }
            onClick={handleSubmitUploadDocument}
          >
            Upload Document
          </PillButton>
          <ActionSnackbar
            severity={severity}
            borderColor={theme.palette[severity].main}
            vertical="top"
            horizontal="center"
            width="50%"
            isOpen={snackBarOpen}
            closeFn={() => {
              setSnackBarOpen(false);
            }}
            snackbarLabel={snackBarMessage}
            hasAction={false}
            autoHideDuration={6000}
          />
        </>
      ) : (
        <Typography>{UPLOAD_NOT_ALLOWED}</Typography>
      )}
    </>
  );
}
