import React from "react";
import StatementsAndDocumentsComponent from "../components/StatementsAndDocuments.component";
import { STATEMENTS_DOCUMENTS_ALL_DOCUMENTS_PAGE_DESCRIPTION_KEY } from "../../../shared/constants/Configurator.constants";
import {
  STATEMENTS_AND_DOCUMENTS_FIELD_TYPES,
  STATEMENTS_AND_DOCUMENTS_HEADER_NAMES,
  STATEMENTS_NO_DATA_MESSAGE,
} from "../../../shared/constants/StatementsDocuments.constants";
import StatementsAndDocumentsCell from "../../../shared/components/table/StatementsAndDocumentsCell";

export default function Statements() {
  return (
    <StatementsAndDocumentsComponent
      pageDescriptionKey={STATEMENTS_DOCUMENTS_ALL_DOCUMENTS_PAGE_DESCRIPTION_KEY}
      documentType={"statement"}
      title={"Statements"}
      headerNames={STATEMENTS_AND_DOCUMENTS_HEADER_NAMES}
      noDataMessage={STATEMENTS_NO_DATA_MESSAGE}
      fieldTypes={STATEMENTS_AND_DOCUMENTS_FIELD_TYPES}
      cellDisplayComponent={StatementsAndDocumentsCell}
    />
  )
}
