import React, { useContext } from "react";
import { Typography } from "@mui/material";
import Counter from "../../../shared/components/form/Counter";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const CounterSetting = ({
  elementKey = "",
  label = "",
  labelColor = "text",
  counter = 0,
  setCounter = () => {},
  minValue = 0,
  maxValue = -1,
  padding = "0 25px 0 0",
  background = "transparent",
}) => {
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  const handleCounterChange = (value) => {
    setCounter(value);
    addNewEdittedField({
      key: elementKey,
      value: value,
      modified: true,
    });
  };

  return (
    <FlexContainer
      width="100%"
      gap="25px"
      justifycontent="space-between"
      padding={padding}
      background={background}
    >
      <Typography fontWeight="bold" fontSize={16} color={labelColor}>
        {label}
      </Typography>
      <Counter
        counter={counter}
        setCounter={handleCounterChange}
        minValue={minValue}
        maxValue={maxValue}
        width="220px"
      />
    </FlexContainer>
  );
};

export default CounterSetting;
