import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import FormItemInput from "../../../shared/components/form/FormItemInput";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";

const InputSetting = ({
  elementKey = "",
  label = "",
  labelColor = "text",
  labelFontSize = 16,
  inputPadding = "0 10px 0 0",
  inputValue = "",
  setInputValue = () => {},
  onChangeHandler = () => {},
  validateInputValue = () => {},
  inputPlaceholder = "",
  blurOnWheel = false,
  adornment = false,
  adornmentPosition = "start",
  adornmentSymbol = "$",
  inputProps = {},
  disabled = false,
  errorMessage = "",
  setErrorMessage = () => {},
  padding = "0 25px 0 0",
  background = "transparent",
  hasInfo = false,
  Info = <></>,
  defaultValue = 0,
}) => {
  const { addNewEdittedField } = useContext(ConfiguratorContext);

  const handleChange = (e) => {
    onChangeHandler(e, setInputValue, setErrorMessage);
    addNewEdittedField({
      key: elementKey,
      value:
        e.target.value.length > 0 && e.target.validity.valid
          ? inputProps?.type === "number"
            ? +e.target.value
            : e.target.value
          : inputProps?.type === "number"
          ? defaultValue
          : "",
      modified: true,
    });
  };

  return (
    <FlexContainer
      width="100%"
      gap="25px"
      justifycontent="space-between"
      padding={padding}
      background={background}
    >
      <Typography
        color={labelColor}
        fontWeight="bold"
        fontSize={labelFontSize}
        sx={{ flexGrow: 3 }}
      >
        {label}
      </Typography>
      {hasInfo ? Info : ""}
      <FormItemInput
        elementWidth="200px"
        wrapperPadding={inputPadding}
        value={inputValue}
        setValue={(e) => handleChange(e)}
        placeholder={inputPlaceholder}
        blurHandler={(e) => validateInputValue(e, setErrorMessage)}
        blurOnWheel={blurOnWheel}
        adornment={adornment}
        adornmentPosition={adornmentPosition}
        adornmentSymbol={adornmentSymbol}
        inputProps={inputProps}
        disabled={disabled}
        errorMessage={errorMessage}
      />
    </FlexContainer>
  );
};

export default InputSetting;
