import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { FlexContainer } from "../styled/Container.styled";

const DashboardInfoItem = ({
  label = "",
  data = "",
  alignitems = "flex-start",
  hasAdditionalInfo = false,
  InfoIcon = <></>,
  infoTooltip = "",
  prefix = "",
  minWidth = "auto",
  labelFontSize = "1rem",
  valueFontSize = "1.4285rem",
  textOverflow = false,
  withTooltip = false,
  maxWidth = "auto",
}) => {
  return (
    <FlexContainer
      flexdirection="column"
      alignitems={alignitems}
      minwidth={minWidth}
      sx={{ cursor: withTooltip ? "pointer" : "default" }}
    >
      <Typography
        color="text.secondary"
        fontWeight="bold"
        fontSize={labelFontSize}
      >
        {label}
      </Typography>
      <FlexContainer
        sx={{
          minWidth: 0,
          maxWidth: maxWidth,
        }}
      >
        <Typography
          alignSelf="flex-start"
          fontSize="0.8571rem"
          fontWeight="bold"
        >
          {prefix}
        </Typography>
        {withTooltip ? (
          <Tooltip title={data}>
            <Typography
              fontSize={valueFontSize}
              fontWeight="bold"
              sx={{
                overflow: textOverflow ? "hidden" : "visible",
                whiteSpace: textOverflow ? "nowrap" : "normal",
                textOverflow: textOverflow ? "ellipsis" : "clip",
              }}
            >
              {data}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            fontSize={valueFontSize}
            fontWeight="bold"
            sx={{
              overflow: textOverflow ? "hidden" : "visible",
              whiteSpace: textOverflow ? "nowrap" : "normal",
              textOverflow: textOverflow ? "ellipsis" : "clip",
            }}
          >
            {data}
          </Typography>
        )}
        {hasAdditionalInfo ? (
          <InfoIcon color="disabled" fontSize="small"></InfoIcon>
        ) : (
          ""
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default DashboardInfoItem;
