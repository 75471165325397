import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import FeeOptions from "./components/FeeOptions";
import CounterSetting from "../shared/CounterSetting";
import InputSetting from "../shared/InputSetting";
import ExpandableSetting from "../shared/ExpandableSetting";
import RadioSetting from "../shared/RadioSetting";
import TimePickerSetting from "../shared/TimePickerSetting";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import InfoText from "../../../shared/components/InfoText";
import {
  CONFIG_PAGE_DESCRIPTION_KEY,
  PAYMENTS_MANDATE_LATE_FEE_KEY,
  PAYMENTS_ONLINE_ALLOWED_KEY,
  PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_KEY,
  PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_KEY,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_CUTOFF_TIME_KEY,
  PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY,
  PAYMENTS_ONLINE_FEE_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_2_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_3_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_4_KEY,
  PAYMENTS_ONLINE_FEE_OPTION_KEY,
  PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY,
  PAYMENTS_ONLINE_LATE_FEE_FILL_KEY,
  PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY,
  PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY,
  PAYMENTS_ONLINE_POSTING_DATE_KEY,
  PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_KEY,
  PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY,
  PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY,
  PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY,
  PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY,
  PAYMENTS_ONLINE_ALLOWED_LABEL,
  PAYMENTS_ONLINE_FEE_LABEL,
  PAYMENTS_ONLINE_FEE_SECONDARY_LABEL,
  PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_LABEL,
  PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_LABEL,
  PAYMENTS_ONLINE_LATE_FEE_FILL_LABEL,
  PAYMENTS_MANDATE_LATE_FEE_LABEL,
  PAYMENTS_ONLINE_OTHER_FEE_FILL_LABEL,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_LABEL,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_SECONDARY_LABEL,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_LABEL,
  PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_INFO,
  PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_LABEL,
  PAYMENTS_ONLINE_CUTOFF_TIME_LABEL,
  PAYMENTS_ONLINE_CUTOFF_TIME_INFO,
  PAYMENTS_ONLINE_POSTING_DATE_LABEL,
  PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_LABEL,
  PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_LABEL,
  PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_LABEL,
  PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_INFO,
  PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_LABEL,
  PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_LABEL,
  PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_LABEL,
  PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_LABEL,
  PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_INFO,
  PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_LABEL,
  PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_LABEL,
  PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY,
} from "../../../shared/constants/Configurator.constants";
import {
  findElementIncludedInArray,
  hex2rgba,
} from "../../../shared/utils/utility-functions";
import EditableText from "../../../shared/components/configurator/EditableText";
import AutoCompleteInputSetting from "../shared/AutoCompleteInputSetting";
import { stateList } from "../../../shared/constants/States.constants";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import { NON_NEGATIVE_AMOUNT_ERROR_MESSAGE } from "../../../shared/constants/Defaults.constants";

const ConfigurationPaymentsCategory = () => {
  const todayEightPM = new Date();
  todayEightPM.setHours(20, 0);

  const theme = useTheme();
  const { userRoles, visibleRole } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);

  // Form States
  const [allowPayment, setAllowPayment] = useState(false);
  const [selectedFeeOption, setSelectedFeeOption] = useState(
    PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
  );
  const [flatValueFee, setFlatValueFee] = useState("");
  const [percentageFee, setPercentageFee] = useState("");
  const [threshold, setThreshold] = useState("");
  const [numberOfDateRanges, setNumberOfDateRanges] = useState(0);
  const [defaultPaymentAmount, setDefaultPaymentAmount] = useState(0);
  const [defaultLateFeesFill, setDefaultLateFeesFill] = useState(0);
  const [mandateLateFee, setMandateLateFee] = useState(false);
  const [defaultOtherFeesFill, setDefaultOtherFeesFill] = useState(0);
  const [limitTotalSinglePayment, setLimitTotalSinglePayment] = useState("");
  const [maxPrincipalAmount, setMaxPrincipalAmount] = useState("");
  const [paymentCutoffStartDate, setPaymentCutoffStartDate] =
    useState(todayEightPM);
  const [postingDate, setPostingDate] = useState(1);
  const [delinquentPaymentCounter, setDelinquentPaymentCounter] = useState(0);
  const [lateChargeThreshold, setLateChargeThreshold] = useState("");
  const [allowAdditionalPrincipal, setAllowAdditionalPrincipal] =
    useState(false);
  const [allowDeletePendingPayment, setAllowDeletePendingPayment] =
    useState(true);
  const [maxDaysIntoFutureForPayment, setMaxDaysIntoFutureForPayment] =
    useState(35);
  const [chargeEscrowShortageOnline, setChargeEscrowShortageOnline] =
    useState(false);
  const [amountChargedForEscrowShortage, setAmountChargedForEscrowShortage] =
    useState(0);
  const [paymentFeeRoutingNo, setPaymentFeeRoutingNo] = useState("");

  // Error Message States
  const [flatValueFeeErrorMessage, setFlatValueFeeErrorMessage] = useState("");
  const [percentageFeeErrorMessage, setPercentageFeeErrorMessage] =
    useState("");
  const [
    limitTotalSinglePaymentErrorMessage,
    setLimitTotalSinglePaymentErrorMessage,
  ] = useState("");
  const [lateChargeThresholdErrorMessage, setLateChargeThresholdErrorMessage] =
    useState("");
  const [maxPrincipalAmountErrorMessage, setMaxPrincipalAmountErrorMessage] =
    useState("");
  const [
    maxDaysIntoFutureForPaymentErrorMessage,
    setMaxDaysIntoFutureForPaymentErrorMessage,
  ] = useState("");
  const [paymentFeeRoutingNoErrorMessage, setPaymentFeeRoutingNoErrorMessage] =
    useState("");

  // Datepicker States
  const [paymentCutoffDatepickerOpen, setPaymentCutoffDatepickerOpen] =
    useState(false);

  // Autocomplete States
  const [listElements, setListElements] = useState([...stateList]);
  const [feeListElements, setFeeListElements] = useState([...stateList]);

  // Configurator States
  const [description, setDescription] = useState("");

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case PAYMENTS_ONLINE_ALLOWED_KEY:
          setAllowPayment(item.value);
          return;
        case PAYMENTS_ONLINE_FEE_OPTION_KEY:
          setSelectedFeeOption(
            `${PAYMENTS_ONLINE_FEE_OPTION_KEY}_${item.value}`,
          );
          return;
        case PAYMENTS_ONLINE_FEE_OPTION_1_KEY:
          setFlatValueFee(+item.value);
          return;
        case PAYMENTS_ONLINE_FEE_OPTION_2_KEY:
          setPercentageFee(+item.value);
          return;
        case PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY:
          setDefaultPaymentAmount(item.value);
          return;
        case PAYMENTS_ONLINE_LATE_FEE_FILL_KEY:
          setDefaultLateFeesFill(item.value);
          return;
        case PAYMENTS_MANDATE_LATE_FEE_KEY:
          setMandateLateFee(item.value);
          return;
        case PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY:
          setDefaultOtherFeesFill(item.value);
          return;
        case PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY:
          setPaymentFeeRoutingNo(item.value);
          return;
        case PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY:
          setLimitTotalSinglePayment(item.value);
          return;
        case PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY:
          setMaxPrincipalAmount(item.value);
          return;
        case PAYMENTS_ONLINE_CUTOFF_TIME_KEY:
          const today = new Date();
          const [hour, minute] = item.value.split(":");
          today.setHours(hour);
          today.setMinutes(minute);
          setPaymentCutoffStartDate(today);
          return;
        case PAYMENTS_ONLINE_POSTING_DATE_KEY:
          setPostingDate(item.value);
          return;
        case PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY:
          setDelinquentPaymentCounter(item.value ? +item.value : 0);
          return;
        case PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY:
          setLateChargeThreshold(item.value);
          return;
        case PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY:
          setAllowAdditionalPrincipal(item.value);
          return;
        case PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY:
          setMaxDaysIntoFutureForPayment(item.value);
          return;
        case PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY:
          setChargeEscrowShortageOnline(item.value);
          return;
        case PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY:
          setAmountChargedForEscrowShortage(item.value);
          return;
        case PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY:
          setAllowDeletePendingPayment(item.value);
          return;
        case CONFIG_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        default:
          return;
      }
    });
  };

  const onChangeHandler = (e, setter, errorMessageSetter) => {
    setter(e.target.value);
    validateInputValue(e, errorMessageSetter);
  };

  const validateInputValue = (e, errorMessageSetter) => {
    if (+e.target.value < 0) {
      errorMessageSetter(NON_NEGATIVE_AMOUNT_ERROR_MESSAGE);
      return;
    }
    if (e?.target?.validity?.valid === false) {
      errorMessageSetter(e.target.validationMessage);
      return;
    }
    errorMessageSetter("");
  };

  return (
    <FlexContainer width="100%" flexdirection="column">
      <FlexContainer
        flexdirection="column"
        width="100%"
        maxwidth="1720px"
        alignitems="center"
        padding="25px 100px"
        gap="25px"
      >
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_ALLOWED_KEY}
          label={PAYMENTS_ONLINE_ALLOWED_LABEL}
          settingId="row-allow-payment-group-label"
          settingValue={allowPayment}
          setValue={(e) => setAllowPayment(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={PAYMENTS_ONLINE_FEE_KEY}
          mainLabel={PAYMENTS_ONLINE_FEE_LABEL}
          expandCondition="true"
          secondaryLabel={PAYMENTS_ONLINE_FEE_SECONDARY_LABEL}
          expandedComponent={
            <>
              <FlexContainer
                width="100%"
                flexdirection="column"
                padding="25px 0px"
              >
                <FeeOptions
                  elementKey={PAYMENTS_ONLINE_FEE_OPTION_KEY}
                  elementKeys={{
                    flatValue: PAYMENTS_ONLINE_FEE_OPTION_1_KEY,
                    percentage: PAYMENTS_ONLINE_FEE_OPTION_2_KEY,
                    threshold: PAYMENTS_ONLINE_FEE_OPTION_3_KEY,
                    dates: PAYMENTS_ONLINE_FEE_OPTION_4_KEY,
                  }}
                  selectedFeeOption={selectedFeeOption}
                  setSelectedFeeOption={setSelectedFeeOption}
                  onChangeHandler={onChangeHandler}
                  validateInputValue={validateInputValue}
                  flatValueFee={flatValueFee}
                  setFlatValueFee={setFlatValueFee}
                  percentageFee={percentageFee}
                  setPercentageFee={setPercentageFee}
                  threshold={threshold}
                  setThreshold={setThreshold}
                  numberOfDateRanges={numberOfDateRanges}
                  setNumberOfDateRanges={setNumberOfDateRanges}
                  flatValueFeeErrorMessage={flatValueFeeErrorMessage}
                  setFlatValueFeeErrorMessage={setFlatValueFeeErrorMessage}
                  percentageFeeErrorMessage={percentageFeeErrorMessage}
                  setPercentageFeeErrorMessage={setPercentageFeeErrorMessage}
                  feeListElements={feeListElements}
                  setFeeListElements={setFeeListElements}
                />
              </FlexContainer>
              <InputSetting
                elementKey={PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_KEY}
                label={PAYMENTS_ONLINE_FEE_CLIENT_ROUTING_NO_LABEL}
                inputValue={paymentFeeRoutingNo}
                setInputValue={setPaymentFeeRoutingNo}
                onChangeHandler={onChangeHandler}
                validateInputValue={validateInputValue}
                padding="25px 0px 0px 0px"
                blurOnWheel
                inputProps={{
                  step: 0.01,
                  min: 0,
                  type: "number",
                  style: {
                    textAlign: "end",
                  },
                }}
                errorMessage={paymentFeeRoutingNoErrorMessage}
                setErrorMessage={setPaymentFeeRoutingNoErrorMessage}
              />
            </>
          }
        />
        <Divider flexItem />
        <AutoCompleteInputSetting
          elementKey={PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_KEY}
          label={PAYMENTS_ONLINE_STATE_TRANSACTION_MAX_LABEL}
          labelFontSize="16px"
          listElements={listElements}
          setListElements={setListElements}
          padding="25px"
          background={hex2rgba(theme.palette.primary.main, 0.1)}
          onChangeHandler={onChangeHandler}
          validateInputValue={validateInputValue}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_KEY}
          label={PAYMENTS_ONLINE_PAYMENT_AMOUNT_FILL_LABEL}
          settingId="row-default-payment-amount-group-label"
          settingValue={defaultPaymentAmount}
          setValue={(e) => setDefaultPaymentAmount(e.target.value)}
          options={[
            { label: "No payment amount", value: 0 },
            { label: "Normal amount", value: 1 },
          ]}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_LATE_FEE_FILL_KEY}
          label={PAYMENTS_ONLINE_LATE_FEE_FILL_LABEL}
          settingId="row-default-late-fees-fill-group-label"
          settingValue={defaultLateFeesFill}
          setValue={(e) => setDefaultLateFeesFill(e.target.value)}
          options={[
            {
              label: "Do not fill in late fees",
              value: 0,
            },
            { label: "Auto fill total late fees due", value: 1 },
          ]}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_MANDATE_LATE_FEE_KEY}
          label={PAYMENTS_MANDATE_LATE_FEE_LABEL}
          settingId="row-mandate-late-fees-group-label"
          settingValue={mandateLateFee}
          setValue={(e) => setMandateLateFee(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_OTHER_FEE_FILL_KEY}
          label={PAYMENTS_ONLINE_OTHER_FEE_FILL_LABEL}
          settingId="row-default-other-fees-fill-group-label"
          settingValue={defaultOtherFeesFill}
          setValue={(e) => setDefaultOtherFeesFill(e.target.value)}
          options={[
            { label: "Do not fill in other fees", value: 0 },
            { label: "Auto fill total other fees due", value: 1 },
          ]}
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_KEY}
          mainLabel={PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_LABEL}
          expandCondition="true"
          secondaryLabel={PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_SECONDARY_LABEL}
          expandAnimation
          expandedComponent={
            <InputSetting
              elementKey={PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_KEY}
              label={PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_LABEL}
              labelColor="primary"
              inputValue={limitTotalSinglePayment}
              setInputValue={setLimitTotalSinglePayment}
              onChangeHandler={onChangeHandler}
              validateInputValue={validateInputValue}
              inputPlaceholder="0"
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
              errorMessage={limitTotalSinglePaymentErrorMessage}
              setErrorMessage={setLimitTotalSinglePaymentErrorMessage}
              padding="25px"
              background={hex2rgba(theme.palette.primary.main, 0.1)}
              hasInfo
              Info={
                <InfoText
                  Icon={InfoOutlinedIcon}
                  iconColor="primary"
                  text={PAYMENTS_ONLINE_CUSTOMER_TRANSACTION_MAX_INFO}
                  textColor="text.secondary"
                  textSize={10}
                  textWeight="bold"
                />
              }
            />
          }
        />
        <Divider flexItem />
        <InputSetting
          elementKey={PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_KEY}
          label={PAYMENTS_ONLINE_PRINCIPAL_TRANSACTION_MAX_LABEL}
          inputValue={maxPrincipalAmount}
          setInputValue={setMaxPrincipalAmount}
          onChangeHandler={onChangeHandler}
          validateInputValue={validateInputValue}
          inputPlaceholder="0"
          blurOnWheel
          adornment
          adornmentPosition="start"
          adornmentSymbol="$"
          inputProps={{
            step: 0.01,
            min: 0,
            type: "number",
            style: {
              textAlign: "end",
            },
          }}
          errorMessage={maxPrincipalAmountErrorMessage}
          setErrorMessage={setMaxPrincipalAmountErrorMessage}
        />
        <Divider flexItem />
        <TimePickerSetting
          elementKey={PAYMENTS_ONLINE_CUTOFF_TIME_KEY}
          label={PAYMENTS_ONLINE_CUTOFF_TIME_LABEL}
          value={paymentCutoffStartDate}
          setValue={setPaymentCutoffStartDate}
          timepickerOpen={paymentCutoffDatepickerOpen}
          setTimepickerOpen={setPaymentCutoffDatepickerOpen}
          hasInfo
          Info={
            <InfoText
              Icon={InfoOutlinedIcon}
              iconColor="primary"
              text={PAYMENTS_ONLINE_CUTOFF_TIME_INFO}
              textColor="text.secondary"
              textSize={10}
              textWeight="bold"
            />
          }
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_POSTING_DATE_KEY}
          label={PAYMENTS_ONLINE_POSTING_DATE_LABEL}
          settingId="row-posting-date-group-label"
          settingValue={postingDate}
          setValue={(e) => setPostingDate(e.target.value)}
          options={[
            { label: "Same day", value: 0 },
            { label: "Next business day", value: 1 },
            { label: "Next business day + 1", value: 2 },
            { label: "Next business day + 2", value: 3 },
          ]}
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_KEY}
          mainLabel={PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_LABEL}
          expandCondition="true"
          secondaryLabel=""
          expandAnimation
          expandedComponentValue={delinquentPaymentCounter}
          expandedComponent={
            <CounterSetting
              elementKey={PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY}
              label={PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_LABEL}
              labelColor="primary"
              counter={delinquentPaymentCounter}
              setCounter={setDelinquentPaymentCounter}
              minValue={0}
              padding="25px"
              background={hex2rgba(theme.palette.primary.main, 0.1)}
            />
          }
        />
        <Divider flexItem />
        <InputSetting
          elementKey={PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_KEY}
          label={PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_LABEL}
          inputValue={lateChargeThreshold}
          setInputValue={setLateChargeThreshold}
          onChangeHandler={onChangeHandler}
          validateInputValue={validateInputValue}
          inputPlaceholder="0"
          blurOnWheel
          adornment
          adornmentPosition="start"
          adornmentSymbol="$"
          inputProps={{
            step: 0.01,
            min: 0,
            type: "number",
            style: {
              textAlign: "end",
            },
          }}
          errorMessage={lateChargeThresholdErrorMessage}
          setErrorMessage={setLateChargeThresholdErrorMessage}
          hasInfo
          Info={
            <InfoText
              Icon={InfoOutlinedIcon}
              iconColor="primary"
              text={PAYMENTS_ONLINE_LATE_CHARGE_THRESHOLD_INFO}
              textColor="text.secondary"
              textSize={10}
              textWeight="bold"
            />
          }
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_KEY}
          label={PAYMENTS_ONLINE_ALLOW_PRINCIPAL_IF_DELINQUENT_LABEL}
          settingId="row-additional-principal-group-label"
          settingValue={allowAdditionalPrincipal}
          setValue={(e) => setAllowAdditionalPrincipal(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        <InputSetting
          elementKey={PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_KEY}
          label={PAYMENTS_ONLINE_MAX_DAYS_INTO_FUTURE_LABEL}
          inputValue={maxDaysIntoFutureForPayment}
          setInputValue={setMaxDaysIntoFutureForPayment}
          onChangeHandler={onChangeHandler}
          validateInputValue={validateInputValue}
          inputPlaceholder="10"
          blurOnWheel
          inputProps={{
            step: 1,
            min: 10,
            type: "number",
            style: {
              textAlign: "end",
            },
          }}
          errorMessage={maxDaysIntoFutureForPaymentErrorMessage}
          setErrorMessage={setMaxDaysIntoFutureForPaymentErrorMessage}
          defaultValue={10}
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_KEY}
          mainLabel={PAYMENTS_ONLINE_CHARGE_ESCROW_SHORTAGE_ONLINE_LABEL}
          expandCondition="true"
          secondaryLabel=""
          expandAnimation
          expandedComponent={
            <InputSetting
              elementKey={
                PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_KEY
              }
              label={PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_LABEL}
              labelColor="primary"
              inputValue={amountChargedForEscrowShortage}
              setInputValue={setAmountChargedForEscrowShortage}
              onChangeHandler={onChangeHandler}
              validateInputValue={validateInputValue}
              inputPlaceholder="0"
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
              padding="25px"
              background={hex2rgba(theme.palette.primary.main, 0.1)}
              hasInfo
              Info={
                <InfoText
                  Icon={InfoOutlinedIcon}
                  iconColor="primary"
                  text={PAYMENTS_ONLINE_AMOUNT_CHARGED_FOR_ESCROW_SHORTAGE_INFO}
                  textColor="text.secondary"
                  textSize={10}
                  textWeight="bold"
                />
              }
            />
          }
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_KEY}
          label={PAYMENTS_ONLINE_ALLOW_DELETE_PENDING_PAYMENT_LABEL}
          settingValue={allowDeletePendingPayment}
          setValue={(e) => setAllowDeletePendingPayment(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
          visibleRole === CLIENT_ADMIN_ROLE) ||
        description ? (
          <FlexContainer width="75%" alignself="flex-start">
            <EditableText
              renderedText={description}
              setRenderedText={setDescription}
              elementKey={CONFIG_PAGE_DESCRIPTION_KEY}
              wasModified={findElementIncludedInArray(
                edittedFields,
                "modified",
                true,
                "key",
                CONFIG_PAGE_DESCRIPTION_KEY,
              )}
              padding="0 0 25px 0"
              fontSize={12}
              withHyperlink
            />
          </FlexContainer>
        ) : (
          ""
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ConfigurationPaymentsCategory;
