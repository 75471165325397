import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useTheme } from "@mui/styles";
import { Divider, TablePagination } from "@mui/material";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import {
  deletePayment,
  deleteRecurringPayment,
  getPaymentActivity,
} from "../../../data/paymentsAPI";
import DataTable from "../../../shared/components/DataTable";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import EditableText from "../../../shared/components/configurator/EditableText";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import PaymentActivityCell from "../../../shared/components/table/PaymentActivityCell";
import { PLACEHOLDER_DESCRIPTION } from "../../../shared/constants/Defaults.constants";
import {
  PAYMENT_ACTIVITY_CANCEL_PAYMENT_TOOLTIP,
  PAYMENT_ACTIVITY_CANCEL_RECURRING_PAYMENT_TOOLTIP,
  PAYMENT_ACTIVITY_EDIT_RECURRING_PAYMENT_TOOLTIP,
  PAYMENT_ACTIVITY_FIELD_TYPES,
  PAYMENT_ACTIVITY_HEADER_NAMES,
  PAYMENT_ACTIVITY_NO_DATA_MESSAGE,
  RECURRING_PAYMENT,
} from "../../../shared/constants/Payments.constants";
import {
  PAYMENT_ACTIVITY_PAGE_DESCRIPTION_KEY,
  PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE_KEY,
} from "../../../shared/constants/Configurator.constants";
import {
  findElementIncludedInArray,
  padCents,
} from "../../../shared/utils/utility-functions";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import { usePrevious } from "../../../shared/hooks/usePrevious";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import { NO_LOAN_REGISTERED_WARNING_MESSAGE } from "../../../shared/constants/UserProfile.constants";
import { utcToZonedTime } from "date-fns-tz";

export default function PaymentActivity() {
  const INITIAL_PAGE_SIZE = 5;

  const navigate = useNavigate();
  const theme = useTheme();
  const { accounts, instance } = useMsal();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { token, userRoles, visibleRole, activeLoan, impersonatedUser } =
    useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const prevActiveLoan = usePrevious(activeLoan);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Token States
  const [skipToken, setSkipToken] = useState("");

  // Table Data States
  const [paymentActivity, setPaymentActivity] = useState([]);

  // Table Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_PAGE_SIZE);
  const [totalRecords, setTotalRecords] = useState(-1);

  // Configurator States
  const [description, setDescription] = useState(PLACEHOLDER_DESCRIPTION);
  const [footerNote, setFooterNote] = useState("");

  // Snackbar States
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Error States
  const [paymentActivityRetrievalError, setPaymentActivityRetrievalError] =
    useState(false);

  // Dialog States
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Row States
  const [rowHasBeenDeleted, setRowHasBeenDeleted] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [changedLoan, setChangedLoan] = useState(false);

  useEffect(() => {
    if (token) {
      callPaymentActivityApi(token, 1, rowsPerPage);
    }
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance, token, activeLoan]);

  useEffect(() => {
    if (prevActiveLoan && activeLoan) {
      clearData();
      setChangedLoan(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoan]);

  useEffect(() => {
    // Second Api Call to get the next page when the Payment Activity screen renders
    if (
      totalRecords !== -1 &&
      skipToken?.length > 0 &&
      paymentActivity.length < (page + 1) * rowsPerPage + rowsPerPage
    ) {
      callPaymentActivityApi(token, 1);
    }

    if (rowHasBeenDeleted === true && totalRecords === -1) {
      callPaymentActivityApi(token, 1, rowsPerPage);
      setRowHasBeenDeleted(false);
    }

    if (changedLoan === true && totalRecords === -1) {
      callPaymentActivityApi(token, 1, rowsPerPage);
      setChangedLoan(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecords]);

  useEffect(() => {
    if (
      skipToken?.length > 0 &&
      paymentActivity.length < (page + 1) * rowsPerPage + rowsPerPage
    ) {
      const noOfPages = rowsPerPage / INITIAL_PAGE_SIZE;
      callPaymentActivityApi(token, noOfPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (rowHasBeenDeleted === true) {
      clearData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowHasBeenDeleted]);

  const callPaymentActivityApi = async (token, noOfPages, pageSize = null) => {
    addPendingRequest();

    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        const paymentActivityData = await getPaymentActivity(
          token,
          impersonatedUser,
          activeLoan,
          noOfPages,
          pageSize,
          skipToken,
        );
        setPaymentActivityDataStates(paymentActivityData);
      } else {
        setPaymentActivityRetrievalError(true);
        setSnackbarOpen(true);
      }
    }
    removePendingRequest();
  };

  const setPaymentActivityDataStates = (data) => {
    if (!data.message) {
      const sumFormatter = new Intl.NumberFormat("en-US");
      const tableData = data.response.map((item) => {
        const itemDate = utcToZonedTime(item.date, userTimeZone);
        const formattedDate = `${`0${itemDate.getMonth() + 1}`.slice(
          -2,
        )}/${`0${itemDate.getDate()}`.slice(-2)}/${itemDate.getFullYear()}`;
        return {
          type: item.paymentType,
          date: item.date ? formattedDate : "Unknown Date",
          status: item.status.toUpperCase(),
          amount: `$ ${padCents(sumFormatter.format(item.amount))}`,
          actions: item.confirmationNumber
            ? [
                {
                  actionType: "cancel",
                  actionCallback: handleCancelPayment,
                  actionTooltip: PAYMENT_ACTIVITY_CANCEL_PAYMENT_TOOLTIP,
                },
              ]
            : [
                {
                  actionType: "edit",
                  actionCallback: handleEditRecurringPayment,
                  actionTooltip:
                    PAYMENT_ACTIVITY_EDIT_RECURRING_PAYMENT_TOOLTIP,
                },
                {
                  actionType: "cancel",
                  actionCallback: handleCancelPayment,
                  actionTooltip:
                    PAYMENT_ACTIVITY_CANCEL_RECURRING_PAYMENT_TOOLTIP,
                },
              ],
          disabled: item.status.toUpperCase() === "PENDING" ? true : false,
          confirmation: item.confirmationNumber,
        };
      });

      const newData = paymentActivity.concat(tableData);

      setPaymentActivity(newData);
      setSkipToken(data.skipToken);
      if (totalRecords === -1) {
        setTotalRecords(data.totalCount);
      }
      setPaymentActivityRetrievalError(false);
      setSnackbarOpen(false);
    } else if (data.message.includes("loan")) {
      setPaymentActivityRetrievalError(true);
    }
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case PAYMENT_ACTIVITY_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE_KEY:
          setFooterNote(item.value);
          return;
        default:
          return;
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const clearData = () => {
    setSkipToken("");
    setPaymentActivity([]);
    setPage(0);
    setRowsPerPage(INITIAL_PAGE_SIZE);
    setTotalRecords(-1);
  };

  const handleEditRecurringPayment = (rowInfo) => {
    navigate("/payments/new", {
      state: {
        from: "activity",
        type: RECURRING_PAYMENT,
        postingDate: rowInfo.date,
      },
    });
  };

  const handleCancelPayment = (rowInfo) => {
    setConfirmationOpen(true);
    setCurrentRow(rowInfo);
  };

  const handleConfirmationClose = () => {
    callPaymentsApi(token, currentRow?.confirmation);
    setConfirmationOpen(false);
  };

  const callPaymentsApi = (token, confirmationNumber) => {
    addPendingRequest();
    const apiCall = confirmationNumber ? deletePayment : deleteRecurringPayment;
    const apiCallParameters = confirmationNumber
      ? [token, confirmationNumber, activeLoan, impersonatedUser]
      : [token, activeLoan, impersonatedUser];
    apiCall(...apiCallParameters).then((data) => {
      if (data?.success === true) {
        setRowHasBeenDeleted(true);
        setCurrentRow({});
      }
      removePendingRequest();
    });
  };

  return (
    <FlexContainer
      flexdirection="column"
      justifycontent="flex-start"
      height="100%"
      width="100%"
    >
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={PAYMENT_ACTIVITY_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            PAYMENT_ACTIVITY_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <FlexContainer
        width="100%"
        flexdirection="column"
        alignself="flex-start"
        alignitems="flex-end"
      >
        <DataTable
          headers={PAYMENT_ACTIVITY_HEADER_NAMES}
          rows={paymentActivity.slice(
            page * rowsPerPage,
            (page + 1) * rowsPerPage,
          )}
          emptyDataMessage={
            paymentActivityRetrievalError || totalRecords === 0
              ? PAYMENT_ACTIVITY_NO_DATA_MESSAGE
              : ""
          }
          rowAttributes={PAYMENT_ACTIVITY_FIELD_TYPES}
          CellDisplayComponent={PaymentActivityCell}
          minWidth="350px"
        />
        {!paymentActivityRetrievalError || totalRecords === 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </FlexContainer>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      footerNote ? (
        <>
          <Divider sx={{ paddingTop: "50px" }} flexItem />
          <EditableText
            renderedText={footerNote}
            setRenderedText={setFooterNote}
            elementKey={PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              PAYMENT_ACTIVITY_PAGE_FOOTER_NOTE_KEY,
            )}
            padding="5px 0 0 0"
            fontSize={12}
            withHyperlink
          />
        </>
      ) : (
        ""
      )}
      <ActionSnackbar
        severity="warning"
        borderColor={theme.palette.warning.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={handleSnackbarClose}
        snackbarLabel={NO_LOAN_REGISTERED_WARNING_MESSAGE}
        actionLabel="Register Loan"
        actionFn={() => navigate("/profile/loan")}
      />
      <ConfirmationDialog
        open={confirmationOpen}
        close={handleConfirmationClose}
        dismiss={(e) => setConfirmationOpen(false)}
        title={`Confirm cancelling ${
          currentRow.confirmation ? "" : "recurring"
        } payment`}
        body={`Are you sure you want to cancel ${
          currentRow.confirmation ? "this" : "the recurring"
        } payment of ${currentRow.amount} from ${currentRow.date}?`}
      />
    </FlexContainer>
  );
}
