import React from "react";
import { Typography } from "@mui/material";
import { FlexContainer } from "./styled/Container.styled";

const InfoText = ({
  elementWidth = "auto",
  Icon = <></>,
  iconColor = "primary",
  text = "",
  textColor = "text",
  textSize = "1rem",
  textWeight = "normal",
  justifyContent = "normal",
}) => {
  return (
    <FlexContainer
      gap="5px"
      width={elementWidth}
      justifycontent={justifyContent}
    >
      <Icon color={iconColor} fontSize="small" />
      <Typography
        color={textColor}
        fontSize={textSize}
        fontWeight={textWeight}
        lineHeight={1}
      >
        {text}
      </Typography>
    </FlexContainer>
  );
};

export default InfoText;
