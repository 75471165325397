import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { FlexContainer } from "../styled/Container.styled";
import { useTheme } from "@mui/styles";
import { FileInput } from "../styled/Form.styled";
import { Typography } from "@mui/material";
import {
  DRAG_DROP_PLACEHOLDER,
  UPLOAD_SIZE_EXCEEDED,
} from "../../constants/StatementsDocuments.constants";
import ActionSnackbar from "../ActionSnackbar";
import React, { useState } from "react";
import { ERROR } from "../../constants/Defaults.constants";

export default function DropFileInput({
  file = {},
  setFile = () => {},
  fileTypes = "",
  size = 5,
}) {
  const theme = useTheme();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [severity, setSeverity] = useState("error");

  const extensionsPreview = fileTypes.map((extension) => {
    if (
      extension ===
      "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "DOCX";
    }
    return extension.toUpperCase();
  });

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile && fileTypes.indexOf(newFile.type.split("/")[1]) > -1) {
      if (newFile.size <= size * 1e6) {
        setFile(newFile);
      } else {
        setSnackBarMessage(UPLOAD_SIZE_EXCEEDED(size));
        setSeverity(ERROR);
        setSnackBarOpen(true);
      }
    }
  };

  const fileRemove = () => {
    setFile(null);
  };

  return (
    <>
      <FlexContainer
        position="relative"
        width="500px"
        height="300px"
        border={`2px dashed ${theme.palette.primary.main}`}
        background="#F0F0F0"
        opacity={"0.6"}
      >
        <FlexContainer flexdirection={"column"}>
          <FileUploadOutlinedIcon
            sx={{
              height: "75px",
              width: "75px",
            }}
          />
          <Typography>{DRAG_DROP_PLACEHOLDER}</Typography>
          <Typography>
            Allowed extensions: <b>{extensionsPreview.join(", ")}</b>.
          </Typography>
          <Typography>
            Maximum file size: <b>{size}MB</b>.
          </Typography>
        </FlexContainer>
        <FileInput
          type="file"
          value=""
          accept={fileTypes}
          onChange={onFileDrop}
          position={"absolute"}
          opacity={"0"}
          top={"0"}
          left={"0"}
          width={"100%"}
          height={"100%"}
          cursor={"pointer"}
        />
      </FlexContainer>
      <ActionSnackbar
        severity={severity}
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackBarOpen}
        closeFn={() => {
          setSnackBarOpen(false);
        }}
        snackbarLabel={snackBarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />

      {file && (
        <FlexContainer margin={"10px 0 0"}>
          <FlexContainer
            position={"relative"}
            margin={"0 0 10px"}
            padding={"15px"}
            borderradius={"20px"}
            gap={"15px"}
            background={"#F0F0F0"}
          >
            <InsertDriveFileOutlinedIcon
              sx={{
                height: "30px",
                width: "30px",
              }}
            />
            <FlexContainer
              flexdirection={"column"}
              justifycontent={"space-between"}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {file.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "lighter",
                }}
              >
                {(file.size / 1e6).toFixed(3)}MB
              </Typography>
            </FlexContainer>
            <Typography
              onClick={fileRemove}
              sx={{
                width: "20px",
                cursor: "pointer",
                color: "red",
                textAlign: "center",
              }}
            >
              x
            </Typography>
          </FlexContainer>
        </FlexContainer>
      )}
    </>
  );
}
