import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoText from "../../../shared/components/InfoText";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { ActionText } from "../../../shared/components/styled/Typography.styled";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import TwoToneItem from "../../../shared/components/dashboard/TwoToneItem";
import RowItem from "../../../shared/components/dashboard/RowItem";
import { hex2rgba } from "../../../shared/utils/utility-functions";
import { NOT_APPLICABLE } from "../../../shared/constants/Defaults.constants";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
} from "../../../shared/constants/Roles.constants";

const DashboardPaymentDetails = ({
  dueDate = "",
  autopay = false,
  amountDue = "",
  principalInterestAmount = "",
  escrowAmount = "",
  lastPaymentReceived = "",
  autoPaymentDate = "",
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { impersonatedUser, userRoles, activeLoanDetails } = useContext(AuthContext);
  const inActiveOrDeadLoan = (activeLoanDetails?.loanDetails?.inActiveIndicator || activeLoanDetails?.loanDetails?.deadLoanIndicator);

  return (
    <FlexContainer flexdirection="column" padding="20px" gap="15px">
      <FlexContainer width="100%" justifycontent="space-between" gap="10px">
        <FlexContainer flexdirection="column" alignitems="flex-start">
          <Typography fontWeight="bold" fontSize="1.125rem">
            Amount Due
          </Typography>
          <Typography fontWeight="bold" color="text.secondary">
            {autoPaymentDate ? `Due ${autoPaymentDate}` : ""}
          </Typography>
        </FlexContainer>
        {autopay ? (
          <FlexContainer
            flexdirection="column"
            gap="5px"
            background={hex2rgba(theme.palette.primary.main, 0.1)}
            padding="10px"
          >
            <InfoText
              Icon={InfoOutlinedIcon}
              iconColor="primary"
              text="AutoPay is activated."
              textColor="text.secondary"
              textSize={12}
              textWeight="bold"
            />
            {!impersonatedUser &&
            userRoles?.includes(PAYMENTS_BORROWER_ROLE) && !inActiveOrDeadLoan ? (
              <ActionText>
                <Typography onClick={(e) => navigate("/payments/activity")}>
                  Manage AutoPay
                </Typography>
              </ActionText>
            ) : null}
          </FlexContainer>
        ) : (
          ""
        )}
        <TwoToneItem
          breakpoint="."
          data={amountDue}
          prefix="$"
          toneOne="primary"
          toneTwo="primary"
          fontSize="2.2857rem"
          prefixFontSize="1.1428rem"
        />
      </FlexContainer>
      <Divider flexItem />
      <RowItem
        label="Principal & Interest"
        value={principalInterestAmount}
        prefix="$"
      />
      <RowItem
        label="Escrow"
        value={escrowAmount}
        prefix={escrowAmount === NOT_APPLICABLE ? "" : "$"}
      />
      <RowItem label="Actual Due Date" value={dueDate} />
      <RowItem label="Last Payment Received" value={lastPaymentReceived} />
      {!inActiveOrDeadLoan ? (
        <PillButton
          variant="contained"
          color="primary"
          width="50%"
          customfontsize="1.25rem"
          onClick={(e) => navigate("/payments/new")}
          disabled={!!impersonatedUser || userRoles?.includes(CLIENT_ADMIN_ROLE)}
        >
          Make a Payment
        </PillButton>
      ) : ""}
    </FlexContainer>
  );
};

export default DashboardPaymentDetails;
