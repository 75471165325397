import React, { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import FormItem from "../../../../shared/components/form/FormItem";
import InfoText from "../../../../shared/components/InfoText";
import {
  ESCROW_SHORTAGE,
  ONE_TIME_PAYMENT,
} from "../../../../shared/constants/Payments.constants";
import {
  checkValidity,
  handleInputChange,
  prefillInput,
} from "../../../../shared/utils/utility-functions";
import { PillButton } from "../../../../shared/components/styled/Button.styled";
import { Box } from "@mui/material";

export default function PaymentAmount({
  fieldsDisabled = true,
  selectedType = ONE_TIME_PAYMENT,
  amountDue = 0,
  lateFeesDue = 0,
  feesDue = 0,
  amount = "",
  setAmount = () => {},
  lateFees = "",
  setLateFees = () => {},
  fees = "",
  setFees = () => {},
  additionalPrincipal = "",
  setAdditionalPrincipal = () => {},
  additionalEscrow = "",
  setAdditionalEscrow = () => {},
  amountErrorMessage = "",
  setAmountErrorMessage = () => {},
  lateFeesErrorMessage = "",
  setLateFeesErrorMessage = () => {},
  feesErrorMessage = "",
  setFeesErrorMessage = () => {},
  additionalPrincipalErrorMessage = "",
  setAdditionalPrincipalErrorMessage = () => {},
  additionalEscrowErrorMessage = "",
  setAdditionalEscrowErrorMessage = () => {},
  mandateLateFee = false,
  isPrincipalOnlyPayment = false,
  principalMaxTransaction = 0,
  principalDisabled = false,
  additionalEscrowAmountAllowed = true,
  helocOrConstructionLoan = false,
}) {
  useEffect(() => {
    if (
      isPrincipalOnlyPayment &&
      principalMaxTransaction &&
      +additionalPrincipal > +principalMaxTransaction
    ) {
      setAdditionalPrincipal(principalMaxTransaction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrincipalOnlyPayment]);

  const handleInputChangeWithMaxValue = (
    e,
    maxValue,
    setter,
    setErrorMessage = null,
  ) => {
    e.preventDefault();
    setter(
      isPrincipalOnlyPayment && maxValue && +e.target.value > maxValue
        ? maxValue
        : e.target.value,
    );
    if (setErrorMessage !== null) {
      checkValidity(e.target, setErrorMessage);
    }
  };
  return (
    <FlexContainer padding="25px 0" flexdirection="column" gap="10px">
      <FormItem
        elementWidth="200px"
        label={`Amount Due ($ ${amountDue})`}
        inputType="input"
        value={selectedType === ONE_TIME_PAYMENT ? amount : amountDue}
        setValue={(e) => handleInputChange(e, setAmount, setAmountErrorMessage)}
        inputPlaceholder="0"
        blurHandler={(e) => checkValidity(e.target, setAmountErrorMessage)}
        blurOnWheel
        adornment
        adornmentPosition="start"
        adornmentSymbol="$"
        disabled={
          // Remove the true || line from below to revert to editable input field that accepts partial amount
          true ||
          fieldsDisabled ||
          selectedType !== ONE_TIME_PAYMENT ||
          helocOrConstructionLoan
        }
        errorMessage={amountErrorMessage}
        hasComponent={
          !helocOrConstructionLoan &&
          selectedType === ONE_TIME_PAYMENT &&
          +amountDue > 0
        }
        component={
          <Box width="200px">
            <PillButton
              variant="contained"
              width="90px"
              color="primary"
              onClick={(e) =>
                prefillInput(
                  e.target,
                  // Remove the ternary operator from below and leave only amountDue.toString() to revert to editable input field that accepts partial amount
                  +amountDue === +amount ? "0" : amountDue.toString(),
                  setAmount,
                  setAmountErrorMessage,
                )
              }
              disabled={
                // Uncomment the line below to revert to editable input field that accepts partial amount
                // +amountDue === +amount ||
                helocOrConstructionLoan
              }
              sx={{ fontSize: "10px", padding: "5px 15px" }}
            >
              {/* Remove the logic from the line below and leave only Full Amount to revert to editable input field that accepts partial amount */}
              {+amountDue === +amount ? "No Amount" : "Full Amount"}
            </PillButton>
          </Box>
        }
        inputProps={{
          step: 0.01,
          min: 0,
          max: amountDue,
          type: "number",
          style: {
            textAlign: "end",
          },
        }}
      />
      {selectedType === ONE_TIME_PAYMENT ? (
        <>
          <FormItem
            elementWidth="200px"
            label={`Late Fees ($ ${lateFeesDue})`}
            inputType="input"
            value={mandateLateFee ? lateFeesDue : lateFees}
            setValue={(e) =>
              handleInputChange(e, setLateFees, setLateFeesErrorMessage)
            }
            inputPlaceholder="0"
            blurHandler={(e) =>
              checkValidity(e.target, setLateFeesErrorMessage)
            }
            blurOnWheel
            adornment
            adornmentPosition="start"
            adornmentSymbol="$"
            disabled={mandateLateFee || fieldsDisabled}
            errorMessage={lateFeesErrorMessage}
            hasComponent={!mandateLateFee && +lateFeesDue > 0}
            component={
              <Box width="200px">
                <PillButton
                  variant="contained"
                  width="90px"
                  color="primary"
                  onClick={(e) =>
                    prefillInput(
                      e.target,
                      lateFeesDue.toString(),
                      setLateFees,
                      setLateFeesErrorMessage,
                    )
                  }
                  disabled={+lateFeesDue === +lateFees}
                  sx={{ fontSize: "10px", padding: "5px 15px" }}
                >
                  Full Amount
                </PillButton>
              </Box>
            }
            inputProps={{
              step: 0.01,
              min: 0,
              max: lateFeesDue,
              type: "number",
              style: {
                textAlign: "end",
              },
            }}
          />
          <FormItem
            elementWidth="200px"
            label={`Fees ($ ${feesDue})`}
            inputType="input"
            value={fees}
            setValue={(e) => handleInputChange(e, setFees, setFeesErrorMessage)}
            inputPlaceholder="0"
            blurHandler={(e) => checkValidity(e.target, setFeesErrorMessage)}
            blurOnWheel
            adornment
            adornmentPosition="start"
            adornmentSymbol="$"
            disabled={fieldsDisabled}
            errorMessage={feesErrorMessage}
            hasComponent={+feesDue > 0}
            component={
              <Box width="200px">
                <PillButton
                  variant="contained"
                  width="90px"
                  color="primary"
                  onClick={(e) =>
                    prefillInput(
                      e.target,
                      feesDue.toString(),
                      setFees,
                      setFeesErrorMessage,
                    )
                  }
                  disabled={+feesDue === +fees}
                  sx={{ fontSize: "10px", padding: "5px 15px" }}
                >
                  Full Amount
                </PillButton>
              </Box>
            }
            inputProps={{
              step: 0.01,
              min: 0,
              max: feesDue,
              type: "number",
              style: {
                textAlign: "end",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
      {selectedType !== ESCROW_SHORTAGE ? (
        <>
          <FormItem
            elementWidth="200px"
            label="Additional Principal Payment"
            inputType="input"
            value={additionalPrincipal}
            setValue={(e) =>
              handleInputChangeWithMaxValue(
                e,
                principalMaxTransaction,
                setAdditionalPrincipal,
                setAdditionalPrincipalErrorMessage,
              )
            }
            inputPlaceholder="0"
            blurHandler={(e) =>
              checkValidity(e.target, setAdditionalPrincipalErrorMessage)
            }
            blurOnWheel
            adornment
            adornmentPosition="start"
            adornmentSymbol="$"
            disabled={
              fieldsDisabled || principalDisabled || helocOrConstructionLoan
            }
            errorMessage={additionalPrincipalErrorMessage}
            hasComponent={isPrincipalOnlyPayment && principalMaxTransaction}
            component={
              <InfoText
                elementWidth="200px"
                Icon={InfoOutlinedIcon}
                iconColor="primary"
                text={`Maximum principal only amount is $${principalMaxTransaction}`}
                textColor="text.hint"
                textSize={10}
                textWeight="bold"
              />
            }
            inputProps={{
              step: 0.01,
              min: 0,
              type: "number",
              style: {
                textAlign: "end",
              },
            }}
          />
          {additionalEscrowAmountAllowed ? (
            <FormItem
              elementWidth="200px"
              label="Additional Escrow Payment"
              inputType="input"
              value={additionalEscrow}
              setValue={(e) =>
                handleInputChange(
                  e,
                  setAdditionalEscrow,
                  setAdditionalEscrowErrorMessage,
                )
              }
              inputPlaceholder="0"
              blurHandler={(e) =>
                checkValidity(e.target, setAdditionalEscrowErrorMessage)
              }
              blurOnWheel
              adornment
              adornmentPosition="start"
              adornmentSymbol="$"
              disabled={fieldsDisabled}
              errorMessage={additionalEscrowErrorMessage}
              hasComponent
              component={
                <InfoText
                  elementWidth="200px"
                  Icon={InfoOutlinedIcon}
                  iconColor="primary"
                  text="No escrow audit shortages allowed"
                  textColor="text.hint"
                  textSize={10}
                  textWeight="bold"
                />
              }
              inputProps={{
                step: 0.01,
                min: 0,
                type: "number",
                style: {
                  textAlign: "end",
                },
              }}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </FlexContainer>
  );
}
