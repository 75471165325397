import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useUnload from "../hooks/useUnload";
import ConfirmationDialog from "./ConfirmationDialog";
import { loginRequest } from "../config/msal.config";
import {
  downloadFile,
  postBatchConfig,
  uploadFile,
} from "../../data/configAPI";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AuthContext } from "../../contexts/AuthContext";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import { FlexContainer } from "./styled/Container.styled";
import { CustomNavbar } from "./styled/Nav.styled";
import { PillButton } from "./styled/Button.styled";
import Logo from "./Logo";
import { useTheme } from "@mui/styles";
import AccountPopper from "./AccountPopper";
import ActionSnackbar from "./ActionSnackbar";
import {
  DOCUMENTS_ALLOW_ACCESS_KEY,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  DOCUMENTS_ALLOW_UPLOAD_KEY,
  LOGOUT_HAS_MESSAGE_KEY,
  LOGOUT_MESSAGE_KEY,
  LOGOUT_SUBTITLE_KEY,
  LOGO_IMAGE_KEY,
  PUBLISH_CHANGES_CONFIRMATION_BODY,
} from "../constants/Configurator.constants";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "../constants/Roles.constants";
import { callLoansApi, determineRoleLabel } from "../utils/msal-functions";
import { GENERIC_ERROR_MESSAGE } from "../constants/Defaults.constants";
import {
  blobToBase64,
  findInArray,
  isAllowed,
} from "../utils/utility-functions";

export default function Navbar() {
  const { instance } = useMsal();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { edittedFields, addBatchOfEdittedFields, addNewEdittedField } =
    useContext(ConfiguratorContext);
  const {
    token,
    userDetails,
    userRoles,
    visibleRole,
    setVisibleRole,
    loans,
    setLoans,
    activeLoan,
    setActiveLoan,
    activeLoanDetails,
    impersonatedUser,
    impersonatedUserDetails,
    setImpersonatedUser,
    setImpersonatedUserDetails,
  } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const inActiveOrDeadLoan = (activeLoanDetails?.loanDetails?.inActiveIndicator || activeLoanDetails?.loanDetails?.deadLoanIndicator)
  const [checked, setChecked] = useState(true);
  const [confirmPublishDialogOpen, setConfirmPublishDialogOpen] =
    useState(false);

  const [loansPopperOpen, setLoansPopperOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const [accountPopperOpen, setAccountPopperOpen] = useState(false);
  const [accountAnchorElement, setAccountAnchorElement] = useState(null);

  // Snackbar States
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(GENERIC_ERROR_MESSAGE);

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [missingLogo, setMissingLogo] = useState(false);
  const [renderedImage, setRenderedImage] = useState();
  const [imageRequestLoading, setImageRequestLoading] = useState(false);

  const isBorrower =
    userRoles?.includes(PAYMENTS_BORROWER_ROLE) ||
    userRoles?.includes(CLIENT_ADMIN_ROLE) ||
    impersonatedUser;

  const configuratorDocumentsAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_ACCESS_KEY,
  );
  const configuratorDocumentsUploadAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_UPLOAD_KEY,
  );
  const configuratorPayoffQuoteAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  );

  const canViewDocuments = isAllowed(
    configuratorDocumentsAllowField,
    "value",
    "true",
    true,
  );
  const canUploadDocuments = isAllowed(
    configuratorDocumentsUploadAllowField,
    "value",
    "true",
    true,
  );
  const canPayoffQuote = isAllowed(
    configuratorPayoffQuoteAllowField,
    "value",
    "true",
    true,
  );

  useEffect(() => {
    const getLogoData = async () => {
      if (token) {
        const currentLogoImage = edittedFields.find(
          (item) => item.key === LOGO_IMAGE_KEY,
        );
        if (currentLogoImage) {
          setSelectedLogo(currentLogoImage.value);
        } else {
          if (!imageRequestLoading) {
            callImageApi(token);
          }
        }
      }
    };
    getLogoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, edittedFields]);

  useEffect(() => {
    if (!selectedLogo) {
      setRenderedImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedLogo);
    setRenderedImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedLogo]);

  const callImageApi = async (token) => {
    setImageRequestLoading(true);
    const imageData = await downloadFile(
      token,
      LOGO_IMAGE_KEY,
      impersonatedUser,
    );
    if (imageData?.type?.includes("image")) {
      addNewEdittedField({
        key: LOGO_IMAGE_KEY,
        value: imageData,
        modified: false,
      });
      setSelectedLogo(imageData);
      setMissingLogo(false);
    } else {
      setMissingLogo(true);
    }
    setImageRequestLoading(false);
  };

  useEffect(() => {
    if (userRoles?.includes(CLIENT_ADMIN_ROLE)) {
      if (checked) {
        setVisibleRole(CLIENT_ADMIN_ROLE);
      } else {
        setVisibleRole(PAYMENTS_BORROWER_ROLE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles, checked]);

  useUnload((e) => {
    if (edittedFields.some((item) => item.modified) || impersonatedUser) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const saveLogoutMarketingItems = async () => {
    if (selectedLogo) {
      const base64Logo = await blobToBase64(selectedLogo);
      sessionStorage.setItem("_l", base64Logo);
    }
    sessionStorage.setItem(
      "_hm",
      edittedFields.find((item) => item.key === LOGOUT_HAS_MESSAGE_KEY)
        ?.value === "true",
    );
    sessionStorage.setItem(
      "_m",
      edittedFields.find((item) => item.key === LOGOUT_MESSAGE_KEY)?.value ||
        "",
    );
    sessionStorage.setItem(
      "_s",
      edittedFields.find((item) => item.key === LOGOUT_SUBTITLE_KEY)?.value ||
        "",
    );
    sessionStorage.setItem("_bc", theme.palette.secondary.main);
  };

  const logout = async () => {
    setAccountPopperOpen(false);
    addPendingRequest();
    if (edittedFields.some((item) => item.modified)) {
      const confirmationResponse = await window.confirm(
        "Are you sure you want to log out before publishing your changes?",
      );
      if (confirmationResponse) {
        const modifiedElements = edittedFields.map((item) => {
          return { ...item, modified: false };
        });
        addBatchOfEdittedFields(modifiedElements);

        saveLogoutMarketingItems();

        instance.logoutRedirect(loginRequest).catch((e) => {
          console.error(e);
        });
      } else {
        removePendingRequest();
      }
    } else {
      saveLogoutMarketingItems();
      instance.logoutRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  };

  const handlePopperClose = (e, anchorEl, setter) => {
    if (anchorEl.current && anchorEl.current.contains(e.target)) {
      return;
    }

    setter(false);
  };

  const openLoanPopper = (e) => {
    if (loans?.length > 1) {
      setAnchorElement(e.currentTarget);
      setLoansPopperOpen(true);
    }
  };

  const selectActiveLoan = (selectedLoan) => {
    setActiveLoan(selectedLoan);
    setLoansPopperOpen(false);
  };

  const toggleAccountPopper = (e) => {
    if (accountPopperOpen) {
      setAccountAnchorElement(null);
      setAccountPopperOpen(false);
      return;
    }
    setAccountAnchorElement(e.currentTarget);
    setAccountPopperOpen(true);
  };

  const accountCallback = () => {
    navigate("/profile");
    setAccountPopperOpen(false);
  };

  const impersonateCallback = () => {
    navigate("/support");
    setAccountPopperOpen(false);
  };

  const endImpersonationSessionCallback = () => {
    setImpersonatedUser(null);
    setImpersonatedUserDetails(null);
    setAccountPopperOpen(false);
    setLoansPopperOpen(false);
    setAnchorElement(null);
    setAccountAnchorElement(null);
    setVisibleRole(userRoles[0]);
    callLoansApi(
      token,
      null,
      setLoans,
      setActiveLoan,
      addPendingRequest,
      removePendingRequest,
      userRoles,
      null,
    );
    if (userRoles?.includes(CLIENT_ADMIN_ROLE)) {
      navigate("/client-configurations", { replace: true });
    } else if (userRoles?.includes(PAYMENTS_SUPPORT_ROLE)) {
      navigate("/support", { replace: true });
    }
  };

  const publishConfiguratorChanges = () => {
    const requestBody = edittedFields
      .filter((item) => item.modified && !item.key.includes("IMAGE"))
      .map((item) => {
        return {
          operation: "POST",
          property: {
            key: item.key,
            value: item.value,
          },
        };
      });

    const fileRequestBody = edittedFields
      .filter((item) => item.modified && item.key.includes("IMAGE"))
      .map((item) => {
        return {
          id: item.key,
          file: item.value,
        };
      });

    callPostConfigApi(token, requestBody, fileRequestBody);
    setConfirmPublishDialogOpen(false);
  };

  const callPostConfigApi = async (token, requestBody, fileRequestBody) => {
    addPendingRequest();
    await postBatchConfig(token, requestBody, impersonatedUser);

    fileRequestBody.forEach(async (item) => {
      const uploadResponse = await uploadFile(token, item, impersonatedUser);
      if (uploadResponse?.message) {
        setSnackbarMessage(uploadResponse.message);
        setSnackbarOpen(true);
      }
    });

    removePendingRequest();
    const modifiedElements = edittedFields.map((item) => {
      return { ...item, modified: false };
    });
    addBatchOfEdittedFields(modifiedElements);
  };

  return (
    <CustomNavbar position="static">
      <FlexContainer justifycontent="space-between" width="100%">
        <FlexContainer
          justifycontent="flex-start"
          gap="50px"
          padding="10px 15px"
          flexshrink="1"
        >
          {renderedImage || missingLogo ? (
            <Logo
              src={missingLogo ? "/MB-no-padding-white.png" : renderedImage}
              clickAction={(e) => navigate("/")}
              cursor="pointer"
            />
          ) : (
            <Box width="150px" />
          )}
          {isBorrower ? (
            <>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                Dashboard
              </Link>
              {!inActiveOrDeadLoan ? (
              <Link
                to="/payments"
                className={
                  location.pathname.includes("/payments") ? "active" : ""
                }
              >
                Payments
              </Link>
              ) : null}
              {canViewDocuments || canUploadDocuments || canPayoffQuote ? (
                <Link
                  to="/statements-documents"
                  className={
                    location.pathname.includes("/statements-documents")
                      ? "active"
                      : ""
                  }
                >
                  {"Statements & Documents"}
                </Link>
              ) : null}
            </>
          ) : null}
          {userRoles?.includes(CLIENT_ADMIN_ROLE) && !impersonatedUser ? (
            <Link
              to="/client-configurations"
              className={
                location.pathname.includes("/client-configurations")
                  ? "active"
                  : ""
              }
            >
              Client Configurations
            </Link>
          ) : null}
          {userRoles?.includes(CLIENT_ADMIN_ROLE) && !impersonatedUser ? (
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} />}
                label={determineRoleLabel(visibleRole)}
              />
            </FormGroup>
          ) : null}
        </FlexContainer>
        <FlexContainer
          background={impersonatedUser ? theme.palette.info.main : "#373737"}
          padding="0 10px"
          flexshrink="5"
          alignself="stretch"
          borderradius="0"
          gap="10px"
        >
          {userRoles?.includes(CLIENT_ADMIN_ROLE) &&
          edittedFields.some((item) => item.modified) ? (
            <PillButton
              variant="contained"
              color="primary"
              onClick={(e) => setConfirmPublishDialogOpen(true)}
            >
              Publish
            </PillButton>
          ) : (
            <FlexContainer
              gap="5px"
              sx={{
                cursor: loans?.length > 1 ? "pointer" : "default",
                paddingRight: impersonatedUserDetails ? "25px" : 0,
              }}
              onClick={openLoanPopper}
            >
              <Typography>
                {activeLoan === "no-loan" ? "" : activeLoan}
              </Typography>
              {loans?.length > 1 ? (
                <>
                  <KeyboardArrowDownIcon
                    sx={{
                      border: "1px solid transparent",
                      borderRadius: "5px",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                  />
                  <Popper
                    open={loansPopperOpen}
                    anchorEl={anchorElement}
                    placement="bottom-start"
                    transition
                    sx={{ zIndex: 10 }}
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper
                          sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            marginTop: 1,
                          }}
                        >
                          <ClickAwayListener
                            onClickAway={(e) =>
                              handlePopperClose(
                                e,
                                anchorElement,
                                setLoansPopperOpen,
                              )
                            }
                          >
                            <Stack>
                              {loans.map((item) => (
                                <MenuItem
                                  key={item}
                                  onClick={(e) => selectActiveLoan(item)}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Stack>
                          </ClickAwayListener>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </>
              ) : (
                ""
              )}
            </FlexContainer>
          )}
          {impersonatedUserDetails ? (
            <Avatar
              onClick={toggleAccountPopper}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                border: `1px solid ${theme.palette.background.default}`,
                cursor: "pointer",
                position: "absolute",
                right: "35px",
                zIndex: 1000,
              }}
            >
              {impersonatedUserDetails?.displayName?.substring(0, 1) || "U"}
            </Avatar>
          ) : null}
          <Avatar
            onClick={toggleAccountPopper}
            sx={{
              backgroundColor: theme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            {userDetails?.name?.substring(0, 1) || "U"}
          </Avatar>
          <AccountPopper
            accountPopperOpen={accountPopperOpen}
            anchorElement={accountAnchorElement}
            handleAccountPopperClose={(e) =>
              handlePopperClose(e, accountAnchorElement, setAccountPopperOpen)
            }
            accountCallback={accountCallback}
            settingsCallback={() => {}}
            impersonateCallback={impersonateCallback}
            endImpersonationSessionCallback={endImpersonationSessionCallback}
            logoutCallback={logout}
          />
        </FlexContainer>
      </FlexContainer>
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={(e) => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
      />
      <ConfirmationDialog
        open={confirmPublishDialogOpen}
        close={(e) => publishConfiguratorChanges()}
        dismiss={(e) => setConfirmPublishDialogOpen(false)}
        title="Publish changes"
        body={PUBLISH_CHANGES_CONFIRMATION_BODY}
        confirmButtonLabel="Yes"
        dismissButtonLabel="No"
      />
    </CustomNavbar>
  );
}
