import React from "react";
import { Box, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import TwoToneItem from "../../../shared/components/dashboard/TwoToneItem";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";

const DashboardSummaryCard = ({
  Icon = <></>,
  iconColor = "inherit",
  iconSize = "medium",
  label = "",
  breakpoint = " ",
  data = "",
  prefix = "",
  valueFontSize = "1rem",
  prefixFontSize = "0.8571rem",
  hasExtraInfo = false,
  clickAction = () => {},
  cursor = "default",
  background = "transparent",
}) => {
  return (
    <FlexContainer
      flexdirection="column"
      width="100%"
      height="100%"
      padding="10px"
      background={background}
      gap="10px"
      alignitems="flex-start"
      borderradius="inherit"
      onClick={clickAction}
      sx={{ cursor }}
    >
      <Icon color={iconColor} sx={{ fontSize: iconSize }} />
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography variant="h6" fontWeight="bold">
          {label}
        </Typography>
        <TwoToneItem
          breakpoint={breakpoint}
          data={data}
          prefix={prefix}
          toneOne="primary"
          toneTwo="primary"
          fontSize={valueFontSize}
          prefixFontSize={prefixFontSize}
        />
      </FlexContainer>
      {hasExtraInfo ? (
        <FlexContainer width="100%" justifycontent="flex-end" gap="5px">
          <Typography fontSize="12px" color="text.hint">
            Learn More
          </Typography>
          <HelpOutlineOutlinedIcon fontSize="small" color="disabled" />
        </FlexContainer>
      ) : (
        <Box width="100%" height="18px" />
      )}
    </FlexContainer>
  );
};

export default DashboardSummaryCard;
