import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const PageTitle = styled((props) => <Typography {...props} />)`
  padding: 35px 100px;
`;

export const SectionTitle = styled((props) => <Typography {...props} />)`
  align-self: flex-start;
  padding-bottom: 1.25rem;
  ${(props) => props.paddingTop && `
    padding-top: ${props.paddingTop};
  `}
`;

export const ActionText = styled((props) => <Box {...props} />)`
  > * {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }

    &:focus {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;

export const TypographyWithMarkdown = styled((props) => (
  <Typography {...props} />
))`
  & .markdown {
    display: inline;
    & > p {
      display: inline;
    }
  }
`;
