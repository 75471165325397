import { LogLevel } from "@azure/msal-browser";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) =>
    searchParams.get(prop) || sessionStorage.getItem(prop),
});

export const msalConfig = {
  auth: {
    clientId: "d7781ae9-5baf-41fd-ad35-97c02617391b",
    authority: `https://${window._env_.AD_B2C_TENANT}.b2clogin.com/${window._env_.AD_B2C_TENANT}.onmicrosoft.com/${window._env_.AD_B2C_POLICY}`,
    knownAuthorities: [`${window._env_.AD_B2C_TENANT}.b2clogin.com`],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["openid", "profile"],
  extraQueryParameters: { co: params.co },
};

export const unifiedRequest = {
  scopes: [
    `https://${window._env_.AD_B2C_TENANT}.onmicrosoft.com/consumer-portal-API/Read`,
  ],
};

export const passwordResetFlow = {
  authority: `https://${window._env_.AD_B2C_TENANT}.b2clogin.com/${window._env_.AD_B2C_TENANT}.onmicrosoft.com/B2C_1A_PASSWORDRESET_RBAC`,
  postLogoutRedirectUri: `${window.location.origin}`,
};
