import { Divider } from "@mui/material";
import React from "react";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import {
  ADVERT_HYPERLINK_KEY,
  ADVERT_IMAGE_KEY,
  ADVERT_MESSAGE_KEY,
  LOGO_IMAGE_KEY,
  THEME_BACKGROUND_COLOR_KEY,
  THEME_ERROR_COLOR_KEY,
  THEME_INFO_COLOR_KEY,
  THEME_PAPER_COLOR_KEY,
  THEME_PRIMARY_COLOR_KEY,
  THEME_SECONDARY_COLOR_KEY,
  THEME_SUCCESS_COLOR_KEY,
  THEME_WARNING_COLOR_KEY,
} from "../../../shared/constants/Configurator.constants";
import LogoSetting from "./components/LogoSetting";
import ThemeChangeSetting from "./components/ThemeChangeSetting";
import LoginAdvert from "./components/LoginAdvert";
import LogoutMessage from "./components/LogoutMessage";

const ConfigurationBrandingCategory = ({ elementKey = "default" }) => {
  return (
    <FlexContainer
      flexdirection="column"
      width="100%"
      maxwidth="1720px"
      alignitems="center"
      padding="25px 100px"
      gap="20px"
    >
      <Divider flexItem />
      <LogoSetting label="Logo" elementKey={LOGO_IMAGE_KEY} />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Primary color"
        paletteOption="primary"
        elementKey={THEME_PRIMARY_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Secondary color"
        paletteOption="secondary"
        elementKey={THEME_SECONDARY_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Background color"
        paletteOption="background"
        elementKey={THEME_BACKGROUND_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Foreground color"
        paletteOption="paper"
        elementKey={THEME_PAPER_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Info color"
        paletteOption="info"
        elementKey={THEME_INFO_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Success color"
        paletteOption="success"
        elementKey={THEME_SUCCESS_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Warning color"
        paletteOption="warning"
        elementKey={THEME_WARNING_COLOR_KEY}
      />
      <Divider flexItem />
      <ThemeChangeSetting
        label="Error color"
        paletteOption="error"
        elementKey={THEME_ERROR_COLOR_KEY}
      />
      <Divider flexItem />
      <LoginAdvert
        label={"Login Advert"}
        imageElementKey={ADVERT_IMAGE_KEY}
        messageElementKey={ADVERT_MESSAGE_KEY}
        hyperlinkKey={ADVERT_HYPERLINK_KEY}
      />
      <Divider flexItem />
      <LogoutMessage />
      <Divider flexItem />
    </FlexContainer>
  );
};

export default ConfigurationBrandingCategory;
