import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
  const [theme, setTheme] = useState({
    palette: {
      type: "light",
      primary: {
        main: "#5312f4",
      },
      secondary: {
        main: "#2d1d52",
      },
      background: {
        default: "#f3f3f3",
        paper: "#ffffff",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.45)",
        disabled: "rgba(0, 0, 0, 0.18)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
      error: {
        main: "#f44336",
      },
      warning: {
        main: "#ff9800",
      },
      info: {
        main: "#2196f3",
      },
      success: {
        main: "#4caf50",
      },
    },
    typography: {
      fontFamily: "Nunito Sans",
      h1: {
        fontSize: "2.285rem",
        fontWeight: "bold",
      },
      h2: {
        fontSize: "1.714rem",
        fontWeight: "bold",
      },
    },
  });
  const [requestPendingCount, setRequestPendingCount] = useState(0);

  const addPendingRequest = () => {
    setRequestPendingCount(
      (previousRequestPendingCount) => previousRequestPendingCount + 1,
    );
  };

  const removePendingRequest = () => {
    setRequestPendingCount(
      (previousRequestPendingCount) => previousRequestPendingCount - 1,
    );
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        requestPendingCount,
        addPendingRequest,
        removePendingRequest,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
