import React from "react";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import UserAccountRequirements from "./components/user-account-requirements/UserAccountRequirements";
import UserManagement from "./components/user-management/UserManagement";

const ConfigurationUserCategory = () => {
  return (
    <FlexContainer
      flexdirection="column"
      width="100%"
      maxwidth="1720px"
      alignitems="flex-start"
      padding="25px 100px"
      gap="20px"
    >
      <UserManagement />
      <UserAccountRequirements />
    </FlexContainer>
  );
};

export default ConfigurationUserCategory;
