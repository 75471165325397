import { TextField, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { ConfiguratorContext } from "../../../../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../../../../shared/components/styled/Container.styled";
import {
  NOTIFICATIONS_EMAIL_ADDRESS_CHANGE_KEY,
  NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_KEY,
  NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_LABEL,
  NOTIFICATIONS_EMAIL_PAYOFF_QUOTE_KEY,
  USER_ACCOUNT_REQUIREMENTS_FORM_LABEL,
  USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_LABEL,
  USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_LABEL,
  USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
  USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_LABEL,
} from "../../../../../../shared/constants/Configurator.constants";
import {
  EMAIL_REGEX,
  ERROR_EMAIL_INVALID,
} from "../../../../../../shared/constants/Login.constants";
import { useDebouncedEffect } from "../../../../../../shared/hooks/useDebouncedEffect";
import { useIsMount } from "../../../../../../shared/hooks/useIsMount";
import {
  formatPhoneNumber,
  hex2rgba,
  removeNonDigits,
} from "../../../../../../shared/utils/utility-functions";

const CompanyDetailsForm = () => {
  const theme = useTheme();
  const isFirstRender = useIsMount();

  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(
    edittedFields.find(
      (item) => item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
    )
      ? formatPhoneNumber(
          edittedFields.find(
            (item) =>
              item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
          ).value,
        )
      : "",
  );
  const [companyContactUsURL, setCompanyContactUsURL] = useState(
    edittedFields.find(
      (item) => item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY,
    )?.value || "",
  );
  const [companyHelpURL, setCompanyHelpURL] = useState(
    edittedFields.find(
      (item) => item.key === USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY,
    )?.value || "",
  );
  const [addressChangeNotificationEmail, setAddressChangeNotificationEmail] =
    useState(
      edittedFields.find(
        (item) => item.key === NOTIFICATIONS_EMAIL_ADDRESS_CHANGE_KEY,
      )?.value || "",
    );
  const [payoffQuoteNotificationEmail, setPayoffQuoteNotificationEmail] =
    useState(
      edittedFields.find(
        (item) => item.key === NOTIFICATIONS_EMAIL_PAYOFF_QUOTE_KEY,
      )?.value || "",
    );
  const [documentUploadNotificationEmail, setDocumentUploadNotificationEmail] =
    useState(
      edittedFields.find(
        (item) => item.key === NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_KEY,
      )?.value || "",
    );

  const [companyPhoneNumberErrorMessage, setCompanyPhoneNumberErrorMessage] =
    useState("");
  const [companyContactUsURLErrorMessage, setCompanyContactUsURLErrorMessage] =
    useState("");
  const [companyHelpURLErrorMessage, setCompanyHelpURLErrorMessage] =
    useState("");
  const [
    addressChangeNotificationEmailErrorMessage,
    setAddressChangeNotificationEmailErrorMessage,
  ] = useState("");
  const [
    payoffQuoteNotificationEmailErrorMessage,
    setPayoffQuoteNotificationEmailErrorMessage,
  ] = useState("");
  const [
    documentUploadNotificationEmailErrorMessage,
    setDocumentUploadNotificationEmailErrorMessage,
  ] = useState("");

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_KEY,
          value: companyPhoneNumberErrorMessage
            ? ""
            : removeNonDigits(companyPhoneNumber),
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [companyPhoneNumber, companyPhoneNumberErrorMessage],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_KEY,
          value: companyContactUsURLErrorMessage ? "" : companyContactUsURL,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [companyContactUsURL, companyContactUsURLErrorMessage],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_KEY,
          value: companyHelpURLErrorMessage ? "" : companyHelpURL,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [companyHelpURL, companyHelpURLErrorMessage],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: NOTIFICATIONS_EMAIL_ADDRESS_CHANGE_KEY,
          value: addressChangeNotificationEmailErrorMessage
            ? ""
            : addressChangeNotificationEmail,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      addressChangeNotificationEmail,
      addressChangeNotificationEmailErrorMessage,
    ],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: NOTIFICATIONS_EMAIL_PAYOFF_QUOTE_KEY,
          value: payoffQuoteNotificationEmailErrorMessage
            ? ""
            : payoffQuoteNotificationEmail,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [payoffQuoteNotificationEmail, payoffQuoteNotificationEmailErrorMessage],
    200,
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        addNewEdittedField({
          key: NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_KEY,
          value: documentUploadNotificationEmailErrorMessage
            ? ""
            : documentUploadNotificationEmail,
          modified: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      documentUploadNotificationEmail,
      documentUploadNotificationEmailErrorMessage,
    ],
    200,
  );

  const validateInputValue = (e, errorMessageSetter) => {
    if (e?.target?.validity?.valid === false) {
      errorMessageSetter(e.target.validationMessage);
      return;
    }
    errorMessageSetter("");
  };

  const validateEmailValue = (e, errorMessageSetter) => {
    if (!EMAIL_REGEX.test(e.target.value)) {
      errorMessageSetter(ERROR_EMAIL_INVALID);
      return;
    }
    errorMessageSetter("");
  };

  const handlePhoneInput = (value) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    setCompanyPhoneNumber(formattedPhoneNumber);
  };

  return (
    <FlexContainer
      flexdirection="column"
      width="100%"
      alignitems="flex-start"
      gap="25px"
    >
      <Typography fontWeight="bold" fontSize={16}>
        {USER_ACCOUNT_REQUIREMENTS_FORM_LABEL}
      </Typography>
      <FlexContainer
        background={hex2rgba(theme.palette.primary.main, 0.1)}
        padding="25px"
        width="100%"
        flexdirection="column"
        gap="15px"
      >
        <FlexContainer width="100%" gap="25px" justifycontent="space-between">
          <Typography fontWeight="bold" sx={{ flexGrow: 3 }}>
            {USER_ACCOUNT_REQUIREMENTS_TENANT_PHONE_NUMBER_LABEL}
          </Typography>
          <TextField
            variant="standard"
            label="Phone no."
            value={companyPhoneNumber}
            onChange={(e) => handlePhoneInput(e.target.value)}
            onBlur={(e) =>
              validateInputValue(e, setCompanyPhoneNumberErrorMessage)
            }
            error={!!companyPhoneNumberErrorMessage}
            helperText={companyPhoneNumberErrorMessage}
          ></TextField>
        </FlexContainer>
        <FlexContainer width="100%" gap="25px" justifycontent="space-between">
          <Typography fontWeight="bold" sx={{ flexGrow: 3 }}>
            {USER_ACCOUNT_REQUIREMENTS_TENANT_CONTACT_URL_LABEL}
          </Typography>
          <TextField
            variant="standard"
            label="Contact Us URL"
            value={companyContactUsURL}
            onChange={(e) => setCompanyContactUsURL(e.target.value)}
            onBlur={(e) =>
              validateInputValue(e, setCompanyContactUsURLErrorMessage)
            }
            error={!!companyContactUsURLErrorMessage}
            helperText={companyContactUsURLErrorMessage}
          ></TextField>
        </FlexContainer>
        <FlexContainer width="100%" gap="25px" justifycontent="space-between">
          <Typography fontWeight="bold" sx={{ flexGrow: 3 }}>
            {USER_ACCOUNT_REQUIREMENTS_TENANT_HELP_URL_LABEL}
          </Typography>
          <TextField
            variant="standard"
            label="Help URL"
            value={companyHelpURL}
            onChange={(e) => setCompanyHelpURL(e.target.value)}
            onBlur={(e) => validateInputValue(e, setCompanyHelpURLErrorMessage)}
            error={!!companyHelpURLErrorMessage}
            helperText={companyHelpURLErrorMessage}
          ></TextField>
        </FlexContainer>
        <FlexContainer width="100%" gap="25px" justifycontent="space-between">
          <Typography fontWeight="bold" sx={{ flexGrow: 3 }}>
            {NOTIFICATIONS_EMAIL_DOCUMENT_UPLOAD_LABEL}
          </Typography>
          {/* <TextField
            fullWidth
            variant="standard"
            label="Mailing address changes"
            value={addressChangeNotificationEmail}
            onChange={(e) => setAddressChangeNotificationEmail(e.target.value)}
            onBlur={(e) =>
              validateEmailValue(
                e,
                setAddressChangeNotificationEmailErrorMessage,
              )
            }
            error={!!addressChangeNotificationEmailErrorMessage}
            helperText={addressChangeNotificationEmailErrorMessage}
          />
          <TextField
            fullWidth
            variant="standard"
            label="Payoff quotes"
            value={payoffQuoteNotificationEmail}
            onChange={(e) => setPayoffQuoteNotificationEmail(e.target.value)}
            onBlur={(e) =>
              validateEmailValue(e, setPayoffQuoteNotificationEmailErrorMessage)
            }
            error={!!payoffQuoteNotificationEmailErrorMessage}
            helperText={payoffQuoteNotificationEmailErrorMessage}
          /> */}
          <TextField
            variant="standard"
            label="Document uploads"
            value={documentUploadNotificationEmail}
            onChange={(e) => setDocumentUploadNotificationEmail(e.target.value)}
            onBlur={(e) =>
              validateEmailValue(
                e,
                setDocumentUploadNotificationEmailErrorMessage,
              )
            }
            error={!!documentUploadNotificationEmailErrorMessage}
            helperText={documentUploadNotificationEmailErrorMessage}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CompanyDetailsForm;
