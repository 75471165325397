import React from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomAlert } from "./styled/Container.styled";

const ActionSnackbar = ({
  severity = "success",
  borderColor = "transparent",
  vertical = "bottom",
  horizontal = "left",
  width = "inherit",
  isOpen = false,
  closeFn = () => {},
  snackbarLabel = "",
  hasAction = true,
  actionLabel = "",
  actionFn = () => {},
  autoHideDuration = null,
}) => {
  const actionButton = hasAction ? (
    <Button color="primary" onClick={actionFn}>
      {actionLabel}
    </Button>
  ) : (
    <IconButton onClick={closeFn}>
      <CloseIcon />
    </IconButton>
  );
  return (
    <Snackbar
      open={isOpen}
      onClose={closeFn}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={autoHideDuration}
      sx={{ width: width }}
    >
      <CustomAlert
        onClose={closeFn}
        severity={severity}
        action={actionButton}
        sx={{
          width: "100%",
          border: "1px solid",
          borderColor: borderColor,
        }}
      >
        {snackbarLabel}
      </CustomAlert>
    </Snackbar>
  );
};

export default ActionSnackbar;
