import { Box, TextField, Typography } from "@mui/material";
import {
  AbsoluteContainer,
  FlexContainer,
  Image,
  RelativeContainer,
} from "../../../../shared/components/styled/Container.styled";
import { hex2rgba } from "../../../../shared/utils/utility-functions";
import { HiddenInput } from "../../../../shared/components/styled/Form.styled";
import ImageGuidelinesTooltip from "../../../../shared/components/configurator/ImageGuidelinesTooltip";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { downloadFile } from "../../../../data/configAPI";
import {
  ADVERT_LABEL,
  ADVERT_SECONDARY_LABEL,
  HAS_ADVERT_KEY,
} from "../../../../shared/constants/Configurator.constants";
import ExpandableSetting from "../../shared/ExpandableSetting";
import { UPLOAD_SIZE_EXCEEDED } from "../../../../shared/constants/StatementsDocuments.constants";
import ActionSnackbar from "../../../../shared/components/ActionSnackbar";

const LoginAdvert = ({
  imageElementKey = "",
  messageElementKey = "",
  label = "",
  hyperlinkKey = "",
  size = 5,
}) => {
  const theme = useTheme();
  const { token } = useContext(AuthContext);
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [imageAdvert, setImageAdvert] = useState(undefined);
  const [renderedImage, setRenderedImage] = useState({});

  const [messageAdvert, setMessageAdvert] = useState(undefined);
  const [hyperlinkAdvert, setHyperlinkAdvert] = useState(undefined);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const getImageData = async () => {
      if (token) {
        const currentLogoImage = edittedFields.find(
          (item) => item.key === imageElementKey,
        );
        if (currentLogoImage) {
          setImageAdvert(currentLogoImage.value);
        } else {
          callImageApi(token);
        }
      }
    };
    getImageData();
    getMessageData();
    getHyperlinkData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getMessageData = () => {
    const currentMessage = edittedFields.find(
      (item) => item.key === messageElementKey,
    );
    if (currentMessage) {
      setMessageAdvert(currentMessage.value);
    }
  };

  const getHyperlinkData = () => {
    const currentHyperlink = edittedFields.find(
      (item) => item.key === hyperlinkKey,
    );
    if (currentHyperlink) {
      setHyperlinkAdvert(currentHyperlink.value);
    }
  };

  useEffect(() => {
    if (!imageAdvert) {
      setRenderedImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(imageAdvert);
    setRenderedImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageAdvert]);

  const callImageApi = async (token) => {
    const imageData = await downloadFile(token, imageElementKey);
    if (imageData?.type?.includes("image")) {
      addNewEdittedField({
        key: imageElementKey,
        value: imageData,
        modified: false,
      });
      setImageAdvert(imageData);
    }
  };

  const uploadImageAdvert = (event) => {
    const newFile = event?.target?.files?.[0];
    if (newFile) {
      if (newFile.size <= size * 1e6) {
        addNewEdittedField({
          key: imageElementKey,
          value: event.target.files[0],
          modified: true,
        });
        setImageAdvert(event?.target?.files?.[0]);
      } else {
        setSnackbarMessage(UPLOAD_SIZE_EXCEEDED(size));
        setSnackbarOpen(true);
      }
    }
  };

  const changeMessage = (e) => {
    addNewEdittedField({
      key: messageElementKey,
      value: e.target.value,
      modified: true,
    });
    setMessageAdvert(e.target.value);
  };

  const changeHyperlink = (e) => {
    addNewEdittedField({
      key: hyperlinkKey,
      value: e.target.value,
      modified: true,
    });
    setHyperlinkAdvert(e.target.value);
  };

  //todo: Remove image from Login Advert
  // const removeImage = () => {
  //   addNewEdittedField({
  //     key: imageElementKey,
  //     value: null,
  //     modified: true,
  //   });
  //   setImageAdvert(undefined);
  //   console.log(edittedFields)
  // }

  return (
    <>
      <ExpandableSetting
        elementKey={HAS_ADVERT_KEY}
        mainLabel={ADVERT_LABEL}
        expandCondition="true"
        secondaryLabel={ADVERT_SECONDARY_LABEL}
        expandedComponent={
          <FlexContainer
            width="100%"
            flexdirection="column"
            background={hex2rgba(theme.palette.primary.main, 0.1)}
            padding="10px"
          >
            <FlexContainer
              flexdirection="row"
              width="100%"
              padding="0 25px 0 0"
              gap="25px"
              justifycontent="space-between"
              margin="0 0 10px"
            >
              <Typography color="primary" fontWeight="bold" fontSize={16}>
                {label}
              </Typography>
              <RelativeContainer
                height={imageAdvert ? "auto" : "50px"}
                border={
                  imageAdvert ? "none" : `1px solid ${theme.palette.text.hint}`
                }
                aspectratio={imageAdvert ? "auto" : "10/3"}
                sx={{
                  cursor: "pointer",
                  background: imageAdvert
                    ? hex2rgba(theme.palette.secondary.main, 0.1)
                    : "transparent",
                }}
              >
                <AbsoluteContainer width="100%" height="100%">
                  <label htmlFor="advert-button-file">
                    <HiddenInput
                      accept="image/*"
                      id="advert-button-file"
                      type="file"
                      onChange={uploadImageAdvert}
                      value=""
                    />
                    <Box
                      width="100%"
                      height="100%"
                      sx={{ cursor: "pointer" }}
                    />
                  </label>
                </AbsoluteContainer>
                {imageAdvert ? (
                  <Image
                    src={renderedImage}
                    maxwidth="250px"
                    alt="Select Image advert"
                  />
                ) : (
                  ""
                )}
                <AbsoluteContainer sx={{ top: -10, right: -25 }}>
                  <FlexContainer flexdirection={"column"}>
                    <ImageGuidelinesTooltip ratio="1.89:1" placement="left" />
                    {/*todo: Remove image from Login Advert*/}
                    {/*<DoNotDisturbOnOutlinedIcon*/}
                    {/*  style={{ color: "red" }}*/}
                    {/*  onClick={removeImage}*/}
                    {/*/>*/}
                  </FlexContainer>
                </AbsoluteContainer>
              </RelativeContainer>
            </FlexContainer>
            <FlexContainer width={"100%"} margin={"0 0 10px"}>
              <TextField
                sx={{
                  width: "100%",
                }}
                variant="standard"
                label="Hyperlink"
                value={hyperlinkAdvert ? hyperlinkAdvert : ""}
                onChange={changeHyperlink}
              />
            </FlexContainer>
            <FlexContainer width={"100%"} margin={"0 0 10px"}>
              <TextField
                sx={{
                  width: "100%",
                }}
                variant="standard"
                label="Message"
                value={messageAdvert ? messageAdvert : ""}
                onChange={changeMessage}
              />
            </FlexContainer>
          </FlexContainer>
        }
      />
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={() => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </>
  );
};

export default LoginAdvert;
