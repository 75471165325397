import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { PillButton } from "../styled/Button.styled";
import { FlexContainer } from "../styled/Container.styled";
import { PORTAL_ROUTES } from "../../constants/Routes.constants";

const FooterItemsEditDialog = ({
  elementKey = "",
  open = false,
  dismiss = () => {},
  close = () => {},
  title = "",
  listElements = [],
}) => {
  const [listElementsCopy, setListElementsCopy] = useState([]);

  useEffect(() => {
    const clonedArray = listElements.map((a) => {
      return {
        ...a,
        editing: false,
        edittedLabel: a.label,
        edittedUrl: a.url,
        edittedInternalURL: a.internalURL,
        edittedInternalNavigation: a.internalNavigation,
      };
    });
    setListElementsCopy(clonedArray);
  }, [listElements]);

  const toggleEdit = (itemIndex) => {
    const clonedArray = listElementsCopy.map((a) => {
      return { ...a };
    });

    clonedArray[itemIndex].editing = !clonedArray[itemIndex].editing;

    if (!clonedArray[itemIndex].editing) {
      clonedArray[itemIndex].edittedLabel = clonedArray[itemIndex].label;
      clonedArray[itemIndex].edittedUrl = clonedArray[itemIndex].url;
      clonedArray[itemIndex].edittedInternalNavigation =
        clonedArray[itemIndex].internalNavigation;
      clonedArray[itemIndex].edittedInternalURL =
        clonedArray[itemIndex].internalURL;
    }

    setListElementsCopy(clonedArray);
  };

  const confirmEdit = (itemIndex) => {
    const clonedArray = listElementsCopy.map((a) => {
      return { ...a };
    });

    clonedArray[itemIndex].label = clonedArray[itemIndex].edittedLabel;
    clonedArray[itemIndex].url = clonedArray[itemIndex].edittedUrl;
    clonedArray[itemIndex].internalNavigation =
      clonedArray[itemIndex].edittedInternalNavigation;
    clonedArray[itemIndex].internalURL =
      clonedArray[itemIndex].edittedInternalURL;
    clonedArray[itemIndex].editing = false;

    setListElementsCopy(clonedArray);
  };

  const handleInternalNavigationToggle = (e, itemIndex) => {
    const clonedArray = listElementsCopy.map((a) => {
      return { ...a };
    });

    clonedArray[itemIndex].edittedInternalNavigation = e.target.checked;

    setListElementsCopy(clonedArray);
  };

  const handleChange = (e, itemIndex, attribute, prev) => {
    const clonedArray = listElementsCopy.map((a) => {
      return { ...a };
    });

    clonedArray[itemIndex][prev] = clonedArray[itemIndex][attribute];
    clonedArray[itemIndex][attribute] = e.target.value;

    setListElementsCopy(clonedArray);
  };

  const deleteItem = (itemIndex) => {
    const clonedArray = listElementsCopy.map((a) => {
      return { ...a };
    });

    clonedArray.splice(itemIndex, 1);
    clonedArray.forEach(
      (item, index) => (item.key = `${elementKey}-item-${index + 1}`),
    );

    setListElementsCopy(clonedArray);
  };

  const addItemInList = () => {
    const clonedArray = listElementsCopy.map((a) => {
      return { ...a };
    });

    const newItem = {
      key: `${elementKey}-item-${clonedArray.length + 1}`,
      label: "",
      edittedLabel: "",
      url: "",
      edittedUrl: "",
      internalURL: "/",
      edittedInternalURL: "/",
      editing: false,
      internalNavigation: false,
      edittedInternalNavigation: false,
    };

    clonedArray.push(newItem);

    setListElementsCopy(clonedArray);
  };

  return (
    <Dialog open={open} onClose={dismiss} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List sx={{ pt: 0 }}>
          {listElementsCopy.map((item, index) => (
            <ListItem
              key={item.key}
              secondaryAction={
                <ButtonGroup>
                  {item.editing ? (
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(e) => confirmEdit(index)}
                    >
                      <CheckIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  ) : (
                    ""
                  )}
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => toggleEdit(index)}
                  >
                    {item.editing ? (
                      <CloseIcon sx={{ fontSize: 14 }} />
                    ) : (
                      <EditIcon sx={{ fontSize: 14 }} />
                    )}
                  </IconButton>
                  <IconButton
                    color="error"
                    size="small"
                    onClick={(e) => deleteItem(index)}
                  >
                    <DeleteIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </ButtonGroup>
              }
            >
              {item.editing ? (
                <FlexContainer
                  flexdirection="column"
                  gap="5px"
                  alignitems="flex-start"
                >
                  <FlexContainer gap="10px">
                    <TextField
                      label="Item label"
                      variant="standard"
                      size="small"
                      value={item.edittedLabel}
                      onChange={(e) => handleChange(e, index, "edittedLabel")}
                    ></TextField>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={item.edittedInternalNavigation}
                            onChange={(e) =>
                              handleInternalNavigationToggle(e, index)
                            }
                          />
                        }
                        label="Portal Navigation"
                      />
                    </FormGroup>
                  </FlexContainer>
                  {item.edittedInternalNavigation ? (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id={`internal-navigation-${item.key}`}>
                        Page
                      </InputLabel>
                      <Select
                        labelId={`internal-navigation-${item.key}`}
                        id="demo-simple-select-standard"
                        value={item.edittedInternalURL}
                        onChange={(e) =>
                          handleChange(e, index, "edittedInternalURL")
                        }
                        label="Age"
                      >
                        {PORTAL_ROUTES.map((routeItem) => (
                          <MenuItem
                            key={routeItem.route}
                            value={routeItem.route}
                          >
                            {routeItem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      label="External URL"
                      variant="standard"
                      size="small"
                      value={item.edittedUrl}
                      onChange={(e) => handleChange(e, index, "edittedUrl")}
                      fullWidth
                    ></TextField>
                  )}
                </FlexContainer>
              ) : (
                <Typography
                  fontWeight="bold"
                  key={item.key}
                  color={item.label ? "text" : "text.hint"}
                >
                  {item.label || "Missing Label"}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
        {listElementsCopy.length < 4 ? (
          <Button
            fullWidth
            variant="outlined"
            onClick={addItemInList}
            sx={{ alignSelf: "center" }}
          >
            <AddIcon />
          </Button>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <PillButton variant="outlined" onClick={dismiss}>
          Cancel
        </PillButton>
        <PillButton
          color="primary"
          variant="contained"
          onClick={() => close(listElementsCopy)}
        >
          Save
        </PillButton>
      </DialogActions>
    </Dialog>
  );
};

export default FooterItemsEditDialog;
