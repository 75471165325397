import React, { useContext, useState } from "react";
import { Input, InputAdornment, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { ThemeContext } from "../../../../../contexts/ThemeContext";
import {
  deleteUser,
  getUserByEmail,
  putNewUserRole,
} from "../../../../../data/tenantAPI";
import { FlexContainer } from "../../../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../../../shared/components/styled/Typography.styled";
import { PillButton } from "../../../../../shared/components/styled/Button.styled";
import DataTable from "../../../../../shared/components/DataTable";
import ConfirmationDialog from "../../../../../shared/components/ConfirmationDialog";
import ActionSnackbar from "../../../../../shared/components/ActionSnackbar";
import UserManagementCell from "../../../../../shared/components/table/UserManagementCell";
import {
  USER_MANAGEMENT_CHANGE_ROLE_ERROR_MESSAGE,
  USER_MANAGEMENT_DEFAULT_DELETE_MESSAGE,
  USER_MANAGEMENT_DELETE_ERROR_MESSAGE,
  USER_MANAGEMENT_FIELD_TYPES,
  USER_MANAGEMENT_HEADER_NAMES,
  USER_MANAGEMENT_NO_ACTION_MESSAGE,
  USER_MANAGEMENT_SEARCH_FAIL_MESSAGE,
  USER_MANAGEMENT_SELF_SEARCH_MESSAGE,
} from "../../../../../shared/constants/Configurator.constants";

const UserManagement = () => {
  const theme = useTheme();
  const { token, userDetails, impersonatedUser } = useContext(AuthContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const [email, setEmail] = useState("");
  const [userList, setUserList] = useState([]);
  const [emptyDataMessage, setEmptyDataMessage] = useState(
    USER_MANAGEMENT_NO_ACTION_MESSAGE,
  );

  const [deleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);
  const [deleteConfirmationPopupBody, setDeleteConfirmationPopupBody] =
    useState(USER_MANAGEMENT_DEFAULT_DELETE_MESSAGE);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const searchUser = async () => {
    addPendingRequest();
    const userResponse = await getUserByEmail(token, email, impersonatedUser);
    if (userResponse && userResponse?.id?.length > 0) {
      const tableEntry = [
        {
          userId: userResponse.id,
          firstName: userResponse.givenName,
          lastName: userResponse.surname,
          role: userResponse.roles[0],
          newRole: userResponse.roles[0],
          email: email,
          roles: [
            { label: "Borrower", value: "payments.borrower" },
            { label: "Client", value: "payments.support" },
            { label: "Client Admin", value: "client.admin" },
          ],
          actions: ["editRole", "delete"],
          anchorEl: null,
          isEditing: false,
          isMoreActionPopperOpen: false,
        },
      ];
      setUserList(tableEntry);
    } else if (userResponse && userResponse.status) {
      switch (userResponse.status) {
        case 400:
          if (userResponse.userMessage.includes("self")) {
            setEmptyDataMessage(USER_MANAGEMENT_SELF_SEARCH_MESSAGE);
          }
          break;
        case 404:
          setEmptyDataMessage(`User with email ${email} could not be found.`);
          break;
        case 500:
          setEmptyDataMessage(USER_MANAGEMENT_SEARCH_FAIL_MESSAGE);
          break;
        default:
          break;
      }
    }
    removePendingRequest();
  };

  const onKeyUpHandle = (e) => {
    if (e.key === "Enter" && email) {
      searchUser();
    }
  };

  const openPopper = (e) => {
    setPopperOpen(e);
  };

  const setPopperOpen = (e) => {
    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    const updatedUserList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        isMoreActionPopperOpen: true,
        anchorEl: e.currentTarget,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];
    setUserList(updatedUserList);
  };

  const handlePopperClose = (e) => {
    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    if (
      dataCopy[rowIndex].anchorEl.current &&
      dataCopy[rowIndex].anchorEl.contains(e.target)
    ) {
      return;
    }

    const updatedUserList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        isMoreActionPopperOpen: false,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];

    setUserList(updatedUserList);
  };

  const toggleRoleEdit = () => {
    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    const updatedUserList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        isEditing: !dataCopy[rowIndex].isEditing,
        isMoreActionPopperOpen: false,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];

    setUserList(updatedUserList);
  };

  const setRole = (newRole) => {
    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    const updatedUserList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        newRole: newRole,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];

    setUserList(updatedUserList);
  };

  const cancelChangeRole = () => {
    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    const updatedUserList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        isEditing: !dataCopy[rowIndex].isEditing,
        isMoreActionPopperOpen: false,
        newRole: dataCopy[rowIndex].role,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];

    setUserList(updatedUserList);
  };

  const changeRole = async () => {
    addPendingRequest();

    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    const changeRoleResponse = await putNewUserRole(
      token,
      userList[0].userId,
      userList[0].newRole,
      impersonatedUser,
    );
    if (changeRoleResponse?.ok) {
      const updatedUserList = [
        ...dataCopy.slice(0, rowIndex),
        {
          ...dataCopy[rowIndex],
          role: dataCopy[rowIndex].newRole,
          isEditing: false,
        },
        ...dataCopy.slice(rowIndex + 1),
      ];

      setUserList(updatedUserList);
    } else {
      setSnackbarMessage(USER_MANAGEMENT_CHANGE_ROLE_ERROR_MESSAGE);
      setSnackbarOpen(true);

      const updatedUserList = [
        ...dataCopy.slice(0, rowIndex),
        {
          ...dataCopy[rowIndex],
          newRole: dataCopy[rowIndex].role,
          isEditing: false,
        },
        ...dataCopy.slice(rowIndex + 1),
      ];

      setUserList(updatedUserList);
    }
    removePendingRequest();
  };

  const openDeletePopup = () => {
    setDeleteConfirmationPopupOpen(true);

    const dataCopy = [...userList];
    const rowIndex = dataCopy.findIndex((item) => item.email === email);

    const updatedUserList = [
      ...dataCopy.slice(0, rowIndex),
      {
        ...dataCopy[rowIndex],
        isMoreActionPopperOpen: false,
      },
      ...dataCopy.slice(rowIndex + 1),
    ];

    const deleteDialogBody = `Are you sure you want to delete ${dataCopy[rowIndex].firstName} ${dataCopy[rowIndex].lastName}? This action is permanent and cannot be reversed.`;

    setDeleteConfirmationPopupBody(deleteDialogBody);
    setUserList(updatedUserList);
  };

  const handleConfirmationClose = async () => {
    addPendingRequest();

    const deleteUserResponse = await deleteUser(
      token,
      userList[0].userId,
      impersonatedUser,
    );
    setDeleteConfirmationPopupOpen(false);

    if (deleteUserResponse?.ok) {
      setEmptyDataMessage(USER_MANAGEMENT_NO_ACTION_MESSAGE);
      setUserList([]);
    } else {
      setSnackbarMessage(USER_MANAGEMENT_DELETE_ERROR_MESSAGE);
      setSnackbarOpen(true);
    }

    removePendingRequest();
  };

  return (
    <>
      <SectionTitle variant="h2" color="primary">
        User Management
      </SectionTitle>
      <FlexContainer gap="25px">
        <Input
          placeholder="Search by email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={onKeyUpHandle}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          sx={{ width: "300px" }}
        />
        <PillButton
          disabled={!email}
          variant="contained"
          color="primary"
          onClick={(e) => searchUser()}
        >
          Search
        </PillButton>
      </FlexContainer>
      <DataTable
        noHeader
        withBorders
        size={
          userList?.[0]?.email?.toLowerCase() ===
          userDetails?.idTokenClaims?.signInName?.toLowerCase()
            ? "medium"
            : "small"
        }
        headers={USER_MANAGEMENT_HEADER_NAMES}
        rows={userList}
        emptyDataMessage={emptyDataMessage}
        rowAttributes={USER_MANAGEMENT_FIELD_TYPES}
        CellDisplayComponent={UserManagementCell}
        cellLogicCallbacks={{
          openPopper: openPopper,
          handlePopperClose: handlePopperClose,
          toggleRoleEdit: toggleRoleEdit,
          setRole: setRole,
          changeRole: changeRole,
          cancelChangeRole: cancelChangeRole,
          deleteUser: openDeletePopup,
        }}
        minWidth="350px"
      />
      <ConfirmationDialog
        open={deleteConfirmationPopupOpen}
        close={handleConfirmationClose}
        dismiss={(e) => setDeleteConfirmationPopupOpen(false)}
        title="Confirm user deletion"
        body={deleteConfirmationPopupBody}
      />
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={(e) => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
      />
    </>
  );
};

export default UserManagement;
