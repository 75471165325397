import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import FormItem from "../../../../shared/components/form/FormItem";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import { MONTHS } from "../../../../shared/constants/Payments.constants";

export default function PaymentRecurrence({
  availableDays,
  unavailableDays = {},
  selectedDay,
  selectedMonth,
  setSelectedDay,
  setSelectedMonth,
}) {
  const today = new Date();

  useEffect(() => {
    if (unavailableDays?.[selectedMonth]?.includes(selectedDay)) {
      const nextAvailableDay = availableDays.find(
        (item) => !unavailableDays[selectedMonth].includes(item.value),
      );
      if (nextAvailableDay) {
        setSelectedDay(nextAvailableDay.value);
      } else {
        setSelectedMonth(selectedMonth === 12 ? 1 : selectedMonth + 1);
        setSelectedDay(availableDays?.[0].value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, selectedMonth]);

  return (
    <FlexContainer flexdirection="column" padding="0 0 25px 0">
      <FormItem
        elementWidth="200px"
        label="Submit transaction on the"
        inputType="dropdown"
        highlightedValue={
          selectedMonth === today.getMonth() + 1 ? today.getDate() : ""
        }
        value={selectedDay}
        setValue={setSelectedDay}
        disabledOptions={unavailableDays?.[selectedMonth]}
        dropdownOptions={availableDays}
        dropdownKey="key"
        dropdownValue="value"
        dropdownLabel="label"
        hasComponent
        component={
          <Typography fontWeight="bold" minWidth="200px" textAlign="start">
            day of the month
          </Typography>
        }
      />
      <FormItem
        elementWidth="200px"
        label="Submit transaction starting"
        inputType="dropdown"
        value={selectedMonth}
        setValue={setSelectedMonth}
        dropdownOptions={MONTHS}
        dropdownKey="label"
        dropdownValue="value"
        dropdownLabel="label"
      />
    </FlexContainer>
  );
}
