import StatementsAndDocumentsComponent from "../components/StatementsAndDocuments.component";
import {
  TAX_DOCUMENTS_PAGE_DESCRIPTION_KEY,
} from "../../../shared/constants/Configurator.constants";
import {
  STATEMENTS_AND_DOCUMENTS_FIELD_TYPES,
  STATEMENTS_AND_DOCUMENTS_HEADER_NAMES,
  TAX_DOCUMENTS_NO_DATA_MESSAGE,
} from "../../../shared/constants/StatementsDocuments.constants";
import StatementsAndDocumentsCell from "../../../shared/components/table/StatementsAndDocumentsCell";
import React from "react";

export default function TaxDocuments(){

  return (
    <StatementsAndDocumentsComponent
      pageDescriptionKey={TAX_DOCUMENTS_PAGE_DESCRIPTION_KEY}
      documentType={"tax"}
      title={"Tax documents"}
      headerNames={STATEMENTS_AND_DOCUMENTS_HEADER_NAMES}
      noDataMessage={TAX_DOCUMENTS_NO_DATA_MESSAGE}
      fieldTypes={STATEMENTS_AND_DOCUMENTS_FIELD_TYPES}
      cellDisplayComponent={StatementsAndDocumentsCell}
    />
  )
}