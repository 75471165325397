import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ConfiguratorContext } from "../../../../contexts/ConfiguratorContext";
import { downloadFile } from "../../../../data/configAPI";
import ActionSnackbar from "../../../../shared/components/ActionSnackbar";
import ImageGuidelinesTooltip from "../../../../shared/components/configurator/ImageGuidelinesTooltip";
import {
  AbsoluteContainer,
  Image,
  FlexContainer,
  RelativeContainer,
} from "../../../../shared/components/styled/Container.styled";
import { HiddenInput } from "../../../../shared/components/styled/Form.styled";
import { UPLOAD_SIZE_EXCEEDED } from "../../../../shared/constants/StatementsDocuments.constants";
import { hex2rgba } from "../../../../shared/utils/utility-functions";

const LogoSetting = ({ elementKey = "", label = "", size = 5 }) => {
  const theme = useTheme();
  const { token, impersonatedUser } = useContext(AuthContext);
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [renderedImage, setRenderedImage] = useState();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const getLogoData = async () => {
      if (token) {
        const currentLogoImage = edittedFields.find(
          (item) => item.key === elementKey,
        );
        if (currentLogoImage) {
          setSelectedFile(currentLogoImage.value);
        } else {
          callImageApi(token);
        }
      }
    };
    getLogoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!selectedFile) {
      setRenderedImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setRenderedImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const callImageApi = async (token) => {
    const imageData = await downloadFile(token, elementKey, impersonatedUser);
    if (imageData?.type?.includes("image")) {
      addNewEdittedField({
        key: elementKey,
        value: imageData,
        modified: false,
      });
      setSelectedFile(imageData);
    }
  };

  const uploadLogo = (event) => {
    const newFile = event?.target?.files?.[0];
    if (newFile) {
      if (newFile.size <= size * 1e6) {
        addNewEdittedField({
          key: elementKey,
          value: event.target.files[0],
          modified: true,
        });
        setSelectedFile(event?.target?.files?.[0]);
      } else {
        setSnackbarMessage(UPLOAD_SIZE_EXCEEDED(size));
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <>
      <FlexContainer
        flexdirection="row"
        width="100%"
        padding="0 25px 0 0"
        gap="25px"
        justifycontent="space-between"
      >
        <Typography fontWeight="bold" fontSize={16}>
          {label}
        </Typography>
        <RelativeContainer
          height={selectedFile ? "auto" : "50px"}
          border={
            selectedFile ? "none" : `1px solid ${theme.palette.text.hint}`
          }
          aspectratio={selectedFile ? "auto" : "10/3"}
          sx={{
            cursor: "pointer",
            background: selectedFile
              ? hex2rgba(theme.palette.secondary.main, 0.1)
              : "transparent",
          }}
        >
          <AbsoluteContainer width="100%" height="100%">
            <label htmlFor="logo-button-file">
              <HiddenInput
                accept="image/*"
                id="logo-button-file"
                type="file"
                onChange={uploadLogo}
                value=""
              />
              <Box width="100%" height="100%" sx={{ cursor: "pointer" }} />
            </label>
          </AbsoluteContainer>
          {selectedFile ? (
            <Image src={renderedImage} maxwidth="250px" alt="Select logo" />
          ) : (
            ""
          )}
          <AbsoluteContainer sx={{ top: -10, right: -25 }}>
            <ImageGuidelinesTooltip ratio="1:1" placement="left" />
          </AbsoluteContainer>
        </RelativeContainer>
      </FlexContainer>
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={() => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
    </>
  );
};

export default LogoSetting;
