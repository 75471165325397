import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { hex2rgba } from "../../utils/utility-functions";

const FormItemDropdown = ({
  elementWidth = "100%",
  highlightedValue = "",
  value = "",
  setValue = () => {},
  disabledOptions = [],
  options = [],
  keyAttribute = "",
  valueAttribute = "",
  labelAttribute = "",
  disabled = false,
  isSmall = false,
  margin = "0",
  errorMessage = "",
}) => {
  const theme = useTheme();
  return (
    <FormControl
      variant="standard"
      sx={{ width: elementWidth, margin: margin }}
      size={isSmall ? "small" : "medium"}
    >
      <Select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        sx={{ minWidth: elementWidth }}
        MenuProps={{
          sx: { maxHeight: 350 },
        }}
        error={!!errorMessage}
      >
        {options.map((item) => (
          <MenuItem
            key={item[keyAttribute]}
            value={item[valueAttribute]}
            disabled={disabledOptions.includes(item[valueAttribute])}
            sx={{
              background:
                item[valueAttribute] === highlightedValue
                  ? hex2rgba(theme.palette.info.main, 0.1)
                  : "inherit",
            }}
          >
            {item[labelAttribute]}
          </MenuItem>
        ))}
      </Select>
      <Typography
        variant="caption"
        color="error"
        maxWidth={elementWidth.includes("%") ? "100%" : elementWidth}
        textAlign="end"
      >
        {errorMessage}
      </Typography>
    </FormControl>
  );
};

export default FormItemDropdown;
