import React from "react";
import { Typography } from "@mui/material";
import { FlexContainer } from "../../../../shared/components/styled/Container.styled";
import ColorPicker from "./ColorPicker";

const ThemeChangeSetting = ({
  label = "",
  paletteOption = "",
  elementKey = "default",
}) => {
  return (
    <FlexContainer
      flexdirection="row"
      width="100%"
      padding="0 25px 0 0"
      gap="25px"
      justifycontent="space-between"
    >
      <Typography fontWeight="bold" fontSize={16}>
        {label}
      </Typography>
      <ColorPicker
        paletteOption={paletteOption}
        elementKey={elementKey}
      ></ColorPicker>
    </FlexContainer>
  );
};

export default ThemeChangeSetting;
