import React from "react";
import { List, ListItemText, Tooltip, Typography } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const ImageGuidelinesTooltip = ({ ratio = "10:2", placement = "right" }) => {
  return (
    <Tooltip
      title={
        <>
          <Typography>
            Recommended ratio: <b>{ratio}</b>
          </Typography>
          <Typography>
            Maximum file size: <b>5MB</b>
          </Typography>
          <Typography>Extensions allowed:</Typography>
          <List sx={{ paddingLeft: 2 }}>
            <ListItemText>
              <b>APNG</b>
            </ListItemText>
            <ListItemText>
              <b>AVIF</b>
            </ListItemText>
            <ListItemText>
              <b>GIF</b>
            </ListItemText>
            <ListItemText>
              <b>JPEG</b>
            </ListItemText>
            <ListItemText>
              <b>PNG</b>
            </ListItemText>
            <ListItemText>
              <b>SVG</b>
            </ListItemText>
            <ListItemText>
              <b>WEBP</b>
            </ListItemText>
          </List>
        </>
      }
      placement={placement}
    >
      <HelpOutlineOutlinedIcon color="primary" />
    </Tooltip>
  );
};

export default ImageGuidelinesTooltip;
