import React from "react";
import { Typography } from "@mui/material";
import { FlexContainer } from "../styled/Container.styled";

const RowItem = ({
  label = "",
  labelColor = "text.secondary",
  hasInfo = false,
  InfoIcon = <></>,
  value = "",
  valueColor = "text",
  prefix = "",
}) => {
  return (
    <FlexContainer width="100%" justifycontent="space-between">
      <Typography color={labelColor} fontWeight="bold">
        {label}{" "}
        {hasInfo ? (
          <InfoIcon fontSize="small" sx={{ verticalAlign: "text-bottom" }} />
        ) : (
          ""
        )}
      </Typography>
      <Typography color={valueColor} fontWeight="bold">
        {`${prefix} `}
        {value}
      </Typography>
    </FlexContainer>
  );
};

export default RowItem;
