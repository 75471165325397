import { Container, styled, TextField, Typography } from "@mui/material";

export const ThemedTextField = styled((props) => <TextField {...props} />)`
  margin-bottom: 10px;
  width: 100%;
`;

export const LoginFormContainer = styled((props) => <Container {...props} />)`
  width: 375px;
  height: 100%;
  min-height: 320px;
  background: #fff;

  form {
    height: calc(100% - 4.5em);
  }

  h2 {
    font-weight: 500;
    padding: 0 25px;
  }
`;

export const HiddenInput = styled((props) => <input {...props} />)`
  display: none;
`;

export const FileInput = styled((props) => <input {...props} />)`
  position: ${(props) => props.position || "initial"};
  opacity: ${(props) => props.opacity || "1"};
  top: ${(props) => props.top || "auto"};
  left: ${(props) => props.left || "auto"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  cursor: ${(props) => props.cursor || "auto"};
`;

export const BorderedTypography = styled((props) => <Typography {...props} />)`
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
  width: 100%;
`;
