import { Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../../../../../contexts/ConfiguratorContext";
import FormItemDropdown from "../../../../../../shared/components/form/FormItemDropdown";
import { FlexContainer } from "../../../../../../shared/components/styled/Container.styled";
import {
  NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY,
  NOTIFICATION_PREFERENCE_OPTIONS,
} from "../../../../../../shared/constants/Configurator.constants";
import {
  ELECTRONIC_STATEMENT_NOTIFICATION_KEY,
  HAZARD_RENEWAL_NOTIFICATION_KEY,
  PAYMENT_DUE_NOTIFICATION_KEY,
  PAYMENT_PROCESSED_NOTIFICATION_KEY,
  PAYMENT_RETURNED_NOTIFICATION_KEY,
  REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY,
} from "../../../../../../shared/constants/Notifications.constants";
import { useIsMount } from "../../../../../../shared/hooks/useIsMount";
import { hex2rgba } from "../../../../../../shared/utils/utility-functions";

const NotificationPreferences = () => {
  const theme = useTheme();
  const isFirstRender = useIsMount();

  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const getNotificationPreferences = () => {
    const preferencesFromConfig = edittedFields.find(
      (item) => item.key === NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY,
    );
    if (preferencesFromConfig) {
      return preferencesFromConfig.value.split(";").map((item) => {
        const keyValue = item.split("=");
        return {
          key: keyValue[0],
          value: keyValue[1],
        };
      });
    } else {
      return [];
    }
  };

  const notificationPreferences = getNotificationPreferences();

  const [paymentDueNotification, setPaymentDueNotification] = useState(
    notificationPreferences.find(
      (item) => item.key === PAYMENT_DUE_NOTIFICATION_KEY,
    )?.value || 3,
  );
  const [paymentProcessedNotification, setPaymentProcessedNotification] =
    useState(
      notificationPreferences.find(
        (item) => item.key === PAYMENT_PROCESSED_NOTIFICATION_KEY,
      )?.value || 3,
    );
  const [paymentReturnedNotification, setPaymentReturnedNotification] =
    useState(
      notificationPreferences.find(
        (item) => item.key === PAYMENT_RETURNED_NOTIFICATION_KEY,
      )?.value || 3,
    );
  const [
    homeownersInsuranceRenewalNotification,
    setHomeownersInsuranceRenewalNotification,
  ] = useState(
    notificationPreferences.find(
      (item) => item.key === HAZARD_RENEWAL_NOTIFICATION_KEY,
    )?.value || 3,
  );
  const [electronicStatementNotification, setElectronicStatementNotification] =
    useState(
      notificationPreferences.find(
        (item) => item.key === ELECTRONIC_STATEMENT_NOTIFICATION_KEY,
      )?.value || 3,
    );
  const [realEstateTaxPaidNotification, setRealEstateTaxPaidNotification] =
    useState(
      notificationPreferences.find(
        (item) => item.key === REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY,
      )?.value || 3,
    );

  useEffect(() => {
    if (!isFirstRender) {
      let value = "";
      value += `${PAYMENT_DUE_NOTIFICATION_KEY}=${paymentDueNotification};`;
      value += `${PAYMENT_PROCESSED_NOTIFICATION_KEY}=${paymentProcessedNotification};`;
      value += `${PAYMENT_RETURNED_NOTIFICATION_KEY}=${paymentReturnedNotification};`;
      value += `${HAZARD_RENEWAL_NOTIFICATION_KEY}=${homeownersInsuranceRenewalNotification};`;
      value += `${ELECTRONIC_STATEMENT_NOTIFICATION_KEY}=${electronicStatementNotification};`;
      value += `${REAL_ESTATE_TAX_PAID_NOTIFICATION_KEY}=${realEstateTaxPaidNotification}`;
      addNewEdittedField({
        key: NOTIFICATIONS_PREFERENCES_TO_BE_ALLOWED_KEY,
        value: value,
        modified: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentDueNotification,
    paymentProcessedNotification,
    paymentReturnedNotification,
    homeownersInsuranceRenewalNotification,
    electronicStatementNotification,
    realEstateTaxPaidNotification,
  ]);

  return (
    <FlexContainer
      background={hex2rgba(theme.palette.primary.main, 0.1)}
      padding="25px"
      width="100%"
      flexdirection="column"
      gap="15px"
    >
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography fontWeight="bold">Payment due notification</Typography>
        <FormItemDropdown
          elementWidth="125px"
          value={paymentDueNotification}
          setValue={setPaymentDueNotification}
          options={NOTIFICATION_PREFERENCE_OPTIONS}
          keyAttribute="label"
          valueAttribute="value"
          labelAttribute="label"
        />
      </FlexContainer>
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography fontWeight="bold">
          Payment processed notification
        </Typography>
        <FormItemDropdown
          elementWidth="125px"
          value={paymentProcessedNotification}
          setValue={setPaymentProcessedNotification}
          options={NOTIFICATION_PREFERENCE_OPTIONS}
          keyAttribute="label"
          valueAttribute="value"
          labelAttribute="label"
        />
      </FlexContainer>
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography fontWeight="bold">Payment returned notification</Typography>
        <FormItemDropdown
          elementWidth="125px"
          value={paymentReturnedNotification}
          setValue={setPaymentReturnedNotification}
          options={NOTIFICATION_PREFERENCE_OPTIONS}
          keyAttribute="label"
          valueAttribute="value"
          labelAttribute="label"
        />
      </FlexContainer>
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography fontWeight="bold">Homeowners insurance renewal</Typography>
        <FormItemDropdown
          elementWidth="125px"
          value={homeownersInsuranceRenewalNotification}
          setValue={setHomeownersInsuranceRenewalNotification}
          options={NOTIFICATION_PREFERENCE_OPTIONS}
          keyAttribute="label"
          valueAttribute="value"
          labelAttribute="label"
        />
      </FlexContainer>
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography fontWeight="bold">
          Electronic statement notification
        </Typography>
        <FormItemDropdown
          elementWidth="125px"
          value={electronicStatementNotification}
          setValue={setElectronicStatementNotification}
          options={NOTIFICATION_PREFERENCE_OPTIONS}
          keyAttribute="label"
          valueAttribute="value"
          labelAttribute="label"
        />
      </FlexContainer>
      <FlexContainer width="100%" justifycontent="space-between">
        <Typography fontWeight="bold">
          Real Estate tax paid notification
        </Typography>
        <FormItemDropdown
          elementWidth="125px"
          value={realEstateTaxPaidNotification}
          setValue={setRealEstateTaxPaidNotification}
          options={NOTIFICATION_PREFERENCE_OPTIONS}
          keyAttribute="label"
          valueAttribute="value"
          labelAttribute="label"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default NotificationPreferences;
