import React, { useContext, useEffect, useState } from "react";
import EditableText from "../../../shared/components/configurator/EditableText";
import { findElementIncludedInArray } from "../../../shared/utils/utility-functions";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { AuthContext } from "../../../contexts/AuthContext";
import { PLACEHOLDER_DESCRIPTION } from "../../../shared/constants/Defaults.constants";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import DataTable from "../../../shared/components/DataTable";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import { getDocumentsByType } from "../../../data/statementsDocumentsAPI";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";
import { utcToZonedTime } from "date-fns-tz";

export default function StatementsAndDocumentsComponent({
  documentType = "",
  pageDescriptionKey = "",
  title = "",
  headerNames = [],
  fieldTypes = [],
  noDataMessage = "",
  cellDisplayComponent = {},
}) {
  const { userRoles, visibleRole, token, activeLoan, impersonatedUser } =
    useContext(AuthContext);
  const [description, setDescription] = useState(PLACEHOLDER_DESCRIPTION);
  const [statements, setStatements] = useState([]);
  const { edittedFields } = useContext(ConfiguratorContext);
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (token) {
      callDocumentsAPI(token);
    }
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoan, token]);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case pageDescriptionKey:
          setDescription(item.value);
          return;
        default:
          return;
      }
    });
  };

  const callDocumentsAPI = async (token) => {
    addPendingRequest();
    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        const statementsData = await getDocumentsByType(
          token,
          activeLoan,
          documentType,
          impersonatedUser,
        );

        if (statementsData?.message) {
          removePendingRequest();
          return;
        }
        changeDateFormat(statementsData);
        setStatements(statementsData);
      }
    }
    removePendingRequest();
  };

  const changeDateFormat = (data) => {
    data.map((elem) => {
      const elementDate = utcToZonedTime(elem.date, userTimeZone);
      elem.date = `${`0${elementDate.getMonth() + 1}`.slice(
        -2,
      )}/${`0${elementDate.getDate()}`.slice(-2)}/${elementDate.getFullYear()}`;
      return elem;
    });
  };

  return (
    <FlexContainer
      flexdirection="column"
      justifycontent="flex-start"
      height="100%"
      width="100%"
    >
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={pageDescriptionKey}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            pageDescriptionKey,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <SectionTitle variant="h2" color="primary" paddingTop="2.5rem">
        {title}
      </SectionTitle>
      <FlexContainer
        width="100%"
        flexdirection="column"
        alignself="flex-start"
        alignitems="flex-end"
      >
        <DataTable
          headers={headerNames}
          rows={statements}
          emptyDataMessage={statements.length === 0 ? noDataMessage : ""}
          rowAttributes={fieldTypes}
          CellDisplayComponent={cellDisplayComponent}
          minWidth="350px"
          sorting
        />
      </FlexContainer>
    </FlexContainer>
  );
}
