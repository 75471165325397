import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import {
  PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY,
  PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_KEY,
} from "../../../shared/constants/Configurator.constants";

const ExpandableSetting = ({
  elementKey = "",
  defaultValue = false,
  mainLabel = "",
  expandCondition = "",
  secondaryLabel = "",
  expandedComponent = <></>,
  expandedComponentValue = "",
  expandAnimation = false,
  expandHeight = "135px",
}) => {
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const currentField = edittedFields.find((item) => item.key === elementKey);
    if (currentField) {
      setValue(currentField.value);
    }
    if (elementKey === PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_KEY) {
      const counterValueField = edittedFields.find(
        (item) => item.key === PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY,
      );
      if (counterValueField?.value === "") {
        setValue("false");
      } else {
        setValue("true");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    let parsedValue = event.target.value;
    let parsedKey = elementKey;
    if (elementKey === PAYMENTS_ONLINE_RESTRICT_PAYMENT_IF_DELINQUENT_KEY) {
      parsedKey = PAYMENTS_ONLINE_DAYS_UNTIL_DELINQUENT_KEY;
      parsedValue =
        event.target.value === "false" ? "" : expandedComponentValue;
    }
    addNewEdittedField({
      key: parsedKey,
      value: parsedValue,
      modified: true,
    });
  };

  return (
    <FlexContainer flexdirection="column" width="100%">
      <FlexContainer
        width="100%"
        gap="25px"
        justifycontent="space-between"
        padding="0 25px 0 0"
      >
        <Typography fontWeight="bold" fontSize={16}>
          {mainLabel}
        </Typography>
        <FormControl>
          <RadioGroup row value={value} onChange={handleChange}>
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </FlexContainer>
      {expandAnimation ? (
        <FlexContainer
          flexdirection="column"
          width="100%"
          height={value === expandCondition ? expandHeight : "0"}
          alignitems="flex-start"
          sx={{ overflow: "hidden", transition: "height 0.3s ease-in-out" }}
        >
          <Typography
            fontWeight="bold"
            color="text.hint"
            padding="10px 0 10px 10px"
            sx={{ alignSelf: "flex-start" }}
          >
            {secondaryLabel}
          </Typography>
          {expandedComponent}
        </FlexContainer>
      ) : value === expandCondition ? (
        <FlexContainer
          flexdirection="column"
          width="100%"
          alignitems="flex-start"
        >
          <Typography
            fontWeight="bold"
            color="text.hint"
            padding="10px 0 10px 10px"
            sx={{ alignSelf: "flex-start" }}
          >
            {secondaryLabel}
          </Typography>
          {expandedComponent}
        </FlexContainer>
      ) : (
        ""
      )}
    </FlexContainer>
  );
};

export default ExpandableSetting;
