export const PORTAL_ROUTES = [
  { label: "Dashboard", route: "/" },
  { label: "Payments Overview", route: "/payments" },
  { label: "New Payment", route: "/payments/new" },
  { label: "Payment Activity", route: "/payments/activity" },
  { label: "Payment Methods", route: "/payments/methods" },
  { label: "New Payment Method", route: "/payments/methods/new" },
  { label: "User Profile", route: "/profile" },
  { label: "Register a loan", route: "/profile/loan" },
  { label: "Statements and Documents", route: "/statements-documents" },
];
