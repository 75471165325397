import React, { useState, useEffect, useContext } from "react";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import AddLoanForm from "./components/AddLoanForm";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import ActionSnackbar from "../../../shared/components/ActionSnackbar";
import EditableText from "../../../shared/components/configurator/EditableText";
import { ConfiguratorContext } from "../../../contexts/ConfiguratorContext";
import { PillButton } from "../../../shared/components/styled/Button.styled";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../shared/components/styled/Typography.styled";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  LOAN_DESCRIPTION,
  LOAN_FOOTER_NOTE,
  LOAN_DISCLOSURE_AGREEMENT,
  LOAN_NOT_VALID_ERROR_MESSAGE,
} from "../../../shared/constants/UserProfile.constants";
import {
  LOAN_PAGE_DESCRIPTION_KEY,
  LOAN_PAGE_FOOTER_NOTE_KEY,
  LOAN_PAGE_DISCLOSURE_AGREEMENT_KEY,
} from "../../../shared/constants/Configurator.constants";
import { postNewLoan } from "../../../data/loanAPI";
import {
  findElementIncludedInArray,
  removeNonDigits,
} from "../../../shared/utils/utility-functions";
import EditableTermsAndConditions from "../../../shared/components/configurator/EditableTermsAndConditions";
import { CLIENT_ADMIN_ROLE } from "../../../shared/constants/Roles.constants";

const NewLoan = () => {
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const {
    token,
    userRoles,
    visibleRole,
    loans,
    setLoans,
    setActiveLoan,
    impersonatedUser,
  } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const [description, setDescription] = useState(LOAN_DESCRIPTION);
  const [footerNote, setFooterNote] = useState(LOAN_FOOTER_NOTE);
  const [disclosureAgreement, setDisclosureAgreement] = useState(
    LOAN_DISCLOSURE_AGREEMENT,
  );
  const [disclosureAgreementCheckbox, setDisclosureAgreementCheckbox] =
    useState(false);

  // Input Field States
  const [loanNumber, setLoanNumber] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");

  // Field Disable States
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Dialog States
  const [confirmationDialogBody, setConfirmationDialogBody] = useState("");

  // Snackbar States
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Error Message States
  const [loanNumberErrorMessage, setLoanNumberErrorMessage] = useState("");
  const [
    socialSecurityNumberErrorMessage,
    setSocialSecurityNumberErrorMessage,
  ] = useState("");

  useEffect(() => {
    const mandatoryFields = [loanNumber, socialSecurityNumber];
    const dialogBody = `Are you sure you want to register loan number ${loanNumber} with Social Security Number ${socialSecurityNumber}?`;
    setSubmitDisabled(
      mandatoryFields.some((item) => item === "") ||
        loanNumberErrorMessage !== "" ||
        socialSecurityNumberErrorMessage !== "" ||
        (disclosureAgreement && !disclosureAgreementCheckbox),
    );
    setConfirmationDialogBody(dialogBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loanNumber,
    socialSecurityNumber,
    loanNumberErrorMessage,
    socialSecurityNumberErrorMessage,
    disclosureAgreement,
    disclosureAgreementCheckbox,
  ]);

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmationClose = () => {
    const requestBody = {
      shownLoanNumber: loanNumber,
      socialSecurityNumber: removeNonDigits(socialSecurityNumber),
    };
    saveLoan(requestBody);
    setConfirmationOpen(false);
  };

  const callLoanSaveApi = (token, requestBody) => {
    addPendingRequest();
    postNewLoan(token, requestBody, impersonatedUser).then((data) => {
      removePendingRequest();
      if (data?.valid === true) {
        if (loans.length === 0) {
          setActiveLoan(data.loanId);
        }
        setLoans([...loans, data.loanId]);
        navigate("/profile");
      } else {
        setSnackbarOpen(true);
      }
    });
  };

  const saveLoan = (requestBody) => {
    callLoanSaveApi(token, requestBody);
    fillConfiguratorFields(edittedFields);
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case LOAN_PAGE_DESCRIPTION_KEY:
          setDescription(item.value);
          return;
        case LOAN_PAGE_FOOTER_NOTE_KEY:
          setFooterNote(item.value);
          return;
        case LOAN_PAGE_DISCLOSURE_AGREEMENT_KEY:
          setDisclosureAgreement(item.value);
          return;
        default:
          return;
      }
    });
  };

  return (
    <FlexContainer flexdirection="column" alignitems="center" width="100%">
      <SectionTitle variant="h2" color="primary">
        Add a loan
      </SectionTitle>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      description ? (
        <EditableText
          renderedText={description}
          setRenderedText={setDescription}
          elementKey={LOAN_PAGE_DESCRIPTION_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            LOAN_PAGE_DESCRIPTION_KEY,
          )}
          padding="0 0 25px 0"
          fontSize={12}
          withHyperlink
        />
      ) : (
        ""
      )}
      <AddLoanForm
        loanNumber={loanNumber}
        setLoanNumber={setLoanNumber}
        socialSecurityNumber={socialSecurityNumber}
        setSocialSecurityNumber={setSocialSecurityNumber}
        loanNumberErrorMessage={loanNumberErrorMessage}
        setLoanNumberErrorMessage={setLoanNumberErrorMessage}
        socialSecurityNumberErrorMessage={socialSecurityNumberErrorMessage}
        setSocialSecurityNumberErrorMessage={
          setSocialSecurityNumberErrorMessage
        }
      />
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      disclosureAgreement ? (
        <EditableTermsAndConditions
          renderedText={disclosureAgreement}
          setRenderedText={setDisclosureAgreement}
          checkboxActive={disclosureAgreementCheckbox}
          setCheckboxActive={setDisclosureAgreementCheckbox}
          elementKey={LOAN_PAGE_DISCLOSURE_AGREEMENT_KEY}
          wasModified={findElementIncludedInArray(
            edittedFields,
            "modified",
            true,
            "key",
            LOAN_PAGE_DISCLOSURE_AGREEMENT_KEY,
          )}
          padding="25px 0"
          fontSize={12}
        />
      ) : (
        ""
      )}
      <PillButton
        color="primary"
        variant="contained"
        width="33%"
        customfontsize="1.25rem"
        onClick={(e) => setConfirmationOpen(true)}
        disabled={
          submitDisabled ||
          !!impersonatedUser ||
          userRoles?.includes(CLIENT_ADMIN_ROLE)
        }
      >
        Submit
      </PillButton>
      {(userRoles?.includes(CLIENT_ADMIN_ROLE) &&
        visibleRole === CLIENT_ADMIN_ROLE) ||
      footerNote ? (
        <>
          <Divider sx={{ paddingTop: "50px" }} flexItem />
          <EditableText
            renderedText={footerNote}
            setRenderedText={setFooterNote}
            elementKey={LOAN_PAGE_FOOTER_NOTE_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              LOAN_PAGE_FOOTER_NOTE_KEY,
            )}
            padding="5px"
            fontSize={12}
            withHyperlink
          />
        </>
      ) : (
        ""
      )}
      <ConfirmationDialog
        open={confirmationOpen}
        close={handleConfirmationClose}
        dismiss={(e) => setConfirmationOpen(false)}
        title="Confirm loan registration"
        body={confirmationDialogBody}
      />
      <ActionSnackbar
        severity="error"
        borderColor={theme.palette.error.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={(e) => {
          setSnackbarOpen(false);
        }}
        snackbarLabel={LOAN_NOT_VALID_ERROR_MESSAGE}
        hasAction={false}
      />
    </FlexContainer>
  );
};

export default NewLoan;
